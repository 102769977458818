import React from "react";
import styled from "styled-components/macro";

import {
  Card as MuiCard,
  CardHeader,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TablePagination
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Paper = styled(MuiPaper)(spacing);

const TableWrapper = styled.div`
  overflow-y: auto;
  max-width: calc(100vw - ${(props) => props.theme.spacing(12)});
`;


const DashboardTable = (props) => {

  const {
    data, 
    title, 
    columns,
    count = 0,
    rowsPerPage = 0,
    page = 1,
    onPageChange = () => {},
    showNoResults,
    onItemClick
  } = props;

  return (
  <Card mb={6}>
    <CardHeader
      title={title}
    />
    <Paper>
      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow>
              { columns.map(item => (<TableCell>{item}</TableCell>)) }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              !showNoResults
              ?
              data.map((item) => (
                <TableRow style={{ cursor: 'pointer' }} key={item.id} onClick={() => onItemClick(item.id)}>
                  <TableCell component="th" scope="row">
                    {item?.data[0]}
                  </TableCell>
                  <TableCell>{item?.data[1]}</TableCell>
                  <TableCell>{item?.data[2]}</TableCell>
                </TableRow>
              ))
              :
              ''
            }
          </TableBody>
        </Table>
        {
          showNoResults
          ?
          <div style={{ fontStyle: 'italic', textAlign: 'center', marginTop: 20 }}>No results...</div>
          :
          ''
        }
        <TablePagination
          rowsPerPageOptions={[]}
          component="div"
          count={count}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={onPageChange}
        />
      </TableWrapper>
    </Paper>
  </Card>
  );  
};

export default DashboardTable;