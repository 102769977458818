import { 
  Document, 
  Page, 
  View, 
  Text,
  Image
} from '@react-pdf/renderer';
import moment from 'moment';
import getUSDFormattedCurrency from '../../../utils/getUSDFormattedCurrency';
import loadStopTypes from "../../../keys/loadStopTypes";

const InvoicePDFDocument = ({ load, company, currentLoggedUser }) => {

  const currentSelectedLoad = load;

  let deliveryName = '';
  let deliveryTime = '';
  let pickupName = '';
  let pickupTime = '';
  const stopsData = [];

  if (currentSelectedLoad?.loadStops?.length) {
    currentSelectedLoad.loadStops.forEach((item) => {
      if (item.type === loadStopTypes.customer.key) {
        if (item?.companyLocation?.addressName) {
          deliveryName = item.companyLocation.addressName;
          deliveryTime = item.deliveredAt ? moment(item.deliveredAt).format("MM/DD/YYYY HH:mm") : '';
        }
      }
      if (item.type === loadStopTypes.location.key) {
        if (item?.companyLocation?.addressName) {
          pickupName = item.companyLocation.addressName;
          pickupTime = item.pickedUpAt ? moment(item.pickedUpAt).format("MM/DD/YYYY HH:mm") : '';
        }
      }
      if (item.type === loadStopTypes.stop.key) {
        stopsData.push({ 
          stopName: item?.companyLocation?.addressName,
          stopArrivalTime: item?.arrivedAt ? moment(item.arrivedAt).format("MM/DD/YYYY HH:mm") : '',
          stopDepartureTime: item?.departuredAt ? moment(item.departuredAt).format("MM/DD/YYYY HH:mm") : ''
        });
      }
    });
  }

  let invoicedAt = currentSelectedLoad?.invoicedAt;
  let dueDate = currentSelectedLoad?.invoiceDueDate;

  if (company?.quickbooksIsOauth2Logged) {
    if (currentSelectedLoad?.quickbooksInvoiceData?.MetaData?.CreateTime) invoicedAt = currentSelectedLoad.quickbooksInvoiceData.MetaData.CreateTime;
    if (currentSelectedLoad?.quickbooksInvoiceData?.DueDate) dueDate = currentSelectedLoad.quickbooksInvoiceData.DueDate;
  }
  
  return (
    <Document subject="Invoice">
      <Page style={{ 
        padding: 20,
        fontFamily: "Helvetica",
        color: "#000",
        fontSize: 11
      }}>
        <View>
          <View
            style={{
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginBottom: 20
            }}
          >
            <View>
              <Text style={{ fontFamily: "Helvetica-Bold",  letterSpacing: 2, fontSize: 14 }}>INVOICE</Text>
            </View>
          </View>
          <View
            style={{
              borderBottom: '0.5px solid #aaa', 
              paddingBottom: 15,
              marginBottom: 15,
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <View>
              {
                currentSelectedLoad?.company?.name &&
                <Text style={{ fontFamily: "Helvetica-Bold", marginBottom: 2 }}>{currentSelectedLoad.company.name}</Text>
              }
              {
                currentSelectedLoad?.company?.address &&
                <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.company.address}</Text>
              }
              {
                currentSelectedLoad?.company?.city &&
                <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.company.city}</Text>
              }
              {
                currentSelectedLoad?.company?.state &&
                <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.company.state}</Text>
              }
              {
                currentSelectedLoad?.company?.zip &&
                <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.company.zip}</Text>
              }
              {
                currentSelectedLoad?.company?.email &&
                <Text style={{ marginBottom: 2 }}>
                  {currentSelectedLoad.company.email}
                </Text>
              }
              {
                currentSelectedLoad?.company?.phoneNumber &&
                <Text style={{ marginBottom: 2 }}>
                  +1 {currentSelectedLoad.company.phoneNumber}
                </Text>
              }
            </View>
            <View>
              {
                currentLoggedUser?.company?.logoPath &&
                <View style={{ width: 100, marginBottom: 5 }}>
                  <Image 
                    src={currentLoggedUser?.company?.logoPath} 
                    style={{ maxWidth: '100%', maxHeight: '100%' }} 
                  />
                </View>
              }
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ marginRight: 20, flex: 1 }}>
              <Text style={{ marginBottom: 5 }}>BILL TO</Text>
              {
                currentSelectedLoad?.companyCustomer?.logoPath &&
                <View style={{ width: 100, marginBottom: 5 }}>
                  <Image 
                    src={currentSelectedLoad?.companyCustomer?.logoPath} 
                    style={{ maxWidth: '100%', maxHeight: '100%' }} 
                  />
                </View>
              }
              <View>
                {
                  currentSelectedLoad?.companyCustomer?.customerName &&
                  <Text style={{ fontFamily: "Helvetica-Bold", marginBottom: 2 }}>{currentSelectedLoad.companyCustomer.customerName}</Text>
                }
                {
                  currentSelectedLoad?.companyCustomer?.poBoxActive
                  ?
                    <>
                      <Text style={{ marginBottom: 2 }}>P.O. Box: {currentSelectedLoad.companyCustomer.poBox}</Text>
                      <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.companyCustomer.city}</Text>
                      <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.companyCustomer.state}</Text>
                      <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.companyCustomer.zip}</Text>
                    </>
                  :
                    currentSelectedLoad?.companyCustomer?.addressName &&
                    <Text style={{ marginBottom: 2 }}>{currentSelectedLoad.companyCustomer.addressName}</Text>
                }
                {
                  currentSelectedLoad?.companyCustomer?.contacts &&
                  currentSelectedLoad.companyCustomer.contacts.length > 0 &&
                  <View>
                    {
                      currentSelectedLoad.companyCustomer.contacts[0]?.firstName &&
                      <Text style={{ marginBottom: 2 }}>
                        {currentSelectedLoad.companyCustomer.contacts[0]?.firstName}  {currentSelectedLoad.companyCustomer.contacts[0]?.lastName}
                      </Text>
                    }
                    {
                      currentSelectedLoad.companyCustomer.contacts[0]?.email &&
                      <Text style={{ marginBottom: 2 }}>
                        {currentSelectedLoad.companyCustomer.contacts[0].email}
                      </Text>
                    }
                    {
                      currentSelectedLoad.companyCustomer.contacts[0]?.phoneNumber &&
                      <Text style={{ marginBottom: 2 }}>+1 {currentSelectedLoad.companyCustomer.contacts[0].phoneNumber}</Text>
                    }
                  </View>
                }
              </View>
            </View>
            <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'flex-end', textAlign: 'right' }}>
              <View>
                <Text style={{ marginBottom: 2 }}>Invoice #: {currentSelectedLoad?.id}</Text>
                <Text style={{ marginBottom: 2 }}>Date: {invoicedAt ? moment(invoicedAt).format("MM/DD/YYYY") : ''}</Text>
                <Text style={{ marginBottom: 2 }}>Due Date: {dueDate ? moment(dueDate).format("MM/DD/YYYY") : ''}</Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{ marginTop: 40, padding: 10 }}>
          <View style={{ flexDirection: 'row', justifyContent: 'space-between', borderBottom: '0.5px solid #aaa', paddingBottom: 7 }}>
            <Text style={{ fontFamily: "Helvetica-Bold", marginLeft: 15, flex: 2 }}>Load</Text>
            <Text style={{ fontFamily: "Helvetica-Bold", marginRight: 15, flex: 1, textAlign: 'right' }}>Amount</Text>
          </View>
          <View>
            <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', borderBottom: '0.5px solid #aaa', paddingBottom: 10, paddingTop: 10 }}>
              <View style={{ flex: 2 }}>
                <Text 
                  style={{ marginLeft: 15, marginBottom: 5 }}
                >
                  Ref Number: {' '}
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {currentSelectedLoad?.refNumber ?? currentSelectedLoad?.id}
                  </Text>
                </Text>

                <Text 
                  style={{ marginLeft: 15, marginBottom: 2 }}
                >
                  Pickup: {' '}
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {pickupName}
                  </Text>
                </Text>
                <Text 
                  style={{ marginLeft: 15, marginBottom: 5 }}
                >
                  Pickup Time: {' '}
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {pickupTime}
                  </Text>
                </Text>

                {
                  stopsData.map((stop, index) => (
                    <View style={{ marginBottom: 5 }}>
                      <Text 
                        style={{ marginLeft: 15, marginBottom: 2 }}
                      >
                        Stop #{index + 1}: {' '}
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {stop.stopName}
                        </Text>
                      </Text>
                      <Text 
                        style={{ marginLeft: 15, marginBottom: 2 }}
                      >
                        Arrival Time: {' '}
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {stop.stopArrivalTime}
                        </Text>
                      </Text>
                      <Text 
                        style={{ marginLeft: 15, marginBottom: 2 }}
                      >
                        Departure Time: {' '}
                        <Text style={{ fontFamily: "Helvetica-Bold" }}>
                          {stop.stopDepartureTime}
                        </Text>
                      </Text>
                    </View>
                  ))
                }

                <Text 
                  style={{ marginLeft: 15, marginBottom: 2 }}
                >
                  Delivery: {' '}
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {deliveryName}
                  </Text>
                </Text>
                <Text 
                  style={{ marginLeft: 15, marginBottom: 2 }}
                >
                  Delivery Time: {' '}
                  <Text style={{ fontFamily: "Helvetica-Bold" }}>
                    {deliveryTime}
                  </Text>
                </Text>
                {
                  company?.invoiceCustomMessage &&
                  <>
                    <Text style={{ marginLeft: 15, marginTop: 50 }}>
                      Invoice Messages:
                    </Text>
                    <Text style={{ marginLeft: 15, marginTop: 2 }}>
                      {company.invoiceCustomMessage}
                    </Text>
                  </>
                }
              </View>
              <Text style={{ marginRight: 15, flex: 1, textAlign: 'right' }}>{getUSDFormattedCurrency(currentSelectedLoad?.tripValue ? currentSelectedLoad.tripValue : 0)}</Text>
            </View>
            <View style={{ flexDirection: 'row', justifyContent: 'flex-end', paddingTop: 10 }}>
              <View>
                <Text style={{ marginRight: 15 }}>Balance Due: &nbsp;&nbsp;&nbsp;{getUSDFormattedCurrency(currentSelectedLoad?.tripValue ? currentSelectedLoad.tripValue : 0)}</Text>
              </View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );

};

export default InvoicePDFDocument;
