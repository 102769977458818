import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import { 
  getLatLng,
  getRawLocationData
} from '../../../utils/loadStopHelpers';
import loadStopTypes from '../../../keys/loadStopTypes';
import TruckDriverMarker from '../TruckDriverMarker';

import Marker from './components/Marker';

const LoadMap = (props) => {

  const {
    width,
    height,
    locationsData = [],
    polyline,
    truckDriver
  } = props;

  const googleMap = useRef(null);
  const googleMaps = useRef(null);
  const currentPolyline = useRef(null);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [googleApiLoaded, setGoogleApiLoaded] = useState(false);

  const handleSelectMarker = (markerId) => {
    const listId = selectedMarkers.find(listItem => listItem === markerId);
    if (listId) {
      setSelectedMarkers(prevState => {
        return [...prevState].filter(marker => marker !== markerId);
      });
    } else {
      setSelectedMarkers(prevState => {
        return [...prevState, markerId];
      });
    }
  }

  const handleUnselectedMarker = (markerId) => {
   setSelectedMarkers([...selectedMarkers].filter(marker => marker !== markerId));
  }

  const defaultCenter = useMemo(() => {
    const loc = {
      lat: 39.742043,
      lng: -104.991531
    };

    let changed = false;

    for(let i = 0; i < locationsData.length; i++) {
      if (
        (
          locationsData[i].type === loadStopTypes.location.key && 
          !locationsData[i].pickedUpAt 
        ) ||
        (
          locationsData[i].type === loadStopTypes.customer.key && 
          !locationsData[i].deliveredAt 
        ) ||
        (
          locationsData[i].type === loadStopTypes.stop.key &&
          (
            !locationsData[i].arrivedAt ||
            !locationsData[i].departuredAt
          )
        )
      ) {
        const data = getLatLng(locationsData[i]);
        if (data.lat && data.lng) {
          loc.lat = data.lat;
          loc.lng = data.lng;
          changed = true;
        }
        break;
      }
    }

    if (!changed && locationsData?.length) {
      const customer = locationsData.find(item => item.type === loadStopTypes.customer.key);
      if (customer) {
        const data = getLatLng(customer);
        if (data.lat && data.lng) {
          loc.lat = data.lat;
          loc.lng = data.lng;
        }
      }
    }

    return loc;
  }, [locationsData]) 

  useEffect(() => {
    if (googleMap.current && googleMaps.current) {
      if (defaultCenter?.lng && defaultCenter?.lat) {
        const center = new googleMaps.current.LatLng(defaultCenter.lat, defaultCenter.lng);
        googleMap.current.panTo(center);
      }
    }
  }, [defaultCenter]);

  const getMapOptions = useCallback((maps) => {
    return {
      fullscreenControl: false,
      mapTypeControl: true,
      mapTypeId: maps.MapTypeId.ROADMAP,
      scaleControl: true,
      scrollwheel: true,
      streetViewControl: false,
    };
  }, []);

  useEffect(() => {
    // Polyline draw.
    if (googleMaps.current && googleMap.current && googleApiLoaded && polyline) {
      if (currentPolyline?.current) currentPolyline.current.setMap(null);
      const path = new googleMaps.current.Polyline({
        path: googleMaps.current.geometry.encoding.decodePath(polyline),
        strokeColor: "#FF0000",
        strokeOpacity: 1.0,
        strokeWeight: 2,
      });
      path.setMap(googleMap.current);
      currentPolyline.current = path;
    }
  }, [polyline, googleApiLoaded]);

  return (
    <div style={{ height, width }}>
      <GoogleMapReact
        options={getMapOptions}
        bootstrapURLKeys={{ 
          key: process.env.REACT_APP_GOOGLE_API_KEY,
          libraries:['geometry'],
        }}
        defaultCenter={defaultCenter}
        defaultZoom={5}
        yesIWantToUseGoogleMapApiInternals={true}
        onGoogleApiLoaded={({ maps, map }) => {
          googleMap.current = map;
          googleMaps.current = maps;
          setGoogleApiLoaded(true);
        }}
      >
        {
          locationsData.map((loc, index) => {
            const lat = getLatLng(loc).lat;
            const lng = getLatLng(loc).lng;
            if (!lat || !lng) return null;
            return (
              <Marker 
                key={index}
                lat={lat}
                lng={lng}
                locationData={{
                  ...getRawLocationData(loc),
                  ...loc
                }}
              />
            );
          })
        }
        {
          truckDriver &&
          truckDriver.lastKnownLocationLat &&
          truckDriver.lastKnownLocationLng
          ?
            <TruckDriverMarker
              lat={truckDriver.lastKnownLocationLat}
              lng={truckDriver.lastKnownLocationLng}
              firstName={truckDriver?.firstName}
              lastName={truckDriver?.lastName}
              makeModel={truckDriver?.driverSelectedTruck?.make + ' ' + truckDriver?.driverSelectedTruck?.model}
              eldId={truckDriver.eldDriverId}
              openPopup={selectedMarkers.includes(truckDriver.id)}
              itemId={truckDriver.id}
              handleUnselectedMarker={handleUnselectedMarker}
              handleSelectMarker={handleSelectMarker}
            />
          :
            <></>
        }
      </GoogleMapReact>
    </div>
  );

};

export default LoadMap;
