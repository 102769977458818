export default {
  booked: {
    key: 'booked',
    display: 'Booked',
    color: '#9ea7ad'
  },
  assigned: {
    key: 'assigned',
    display: 'Assigned',
    color: '#2dccff'
  },
  inTransit: {
    key: 'inTransit',
    display: 'In Transit',
    color: '#ff3838'
  },
  delivered: {
    key: 'delivered',
    display: 'Delivered',
    color: '#fce83a'
  },
  invoiced: {
    key: 'invoiced',
    display: 'Invoiced',
    color: '#ffb302'
  },
  paid: {
    key: 'paid',
    display: 'Paid',
    color: '#56f000'
  },
};
