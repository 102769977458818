export default {
  oilChangeExpiration: {
    key: 'oilChangeExpiration', 
    display: 'Oil Change Due'
  },
  licenceRegistrationExpiration: { 
    key: 'licenceRegistrationExpiration',
    display: 'Licence Plate Expiration'
  },
  annualTruckAndTrailerInspection: {
    key: 'annualTruckAndTrailerInspection', 
    display: 'Inspection Due'
  },
  driverMedicalExpiration: {
    key: 'driverMedicalExpiration', 
    display: 'Driver Medical Expiration'
  },
  driverLicenceExpiration: {
    key: 'driverLicenceExpiration', 
    display: 'Driver Licence Expiration'
  },
  drivingRecordDateMVR: {
    key: 'drivingRecordDateMVR', 
    display: 'Driver Record Date (MVR)'
  },
  loadStatusChange: {
    key: 'loadStatusChange', 
    display: 'Load Status Change'
  }
};
