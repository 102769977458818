import React, { useMemo } from 'react';
import {
  Typography,
} from "@mui/material";
import loadStopTypes from '../../../keys/loadStopTypes';

const TruckDriverDetailsSection = (props) => {

  const { 
    data, 
    load
  } = props;

  const trailer = useMemo(() => {
    let trailer = '';
    if (data?.driverSelectedTrailer?.make) {
      trailer += data?.driverSelectedTrailer?.make;
    }
    if (data?.driverSelectedTrailer?.model) {
      trailer += data?.driverSelectedTrailer?.model;
    }

    const loadStops = load?.loadStops || load?.loadStopsData;
    if (loadStops?.length) {
      let lastSpecificTrailer = '';
      for (let i = 0; i < loadStops.length; i++) {
        if (
          loadStops[i]?.deliveredAt ||
          loadStops[i]?.arrivedAt ||
          loadStops[i]?.departuredAt ||
          loadStops[i]?.pickedUpAt
        ) {
          if (loadStops[i].trailer?.make) {
            lastSpecificTrailer = loadStops[i].trailer?.make;
          }
          if (loadStops[i].trailer?.model) {
            lastSpecificTrailer += loadStops[i].trailer?.make;
          }
        }
      }
      if (lastSpecificTrailer !== '') trailer = lastSpecificTrailer;
    }

    return trailer;
  }, [data, load]);

  const previousLoadAddress = useMemo(() => {
    let value = ''
    if (data?.previousLoad?.loadStops?.length) {
      const deliveryData = data.previousLoad.loadStops.find(stop => stop.type === loadStopTypes.customer.key);
      if (deliveryData?.companyLocation?.addressName) value = deliveryData.companyLocation.addressName;
    }
    return value;
  }, [data]);

  return (
    <>
      <div>
        <div>
          <Typography mt={2}>
            ID: <span style={{ fontWeight: 'bold' }}>{data.id}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            First Name: <span style={{ fontWeight: 'bold' }}>{data.firstName}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Last Name: <span style={{ fontWeight: 'bold' }}>{data.lastName}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Truck: <span style={{ fontWeight: 'bold' }}>{data?.driverSelectedTruck?.make} {data?.driverSelectedTruck?.model}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Trailer: <span style={{ fontWeight: 'bold' }}>{trailer}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Previous Load ID#: <span style={{ fontWeight: 'bold' }}>{data.previousLoadId}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Previous Load Ref Number: <span style={{ fontWeight: 'bold' }}>{data?.previousLoad?.refNumber}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Previous Load Address: <span style={{ fontWeight: 'bold' }}>{previousLoadAddress}</span>
          </Typography>
        </div>
      </div>
    </>
  );

};

export default TruckDriverDetailsSection;
