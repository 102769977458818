import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import { 
  fetchCurrentSelectedCompany,
  activateCompany,
  deactivateCompany
} from '../../redux/slices/companies';
import { resetState } from '../../redux/slices/companyAdminUsers';
import { resetState as resetBilling } from '../../redux/slices/billing';
import getUSDFormattedCurrency from '../../utils/getUSDFormattedCurrency';

import {
  Chip as MuiChip,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { spacing, display} from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.nonActive && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function CompanyDetails() {
  const navigate = useNavigate();
  const currentSelectedCompany = useSelector(state => state.companies.currentSelectedCompany);
  const params = useParams();
  const dispatch = useDispatch();

  const [showDeactivateCompanyDialog, setShowDeactivateCompanyDialog] = useState(false);
  const [showActivateCompanyDialog, setShowActivateCompanyDialog] = useState(false);

  const companyId = params && params.companyId ? params.companyId : null;

  useEffect(() => {
    if (companyId) dispatch(fetchCurrentSelectedCompany(companyId));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Company Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Company Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={""} onClick={() => navigate(-1)}>
                Companies
            </Link>
            
            <Typography>Company Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(resetBilling());
                navigate(`/companies/company-details/${companyId}/billing`);
              }}
              style={{ marginRight: 10 }}
            >
              <Icon.Receipt />
              <span style={{marginLeft:5}}> Billing </span>
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(resetState());
                navigate(`/companies/company-details/${companyId}/admin-users`);
              }}
            >
              <Icon.People />
              <span style={{marginLeft:5}}> Admin Users List </span>
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              <Table>
                <TableBody>
                <TableRow>
                    <TableCell>ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompany && currentSelectedCompany.id ? currentSelectedCompany.id : ''}</TableCell>
                  </TableRow>
                <TableRow>
                    <TableCell>Company Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompany && currentSelectedCompany.name ? currentSelectedCompany.name : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Owner First Name:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompany && currentSelectedCompany.ownerFirstName ? currentSelectedCompany.ownerFirstName : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Owner Last Name:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompany && currentSelectedCompany.ownerLastName ? currentSelectedCompany.ownerLastName : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Email:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompany && currentSelectedCompany.email ? currentSelectedCompany.email : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Load Billing Price:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{getUSDFormattedCurrency(currentSelectedCompany?.loadBillingPrice ? currentSelectedCompany.loadBillingPrice : 0)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Phone Number:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompany && currentSelectedCompany.phoneNumber ? currentSelectedCompany.phoneNumber : ''} {currentSelectedCompany && currentSelectedCompany.phoneNumberExtension ? `Ext: ${currentSelectedCompany.phoneNumberExtension}` : ''}</TableCell>
                  </TableRow>
                  {
                  currentSelectedCompany && currentSelectedCompany.address
                    ?
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Address:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedCompany.address}</TableCell>
                    </TableRow>
                    :
                      ''
                  }
                  {
                    currentSelectedCompany && currentSelectedCompany.city
                    ?
                    <TableRow>
                      <TableCell component="th" scope="row">
                        City:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedCompany.city}</TableCell>
                    </TableRow>
                    :
                      ''
                  }
                  {
                    currentSelectedCompany && currentSelectedCompany.state
                    ?
                    <TableRow>
                      <TableCell component="th" scope="row">
                        State:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedCompany.state}</TableCell>
                    </TableRow>
                    :
                      ''
                  }
                  {
                    currentSelectedCompany && currentSelectedCompany.zip
                    ?
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Zip:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedCompany.zip}</TableCell>
                    </TableRow>
                    :
                      ''
                  }
                  <TableRow>
                    <TableCell>Login Access:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                    {currentSelectedCompany && currentSelectedCompany.loginAccess && (
                          <Chip
                            
                            size="small"
                            
                            label="Active"
                            active={+true}
                          />
                        )}
                     {currentSelectedCompany && !currentSelectedCompany.loginAccess && (
                          <Chip
                            size="small"
                            
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div style={{display: "flex", justifyContent: "space-between"}}>
              <Button 
                onClick={() => {
                  navigate(`/companies/edit/${currentSelectedCompany && currentSelectedCompany.id ? currentSelectedCompany.id : ''}`);
                }}
                marginBottom={5}
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
                >
                  <Icon.Edit />
                <span style={{marginLeft:5}}> Edit </span>
              </Button>
              {
                currentSelectedCompany && 
                currentSelectedCompany.loginAccess 
                ?
                  <Button 
                    variant="outlined" 
                    color="primary"
                    marginBottom={5}
                    mt={3}
                    marginLeft={5}
                    onClick={() => setShowDeactivateCompanyDialog(true)}
                  >
                    <Icon.Close />
                      <span style={{marginLeft:5}}> Deactivate </span>
                  </Button>
                :
                  <Button 
                    variant="outlined" 
                    color="primary"
                    marginBottom={5}
                    mt={3}
                    marginLeft={5}
                    onClick={() => setShowActivateCompanyDialog(true)}
                  >
                    <Icon.Check />
                      <span style={{marginLeft:5}}> Activate </span>
                  </Button>
              }
              </div>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <Dialog
        open={showDeactivateCompanyDialog}
        onClose={() => setShowDeactivateCompanyDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate this Company?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowDeactivateCompanyDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(deactivateCompany(err => {
                  if (err) return;
                  setShowDeactivateCompanyDialog(false);
                }
              ));
            }} 
          >
            Yes, deactivate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showActivateCompanyDialog}
        onClose={() => setShowActivateCompanyDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate this Company?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowActivateCompanyDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(activateCompany(err => {
                  if (err) return;
                  setShowActivateCompanyDialog(false);
                }
              ));
            }} 
          >
            Yes, activate
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CompanyDetails;