import React from 'react';
import { Typography } from "@mui/material";

const CompanyCustomerDetailsSection = (props) => {

  const { data } = props;

  return (
    <>
      <div>
        <div>
          <Typography mt={2}>
            ID: <span style={{ fontWeight: 'bold' }}>{data?.id}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Customer Name: <span style={{ fontWeight: 'bold' }}>{data?.customerName}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            MC#: <span style={{ fontWeight: 'bold' }}>{data?.mcNumber}</span>
          </Typography>
        </div>
        {
          data?.poBoxActive
          ?
          <>
            <div>
              <Typography mt={2}>
                P.O. Box: <span style={{ fontWeight: 'bold' }}>{data?.poBox}</span>
              </Typography>
            </div>
            <div>
              <Typography mt={2}>
                City: <span style={{ fontWeight: 'bold' }}>{data?.city}</span>
              </Typography>
            </div>
            <div>
              <Typography mt={2}>
                State: <span style={{ fontWeight: 'bold' }}>{data?.state}</span>
              </Typography>
            </div>
            <div>
              <Typography mt={2}>
                Zip: <span style={{ fontWeight: 'bold' }}>{data?.zip}</span>
              </Typography>
            </div>
          </>
          :
            <div>
              <Typography mt={2}>
                Address: <span style={{ fontWeight: 'bold' }}>{data?.addressName}</span>
              </Typography>
            </div>
        }
        <div>
          <Typography mt={2}>
            Commodity Type: <span style={{ fontWeight: 'bold' }}>{data?.commodityType}</span>
          </Typography>
        </div>
        {
          data?.contacts?.length
          ?
            <div style={{ marginTop: 15 }}>
              <Typography variant="h6" gutterBottom display="inline">
                  Contact(s)
              </Typography>
              {
                data.contacts.map((contact, index) => (
                  <div style={{ border: '1px solid #eee', borderRadius: 5, padding: 10, marginBottom: 15, marginTop: index + 1 === data.contacts.length ? 0 : 15 }}>
                    <Typography mt={2}>
                      First Name: <span style={{ fontWeight: 'bold' }}>{contact?.firstName}</span>
                    </Typography>
                    <Typography mt={2}>
                      Last Name: <span style={{ fontWeight: 'bold' }}>{contact?.lastName}</span>
                    </Typography>
                    <Typography mt={2}>
                      Role: <span style={{ fontWeight: 'bold' }}>{contact?.role}</span>
                    </Typography>
                    <Typography mt={2}>
                      Email: <span style={{ fontWeight: 'bold' }}>{contact?.email}</span>
                    </Typography>
                    <Typography mt={2}>
                      Phone Number: <span style={{ fontWeight: 'bold' }}>{contact?.phoneNumber}</span>
                    </Typography>
                  </div>
                ))
              }
            </div>
          :
            ''
        }
      </div>
    </>
  );

};

export default CompanyCustomerDetailsSection;
