import React from 'react';

const BrokerDashboard = () => {

  return (
    <>
      <div style={{ textAlign: 'center', fontStyle: 'italic' }}>No stats available...</div>
    </>
  );

};

export default BrokerDashboard;
