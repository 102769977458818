import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { 
  useParams,
  useNavigate
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import { fetchCurrentSelectedCompanyCustomer } from '../../redux/slices/companyCustomers';
import { getQuickbooksCustomerById } from '../../redux/slices/companySettings';

import {
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { spacing, display} from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function CompanyCustomerDetails() {
  const navigate = useNavigate();
  const currentSelectedCompanyCustomer = useSelector(state => state.companyCustomers.currentSelectedCompanyCustomer);
  const companySettings = useSelector(state => state.companySettings);
  const historyStack = useSelector(state => state.historyStack);

  const params = useParams();
  const dispatch = useDispatch();

  const companyCustomerId = params && params.companyCustomerId ? params.companyCustomerId : null;
  const reduxTempCustomerQuickbooksData = companySettings?.integrations?.accounting?.quickbooks?.tempQuickbooksDataPerAppCustomerId ?? false;

  const finalCustomerQuickbooksData = (reduxTempCustomerQuickbooksData && companyCustomerId && reduxTempCustomerQuickbooksData[companyCustomerId]) ? reduxTempCustomerQuickbooksData[companyCustomerId] : null;

  useEffect(() => {
    if (companyCustomerId) dispatch(fetchCurrentSelectedCompanyCustomer(companyCustomerId));
  }, [companyCustomerId]);


  useEffect(() => {
    if (currentSelectedCompanyCustomer?.quickbooksCustomerId) {
      if (reduxTempCustomerQuickbooksData && reduxTempCustomerQuickbooksData[currentSelectedCompanyCustomer.id]) return;
      dispatch(getQuickbooksCustomerById({
        id: currentSelectedCompanyCustomer?.quickbooksCustomerId,
        localCustomerId: currentSelectedCompanyCustomer.id
      }));
    }
  }, [currentSelectedCompanyCustomer?.quickbooksCustomerId, currentSelectedCompanyCustomer?.id, reduxTempCustomerQuickbooksData, dispatch]);

  let contacts = [];
  try {
    if (currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.contacts) {
      if (typeof currentSelectedCompanyCustomer.contacts === 'string') {
        contacts = JSON.parse(currentSelectedCompanyCustomer.contacts);
      } else if (Array.isArray(currentSelectedCompanyCustomer.contacts)) {
        contacts = currentSelectedCompanyCustomer.contacts;
      }
    }
  } catch (e) {}

  return (
    <React.Fragment>
      <Helmet title="Customer Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Customer Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link 
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (historyStack[historyStack.length - 2]?.indexOf('company-customers') === -1) {
                  navigate('/company-customers', { replace: true });
                } else {
                  navigate(-1);
                }
              }}
            >
              Customers
            </Link>
            
            <Typography>Customer Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              {
                currentSelectedCompanyCustomer?.logoPath &&
                <div style={{ width: 150, marginTop: 22 }}>
                  <img 
                    src={currentSelectedCompanyCustomer.logoPath} 
                    alt=""
                    style={{ maxWidth: '100%', maxHeight: '100%' }} 
                  />
                </div>
              }
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.id ? currentSelectedCompanyCustomer.id : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Customer Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.customerName ? currentSelectedCompanyCustomer.customerName : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>MC#:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.mcNumber ? currentSelectedCompanyCustomer.mcNumber : ''}</TableCell>
                  </TableRow>
                  {
                    currentSelectedCompanyCustomer?.poBoxActive
                    ?
                      <>
                        <TableRow>
                          <TableCell>P.O. Box:</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.poBox ? currentSelectedCompanyCustomer.poBox : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>City:</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.city ? currentSelectedCompanyCustomer.city : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>State:</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.state ? currentSelectedCompanyCustomer.state : ''}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Zip:</TableCell>
                          <TableCell></TableCell>
                          <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.zip ? currentSelectedCompanyCustomer.zip : ''}</TableCell>
                        </TableRow>
                      </>
                    :
                      <TableRow>
                        <TableCell>Address:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.addressGeoData && currentSelectedCompanyCustomer.addressGeoData.formatted_address ? currentSelectedCompanyCustomer.addressGeoData.formatted_address : ''}</TableCell>
                      </TableRow>
                  }
                  {
                    currentSelectedCompanyCustomer && 
                    currentSelectedCompanyCustomer.commodityType
                    ?
                    <TableRow>
                      <TableCell>Commodity Type:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedCompanyCustomer.commodityType}</TableCell>
                    </TableRow>
                    :
                      ''
                  }
                  {
                    currentSelectedCompanyCustomer?.quickbooksCustomerId
                    ?
                    <>
                      <TableRow>
                        <TableCell>Quickbooks Customer ID:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{currentSelectedCompanyCustomer.quickbooksCustomerId}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quickbooks Customer First Name:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{finalCustomerQuickbooksData?.GivenName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quickbooks Customer Last Name:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{finalCustomerQuickbooksData?.FamilyName}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Quickbooks Customer Company Name:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{finalCustomerQuickbooksData?.CompanyName}</TableCell>
                      </TableRow>
                    </>
                    :
                      ''
                  }
                </TableBody>
              </Table>
              {
                contacts &&
                contacts.length > 0 &&
                <div style={{ marginTop: 15 }}>
                  <Typography variant="h6" gutterBottom display="inline">
                    Contact(s)
                  </Typography>
                  {
                    contacts.map(contact => (
                      <div key={contact.id} style={{ border: '1px solid #eee', borderRadius: 5, padding: 20, marginBottom: 15, marginTop: 15 }}>
                        <Typography mt={2}>
                          First Name: <span style={{ fontWeight: 'bold' }}>{contact.firstName}</span>
                        </Typography>
                        <Typography mt={2}>
                          Last Name: <span style={{ fontWeight: 'bold' }}>{contact.lastName}</span>
                        </Typography>
                        <Typography mt={2}>
                          Role: <span style={{ fontWeight: 'bold' }}>{contact.role}</span>
                        </Typography>
                        <Typography mt={2}>
                          Email: <span style={{ fontWeight: 'bold' }}>{contact.email}</span>
                        </Typography>
                        <Typography mt={2}>
                          Phone Number: <span style={{ fontWeight: 'bold' }}>{contact.phoneNumber} {contact.phoneNumberExtension ? `Ext: ${contact.phoneNumberExtension}` : ''}</span>
                        </Typography>
                      </div>
                    ))
                  }
                </div>
              }
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                  onClick={() => {
                    navigate(`/company-customers/edit/${currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.id ? currentSelectedCompanyCustomer.id : ''}`);
                  }}
                  marginBottom={5}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  >
                    <Icon.Edit />
                  <span style={{marginLeft:5}}> Edit </span>
                </Button>
              </div>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CompanyCustomerDetails;