import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";

import { 
  setAvailableTruckDriversFiltersSkip,
  triggerFetchDashboardAvailableTruckDrivers
} from '../../../../redux/slices/dashboards';
import getUSDFormattedCurrency from '../../../../utils/getUSDFormattedCurrency';


import Stats from "../Stats";
import LineChart from '../LineChart';
import DoughnutChart from '../DoughnutChart';
import Table from '../Table';
import WrapperWithLoader from './components/WrapperWithLoader';

import { Grid } from "@mui/material";

const CompanyAdminDashboard = () => {

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dashboards = useSelector(state => state.dashboards);
  const dashboard = dashboards?.[currentLoggedUser?.role];
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [availableTruckDriversPage, setAvailableTruckDriversPage] = useState(0);
  const handleAvailableTruckDriversChangePage = (event, newPage) => {
    const rowsPerPage = dashboard?.availableTruckDrivers?.filters?.limit ?? 0;
    const rowsSkip = dashboard?.availableTruckDrivers?.filters?.skip ?? 0;
    setAvailableTruckDriversPage(newPage);
    if (newPage > availableTruckDriversPage) {
      dispatch(setAvailableTruckDriversFiltersSkip({
        skip: rowsPerPage + rowsSkip,
        userType: currentLoggedUser?.role
      }));
    } else {
      dispatch(setAvailableTruckDriversFiltersSkip({
        skip: rowsSkip - rowsPerPage,
        userType: currentLoggedUser?.role
      }));
    }
    dispatch(triggerFetchDashboardAvailableTruckDrivers());
  };

  return (
    <Grid style={{ width: 'calc(100% - 10px)' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Available Loads"
              amount={dashboard?.data?.companyUserNumberOfAvailableLoads ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Available Truck Drivers"
              amount={dashboard?.data?.companyUserNumberOfAvailableTruckDrivers ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Available Equipment"
              amount={dashboard?.data?.companyUserNumberOfAvailableEquipment ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Oil Change Expiration"
              amount={dashboard?.data?.companyUserNumberOfOilChangeExpiration ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Medical Expiration"
              amount={dashboard?.data?.companyUserNumberOfMedicalExpiration ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch2?.requestBusy}>
            <LineChart
              title="Year Revenue"
              label="Sales ($)"
              lineData={dashboard?.data?.companyUserYearRevenue ? [...dashboard.data.companyUserYearRevenue] : []}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} lg={4}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch3?.requestBusy}>
            <DoughnutChart
              title="Top Truck Drivers"
              columns={[{0: 'Name', 1: 'Revenue ($)'}]}
              onItemClick={(item) => navigate(`/truck-drivers/truck-driver-details/${item?.user?.id}`)}
              showNoResults={dashboard?.fetchBatch3?.lastFetch && dashboard?.data?.companyUserTopTruckDriversByRevenue?.length === 0}
              circleData={
                dashboard.data.companyUserTopTruckDriversByRevenue 
                ? 
                  dashboard.data.companyUserTopTruckDriversByRevenue.map(data => data?.revenue ? parseFloat(data.revenue).toFixed(2) : 0)
                :
                  []
              }
              rowsData={
                dashboard?.data?.companyUserTopTruckDriversByRevenue 
                ? 
                  dashboard.data.companyUserTopTruckDriversByRevenue.map(data => ({
                    metaData: data,
                    0: `${data?.user?.firstName} ${data?.user?.lastName}`,
                    1: data?.revenue ? getUSDFormattedCurrency(data.revenue) : 0
                  }))
                :
                  []
                }
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} lg={4}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch3?.requestBusy}>
            <DoughnutChart
              title="Top Dispatcher (By Load Number)"
              onItemClick={(item) => navigate(`/users/user-details/${item?.dispatcher?.id}`)}
              columns={[{0: 'Name', 1: 'Loads'}]}
              showNoResults={dashboard?.fetchBatch3?.lastFetch && dashboard?.data?.companyUserTopDispatchersByLoadNumber?.length === 0}
              circleData={
                dashboard?.data?.companyUserTopDispatchersByLoadNumber
                ?
                  dashboard.data.companyUserTopDispatchersByLoadNumber.map(data => data?.loadNumbers ?? 0) 
                : 
                  []
              }
              rowsData={
                dashboard.data.companyUserTopDispatchersByLoadNumber
                ?
                  dashboard.data.companyUserTopDispatchersByLoadNumber.map(data => ({
                    metaData: data,
                    0: `${data?.dispatcher?.firstName} ${data?.dispatcher?.lastName}`,
                    1: data.loadNumbers ?? 0
                  })) 
                : 
                  []
              }
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} lg={4}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch3?.requestBusy}>
            <DoughnutChart
              title="Top Dispatcher (By Revenue)"
              onItemClick={(item) => navigate(`/users/user-details/${item?.dispatcher?.id}`)}
              columns={[{0: 'Name', 1: 'Revenue ($)'}]}
              showNoResults={dashboard?.fetchBatch3?.lastFetch && dashboard?.data?.companyUserTopDispatchersByRevenue?.length === 0}
              circleData={
                dashboard?.data?.companyUserTopDispatchersByRevenue
                ?
                  dashboard.data.companyUserTopDispatchersByRevenue.map(data => data?.revenue ? parseFloat(data.revenue).toFixed(2) : 0) 
                : 
                  []
                }
              rowsData={
                dashboard?.data?.companyUserTopDispatchersByRevenue
                ?
                  dashboard.data.companyUserTopDispatchersByRevenue.map(data => ({
                    metaData: data,
                    0: `${data?.dispatcher?.firstName} ${data?.dispatcher?.lastName}`,
                    1: data?.revenue ? getUSDFormattedCurrency(data.revenue) : 0
                  })) 
                : 
                  []
              }
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <WrapperWithLoader isLoading={dashboard?.availableTruckDrivers?.requestBusy}>
            <Table 
              title="Available Drivers"
              onItemClick={(id) => navigate(`/truck-drivers/truck-driver-details/${id}`)}
              columns={['First Name', 'Last Name', 'Address']}
              showNoResults={dashboard?.availableTruckDrivers?.initialFetch && dashboard?.availableTruckDrivers?.data?.length === 0}
              data={
                dashboard?.availableTruckDrivers?.data
                ?
                dashboard.availableTruckDrivers.data.map(data => ({
                  id: data.id,
                  data: [data?.firstName, data?.lastName, `${data?.city}, ${data?.zip} ${data?.address}`]
                })) 
              :
                []
              }
              count={dashboard?.availableTruckDrivers?.total ?? 0}
              rowsPerPage={dashboard?.availableTruckDrivers?.filters?.limit ?? 0}
              page={availableTruckDriversPage}
              onPageChange={handleAvailableTruckDriversChangePage}
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
    </Grid>
  );

};

export default CompanyAdminDashboard;
