import React, { useEffect, useState, useRef, useCallback } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import {
  useParams,
  useNavigate,
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import { Formik } from "formik";
import { BlobProvider } from '@react-pdf/renderer';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import moment from 'moment';
import InvoicePDFDocument from '../../components/PDFDocuments/InvoicePDFDocument';
import blobToPDF from '../../utils/blobToPDF';
import { setForceShowSpinner } from "../../redux/slices/forceShowSpinner";
import { 
  sendInvoicePdfEmail,
  updateInvoice
} from '../../redux/slices/invoices';
import { shipInvoiceToTheQuickbooks } from '../../redux/slices/companySettings';
import getUSDFormattedCurrency from '../../utils/getUSDFormattedCurrency';
import QbLogo  from '../../vendor/qb-logo.png';

import {
  Chip as MuiChip,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardContent,
  Button,
  TextField,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody
} from "@mui/material";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import {
  Check as CheckIcon,
  Close as CloseIcon,
  MailOutline as MailOutlineIcon,
  Download as DownloadIcon,
  Edit as EditIcon
} from "@mui/icons-material";
import { spacing, display} from "@mui/system";
import { 
  fetchCurrentSelectedLoad,
  setPaid,
  setInvoiced
} from "../../redux/slices/loads";
import loadStatusTypes from "../../keys/loadStatusTypes";
import loadStopTypes from "../../keys/loadStopTypes";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

const getSendPDFEmailInitialValues = () => ({
  emailTo: "",
  emailSubject: "",
  emailBody: "",
  attachPod: true,
});

function InvoiceDetails() {
  const navigate = useNavigate();
  const formikRef = useRef();
  const [sendPDFEmailInitialValues, setSendPDFEmailInitialValues] = useState(getSendPDFEmailInitialValues());
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const company = currentLoggedUser?.company;
  const requestBusy = useSelector(state => state.requestBusy);
  const historyStack = useSelector(state => state.historyStack);
  const [showSendPdfEmailModal, setShowSendPdfEmailModal] = useState(false);
  const [triggerPdfDownload, setTriggerPdfDownload] = useState(false);
  const [triggerPdfSendEmailValues, setTriggerPdfSendEmailValues] = useState(null);
  const [showSetupQuickbooksCustomerModal, setShowSetupQuickbooksCustomerModal] = useState(false);
  const [showSetupQuickbooksProductModal, setShowSetupQuickbooksProductModal] = useState(false);
  const [showInvoiceDueDateEditMode, setShowInvoiceDueDateEditMode] = useState(false);
  const [invoiceDueDateValue, setInvoiceDueDateValue] = useState(null);
  const params = useParams();
  const dispatch = useDispatch();

  const loadId = params && params.loadId ? params.loadId : null;
  const currentSelectedLoadUncached = useSelector(state => state.loads.currentSelectedLoad);
  const currentSelectedLoadCached = useSelector(state => state?.loads?.currentSelectedLoadCaches?.[loadId]);

  const currentSelectedLoad = currentSelectedLoadUncached || currentSelectedLoadCached;

  let invoicedAt = currentSelectedLoad?.invoicedAt;
  let dueDate = currentSelectedLoad?.invoiceDueDate;

  if (company?.quickbooksIsOauth2Logged) {
    if (currentSelectedLoad?.quickbooksInvoiceData?.MetaData?.CreateTime) invoicedAt = currentSelectedLoad.quickbooksInvoiceData.MetaData.CreateTime;
    if (currentSelectedLoad?.quickbooksInvoiceData?.DueDate) dueDate = currentSelectedLoad.quickbooksInvoiceData.DueDate;
  }

  useEffect(() => {
    if (loadId) dispatch(fetchCurrentSelectedLoad(loadId, { refreshQuickbooksInvoiceData: 1 }));
  }, []);

  useEffect(() => {
    if (currentSelectedLoad) {
      if (currentSelectedLoad.status !== loadStatusTypes.paid.key && currentSelectedLoad.status !== loadStatusTypes.invoiced.key) {
        navigate(`/loads/load-details/${currentSelectedLoad.id}`, { replace: true });
      }
    }
  }, [currentSelectedLoad, navigate]);

  useEffect(() => {
    if (dueDate) setInvoiceDueDateValue(dueDate);
  }, [dueDate]);

  const handleSubmit = values => setTriggerPdfSendEmailValues(values);

  const handleShipQuickbooksInvoice = useCallback(() => {
    if (!company?.quickbooksProductItemId) {
      return setShowSetupQuickbooksProductModal(true);
    }
    if (currentSelectedLoad?.companyCustomer?.quickbooksCustomerId) {
      dispatch(shipInvoiceToTheQuickbooks({ loadId: currentSelectedLoad?.id }));
    } else {
      setShowSetupQuickbooksCustomerModal(true);
    }
  }, [currentSelectedLoad, dispatch, company]);

  if (!currentSelectedLoad) return '';

  let deliveryName = '';
  let deliveryTime = '';
  let pickupName = '';
  let pickupTime = '';
  const stopsData = [];

  if (currentSelectedLoad?.loadStops?.length) {
    currentSelectedLoad.loadStops.forEach((item) => {
      if (item.type === loadStopTypes.customer.key) {
        if (item?.companyLocation?.addressName) {
          deliveryName = item.companyLocation.addressName;
          deliveryTime = item.deliveredAt ? moment(item.deliveredAt).format("MM/DD/YYYY HH:mm") : '';
        }
      }
      if (item.type === loadStopTypes.location.key) {
        if (item?.companyLocation?.addressName) {
          pickupName = item.companyLocation.addressName;
          pickupTime = item.pickedUpAt ? moment(item.pickedUpAt).format("MM/DD/YYYY HH:mm") : '';
        }
      }
      if (item.type === loadStopTypes.stop.key) {
        stopsData.push({ 
          stopName: item?.companyLocation?.addressName,
          stopArrivalTime: item?.arrivedAt ? moment(item.arrivedAt).format("MM/DD/YYYY HH:mm") : '',
          stopDepartureTime: item?.departuredAt ? moment(item.departuredAt).format("MM/DD/YYYY HH:mm") : ''
        });
      }
    });
  }

  return (
    <React.Fragment>
      <Helmet title="Invoice Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Invoice Details
          </Typography>
          {
            historyStack[historyStack.length - 2] &&
            historyStack[historyStack.length - 2]?.indexOf('/invoices') !== -1 &&
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link 
                style={{ cursor: 'pointer' }}
                onClick={() => navigate(-1)}
              >
                Invoices
              </Link>
              <Typography>Invoice Details</Typography>
            </Breadcrumbs>
          }
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="text" 
              color="primary" 
              onClick={() => {
                navigate(`/loads/load-details/${currentSelectedLoad?.id}`);
              }}
              style={{ marginRight: 20 }}
            >
              Open Load
            </Button>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                if (currentSelectedLoad?.status === loadStatusTypes.invoiced.key) {
                  dispatch(setPaid({ loadId: currentSelectedLoad?.id }));
                } else if (currentSelectedLoad?.status === loadStatusTypes.paid.key) {
                  dispatch(setInvoiced({ loadId: currentSelectedLoad?.id }));
                }
              }}
            >
              {
                currentSelectedLoad?.status === loadStatusTypes.invoiced.key 
                ?
                  <>
                    <CheckIcon style={{ marginRight: 5 }} />
                    Mark as Paid
                  </>
                :
                currentSelectedLoad?.status === loadStatusTypes.paid.key 
                ?
                  <>
                    <CloseIcon style={{ marginRight: 5 }} />
                    Mark as not Paid
                  </>
                :
                  ''
              }
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Shadow>
        <Card mb={6}>
          <CardContent style={{ paddingBottom: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: 25 }}>
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={() => setTriggerPdfDownload(true)}
                size="small"
                style={{ marginRight: 10 }}
              >
                <DownloadIcon style={{ marginRight: 5 }} />
                Download PDF
              </Button>
              <Button 
                variant="outlined" 
                color="primary" 
                onClick={() => setShowSendPdfEmailModal(true)}
                size="small"
                style={{ marginRight: 10 }}
              >
                <MailOutlineIcon style={{ marginRight: 5 }} />
                Email PDF
              </Button>
              {
                company &&
                company?.quickbooksIsOauth2Logged &&
                company?.quickbooksOauth2DataSaved &&
                currentSelectedLoad &&
                !currentSelectedLoad.quickbooksInvoiceId
                ?
                <Button 
                  variant="outlined" 
                  onClick={handleShipQuickbooksInvoice}
                  size="small"
                  style={{ borderColor: '#0e8900', color: '#0e8900' }}
                >
                  <img src={QbLogo} style={{width: 20 , marginRight: 5, }} alt="" />
                    Send
                  </Button>
                :
                  ''
              }
              {
                company?.quickbooksIsOauth2Logged &&
                currentSelectedLoad?.quickbooksInvoiceId &&
                <Button 
                  variant="outlined" 
                  onClick={() => {
                    const link = `https://app.${process.env.NODE_ENV === 'development' ? 'sandbox.' : ''}qbo.intuit.com/app/invoice?txnId=${currentSelectedLoad.quickbooksInvoiceId}`;
                    window.open(link, '_blank');
                  }}
                  size="small"
                  style={{ borderColor: '#0e8900', color: '#0e8900' }}
                >
                  <img src={QbLogo} style={{width: 20 , marginRight: 5, }} alt="" />
                  View
                </Button>
              }
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
              <div>
                <Typography variant="body2">
                  {
                    currentSelectedLoad?.company?.name &&
                    <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                      {currentSelectedLoad.company.name}
                      <br />
                    </span>
                  }
                  {
                    currentSelectedLoad?.company?.address &&
                    <>
                      {currentSelectedLoad.company.address}
                      <br />
                    </>
                  }
                  {
                    currentSelectedLoad?.company?.city &&
                    <>
                      {currentSelectedLoad.company.city}
                      <br />
                    </>
                  }
                  {
                    currentSelectedLoad?.company?.state &&
                    <>
                      {currentSelectedLoad.company.state}
                      <br />
                    </>
                  }
                  {
                    currentSelectedLoad?.company?.zip &&
                    <>
                      {currentSelectedLoad.company.zip}
                      <br />
                    </>
                  }
                  {
                    currentSelectedLoad?.company?.email &&
                    <Link href={`mailto:${currentSelectedLoad.company.email}`}>
                      {currentSelectedLoad.company.email}
                    </Link>
                  }
                  {
                    currentSelectedLoad?.company?.phoneNumber &&
                    <div>
                    {currentSelectedLoad.company.phoneNumber}
                      <br />
                    </div>
                  }
                </Typography>
              </div>
              <div>
                {
                  currentLoggedUser?.company?.logoPath &&
                  <div style={{ width: 150 }}>
                    <img 
                      src={currentLoggedUser?.company?.logoPath} 
                      alt=""
                      style={{ maxWidth: '100%', maxHeight: '100%' }} 
                    />
                  </div>
                }
              </div>
            </div>
            <Divider />
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20, marginTop: 10 }}>
              <div style={{ marginRight: 10 }}>
                <Typography variant="caption"><span style={{ fontSize: 16 }}>BILL TO</span></Typography>
                {
                  currentSelectedLoad?.companyCustomer?.logoPath &&
                  <div style={{ width: 150, marginTop: 3 }}>
                    <img 
                      src={currentSelectedLoad?.companyCustomer?.logoPath} 
                      alt=""
                      style={{ maxWidth: '100%', maxHeight: '100%' }} 
                    />
                  </div>
                }
                <Typography variant="body2">
                  {
                    currentSelectedLoad?.companyCustomer?.customerName &&
                    <>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                        {currentSelectedLoad.companyCustomer.customerName}
                      </span>
                      <br />
                    </>
                  }
                  {
                    currentSelectedLoad?.companyCustomer?.poBoxActive
                    ?
                    <>
                      <>
                        P.O. Box: {currentSelectedLoad?.companyCustomer?.poBox}
                        <br />
                      </>
                      <>
                        {currentSelectedLoad?.companyCustomer?.city}
                        <br />
                      </>
                      <>
                        {currentSelectedLoad?.companyCustomer?.state}
                        <br />
                      </>
                      <>
                        {currentSelectedLoad?.companyCustomer?.zip}
                        <br />
                      </>
                    </>
                    :
                    currentSelectedLoad?.companyCustomer?.addressName &&
                    <>
                      {currentSelectedLoad.companyCustomer.addressName}
                      <br />
                    </>
                  }
                  {
                    currentSelectedLoad?.companyCustomer?.contacts &&
                    currentSelectedLoad.companyCustomer.contacts.length > 0 &&
                    <>
                      {
                        currentSelectedLoad.companyCustomer.contacts[0]?.firstName &&
                        <>
                          {currentSelectedLoad.companyCustomer.contacts[0]?.firstName} {' '} {currentSelectedLoad.companyCustomer.contacts[0]?.lastName}
                          <br />
                        </> 
                      }
                      {
                        currentSelectedLoad.companyCustomer.contacts[0]?.email &&
                        <div>
                          <Link href={`mailto:${currentSelectedLoad.companyCustomer.contacts[0].email}`}>
                            {currentSelectedLoad.companyCustomer.contacts[0].email}
                          </Link>
                          <br />
                        </div> 
                      }
                      {
                        currentSelectedLoad.companyCustomer.contacts[0]?.phoneNumber &&
                        <>
                          {currentSelectedLoad.companyCustomer.contacts[0].phoneNumber}
                          <br />
                        </> 
                      }
                    </>
                  }
                </Typography>
              </div>
              <div style={{ width: 250 }}>
                <div style={{ marginTop: 20 }}></div>
                <Typography variant="caption">Invoice #:</Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: 10 }}>{currentSelectedLoad?.id}</Typography>
                <Typography variant="caption">Load Ref Number:</Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: 10 }}>{currentSelectedLoad?.refNumber}</Typography>
                <Typography variant="caption">Date: </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: 10 }}>{invoicedAt ? moment(invoicedAt).format("MM/DD/YYYY") : ''}</Typography>
                <Typography variant="caption">Due Date: </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: 10 }}>
                  {dueDate ? moment(dueDate).format("MM/DD/YYYY") : ''}
                  {
                    showInvoiceDueDateEditMode
                    ?
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <DesktopDatePicker
                          inputFormat="MM/dd/yyyy"
                          value={invoiceDueDateValue} 
                          onChange={value => setInvoiceDueDateValue(value)}
                          renderInput={(params) => <TextField size="small" my={2} {...params} />}
                          variant="outlined"
                          my={2}
                        />
                        <CheckIcon 
                          style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }}
                          onClick={() => {
                            setShowInvoiceDueDateEditMode(false);
                            dispatch(updateInvoice({
                              id: currentSelectedLoad?.id,
                              invoiceDueDate: invoiceDueDateValue
                            }));
                          }}
                        />
                        <CloseIcon 
                          style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }}
                          onClick={() => setShowInvoiceDueDateEditMode(false)}
                        />
                      </div>
                    :
                      <EditIcon 
                        style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }}
                        onClick={() => setShowInvoiceDueDateEditMode(true)}
                      />
                  }
                </Typography>
                <Typography variant="caption">Paid At: </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{currentSelectedLoad?.paidAt ? moment(currentSelectedLoad.paidAt).format("MM/DD/YYYY") : 'Not Paid'}</Typography>
                {
                  company &&
                  company?.quickbooksIsOauth2Logged &&
                  <>
                    <Typography variant="caption" style={{ marginTop: 10, display: 'block' }}>Quickbooks Invoice ID:</Typography>
                    {
                      currentSelectedLoad?.quickbooksInvoiceId
                      ?
                        <Typography variant="body2" style={{ fontWeight: 'bold' }}>{currentSelectedLoad.quickbooksInvoiceId}</Typography>
                      :
                        <Typography variant="body2" style={{ fontSize: '0.9em', fontStyle: 'italic' }}>Invoice is not created yet on Quickbooks</Typography>
                    }
                  </>
                }
              </div>
            </div>
            <Table style={{ marginTop: 20 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Load</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell 
                    component="th" 
                    scope="row" 
                  >
                    <div 
                      onClick={() => navigate(`/loads/load-details/${currentSelectedLoad?.id}`)}
                      style={{ marginBottom: 7, cursor: 'pointer'  }}
                    >
                      Ref Number: <span style={{ fontWeight: 'bold' }}>{currentSelectedLoad?.refNumber ?? currentSelectedLoad?.id}</span>
                    </div>
                    <div>
                      Pickup: <span style={{ fontWeight: 'bold' }}>{pickupName}</span>
                    </div>
                    <div style={{ marginBottom: 7 }}>
                      Pickup Time: <span style={{ fontWeight: 'bold' }}>{pickupTime}</span>
                    </div>
                    {
                      stopsData.map((stop, index) => (
                        <div style={{ marginBottom: 7 }}>
                          <div>
                            Stop #{index + 1}: <span style={{ fontWeight: 'bold' }}>{stop.stopName}</span>
                          </div>
                          <div>
                            Arrival Time: <span style={{ fontWeight: 'bold' }}>{stop.stopArrivalTime}</span>
                          </div>
                          <div>
                            Departure Time: <span style={{ fontWeight: 'bold' }}>{stop.stopDepartureTime}</span>
                          </div>
                        </div>
                      ))
                    }
                    <div>
                      Delivery: <span style={{ fontWeight: 'bold' }}>{deliveryName}</span>
                    </div>
                    <div>
                      Delivery Time: <span style={{ fontWeight: 'bold' }}>{deliveryTime}</span>
                    </div>
                    {
                      company?.invoiceCustomMessage &&
                      <div style={{ marginTop: 50 }}>
                        <div>
                          Invoice Messages:
                        </div>
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                          {company.invoiceCustomMessage}
                        </div>
                      </div>
                    }
                  </TableCell>
                  <TableCell align="right">{getUSDFormattedCurrency(currentSelectedLoad?.tripValue ? currentSelectedLoad.tripValue : 0)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }}></TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }}>
                    <span style={{ marginRight: 50 }}>Balance Due: </span> 
                    <span style={{ fontWeight: 'bold' }}>{getUSDFormattedCurrency(currentSelectedLoad?.tripValue ? currentSelectedLoad.tripValue : 0)}</span>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Shadow>
      <Dialog
        open={showSendPdfEmailModal}
        onClose={() => setShowSendPdfEmailModal(false)}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Send Invoice PDF via Email</DialogTitle>
        <Formik
          initialValues={sendPDFEmailInitialValues}
          validationSchema={Yup.object().shape({
            emailTo: Yup.string().email("Must be a valid email").min(3,"Email must be at least 3 characters").max(255,"Email must be at most 255 characters").required("Required"),
            emailSubject: Yup.string().required("Required")
          })}
          onSubmit={handleSubmit}
          enableReinitialize={true}
          innerRef={formikRef}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
            isValid,
          }) => {
            return (
              <>
                <DialogContent>
                  <DialogContentText style={{ marginBottom: 10 }}>
                    Enter "To", "Subject" and "Body" of the email. We will attach PDF Invoice and send it along.
                  </DialogContentText>
                  <TextField
                    name="emailTo"
                    value={values.emailTo}
                    label="To*"
                    type="email"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ marginBottom: 10 }}
                  />
                  <TextField
                    name="emailSubject"
                    value={values.emailSubject}
                    label="Subject*"
                    type="text"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ marginBottom: 10 }}
                  />
                  <TextField
                    name="emailBody"
                    value={values.emailBody}
                    label="Body"
                    fullWidth
                    onChange={handleChange}
                    onBlur={handleBlur}
                    on
                    multiline
                    rows={5}
                  />
                  <div style={{ marginTop: 5 }}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={values.attachPod} onChange={handleChange} name="attachPod" />
                      }
                      label="Attach Proof Of Deilvery (POD)"
                    />
                  </div>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setShowSendPdfEmailModal(false)} color="primary">
                    Cancel
                  </Button>
                  <Button 
                    color="primary" variant="contained"
                    onClick={handleSubmit}
                    disabled={!isValid || (isValid && !Object.keys(touched).length) || requestBusy}
                  >
                    Send
                  </Button>
                </DialogActions>
              </>
            )}}
        </Formik>
      </Dialog>
      {
        (triggerPdfDownload ||
        triggerPdfSendEmailValues) &&
        <BlobProvider
          document={(
            <InvoicePDFDocument 
              load={currentSelectedLoad}
              company={company}
              currentLoggedUser={currentLoggedUser}
            />
          )}
        >
          {({ loading, blob, error }) => {
            if (error) return '';
            if (loading) {
              dispatch(setForceShowSpinner(true));
            }
            if (blob) {
              dispatch(setForceShowSpinner(false));
              if (triggerPdfDownload) {
                blobToPDF(blob, `load-${currentSelectedLoad?.id}-invoice`);
                setTriggerPdfDownload(false);
              }
              if (triggerPdfSendEmailValues) {
                dispatch(sendInvoicePdfEmail({...triggerPdfSendEmailValues, loadId: currentSelectedLoad?.id ?? null}, blob, (err) => {
                  if (err) return;
                  if (formikRef?.current?.resetForm) formikRef?.current?.resetForm();
                  setShowSendPdfEmailModal(false);
                }));
                setTriggerPdfSendEmailValues(null);
              }
            }
            return '';
          }}
        </BlobProvider>
      }
      {
        showSetupQuickbooksCustomerModal && 
        <Dialog
          open={showSetupQuickbooksCustomerModal}
          onClose={() => setShowSetupQuickbooksCustomerModal(false)}
        >
          <DialogTitle>
            Hold up!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Before you ship this invoice to the Quickbooks, you will need to setup Quickbooks customer and pair him with your customer here. After you setup that, you can ship this invoice.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSetupQuickbooksCustomerModal(false)}>
              Not now
            </Button>
            <Button 
              color="primary"
              variant="contained"
              onClick={() => {
                navigate(`/company-customers/edit/${currentSelectedLoad?.companyCustomer?.id}`);
              }}
            >
              Pair
            </Button>
          </DialogActions>
        </Dialog>
      }
      {
        showSetupQuickbooksProductModal && 
        <Dialog
          open={showSetupQuickbooksProductModal}
          onClose={() => setShowSetupQuickbooksProductModal(false)}
        >
          <DialogTitle>
            Hold up!
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Before you ship this invoice to the Quickbooks, you will need to setup Quickbooks product and pair it with your account on config page. After you setup that, you can ship this invoice.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowSetupQuickbooksProductModal(false)}>
              Not now
            </Button>
            <Button 
              color="primary"
              variant="contained"
              onClick={() => navigate(`/settings/accounting-integrations?openQuickbooksConfig=1`)}
            >
              Setup
            </Button>
          </DialogActions>
        </Dialog>
      }
    </React.Fragment>
  );
}

export default InvoiceDetails;
