import React, { useState, useEffect, useCallback, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import Marker from '../components/TruckDriverMarker/index';
import { 
  useSelector,
  useDispatch
} from "react-redux";
import {
  Chip as MuiChip,
  Divider as MuiDivider,
  Typography,
  IconButton,
  Card as MuiCard,
  CardContent,
  ListItem,
  ListItemText,
  TextField,
  InputAdornment,
} from "@mui/material";
import {  spacing } from "@mui/system";
import CloseIcon from '@mui/icons-material/Close';
import {FilterList as FilterListIcon, ArrowBack, ArrowForward, Search as SearchIcon } from "@mui/icons-material";
import { Avatar } from "@mui/material";
import { 
  fetchTruckDriversMap,
  setFiltersSkip,
  setSearchField
} from '../../redux/slices/truckDriversMap';

const Card = styled(MuiCard)(spacing);
const Divider = styled(MuiDivider)(spacing);

function DriversMap() {
    const [openList, setOpenList] = useState(true);
    const [page, setPage] = React.useState(1);
    const googleMap = useRef(null);
    const googleMaps = useRef(null);
    const [selectedMarkers, setSelectedMarkers] = useState([]);
    const truckDriversMap = useSelector(state => state.truckDriversMap);
    const dispatch = useDispatch();
    const options = {
        center: {
          lat: 42.52,
          lng: -97.22
        },
        zoom: 4.5
    }
           
    useEffect(() => {
      if (truckDriversMap && !truckDriversMap.initialFetch) dispatch(fetchTruckDriversMap());
    }, []);

    const handleFetchReset = () => {
      dispatch(setFiltersSkip(0));
      dispatch(fetchTruckDriversMap());
    };

    const rightArrowHandle = () => {
      dispatch(setFiltersSkip(truckDriversMap.filters.skip + truckDriversMap.filters.limit))
      dispatch(fetchTruckDriversMap());
      setPage(page + 1);
      setSelectedMarkers([]);
    };

    const leftArrowHandle = () => {
      dispatch(setFiltersSkip(truckDriversMap.filters.skip - truckDriversMap.filters.limit))
      dispatch(fetchTruckDriversMap());
      setPage(page - 1);
      setSelectedMarkers([]);
    };

    const getMapOptions = useCallback((maps) => {
      return {
        fullscreenControl: false,
        mapTypeControl: true,
        mapTypeId: maps.MapTypeId.ROADMAP,
        scaleControl: true,
        scrollwheel: true,
        streetViewControl: false,
      };
    }, []);

    const handleSelectMarker = (markerId) => {
      const listId = selectedMarkers.find(listItem => listItem === markerId);
      if (listId) {
        setSelectedMarkers(prevState => {
          return [...prevState].filter(marker => marker !== markerId);
        });
      } else {
        setSelectedMarkers(prevState => {
          return [...prevState, markerId];
        });
      }
    }

    const handleSearchField = (e) => {
      const value = e.target.value;
      dispatch(setSearchField(value));
    };

    const handleUnselectedMarker = (markerId) => {
     setSelectedMarkers([...selectedMarkers].filter(marker => marker !== markerId));
    }

    return (
      <React.Fragment>
      <Helmet title="Drivers Map" />
        <Typography variant="h3" gutterBottom display="inline">
            Drivers Map
        </Typography>
        <Divider my={6} />
          <Card mb={6}>
            <CardContent style={{paddingBottom: 16}}>
              <div style={{display: 'flex', height: '80vh', width: '100%' }}>
                <GoogleMapReact
                  options={getMapOptions}
                  bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
                  defaultCenter={options.center}
                  defaultZoom={options.zoom}
                  onGoogleApiLoaded={({ maps, map }) => {
                    googleMap.current = map;
                    googleMaps.current = maps;
                  }}
                >
                  {truckDriversMap.data.map(item => {
                    if (!item.lastKnownLocationLat || !item.lastKnownLocationLng) return <></>;
                    return (
                      <Marker
                        key={item.id}
                        lat={item.lastKnownLocationLat}
                        lng={item.lastKnownLocationLng}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        makeModel={item.driverSelectedTruck.make + ' ' + item.driverSelectedTruck.model}
                        eldId={item.eldDriverId}
                        openPopup={selectedMarkers.includes(item.id)}
                        itemId={item.id}
                        handleUnselectedMarker={handleUnselectedMarker}
                        handleSelectMarker={handleSelectMarker}
                      />
                    );
                  })}
                </GoogleMapReact>
                <div>
                  {!openList ? (
                    <IconButton
                      style={{marginLeft: 17}}
                      onClick={() => setOpenList(true)}
                    >
                      <FilterListIcon />
                    </IconButton>
                  ) : ('')}
                </div>
                {openList ?( 
                  <div style={{width: '30%'}}>
                    <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                      <IconButton
                        onClick={() => setOpenList(false)}
                        >
                          <CloseIcon />
                      </IconButton>
                    </div>
                    <div style={{ paddingLeft: 15, paddingTop: 10, paddingBottom: 10 }}>
                      <TextField
                        value={truckDriversMap?.filters?.searchField ?? ''}
                        onChange={handleSearchField}
                        onKeyDown={(e) => {
                          if (e?.keyCode === 13) handleFetchReset();
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleFetchReset}
                                edge="end"
                              >
                                <SearchIcon />
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        size="small"
                        label="Search..."
                        fullWidth
                      />
                    </div>
                    {
                      truckDriversMap?.data?.length === 0 &&
                      truckDriversMap?.initialFetch
                      ?
                        <div style={{ textAlign: 'center', marginTop: 20 }}>No results... </div>
                      :
                        <>
                          <div style={{display: 'flex', justifyContent: 'space-around', paddingLeft: 12, paddingBottom: 15}}>
                          <div>
                            <IconButton 
                              onClick={leftArrowHandle} 
                              disabled={truckDriversMap.filters.skip === 0}
                            >
                              <ArrowBack />
                            </IconButton>
                          </div>
                          <div>
                            <Typography  style={{paddingTop: 8, fontSize: 15}}>
                              {page} of {Math.ceil(truckDriversMap.total / truckDriversMap.filters.limit)}
                            </Typography>
                          </div>
                          <div>
                            <IconButton 
                              onClick={rightArrowHandle} 
                              disabled={(truckDriversMap.filters.skip + truckDriversMap.filters.limit) > truckDriversMap.total || (truckDriversMap.filters.skip + truckDriversMap.filters.limit) === truckDriversMap.total}
                            >
                              <ArrowForward />
                            </IconButton>
                          </div>
                        </div>
                        <div style={{ overflowY: 'scroll', height: '80%' }}>
                        {truckDriversMap.data.map(item => 
                            <ListItem 
                            button
                            onClick={() => {
                              handleSelectMarker(item.id);
                              if (googleMap.current && googleMaps.current) {
                                if (item?.lastKnownLocationLat && item?.lastKnownLocationLng) {
                                  const center = new googleMaps.current.LatLng(item.lastKnownLocationLat, item.lastKnownLocationLng);
                                  googleMap.current.panTo(center);
                                  googleMap.current.setZoom(5);
                                }
                              }
                            }}
                            key={item.id}
                          >
                            <Avatar src={item?.avatar ?? null}  />
                            <ListItemText style={{paddingLeft: 10}}>{item?.driverSelectedTruck?.equipmentNumber + ' - '}{item.firstName + ' ' + item.lastName}</ListItemText>
                          </ListItem>
                        )}
                        </div>
                      </>
                    }
                  </div>
                ) : ('')}
              </div>
          </CardContent>
        </Card>
    </React.Fragment>
  ); 
}

export default DriversMap;