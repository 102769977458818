import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  fetchCompanyAdminUsers,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersFirstName,
  setFiltersLastName,
  setFiltersStatus,
  setFiltersLoginAccess,
  setSortsRegistrationDate,
  setCurrentSelectedCompanyAdmin
} from "../../redux/slices/companyAdminUsers";
import { 
  useNavigate,
  useParams
} from 'react-router-dom';
import { 
  useDispatch,
  useSelector,
} from 'react-redux';
import userRoles from "../../keys/userRoles";
import moment from 'moment';

import {
  TextField as MuiTextField,
  Breadcrumbs as MuiBreadcrumbs,
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Link,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const TextField = styled(MuiTextField)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.nonActive && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "firstName", alignment: "left", label: "First Name" },
  { id: "product", alignment: "left", label: "Last Name" },
  { id: "date", alignment: "left", label: "Registration Date" },
  { id: "product", alignment: "left", label: "Type" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "loginAccess", alignment: "left", label: "Login Access" }
];

const EnhancedTableHead = (props) => {
  const { companyId } = props;

  const dispatch = useDispatch();
  const registrationDateSort = useSelector(state => state.companyAdminUsers.sorts.registrationDate);

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {
              headCell.label === 'Registration Date'
              ?
                <TableSortLabel
                  direction={registrationDateSort}
                  onClick={() => {
                    if (registrationDateSort === 'asc') dispatch(setSortsRegistrationDate('desc'));
                    else dispatch(setSortsRegistrationDate('asc'));
                    dispatch(fetchCompanyAdminUsers({companyId}));
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              :
                headCell.label
            }
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows, setOpenFilter }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(state => state.companyAdminUsers.filters.limit);
  const rowsSkip = useSelector(state => state.companyAdminUsers.filters.skip);
  const rowsTotal = useSelector(state => state.companyAdminUsers.total);
  const initialFetch = useSelector(state => state.companyAdminUsers.initialFetch);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      dispatch(setFiltersSkip(rowsPerPage + rowsSkip));
    } else {
      dispatch(setFiltersSkip(rowsSkip - rowsPerPage));
    }
    dispatch(fetchCompanyAdminUsers({companyId}));
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setFiltersLimit(value));
    dispatch(setFiltersSkip(0));
    dispatch(fetchCompanyAdminUsers({companyId}));
    setPage(0);
  };

  const navigate = useNavigate();
  const params = useParams();
  
  const companyId = params && params.companyId ? params.companyId : null;

  return (
    <div>
      <Paper>
      < Toolbar>
          <Spacer />
          <div>
            <Tooltip title="Filter list">
              <IconButton onClick={() => setOpenFilter(true)} aria-label="Filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead companyId={companyId} />
            <TableBody>
              {
                rows.map((row, index) => {
                  return (
                    <TableRow
                     style={{cursor: "pointer"}}
                      onClick={() => {
                        dispatch(setCurrentSelectedCompanyAdmin(row));
                        navigate(`/companies/company-details/${companyId}/admin-users/admin-details/${row.id}`);
                        }}
                      hover                      
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.firstName}</TableCell>
                      <TableCell align="left">{row.lastName}</TableCell>
                      <TableCell align="left">{moment(row.createdAt).format("MM/DD/YYYY")}</TableCell>
                      <TableCell align="left">{row && row.role && userRoles[row.role] && userRoles[row.role].display ? userRoles[row.role].display : ''}</TableCell>
                      <TableCell>
                        {row.isActive && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Active"
                            active={+true}
                          />
                        )}
                        {!row.isActive && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        {row.loginAccess && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Active"
                            active={+true}
                          />
                        )}
                        {!row.loginAccess && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell padding="none" align="right">
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length && initialFetch
          ?
            <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
          :
            ''
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CompanyAdminUsers() {
  const navigate = useNavigate();
  const companyAdminUsers = useSelector(state => state.companyAdminUsers);
  const params = useParams();
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    firstName: '',
    lastName: '',
    isActive: '-1',
    loginAccess: '-1'
  });

  const companyId = params && params.companyId ? params.companyId : null;

  useEffect(() => {
    if (companyAdminUsers && !companyAdminUsers.initialFetch) dispatch(fetchCompanyAdminUsers({ companyId }));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Admin Users" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Admin Users
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                Companies
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                Company Details
            </Link>
            <Typography>Admin Users</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(setCurrentSelectedCompanyAdmin(null));
                navigate(`/companies/company-details/${companyId}/admin-users/add-company-admin-user`);
              }}
            >
              <AddIcon />
              Add Admin User
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable setOpenFilter={setOpenFilter} rows={companyAdminUsers.data} />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <div style={{ padding: 15 }}>
          <Typography textAlign="center" variant="h3">
            Filters
          </Typography>
          <Divider paddingTop="15px" />
          <TextField
            label="First Name"
            value={filterValues.firstName}
            fullWidth
            onChange={e => setFilterValues(prevState => ({ ...prevState, firstName: e.target.value }))}
            variant="outlined"
            style={{ 
              marginTop: 20,
              width: 300,
              display: 'block'
            }}
          />
          <TextField
            label="Last Name"
            value={filterValues.lastName}
            fullWidth
            onChange={e => setFilterValues(prevState => ({ ...prevState, lastName: e.target.value }))}
            variant="outlined"
            style={{ 
              marginTop: 20,
              width: 300,
              display: 'block',
              marginBottom: 20
            }}
          />
          <div style={{ marginBottom: 20 }}>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select
              label="Status"
              value={filterValues.isActive}
              fullWidth
              onChange={e => setFilterValues(prevState => ({ ...prevState, isActive: e.target.value }))}
              variant="outlined"
              style={{ 
                width: 300,
                display: 'block'
              }}
            >
              <MenuItem value="-1">Select</MenuItem>
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Not Active</MenuItem>
            </Select>
          </FormControl>
          </div>
          <div>
          <FormControl fullWidth>
            <InputLabel>Login Access</InputLabel>
            <Select
              label="Login Access"
              value={filterValues.loginAccess}
              fullWidth
              onChange={e => setFilterValues(prevState => ({ ...prevState, loginAccess: e.target.value }))}
              variant="outlined"
              style={{ 
                width: 300,
                display: 'block'
              }}
            >
              <MenuItem value="-1">Select</MenuItem>
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Not Active</MenuItem>
            </Select>
          </FormControl>
          </div>
          <div style={{ 
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-around'
          }}>
            <Button 
              variant="text"
              color="secondary"
              onClick={() => {
                setFilterValues(() => ({
                  firstName: '',
                  lastName: '',
                  isActive: '-1',
                  loginAccess: '-1'
                }));
                dispatch(setFiltersFirstName(''));
                dispatch(setFiltersLastName(''));
                dispatch(setFiltersStatus('-1'));
                dispatch(setFiltersLoginAccess('-1'));
                dispatch(setFiltersSkip(0));
                dispatch(fetchCompanyAdminUsers({ companyId }));
              }}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setFiltersFirstName(filterValues.firstName));
                dispatch(setFiltersLastName(filterValues.lastName));
                dispatch(setFiltersStatus(filterValues.isActive));
                dispatch(setFiltersLoginAccess(filterValues.loginAccess));
                dispatch(setFiltersSkip(0));
                dispatch(fetchCompanyAdminUsers({ companyId }));
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default CompanyAdminUsers;