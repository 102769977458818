import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { refreshToken } from './currentLoggedUser';
import { resetAvailableTrailersState } from './loads';
import { fetchNotifications } from './notifications';

const getInitialState = () => ({
  data: [],
  availableEquipmentData: {
    trailers: [],
    trucks: []
  },
  filters: {
    limit: 10,
    skip: 0,
    type: '-1',
    equipmentNumber: '',
    isActive: '1',
  },
  total: 0,
  initialFetch: false,
  currentSelectedEquipment: null,
  shouldFetchAvailableEquipmentData: true,
});

export const equipmentsSlice = createSlice({
  name: "equipments",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setAvailableEquipmentData: (state, action) => {
      state.availableEquipmentData = action.payload;
      return state;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setFiltersType: (state, action) => {
      state.filters.type = action.payload;
      return state;
    },
    setFiltersEquipmentNumber: (state, action) => {
      state.filters.equipmentNumber = action.payload;
      return state;
    },
    setFiltersStatus: (state, action) => {
      state.filters.isActive = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    setCurrentSelectedEquipment: (state, action) => {
      state.currentSelectedEquipment = action.payload;
      return state;
    },
    setShouldFetchAvailableEquipmentData: (state, action) => {
      state.shouldFetchAvailableEquipmentData = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setData, 
  setAvailableEquipmentData,
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setInitialFetch,
  setFiltersEquipmentNumber,
  setFiltersStatus,
  setFiltersType,
  setCurrentSelectedEquipment,
  setShouldFetchAvailableEquipmentData,
  resetState
} = equipmentsSlice.actions;

export const fetchEquipments = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.equipments && state.equipments.filters ? state.equipments.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (filters.type && filters.type !== '-1') queryParams.append('type', filters.type);
  if (filters.equipmentNumber) queryParams.append('equipmentNumber', filters.equipmentNumber);
  if (filters.isActive !== '-1') queryParams.append('isActive', filters.isActive);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/equipments?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
      });
  }));
};

export const fetchAvailableEquipmentData = () => (dispatch, getState) => {
  const equipments = getState().equipments;
  if (equipments && !equipments.shouldFetchAvailableEquipmentData) return;
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get('/api/equipments/get-unassigned')
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setAvailableEquipmentData(response.data.data));
          dispatch(setShouldFetchAvailableEquipmentData(false));
        }
      })
      .catch(() => {});
  }));
};

export const addEquipment = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post(
          '/api/equipments',
          { data }
      )
      .then(response => {
          if (response.status === 201 && response.data && response.data.data){
              dispatch(resetState());
              window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
              dispatch(setRequestBusy(false));
              dispatch(resetAvailableTrailersState());
              dispatch(setShouldFetchAvailableEquipmentData(true));
              dispatch(fetchNotifications({ refresh: true }));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const updateEquipment = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/equipments',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              const newData = {
                id: getState().equipments.currentSelectedEquipment.id,
                ...data
              };
              dispatch(setRequestBusy(false));
              dispatch(resetState());
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(setCurrentSelectedEquipment(newData));
              dispatch(resetAvailableTrailersState());
              dispatch(setShouldFetchAvailableEquipmentData(true));
              dispatch(fetchNotifications({ refresh: true }));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const fetchCurrentSelectedEquipment = id => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/equipments/${id}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedEquipment(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
  }));
}; 

export const deactivateCurrentSelectedEquipment = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const currentSelectedEquipment = getState().equipments.currentSelectedEquipment;
  const data = {
    id: currentSelectedEquipment.id,
    isActive: false
  }
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/equipments',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage('Equipment successfully deactivated'));
              dispatch(setCurrentSelectedEquipment({
                ...currentSelectedEquipment,
                ...data
              }));
              dispatch(resetAvailableTrailersState());
              dispatch(setShouldFetchAvailableEquipmentData(true));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }));
};

export const activateCurrentSelectedEquipment = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const currentSelectedEquipment = getState().equipments.currentSelectedEquipment;
  const data = {
    id: currentSelectedEquipment.id,
    isActive: true
  }
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/equipments',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage('Equipment successfully activated'));
              dispatch(setCurrentSelectedEquipment({
                ...currentSelectedEquipment,
                ...data
              }));
              dispatch(resetAvailableTrailersState());
              dispatch(setShouldFetchAvailableEquipmentData(true));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }));
};

export default equipmentsSlice.reducer;

