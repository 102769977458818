import React, { useState, useEffect, useCallback, useMemo, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useTheme } from '@mui/styles';
import { 
  useNavigate,
  useParams
} from 'react-router-dom';
import { 
  getTotalMiles,
} from '../../utils/loadStopHelpers';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import moment from 'moment';
import LoadMap from '../components/LoadMap';
import loadsStatusTypes from '../../keys/loadStatusTypes'
import getUSDFormattedCurrency from '../../utils/getUSDFormattedCurrency';

import {
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  CardContent,
  Paper,
  IconButton,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import { spacing, display } from "@mui/system";
import { 
  fetchCurrentSelectedLoad,
  //setDriverAcceptLoad,
  setActionTimestamp,
  resetAllStatuses,
  setInvoiced
} from "../../redux/slices/loads";
import {
  setState as setLoadNotesState
} from "../../redux/slices/loadNotes";
import LoadDetailsAcivityTabs from "../components/LoadDetailsActivityTabs";
import CompanyCustomerDetailsSection from '../components/CompanyCustomerDetailsSection';
import EquipmentDetails from '../components/EquipmentDetails';
import CompanyLocationDetailsSection from '../components/CompanyLocationDetailsSection';
import TruckDriverDetailsSection from '../components/TruckDriverDetailsSection';
import DispatcherDetailsSection from '../components/DispatcherDetailsSection';
import LoadBreakdown from '../components/LoadBreakdown';
import FilePreview from "../components/FilePreview";
import { getLoadStopsMetadata } from '../../utils/loadStopHelpers';
import loadStatusTypes from "../../keys/loadStatusTypes";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background-color: ${(props) => props.bColor };
  color: ${(props) => props.theme.palette.common.white};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function LoadDetails() {
  const navigate = useNavigate();
  const historyStack = useSelector(state => state.historyStack);
  const params = useParams();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [resetAllStatusesHandler, setResetAllStatusesHandler] = useState(null);
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const showCreateInvoiceModalInitializedOnce = useRef(false);
  const [filePreviewData, setFilePreviewData] = useState(null);

  const loadId = params && params.loadId ? params.loadId : null;
  const currentSelectedLoadUncached = useSelector(state => state.loads.currentSelectedLoad);
  const currentSelectedLoadCached = useSelector(state => state?.loads?.currentSelectedLoadCaches?.[loadId]);

  const currentSelectedLoad = currentSelectedLoadUncached || currentSelectedLoadCached;

  useEffect(() => {
    if (loadId) {
      dispatch(fetchCurrentSelectedLoad(loadId));
      dispatch(setLoadNotesState(loadId));
    }
  }, [loadId]);

  const totalMiles = getTotalMiles(currentSelectedLoad?.loadStops);

  const {
    customerData,
    pickupData,
    additionalStopsIndexes,
  } = getLoadStopsMetadata(currentSelectedLoad?.loadStops);

  useEffect(() => {
    if (
      customerData?.deliveredAt && 
      currentSelectedLoad?.status === loadStatusTypes.delivered.key &&
      !showCreateInvoiceModalInitializedOnce.current
    ) {
      showCreateInvoiceModalInitializedOnce.current = true;
      window.setTimeout(() => setShowCreateInvoiceModal(true), 1000);
    }
  }, [customerData, currentSelectedLoad]);

  const getNextStatus = useCallback(() => {

    let nextStatusDescription = '';
    let nextStatusTrigger;
    let readyToBeInvoiced = false;
    let invoiced = false;

    /* In case if they change their mind to add here setup for accepted by driver status.
    if (!currentSelectedLoad?.isAcceptedByDriver) {
      nextStatusDescription = 'Accepted By Driver';
      nextStatusTrigger = () => dispatch(setDriverAcceptLoad());
      return {
        nextStatusDescription,
        nextStatusTrigger
      };
    } 
    */
    
    if (pickupData) {
      if (!pickupData?.pickedUpAt) {
        nextStatusDescription = 'At Shipper';
        nextStatusTrigger = () => dispatch(setActionTimestamp({ 
          loadId: currentSelectedLoad?.id,
          loadStopId: pickupData.id,
          pickedUpAt: new Date(),
        }));
        return {
          nextStatusDescription,
          nextStatusTrigger
        };
      }
    }

    if (additionalStopsIndexes?.length && currentSelectedLoad?.loadStops) {
      let loadStop;
      for (let i = 0; i < additionalStopsIndexes.length; i++) {
        loadStop = currentSelectedLoad.loadStops[additionalStopsIndexes[i]];
        if (!loadStop.arrivedAt) {
          nextStatusDescription = `Stop#${i+1} - Arrived`;
          nextStatusTrigger = () => dispatch(setActionTimestamp({ 
            loadId: currentSelectedLoad?.id,
            loadStopId: loadStop.id,
            arrivedAt: new Date(),
          }));
          return {
            nextStatusDescription,
            nextStatusTrigger
          };
          break;
        }
        if (loadStop.arrivedAt && !loadStop.departuredAt) {
          nextStatusDescription = `Stop#${i+1} - Departured`;
          nextStatusTrigger = () => dispatch(setActionTimestamp({ 
            loadId: currentSelectedLoad?.id,
            loadStopId: loadStop.id,
            departuredAt: new Date(),
          }));
          return {
            nextStatusDescription,
            nextStatusTrigger
          };
          break;
        }
      }
    }

    if (customerData) {
      if (!customerData?.deliveredAt) {
        nextStatusDescription = 'At Delivery';
        nextStatusTrigger = () => dispatch(setActionTimestamp({ 
          loadId: currentSelectedLoad?.id,
          loadStopId: customerData.id,
          deliveredAt: new Date(),
        }));
        return {
          nextStatusDescription,
          nextStatusTrigger
        };
      }
    }

    if (customerData) {
      if (customerData?.deliveredAt && currentSelectedLoad?.status === loadStatusTypes.delivered.key) {
        nextStatusDescription = 'Invoiced';
        readyToBeInvoiced = true;
        nextStatusTrigger = () => dispatch(setInvoiced(
          { 
            loadId: currentSelectedLoad?.id
          },
          (err) => {
            if (err) return;
            navigate(`/accounting/invoices/invoice-details/${currentSelectedLoad.id}`, { replace: true });
          }
        ));
        return {
          nextStatusDescription,
          nextStatusTrigger,
          readyToBeInvoiced,
        };
      }
    }

    if (customerData) {
      if (
        currentSelectedLoad?.status === loadStatusTypes.invoiced.key || 
        currentSelectedLoad?.status === loadStatusTypes.paid.key
      ) {
        invoiced = true;
        return {
          invoiced,
        };
      }
    }

    return {
      nextStatusDescription,
      nextStatusTrigger,
      readyToBeInvoiced,
      invoiced
    };
  }, [
    additionalStopsIndexes, 
    currentSelectedLoad, 
    customerData, 
    dispatch, 
    pickupData,
    navigate
  ]);

  const {
    nextStatusTrigger,
    nextStatusDescription,
    readyToBeInvoiced,
    invoiced
  } = getNextStatus();

  const uploadedFiles = useMemo(() => {
    let uploadedFiles = []
    try {
      if (currentSelectedLoad?.uploadedFiles) {
        if (typeof currentSelectedLoad.uploadedFiles === 'string') {
          uploadedFiles = JSON.parse(currentSelectedLoad.uploadedFiles);
        } else if (Array.isArray(currentSelectedLoad.uploadedFiles)) {
          uploadedFiles = currentSelectedLoad.uploadedFiles;
        }
      }
    } catch (e) {
      return [];
    }
    return uploadedFiles;
  }, [currentSelectedLoad]);

  if (!currentSelectedLoad) return '';

  return (
    <React.Fragment>
      <Helmet title="Load Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Load Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link 
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (historyStack[historyStack.length - 2]?.indexOf('loads') === -1) {
                  navigate('/loads', { replace: true });
                } else {
                  navigate(-1);
                }
              }}
            >
              Loads
            </Link>
            <Typography>Load Details</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card mb={6}>
        <CardContent style={{ paddingBottom: 20 }}>
          <LoadMap 
            width="100%"
            height="400px"
            polyline={currentSelectedLoad?.directionsData?.routes[0]?.overview_polyline?.points}
            locationsData={currentSelectedLoad?.loadStops}
            truckDriver={currentSelectedLoad?.driver}
          />
        </CardContent>
      </Card>

      <Card mb={6}>
        <CardContent style={{ paddingBottom: 20 }}>
          <div style={{ display: 'flex', borderBottom: '1px solid rgba(0,0,0,0.12)', marginBottom: 10 }}>
            <div style={{ flex: 1.5 }}>
              <div style={{
                display: 'flex',
                justifyContent: 'center',
                borderBottom: '1px solid rgba(0,0,0,0.12)',
                paddingBottom: 10,
                marginBottom: 7
              }}>
                <div>
                  <div style={{ textAlign: 'center', marginBottom: 10 }}>
                    <Typography style={{ fontSize: 20, fontWeight: 100 }}  mt={2}>Status</Typography>
                  </div>
                  <div>
                    {
                      Object.keys(loadsStatusTypes).map(item => (
                        <Chip 
                          size="medium"
                          mr={2}
                          mb={1}
                          label={loadsStatusTypes[item].display} 
                          bColor={loadsStatusTypes[item].color}
                          style={{
                            opacity: loadsStatusTypes[item].key === currentSelectedLoad?.status ? 1 : 0.2
                          }}
                        />
                      ))
                    }
                  </div>
                </div>
              </div>
              {
                currentSelectedLoad?.driverId &&
                <div 
                  style={{ 
                    display: 'flex', 
                    justifyContent: 'center',
                    paddingRight: 10,
                    borderBottom: '1px solid rgba(0,0,0,0.12)',
                    paddingBottom: 15,
                    marginBottom: 20 
                  }}
                >
                  <div style={{ textAlign: 'center', paddingTop: 3 }}>
                    {
                      nextStatusDescription &&
                      <Typography mt={2}>
                        Next Status: 
                        <div style={{ fontWeight: 'bold', marginBottom: 3 }}>
                          {nextStatusDescription}
                        </div>
                      </Typography>
                    }
                    {
                      invoiced
                      ?
                        <Button
                          variant="contained"
                          size="small"
                          style={{ width: 150 }}
                          onClick={() => navigate(`/accounting/invoices/invoice-details/${currentSelectedLoad?.id}`)}
                        >
                          OPEN INVOICE
                        </Button>
                      :
                        <Button
                          variant="contained"
                          size="small"
                          style={{ width: 150 }}
                          onClick={nextStatusTrigger}
                          disabled={!nextStatusTrigger}
                        >
                          {
                            readyToBeInvoiced ?
                            'CREATE INVOICE'
                            :
                            'SET NEXT STATUS'
                          }
                        </Button>
                    }
                    {
                      currentSelectedLoad?.status !== loadsStatusTypes.booked.key &&
                      !currentSelectedLoad?.invoicedAt &&
                      <div>
                        <Button
                          variant="outlined"
                          size="small"
                          color="error"
                          style={{ width: 150, marginTop: 10 }}
                          onClick={() => {
                            setResetAllStatusesHandler(() => () =>                             dispatch(resetAllStatuses((err) => {
                              if (err) return;
                              setResetAllStatusesHandler(false)
                            })));
                          }}
                        >RESET ALL STATUSES</Button>
                      </div>
                    }
                  </div>
                </div>
              }
              <div>
                <Typography mt={2}>
                  ID: <span style={{ fontWeight: 'bold' }}>{currentSelectedLoad?.id}</span>
                </Typography>
              </div>
              <div>
                <Typography mt={2}>
                  Ref Number: <span style={{ fontWeight: 'bold' }}>{currentSelectedLoad?.refNumber}</span>
                </Typography>
              </div>
              <div>
                <Typography mt={2}>
                  Trip Amount: <span style={{ fontWeight: 'bold' }}>{getUSDFormattedCurrency(currentSelectedLoad?.tripAmount ? currentSelectedLoad.tripAmount : 0)}</span>
                </Typography>
              </div>
              <div>
                <Typography mt={2}>
                  Trip Value: <span style={{ fontWeight: 'bold' }}>{getUSDFormattedCurrency(currentSelectedLoad?.tripValue ? currentSelectedLoad.tripValue : 0)}</span>
                </Typography>
              </div>
              <div>
                <Typography mt={2}>
                  Total Miles: <span style={{ fontWeight: 'bold' }}>{totalMiles}</span>
                </Typography>
              </div>
              <div>
                <Typography mt={2}>
                  Created: <span style={{ fontWeight: 'bold' }}>{
                  currentSelectedLoad?.createdAt ? moment(currentSelectedLoad.createdAt).format("MM/DD/YYYY HH:mm") : ''}</span>
                </Typography>
              </div>
              <div>
                <Typography mt={2}>
                  Last Update: <span style={{ fontWeight: 'bold' }}>{
                  currentSelectedLoad?.updatedAt ? moment(currentSelectedLoad.updatedAt).format("MM/DD/YYYY HH:mm") : ''}</span>
                </Typography>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <LoadDetailsAcivityTabs setFilePreviewData={setFilePreviewData} />
            </div>
          </div>
          <div>
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              <div>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                  Customer Information
                </Typography>
                <CompanyCustomerDetailsSection 
                  data={currentSelectedLoad?.companyCustomer}
                />
              </div>
            </div>
            <Divider />
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              <div>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                  Pickup Information
                </Typography>
                <CompanyLocationDetailsSection 
                  data={pickupData?.companyLocation}
                  showAdditionalInfo={true}
                  additionalData={{
                    fixedAppt: pickupData?.fixedAppt,
                    fixedApptStartDate: pickupData?.fixedApptStartDate,
                    fixedApptStartTime: pickupData?.fixedApptStartTime,
                    fixedApptEndDate: pickupData?.fixedApptEndDate,
                    fixedApptEndTime: pickupData?.fixedApptEndTime,
                    fixedApptTimezone: pickupData?.fixedApptTimezone,
                    activity: pickupData?.activity,
                    handlingTime: pickupData?.handlingTime,
                    notes: pickupData?.notes,
                    driverAssistRequired: pickupData?.driverAssistRequired,
                  }}
                  allowActionTimestampsEdit={true}
                  rawLoadStopData={pickupData}
                />
              </div>
              {
                pickupData?.trailer &&
                <div style={{ marginTop: 20 }}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                    Trailer Information
                  </Typography>
                  <EquipmentDetails
                    data={pickupData.trailer}
                  />
                </div>
              }
            </div>
            {
              additionalStopsIndexes?.length !== 0 &&
              <>
                <Divider />
                <div style={{ marginBottom: 20, marginTop: 20 }}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                    Additional Stops
                  </Typography>
                    {
                      additionalStopsIndexes.map((loadStopIndex, index) => {
                        const loadStopData = currentSelectedLoad?.loadStops?.length &&
                        currentSelectedLoad.loadStops[loadStopIndex] ? currentSelectedLoad.loadStops[loadStopIndex] : null;
                        
                        return (
                          <>
                            <div style={{ marginBottom: 20, marginTop: 20 }}>
                              <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                Stop #{index + 1}
                              </Typography>
                              {
                                loadStopData &&
                                <>
                                  <div>
                                    <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                      Pickup Information
                                    </Typography>
                                    <CompanyLocationDetailsSection 
                                      data={loadStopData?.companyLocation}
                                      showAdditionalInfo={true}
                                      additionalData={{
                                        fixedAppt: loadStopData?.fixedAppt,
                                        fixedApptStartDate: loadStopData?.fixedApptStartDate,
                                        fixedApptStartTime: loadStopData?.fixedApptStartTime,
                                        fixedApptEndDate: loadStopData?.fixedApptEndDate,
                                        fixedApptEndTime: loadStopData?.fixedApptEndTime,
                                        fixedApptTimezone: loadStopData?.fixedApptTimezone,
                                        activity: loadStopData?.activity,
                                        handlingTime: loadStopData?.handlingTime,
                                        notes: loadStopData?.notes,
                                        driverAssistRequired: loadStopData?.driverAssistRequired,
                                      }}
                                      allowActionTimestampsEdit={true}
                                      rawLoadStopData={loadStopData}
                                    />
                                  </div>
                                  {
                                    loadStopData?.trailer &&
                                    <div style={{ marginTop: 20 }}>
                                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                        Trailer Information
                                      </Typography>
                                      <EquipmentDetails
                                        data={loadStopData.trailer}
                                      />
                                    </div>
                                  }
                                </>
                              }
                            </div>
                            {
                              additionalStopsIndexes.length - 1 !== index &&
                              <Divider />
                            }
                          </>
                        );
                      })
                    }
                </div>
              </>
            }
            <Divider />
            <div style={{ marginBottom: 20, marginTop: 20 }}>
              <div>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                  Delivery Information
                </Typography>
                <CompanyLocationDetailsSection 
                  data={customerData?.companyLocation}
                  showAdditionalInfo={true}
                  additionalData={{
                    fixedAppt: customerData?.fixedAppt,
                    fixedApptStartDate: customerData?.fixedApptStartDate,
                    fixedApptStartTime: customerData?.fixedApptStartTime,
                    fixedApptEndDate: customerData?.fixedApptEndDate,
                    fixedApptEndTime: customerData?.fixedApptEndTime,
                    fixedApptTimezone: customerData?.fixedApptTimezone,
                    activity: customerData?.activity,
                    handlingTime: customerData?.handlingTime,
                    notes: customerData?.notes,
                    driverAssistRequired: customerData?.driverAssistRequired,
                  }}
                  allowActionTimestampsEdit={true}
                  rawLoadStopData={customerData}
                />
              </div>
              {
                customerData?.trailer &&
                <div style={{ marginTop: 20 }}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                    Trailer Information
                  </Typography>
                  <EquipmentDetails
                    data={customerData.trailer}
                  />
                </div>
              }
            </div>
            {
              currentSelectedLoad?.driver &&
              <>
                <Divider />
                <div style={{ marginBottom: 20, marginTop: 20 }}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                    Driver
                  </Typography>
                  <TruckDriverDetailsSection data={currentSelectedLoad.driver} load={currentSelectedLoad} />
                </div>
              </>
            }
            <Divider />
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                Dispatcher
              </Typography>
              {
                currentSelectedLoad?.dispatcher &&
                <DispatcherDetailsSection data={currentSelectedLoad.dispatcher} />
              }
            </div>
            {
              uploadedFiles?.length > 0 &&
              <>
                <Divider />
                <div style={{ marginTop: 20, marginBottom: 20 }}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                    Files
                  </Typography>
                  {
                    uploadedFiles.map((file, index) => (
                      <Paper key={index} style={{ padding: 15, backgroundColor: '#eee', width: '50%', marginTop: 15, position: 'relative', }}>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                          <div style={{ marginRight: 10 }}>
                            {file.name}
                          </div>
                          <div style={{ display: 'flex' }}>
                            <div>
                              <IconButton onClick={() => {
                                setFilePreviewData(() => ({
                                  url: file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : '',
                                  type: file && file.urls && file.urls.file ? 'pdf' : 'image'
                                }));
                              }}>
                                <RemoveRedEyeIcon />
                              </IconButton>
                            </div>
                            <div>
                              <IconButton onClick={() => {
                                let url = file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : null;
                                if (!url) return;
                                if (file.name) url += `&fileName=${file.name}`;
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = file.name;
                                document.body.appendChild(a);
                                a.click();
                                document.body.removeChild(a);
                              }}>
                                <DownloadIcon />
                              </IconButton>
                            </div>
                          </div>
                        </div>
                        {
                          file.isPodFile &&
                          <div style={{ position: 'absolute', top: -10, left: 0, backgroundColor: theme.palette.primary.main, width: 40, height: 20, borderRadius: 20, transform: [{ rotate: '-30deg' }] }}>
                            <div style={{ fontSize: 10, textAlign: 'center', position: 'relative', top: 2, color: '#fff', }}>POD</div>
                          </div>
                        }
                      </Paper>
                    ))
                  }
                </div>
              </>
            }
            <Divider />
            <div style={{ marginTop: 20, marginBottom: 20 }}>
              <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                Breakdown
              </Typography>
              <LoadBreakdown load={currentSelectedLoad} />
            </div>
          </div>
          <div style={{display: "flex", justifyContent: "space-between"}}>
            {
              !currentSelectedLoad?.invoicedAt &&
              <Button onClick={() => navigate(`/loads/edit/${loadId}`)}
                type="submit"
                variant="contained"
                color="primary"
                mt={3}
              >
                  <Icon.Edit />
                  <span style={{marginLeft:5}}>Edit</span>
              </Button>
            }
          </div>
        </CardContent>
      </Card>
      <Dialog
        open={resetAllStatusesHandler}
        onClose={() => setResetAllStatusesHandler(null)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to reset stasues? It will reset load all the way to the status: <span style={{ fontWeight: 'bold' }}>Booked</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setResetAllStatusesHandler(null)} 
            color="primary"
          >
            No
          </Button>
          <Button color="error" onClick={resetAllStatusesHandler}>
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showCreateInvoiceModal}
        onClose={() => setShowCreateInvoiceModal(false)}
      >
        <DialogTitle>
          Create Invoice?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This Load is marked as <span style={{ fontWeight: 'bold' }}>Delivered</span>. Would you like to create invoice?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowCreateInvoiceModal(false)} 
            color="primary"
          >
            Not now
          </Button>
          <Button color="primary" onClick={() => {
            dispatch(setInvoiced(
              { 
                loadId: currentSelectedLoad?.id
              },
              (err) => {
                if (err) return;
                navigate(`/accounting/invoices/invoice-details/${currentSelectedLoad.id}`, { replace: true });
              }
            ));
          }} variant="contained">
            Yes, create
          </Button>
        </DialogActions>
      </Dialog>
      { filePreviewData ? <FilePreview url={filePreviewData.url} type={filePreviewData.type} onClose={() => setFilePreviewData(null)} /> : '' }
    </React.Fragment>
  );
}

export default LoadDetails;
