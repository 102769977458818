export default {
  loads: {
    key: 'loads',
    display: 'Load'
  },
  truckDrivers: {
    key: 'truckDrivers',
    display: 'Truck Driver'
  },
  equipments: {
    key: 'equipments',
    display: 'Equipment'
  },
  customers: {
    key: 'customers',
    display: 'Customer'
  },
  locations: {
    key: 'locations',
    display: 'Location'
  }
};
