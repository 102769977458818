export default (blob, fileName) => {
  const link = document.createElement("a");
  link.href = URL.createObjectURL(blob);
  link.setAttribute('download', `${fileName}.pdf`);
  document.body.append(link);
  link.click();
  link.remove();
  window.addEventListener("focus", () => URL.revokeObjectURL(link.href), {
    once: true
  });
}
