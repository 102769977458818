import React from "react";
import styled, { withTheme } from "styled-components/macro";
import Chart from "react-chartjs-2";

import { orange, red } from "@mui/material/colors";
import {
  Card as MuiCard,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell as MuiTableCell,
  TableHead,
  TableRow as MuiTableRow,
} from "@mui/material";
import { spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const ChartWrapper = styled.div`
  height: 168px;
  position: relative;
`;

const TableRow = styled(MuiTableRow)`
  height: 42px;
`;

const TableCell = styled(MuiTableCell)`
  padding-top: 0;
  padding-bottom: 0;
`;

const DoughnutChart = ({ theme, title, columns=[], rowsData=[], circleData, showNoResults, onItemClick = () => {}}) => {
  const data = {
    datasets: [
      {
        data: circleData,
        backgroundColor: [
          theme.palette.secondary.main,
          red[500],
          orange[500],
          theme.palette.grey[200],
        ],
        borderWidth: 5,
        borderColor: theme.palette.background.paper,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    cutout: "80%",
  };

  return (
    <Card mb={6}>
      <CardHeader
        title={title}
      />

      <CardContent>
        <ChartWrapper>
          <Chart type="doughnut" data={data} options={options} />
        </ChartWrapper>
        {
          showNoResults
          ?
          <div style={{ fontStyle: 'italic', textAlign: 'center' }}>No results...</div>
          :
          <Table>
            <TableHead>
              {
              columns.map((item) => {
                  return (
                  <TableRow>
                  <TableCell>{item[0]}</TableCell>
                  <TableCell align="right"> {item[1]}</TableCell>
                  </TableRow>
                  )
              })
              }
            </TableHead>
            <TableBody>
                {
                  rowsData.map((item) => {
                      return (
                      <TableRow style={{ cursor: 'pointer' }} onClick={() => onItemClick(item.metaData)}>
                      <TableCell component="th" scope="row"> {item[0]} </TableCell>
                      <TableCell align="right">{item[1]}</TableCell>
                      </TableRow>
                      )
                  })
                }
            </TableBody>
          </Table>
        }
      </CardContent>
    </Card>
  );
};

export default withTheme(DoughnutChart);
