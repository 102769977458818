import React from 'react';
import {
  Typography,
} from "@mui/material";
import moment from 'moment';
import { useDispatch } from 'react-redux';
import timezoneTypes from '../../../keys/timezoneTypes';
import loadStopTypes from '../../../keys/loadStopTypes';
import LoadActionTimestampDetails from '../LoadActionTimestampDetails';
import { updateExistingActionTimestamp } from '../../../redux/slices/loads';

const CompanyLocationDetailsSection = (props) => {

  const { 
    data,
    showAdditionalInfo,
    additionalData,
    allowActionTimestampsEdit,
    rawLoadStopData
  } = props;

  const dispatch = useDispatch();

  return (
    <>
      <div>
        <div>
          <Typography mt={2}>
            ID: <span style={{ fontWeight: 'bold' }}>{data?.id}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Company Name: <span style={{ fontWeight: 'bold' }}>{data?.companyName}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Address: <span style={{ fontWeight: 'bold' }}>{data?.addressName}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Commodity Type: <span style={{ fontWeight: 'bold' }}>{data?.commodityType}</span>
          </Typography>
        </div>
        <div>
          {
            rawLoadStopData?.type === loadStopTypes.customer.key &&
            rawLoadStopData?.deliveredAt &&
            rawLoadStopData?.id &&
            <Typography mt={2}>
              Delivered: {(
                <LoadActionTimestampDetails 
                  value={rawLoadStopData.deliveredAt} 
                  onChange={(value) => dispatch(updateExistingActionTimestamp({ deliveredAt: value, loadStopId: rawLoadStopData?.id }))} 
                  allowEdit={allowActionTimestampsEdit} 
                />
              )}
            </Typography> 
          }
          {
            rawLoadStopData?.type === loadStopTypes.stop.key &&
            rawLoadStopData?.id &&
            <>
              {
                rawLoadStopData?.arrivedAt &&
                <Typography mt={2}>
                  Arrived At: {(
                    <LoadActionTimestampDetails 
                      value={rawLoadStopData.arrivedAt} 
                      onChange={(value) => dispatch(updateExistingActionTimestamp({ arrivedAt: value, loadStopId: rawLoadStopData?.id }))} 
                      allowEdit={allowActionTimestampsEdit} 
                    />
                  )}
                </Typography> 
              }
              {
                rawLoadStopData?.departuredAt &&
                <Typography mt={2}>
                  Departured At: {(
                    <LoadActionTimestampDetails 
                      value={rawLoadStopData.departuredAt} 
                      onChange={(value) => dispatch(updateExistingActionTimestamp({ departuredAt: value, loadStopId: rawLoadStopData?.id }))} 
                      allowEdit={allowActionTimestampsEdit} 
                    />
                  )}
                </Typography> 
              }
            </>
          }
          {
            rawLoadStopData?.type === loadStopTypes.location.key &&
            rawLoadStopData?.pickedUpAt &&
            rawLoadStopData?.id &&
            <Typography mt={2}>
              Pickup: {(
                <LoadActionTimestampDetails 
                  value={rawLoadStopData.pickedUpAt} 
                  onChange={(value) => dispatch(updateExistingActionTimestamp({ pickedUpAt: value, loadStopId: rawLoadStopData?.id }))} 
                  allowEdit={allowActionTimestampsEdit} 
                />
              )}
            </Typography> 
          }
        </div>
        {
          showAdditionalInfo &&
          <div>
            {
              additionalData?.fixedAppt &&
              <>
                <Typography mt={2}>
                  Fixed Appointment Start Date: <span style={{ fontWeight: 'bold' }}>{moment(additionalData?.fixedApptStartDate).format("MM/DD/YYYY HH:mm")}</span>
                </Typography>
                <Typography mt={2}>
                  Fixed Appointment Start Time: <span style={{ fontWeight: 'bold' }}>{moment(additionalData?.fixedApptStartTime).format("hh:mm")}</span>
                </Typography>
                <Typography mt={2}>
                  Fixed Appointment End Date: <span style={{ fontWeight: 'bold' }}>{moment(additionalData?.fixedApptEndDate).format("MM/DD/YYYY HH:mm")}</span>
                </Typography>
                <Typography mt={2}>
                  Fixed Appointment End Time: <span style={{ fontWeight: 'bold' }}>{moment(additionalData?.fixedApptEndTime).format("hh:mm")}</span>
                </Typography>
                <Typography mt={2}>
                  Fixed Appointment Timezone: <span style={{ fontWeight: 'bold' }}>
                    {
                      additionalData?.fixedApptTimezone &&
                      timezoneTypes[additionalData.fixedApptTimezone]?.display
                      ? 
                        timezoneTypes[additionalData.fixedApptTimezone].display
                      :
                        ''
                    }</span>
                </Typography>
              </>
            }
            <Typography mt={2}>
              Activity: <span style={{ fontWeight: 'bold' }}>{additionalData?.activity}</span>
            </Typography>
            <Typography mt={2}>
              Handling Time: <span style={{ fontWeight: 'bold' }}>{additionalData?.handlingTime}</span>
            </Typography>
            <Typography mt={2}>
              Notes: <span style={{ fontWeight: 'bold' }}>{additionalData?.notes}</span>
            </Typography>
            <Typography mt={2}>
              Driver Assist Required: <span style={{ fontWeight: 'bold' }}>{additionalData?.driverAssistRequired ? 'Yes' : 'No'}</span>
            </Typography>
          </div>
        }
        {
          data?.contacts?.length
          ?
            <div style={{ marginTop: 15 }}>
              <Typography variant="h6" gutterBottom display="inline">
                  Contact(s)
              </Typography>
              {
                data.contacts.map((contact, index) => (
                  <div style={{ border: '1px solid #eee', borderRadius: 5, padding: 10, marginBottom: 15, marginTop: index + 1 === data.contacts.length ? 0 : 15 }}>
                    <Typography mt={2}>
                      First Name: <span style={{ fontWeight: 'bold' }}>{contact?.firstName}</span>
                    </Typography>
                    <Typography mt={2}>
                      Last Name: <span style={{ fontWeight: 'bold' }}>{contact?.lastName}</span>
                    </Typography>
                    <Typography mt={2}>
                      Role: <span style={{ fontWeight: 'bold' }}>{contact?.role}</span>
                    </Typography>
                    <Typography mt={2}>
                      Email: <span style={{ fontWeight: 'bold' }}>{contact?.email}</span>
                    </Typography>
                    <Typography mt={2}>
                      Phone Number: <span style={{ fontWeight: 'bold' }}>{contact?.phoneNumber}</span>
                    </Typography>
                  </div>
                ))
              }
            </div>
          :
            ''
        }
      </div>
    </>
  );

};

export default CompanyLocationDetailsSection;
