import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import { 
  fetchEquipments,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersType,
  setFiltersEquipmentNumber,
  setCurrentSelectedEquipment,
  setFiltersStatus
} from "../../redux/slices/equipments";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import equipmentTypes from '../../keys/equipmentTypes';
import moment from 'moment';

import {
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Drawer,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "type", alignment: "left", label: "Type" },
  { id: "equipmentNumber", alignment: "left", label: "Unit Number" },
  { id: "makeModel", alignment: "left", label: "Make and Model" },
  { id: "year", alignment: "left", label: "Year" },
  { id: "licencePlateNumber", alignment: "left", label: "Plate Number" },
  { id: "licencePlateExpiration", alignment: "left", label: "Plate Expiration" },
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows, setOpenFilter }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(state => state.equipments.filters.limit);
  const rowsSkip = useSelector(state => state.equipments.filters.skip);
  const rowsTotal = useSelector(state => state.equipments.total);
  const initialFetch = useSelector(state => state.equipments.initialFetch);
  
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      dispatch(setFiltersSkip(rowsPerPage + rowsSkip));
    } else {
      dispatch(setFiltersSkip(rowsSkip - rowsPerPage));
    }
    dispatch(fetchEquipments());
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setFiltersLimit(value));
    dispatch(setFiltersSkip(0));
    dispatch(fetchEquipments());
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper>
        <Toolbar>
          <Spacer />
          <div>
            <Tooltip title="Filter list">
              <IconButton onClick={() => setOpenFilter(true)} aria-label="Filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {
                rows.map((row, index) => {

                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        dispatch(setCurrentSelectedEquipment(row));
                        navigate(`/equipment/equipment-details/${row.id}`);
                      }} 
                      hover
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                     
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.type ? equipmentTypes[row.type].display : ''}</TableCell>
                      <TableCell align="left">{row.equipmentNumber}</TableCell>
                      <TableCell align="left">{row.make + ' ' + row.model}</TableCell>
                      <TableCell align="left">{row.year}</TableCell>
                      <TableCell align="left">{row.licencePlateNumber}</TableCell>
                      <TableCell align="left">{row.licencePlateExpiration ? moment(row.licencePlateExpiration).format("MM/DD/YYYY") : ''}</TableCell>
                      <TableCell padding="none" align="right">
                        
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length && initialFetch
          ?
            <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
          :
            ''
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function Equipments() {
  const navigate = useNavigate();
  const equipments = useSelector(state => state.equipments);
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    type: '-1',
    equipmentNumber: '',
    isActive: '1'
  });

  useEffect(() => {
    if (equipments && !equipments.initialFetch) dispatch(fetchEquipments());
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Equipment" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Equipment
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(setCurrentSelectedEquipment(null));
                navigate('/equipment/add');
              }}
            >
              <AddIcon />
              Add Equipment
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable setOpenFilter={setOpenFilter} rows={equipments.data} />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <div style={{ padding: 15 }}>
          <Typography textAlign="center" variant="h3">
            Filters
          </Typography>
          <Divider paddingTop="15px" />
          <div style={{ marginTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={filterValues.type}
                fullWidth
                onChange={e => setFilterValues(prevState => ({ ...prevState, type: e.target.value }))}
                variant="outlined"
                style={{ 
                  width: 300,
                  display: 'block'
                }}
              >
                <MenuItem value="-1">Select</MenuItem>
                {
                  Object.keys(equipmentTypes).map(type => (
                    <MenuItem value={equipmentTypes[type].key}>{equipmentTypes[type].display}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <div style={{ marginTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={filterValues.isActive}
                fullWidth
                onChange={e => setFilterValues(prevState => ({ ...prevState, isActive: e.target.value }))}
                variant="outlined"
                style={{ 
                  width: 300,
                  display: 'block'
                }}
              >
                <MenuItem value="-1">All</MenuItem>
                <MenuItem value="1">Active</MenuItem>
                <MenuItem value="0">Not Active</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            label="Equipment Number"
            value={filterValues.equipmentNumber}
            fullWidth
            onChange={e => setFilterValues(prevState => ({ ...prevState, equipmentNumber: e.target.value }))}
            variant="outlined"
            style={{ 
              marginTop: 20,
              width: 300,
              display: 'block',
              marginBottom: 20
            }}
          />
          <div style={{ 
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-around'
          }}>
            <Button 
              variant="text"
              color="secondary"
              onClick={() => {
                setFilterValues(() => ({
                  type: '-1',
                  equipmentNumber: '',
                  isActive: '1'
                }));
                dispatch(setFiltersEquipmentNumber(''));
                dispatch(setFiltersType('-1'));
                dispatch(setFiltersStatus('1'));
                dispatch(setFiltersSkip(0));
                dispatch(fetchEquipments());
              }}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setFiltersEquipmentNumber(filterValues.equipmentNumber));
                dispatch(setFiltersType(filterValues.type));
                dispatch(setFiltersStatus(filterValues.isActive));
                dispatch(setFiltersSkip(0));
                dispatch(fetchEquipments());
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default Equipments;
