import React from 'react';
import { useSelector } from 'react-redux';

import Stats from "../Stats";
import LineChart from '../LineChart';
import WrapperWithLoader from './components/WrapperWithLoader';

import { Grid } from "@mui/material";

const AdminDashboard = () => {

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dashboards = useSelector(state => state.dashboards);
  const dashboard = dashboards?.[currentLoggedUser?.role];

  return (
    <Grid style={{ width: 'calc(100% - 10px)' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Created Companies"
              amount={dashboard?.data?.adminNumberOfCreatedCompanies ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Created Loads"
              amount={dashboard?.data?.adminNumberOfCreatedLoads ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <LineChart
              title="Year Revenue"
              label="Sales ($)"
              lineData={dashboard?.data?.adminYearRevenue ? [...dashboard.data.adminYearRevenue] : []}
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
    </Grid>
  );

};

export default AdminDashboard;
