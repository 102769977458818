import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { setErrorDialogMessage } from './messages';
import { refreshToken } from './currentLoggedUser';

const getInitialState = () => ({
    data: [],
    filters: {
      limit: 10,
      skip: 0,
      searchField: '',
    },
    total: 0,
    initialFetch: false
  });

export const truckDriversMapSlice = createSlice({
name: "truckDriversMap",
initialState: getInitialState(),
reducers: {
    setData: (state, action) => {
    state.data = action.payload;
    return state;
    },
    setTotal: (state, action) => {
    state.total = action.payload;
    return state;
    },
    setSearchField: (state, action) => {
      state.filters.searchField = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
    state.filters.limit = action.payload;
    return state;
    },
    setFiltersSkip: (state, action) => {
    state.filters.skip = action.payload;
    return state;
    },
    setInitialFetch: (state, action) => {
    state.initialFetch = action.payload;
    return state;
    },
    resetState: () => getInitialState()
}
});

export const { 
    setData, 
    setTotal,
    setSearchField,
    setFiltersLimit,
    setFiltersSkip,
    setInitialFetch,
    resetState
  } = truckDriversMapSlice.actions;

  export const fetchTruckDriversMap = () => (dispatch, getState) => {
    if (getState().requestBusy) return;
    const state = getState();
    const filters = state && state.truckDriversMap && state.truckDriversMap.filters ? state.truckDriversMap.filters : {};
    dispatch(setRequestBusy(true));
    const queryParams = new URLSearchParams();
    queryParams.append('limit', filters.limit);
    queryParams.append('skip', filters.skip);
    queryParams.append('includeTruckDriver', '1'); 
    queryParams.append('isActive', '1'); 
    queryParams.append('searchMode', 'driversMap'); 
    if (filters?.searchField) {
      queryParams.append('driversMapEquipmentNumber', filters.searchField); 
      queryParams.append('driversMapFirstName', filters.searchField); 
      queryParams.append('driversMapLastName', filters.searchField); 
    }
    dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      axios.get(`/api/users?${queryParams.toString()}`)
        .then(response => {
          if (response.status === 200 && response.data && response.data.data) {
            dispatch(setData(response.data.data.rows));
            dispatch(setTotal(response.data.data.total));
            dispatch(setInitialFetch(true));
            dispatch(setRequestBusy(false));
          } else {
            throw new Error('');
          }
        })
        .catch(error => { 
          let errors = [];
          if (
            error && 
            error.response && 
            error.response.data && 
            error.response.data.messages && 
            Array.isArray(error.response.data.messages) && 
            error.response.data.messages.length
          ) {
            errors = error.response.data.messages;
          } else {
            errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('. ');
          dispatch(setErrorDialogMessage(errorMessage));
        });
    }));
  };

  export default truckDriversMapSlice.reducer;