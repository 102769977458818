import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components/macro";
import io from 'socket.io-client';

import timeHelper from '../../../utils/timeHelper';
import getRootPath from '../../../utils/getRootPathHelper';
import { 
  fetchChatMessages,
  attemptSendMessage,
  pushIntoChatsData,
  incrementChatsFiltersSkip,
  attemptClearChatNotifications,
} from "../../../redux/slices/loads";

import {
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  Avatar,
  Fab,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";


const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled.div`
  width: 100%;
  height: 490px;
`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: 430px;
  position: relative;
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: ${(props) => props.position};
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  width: 34px;
  height: 34px;
  bottom: 5px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
  word-break: break-all;
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const NoMessagesBox = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
  setFilePreviewData = () => {},
  thumbnailImageUrl,
  fullHdImageUrl,
}) {

  const handleImageClick = () => {
    if (setFilePreviewData) {
      setFilePreviewData(() => ({
        url: fullHdImageUrl || thumbnailImageUrl,
        type: 'image'
      }));
    }
  };

  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <div style={{ display: 'flex', justifyContent: position === "right" ? 'flex-end' : position === "left" ? 'flex-start' : 'center' }}>
          <ChatMessageAvatar alt="" src={avatar} />
        </div>
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          {
            thumbnailImageUrl
            ?
              <div onClick={handleImageClick}>
                <img 
                  src={thumbnailImageUrl} 
                  alt="" 
                  style={{ maxWidth: '100%', width: 150, height: 150, }}
                />
              </div>
            :
              <Typography variant="body2">{message}</Typography>
          }
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function LoadChat({ setFilePreviewData = () => {} }) {

  const containerElem = useRef();
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state?.currentLoggedUser);
  const currentSelectedLoad = useSelector(state => state?.loads?.currentSelectedLoad);
  const allChats = useSelector(state => state?.loads?.chats);
  const [inputValue, setInputValue] = useState('');
  let chats;
  if (allChats?.[currentSelectedLoad?.id]) chats = allChats[currentSelectedLoad.id];

  useEffect(() => {
    if (!chats.initialFetch) dispatch(fetchChatMessages(() => {
      if (containerElem.current) containerElem.current.scrollTo(0, 9999999999999999);
    }));
    if (containerElem.current) containerElem.current.scrollTo(0, 9999999999999999);
  }, [chats.initialFetch, dispatch]);

  useEffect(() => {
    const handler = () => {
      const scrollTop = containerElem?.current?.scrollTop;
      if (scrollTop < 5 && chats.hasMore) {
        dispatch(fetchChatMessages(() => {
          containerElem.current.scrollTo(0, 300);
        }));
      }
    };
    if (containerElem.current) {
      containerElem.current.addEventListener('scroll', handler);
    }
    return () => {
      containerElem?.current?.removeEventListener('scroll', handler);
    };
  }, [dispatch, chats, currentSelectedLoad]);

  useEffect(() => {
    let socket;

    const establishStream = () => {
      if (chats.initialFetch) {
        if (socket) socket.close();
        socket = io(
          `${getRootPath()}/api/loads/chats`,
          {
            query: `bearerToken=${currentLoggedUser && currentLoggedUser.bearerToken ? currentLoggedUser.bearerToken : ''}&chatId=${currentSelectedLoad?.id}`,
            transports: ['websocket']
          }
        );
        socket.on('connect', () => {
          socket.on('getAll', data => {
            if (data) {
              try {
                if (data?.createdById === currentLoggedUser?.id) return;
                dispatch(pushIntoChatsData({ 
                  id: currentSelectedLoad?.id,
                  data: [data]
                }));
                dispatch(incrementChatsFiltersSkip({ 
                  id: currentSelectedLoad?.id,
                  value: 1
                }));
                dispatch(attemptClearChatNotifications());
                containerElem.current.scrollTo(0, 9999999999999999);
              } catch (e) {}
            }
          });
        });
      }
    };

    establishStream();

    return () => {
      if (socket) socket.close();
    }
  }, [
    chats,
    currentLoggedUser,
    currentSelectedLoad,
    dispatch
  ]);

  useEffect(() => {
    if (currentSelectedLoad?.dispatcherChatNotificationsCount) dispatch(attemptClearChatNotifications());
  }, []);

  return (
    <ChatContainer container component={Card}>
      <ChatMessages ref={containerElem}>
        {
          chats?.initialFetch &&
          chats?.data?.length === 0
          ?
            <NoMessagesBox>
              No messages...
            </NoMessagesBox>
          :
            chats.data.map(item => {
              const name = item?.createdBy?.firstName + ' ' + item?.createdBy?.lastName;
              const avatar = item?.createdBy?.avatar ?? null;
              const position = item?.createdById === currentLoggedUser?.id ? 'right' : 'left';
              const message = item?.body ?? null;
              const time = item?.createdAt ? timeHelper.getTime(new Date(item.createdAt)) : null;
              const thumbnailImageUrl = item?.thumbnailImageUrl;
              const fullHdImageUrl = item?.fullHdImageUrl;

              return (
                <ChatMessageComponent
                  name={name}
                  avatar={avatar}
                  message={message}
                  time={time}
                  position={position}
                  setFilePreviewData={setFilePreviewData}
                  thumbnailImageUrl={thumbnailImageUrl}
                  fullHdImageUrl={fullHdImageUrl}
                />
              );
            })
        }
      </ChatMessages>
      <Divider />
      <ChatInput container>
        <Grid item style={{ flexGrow: 1 }}>
          <TextField 
            variant="outlined" 
            label="Type your message" 
            fullWidth 
            size="small"
            value={inputValue}
            onChange={e => {
              const value = e.target.value;
              if (value.length > 5000) return;
              setInputValue(value);
            }}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <Fab 
              style={{ position: 'relative', bottom: 2 }} 
              color="primary" 
              aria-label="add" 
              size="small"
              disabled={!inputValue.length}
              onClick={() => {
                if (!inputValue.length) return;
                dispatch(attemptSendMessage({
                  body: inputValue,
                }, () => {
                  setInputValue('');
                  if (containerElem.current) containerElem.current.scrollTo(0, 9999999999999999)
                }));
              }}
            >
              <SendIcon />
            </Fab>
          </Box>
        </Grid>
      </ChatInput>
    </ChatContainer>
  );
}

export default LoadChat;
