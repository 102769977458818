import { createSlice } from "@reduxjs/toolkit";
import { v4 } from 'uuid';
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { parseResponseError } from '../helpers/errorHandlingHelpers';
import { refreshToken } from './currentLoggedUser';
import { quickbooksRefreshToken } from './companySettings';
import { setForceShowSpinner } from "./forceShowSpinner";
import { resetState as invoicesResetState } from './invoices';
import { fetchNotifications } from './notifications';
import { getLoadBreakdownData } from '../../utils/loadHelpers';

const getCustomersInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    searchValue: '',
  },
  total: 0,
  initialFetch: false,
});

const getLocationsInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    searchValue: '',
  },
  total: 0,
  initialFetch: false,
});

const getAvailableTrailersInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
  },
  total: 0,
  initialFetch: false,
});

const getAvailableTruckDriversInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    searchValue: '',
  },
  total: 0,
  initialFetch: false,
});

const getAvailableDispatchersInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    searchValue: '',
  },
  total: 0,
  initialFetch: false,
});

const getChatsInitialState = () => ({
  data: [],
  initialFetch: false,
  filters: {
    skip: 0,
  },
  requestBusy: false,
  hasMore: true,
});

const getInitialState = () => ({
  availableTruckDrivers: getAvailableTruckDriversInitialState(),
  availableDispatchers: getAvailableDispatchersInitialState(),
  availableTrailers: getAvailableTrailersInitialState(),
  customers: getCustomersInitialState(),
  locations: getLocationsInitialState(),
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    status: '-1',
  },
  total: 0,
  initialFetch: false,
  currentSelectedLoad: null,
  currentSelectedLoadCaches: {},
  chats: {},
});

export const loadsSlice = createSlice({
  name: "loads",
  initialState: getInitialState(),
  reducers: {
    
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setAvailableTruckDriversData: (state, action) => {
      state.availableTruckDrivers.data = action.payload;
      return state;
    },
    concatAvailableTruckDriversData: (state, action) => {
      state.availableTruckDrivers.data = state.availableTruckDrivers.data.concat(action.payload);
      return state;
    },
    setAvailableDispatchersData: (state, action) => {
      state.availableDispatchers.data = action.payload;
      return state;
    },
    concatAvailableDispatchersData: (state, action) => {
      state.availableDispatchers.data = state.availableDispatchers.data.concat(action.payload);
      return state;
    },
    setAvailableTrailersData: (state, action) => {
      state.availableTrailers.data = action.payload;
      return state;
    },
    setCustomersData: (state, action) => {
      state.customers.data = action.payload;
      return state;
    },
    concatCustomersData: (state, action) => {
      state.customers.data = state.customers.data.concat(action.payload);
      return state;
    },
    setLocationsData: (state, action) => {
      state.locations.data = action.payload;
      return state;
    },
    concatLocationsData: (state, action) => {
      state.locations.data = state.locations.data.concat(action.payload);
      return state;
    },

    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setAvailableTruckDriversTotal: (state, action) => {
      state.availableTruckDrivers.total = action.payload;
      return state;
    },
    setAvailableDispatchersTotal: (state, action) => {
      state.availableDispatchers.total = action.payload;
      return state;
    },
    setAvailableTrailersTotal: (state, action) => {
      state.availableTrailers.total = action.payload;
      return state;
    },
    setCustomersTotal: (state, action) => {
      state.customers.total = action.payload;
      return state;
    },
    setLocationsTotal: (state, action) => {
      state.locations.total = action.payload;
      return state;
    },

    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFilterAvailableTruckDriversLimit: (state, action) => {
      state.availableTruckDrivers.filters.limit = action.payload;
      return state;
    },
    setFilterAvailableDispatchersLimit: (state, action) => {
      state.availableDispatchers.filters.limit = action.payload;
      return state;
    },
    setFilterAvailableTrailersLimit: (state, action) => {
      state.availableTrailers.filters.limit = action.payload;
      return state;
    },
    setFilterCustomersLimit: (state, action) => {
      state.customers.filters.limit = action.payload;
      return state;
    },
    setFilterLocationsLimit: (state, action) => {
      state.locations.filters.limit = action.payload;
      return state;
    },

    setFilterAvailableTruckDriversSearchValue: (state, action) => {
      state.availableTruckDrivers.filters.searchValue = action.payload;
      return state;
    },
    setFilterAvailableDispatchersSearchValue: (state, action) => {
      state.availableDispatchers.filters.searchValue = action.payload;
      return state;
    },
    setFilterCustomersSearchValue: (state, action) => {
      state.customers.filters.searchValue = action.payload;
      return state;
    },
    setFilterLocationsSearchValue: (state, action) => {
      state.locations.filters.searchValue = action.payload;
      return state;
    },

    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setAvailableTruckDriversSkip: (state, action) => {
      state.availableTruckDrivers.filters.skip = action.payload;
      return state;
    },
    setAvailableDispatchersSkip: (state, action) => {
      state.availableDispatchers.filters.skip = action.payload;
      return state;
    },
    setAvailableTrailersSkip: (state, action) => {
      state.availableTrailers.filters.skip = action.payload;
      return state;
    },
    setCustomersSkip: (state, action) => {
      state.customers.filters.skip = action.payload;
      return state;
    },
    setLocationsSkip: (state, action) => {
      state.locations.filters.skip = action.payload;
      return state;
    },

    setFiltersStatus: (state, action) => {
      state.filters.status = action.payload;
      return state;
    },

    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    setAvailableTruckDriversInitialFetch: (state, action) => {
      state.availableTruckDrivers.initialFetch = action.payload;
      return state;
    },
    setAvailableDispatchersInitialFetch: (state, action) => {
      state.availableDispatchers.initialFetch = action.payload;
      return state;
    },
    setAvailableTrailersInitialFetch: (state, action) => {
      state.availableTrailers.initialFetch = action.payload;
      return state;
    },
    setCustomersInitialFetch: (state, action) => {
      state.customers.initialFetch = action.payload;
      return state;
    },
    setLocationsInitialFetch: (state, action) => {
      state.locations.initialFetch = action.payload;
      return state;
    },

    setCurrentSelectedLoad: (state, action) => {
      state.currentSelectedLoad = action.payload;
      if (action?.payload?.id) {
        const oldState = state.currentSelectedLoadCaches?.[action.payload.id];
        state.currentSelectedLoadCaches[action.payload.id] = {
          ...(oldState ?? {}),
          ...action.payload,
        };
        if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      }
      return state;
    },
    updatePartialCurrentSelectedLoad: (state, action) => {
      state.currentSelectedLoad = {
        ...state.currentSelectedLoad,
        ...action.payload,
      }
      return state;
    },

    pushIntoChatsData: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].data = state.chats[action.payload.id].data.concat(action.payload.data);
      return state;
    },
    unshiftIntoChatsData: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].data = action.payload.data.concat(state.chats[action.payload.id].data);
      return state;
    },
    setChatsInitialFetch: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].initialFetch = action.payload.data;
      return state;
    },
    setChatsFiltersSkip: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].filters.skip = action.payload.data;
      return state;
    },
    setChatsRequestBusy: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].requestBusy = action.payload.data;
      return state;
    },
    setChatsHasMore: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].hasMore = action.payload.data;
      return state;
    },
    replaceChatsMessagesData: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      const targetIndex = state.chats[action.payload.id].data.map(item => item.id).indexOf(action.payload.targetId);
      if (targetIndex !== -1) {
        state.chats[action.payload.id].data[targetIndex] = action.payload.replaceData;
      }
      return state;
    },
    incrementChatsFiltersSkip: (state, action) => {
      if (!state.chats[action.payload.id]) state.chats[action.payload.id] = getChatsInitialState();
      state.chats[action.payload.id].filters.skip += action.payload.value;
      return state;
    },

    resetState: () => getInitialState(),
    resetLoadsCustomersState: (state) => {
      state.customers = getCustomersInitialState();
      return state;
    },
    resetLoadsLocationsState: (state) => {
      state.locations = getLocationsInitialState();
      return state;
    },
    resetAvailableTrailersState: (state) => {
      state.availableTrailers = getAvailableTrailersInitialState();
      return state;
    },
    resetAvailableTruckDriversState: (state) => {
      state.availableTruckDrivers = getAvailableTruckDriversInitialState();
      return state;
    },
    resetAvailableDispatchersState: (state) => {
      state.availableDispatchers = getAvailableDispatchersInitialState();
      return state;
    },

  }
});

export const { 
  setData,
  setAvailableTruckDriversData,
  concatAvailableTruckDriversData,
  setAvailableDispatchersData,
  concatAvailableDispatchersData,
  setAvailableTrailersData,
  setCustomersData,
  concatCustomersData,
  setLocationsData,
  concatLocationsData,
  setTotal,
  setAvailableTruckDriversTotal,
  setAvailableDispatchersTotal,
  setAvailableTrailersTotal,
  setCustomersTotal,
  setLocationsTotal,
  setFiltersLimit,
  setFilterAvailableTruckDriversLimit,
  setFilterAvailableDispatchersLimit,
  setFilterAvailableTrailersLimit,
  setFilterCustomersLimit,
  setFilterLocationsLimit,
  setFilterAvailableTruckDriversSearchValue,
  setFilterAvailableDispatchersSearchValue,
  setFilterCustomersSearchValue,
  setFilterLocationsSearchValue,
  setFiltersSkip,
  setAvailableTruckDriversSkip,
  setAvailableDispatchersSkip,
  setAvailableTrailersSkip,
  setCustomersSkip,
  setLocationsSkip,
  setFiltersStatus,
  setInitialFetch,
  setAvailableTruckDriversInitialFetch,
  setAvailableDispatchersInitialFetch,
  setAvailableTrailersInitialFetch,
  setCustomersInitialFetch,
  setLocationsInitialFetch,
  setCurrentSelectedLoad,
  updatePartialCurrentSelectedLoad,
  pushIntoChatsData,
  unshiftIntoChatsData,
  setChatsInitialFetch,
  setChatsFiltersSkip,
  setChatsRequestBusy,
  setChatsHasMore,
  replaceChatsMessagesData,
  incrementChatsFiltersSkip,
  resetState,
  resetLoadsCustomersState,
  resetLoadsLocationsState,
  resetAvailableTrailersState,
  resetAvailableTruckDriversState,
  resetAvailableDispatchersState
} = loadsSlice.actions;

export const fetchLoads = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.loads && state.loads.filters ? state.loads.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (filters.status && filters.status !== '-1') queryParams.append('status', filters.status);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/loads?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const addLoad = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    dispatch(setRequestBusy(true));
    axios.post(
      '/api/loads',
      { data }
    )
    .then(response => {
      if (response.status === 201 && response.data && response.data.data) {
        dispatch(resetState());
        window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
        dispatch(fetchNotifications({ refresh: true }));
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => {
      parseResponseError(error, dispatch)
      callback(error);
    })
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const updateLoad = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.put(
      '/api/loads',
      { data }
    )
    .then(response => {
      if (response.status === 200) {
        const newData = {
          id: getState().loads.currentSelectedLoad.id,
          ...data
        };
        dispatch(resetState());
        dispatch(setSuccessDialogMessage(response.data.messages[0]));
        dispatch(setCurrentSelectedLoad(newData));
        dispatch(fetchNotifications({ refresh: true }));
        callback();
      } else {
          throw new Error('');
      }
    })
    .catch(error => {
      parseResponseError(error, dispatch)
      callback(error);
    })
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const fetchCurrentSelectedLoad = (id, additionalParams = {}) => (dispatch, getState) => {

  const { refreshQuickbooksInvoiceData } = additionalParams;
  const { currentLoggedUser } = getState();
  const company = currentLoggedUser?.company;
  const quickbooksOauth2DataSaved = company?.quickbooksOauth2DataSaved;
  const quickbooksIsOauth2Logged = company?.quickbooksIsOauth2Logged;

  const shouldRefreshQuickbooksInvoiceData = quickbooksOauth2DataSaved && quickbooksIsOauth2Logged && refreshQuickbooksInvoiceData ? true : false;

  const proceedFunction = () => {
    dispatch(setForceShowSpinner(true));
    const queryParams = new URLSearchParams();
    if (shouldRefreshQuickbooksInvoiceData) queryParams.append('refreshQuickbooksInvoiceData', 1);
    axios.get(`/api/loads/${id}?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedLoad(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
      .finally(() => dispatch(setForceShowSpinner(false)));
  }

  if (shouldRefreshQuickbooksInvoiceData) {
    dispatch(quickbooksRefreshToken(err => {
      if (err) return;
      proceedFunction();
    }));
  } else {
    dispatch(refreshToken(err => {
      if (err) return;
      proceedFunction();
    }));
  }
}; 

export const fetchAvailableTruckDrivers = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const initialFetch = state?.loads?.availableTruckDrivers?.initialFetch;
  const filters = state && state.loads && state.loads.availableTruckDrivers && state.loads.availableTruckDrivers.filters ? state.loads.availableTruckDrivers.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('includeTruckDriver', '1'); 
  queryParams.append('isActive', '1'); 
  queryParams.append('searchMode', 'loadsEntitySearch'); 
  if (filters?.searchValue) {
    queryParams.append('loadsEntityEquipmentNumber', filters.searchValue); 
    queryParams.append('loadsEntityFirstName', filters.searchValue); 
    queryParams.append('loadsEntityLastName', filters.searchValue); 
  }
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/users?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (initialFetch) dispatch(concatAvailableTruckDriversData(response.data.data.rows));
          else dispatch(setAvailableTruckDriversData(response.data.data.rows));
          dispatch(setAvailableTruckDriversTotal(response.data.data.total));
          dispatch(setAvailableTruckDriversInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const fetchAvailableDispatchers = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const initialFetch = state?.loads?.availableDispatchers?.initialFetch;
  const filters = state && state.loads && state.loads.availableDispatchers && state.loads.availableDispatchers.filters ? state.loads.availableDispatchers.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('includeDispatchers', '1');
  queryParams.append('isActive', '1'); 
  queryParams.append('includeMe', '1'); 
  queryParams.append('searchMode', 'loadsEntitySearch'); 
  if (filters?.searchValue) {
    queryParams.append('loadsEntityFirstName', filters.searchValue); 
    queryParams.append('loadsEntityLastName', filters.searchValue); 
  }
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/users?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (initialFetch) dispatch(concatAvailableDispatchersData(response.data.data.rows));
          else dispatch(setAvailableDispatchersData(response.data.data.rows));
          dispatch(setAvailableDispatchersTotal(response.data.data.total));
          dispatch(setAvailableDispatchersInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const fetchAvailableTrailers = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.loads && state.loads.availableTrailers && state.loads.availableTrailers.filters ? state.loads.availableTrailers.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('includeUnassignedTrailers', '1');
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/equipments?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setAvailableTrailersData(response.data.data.rows));
          dispatch(setAvailableTrailersTotal(response.data.data.total));
          dispatch(setAvailableTrailersInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const fetchCustomers = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const initialFetch = state?.loads?.customers?.initialFetch;
  const filters = state && state.loads && state.loads.customers && state.loads.customers.filters ? state.loads.customers.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('sort', 'customerName-desc');
  queryParams.append('searchMode', 'loadsEntitySearch'); 
  if (filters?.searchValue) {
    queryParams.append('customerName', filters.searchValue); 
    queryParams.append('mcNumber', filters.searchValue); 
  }
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/company-customers?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (initialFetch) dispatch(concatCustomersData(response.data.data.rows));
          else dispatch(setCustomersData(response.data.data.rows));
          dispatch(setCustomersTotal(response.data.data.total));
          dispatch(setCustomersInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const fetchLocations = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const initialFetch = state?.loads.locations?.initialFetch;
  const filters = state && state.loads && state.loads.locations && state.loads.locations.filters ? state.loads.locations.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('sort', 'companyName-desc');
  queryParams.append('searchMode', 'loadsEntitySearch'); 
  if (filters?.searchValue) {
    queryParams.append('companyName', filters.searchValue); 
  }
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/company-locations?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (initialFetch) dispatch(concatLocationsData(response.data.data.rows));
          else dispatch(setLocationsData(response.data.data.rows));
          dispatch(setLocationsTotal(response.data.data.total));
          dispatch(setLocationsInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const setDriverAcceptLoad = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const { loads } = getState();
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.post(
      '/api/loads/driver-accept-load',
      { data: { loadId: loads?.currentSelectedLoad?.id } }
    )
    .then(response => {
      if (response.status === 200 && response?.data?.data) {
        const newData = {
          ...response?.data?.data
        };
        dispatch(resetState());
        dispatch(setSuccessDialogMessage(response.data.messages[0]));
        dispatch(setCurrentSelectedLoad(newData));
        dispatch(fetchNotifications({ refresh: true }));
      } else {
          throw new Error('');
      }
    })
    .catch(error => parseResponseError(error, dispatch))
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const setActionTimestamp = (params) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    const data = {
      loadId: params?.loadId,
      loadStopId: params?.loadStopId,
    };
    const pickedUpAt = params?.pickedUpAt;
    if (pickedUpAt) data.pickedUpAt = pickedUpAt;
    const deliveredAt = params?.deliveredAt;
    if (deliveredAt) data.deliveredAt = deliveredAt;
    const arrivedAt = params?.arrivedAt;
    if (arrivedAt) data.arrivedAt = arrivedAt;
    const departuredAt = params?.departuredAt;
    if (departuredAt) data.departuredAt = departuredAt;
    axios.post('/api/loads/set-action-timestamp', { data })
    .then(response => {
      if (response.status === 200 && response?.data?.data) {
        const newData = {
          ...response?.data?.data
        };
        dispatch(resetState());
        dispatch(setSuccessDialogMessage(response.data.messages[0]));
        dispatch(setCurrentSelectedLoad(newData));
        dispatch(invoicesResetState());
        dispatch(fetchNotifications({ refresh: true }));
      } else {
        throw new Error('');
      }
    })
    .catch(error => parseResponseError(error, dispatch))
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const updateExistingActionTimestamp = (params) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    const data = {
      loadStopId: params?.loadStopId,
    };
    const pickedUpAt = params?.pickedUpAt;
    if (pickedUpAt) data.pickedUpAt = pickedUpAt;
    const deliveredAt = params?.deliveredAt;
    if (deliveredAt) data.deliveredAt = deliveredAt;
    const arrivedAt = params?.arrivedAt;
    if (arrivedAt) data.arrivedAt = arrivedAt;
    const departuredAt = params?.departuredAt;
    if (departuredAt) data.departuredAt = departuredAt;
    axios.post('/api/loads/update-existing-action-timestamp', { data })
    .then(response => {
      if (response.status === 200) {
        const currentSelectedLoad = { ...(JSON.parse(JSON.stringify((getState()?.loads?.currentSelectedLoad ?? {})))) };
        dispatch(resetState());
        dispatch(invoicesResetState());
        // We will set locally changes here since we don't return whole object from BE in order to improve performance. BE is not setting any field that is not send from FE so we can update everything locally. If we ever have more changes on BE that FE is not aware of, this should be replaced with one that is going to be returned from BE. 
        if (currentSelectedLoad?.loadStops?.length && data?.loadStopId) {
          const loadStopTargetIndex = currentSelectedLoad.loadStops.map(item => item.id).indexOf(data.loadStopId);
          if (loadStopTargetIndex !== -1) {
            const newLoadStopData = {
              ...(currentSelectedLoad.loadStops[loadStopTargetIndex] ?? {}),
            };
            if (data.pickedUpAt) newLoadStopData.pickedUpAt = data.pickedUpAt;
            if (data.deliveredAt) newLoadStopData.deliveredAt = data.deliveredAt;
            if (data.arrivedAt) newLoadStopData.arrivedAt = data.arrivedAt;
            if (data.departuredAt) newLoadStopData.departuredAt = data.departuredAt;
            currentSelectedLoad.loadStops[loadStopTargetIndex] = newLoadStopData;
          }
          dispatch(setCurrentSelectedLoad(currentSelectedLoad));
        }
      } else {
        throw new Error('');
      }
    })
    .catch(error => parseResponseError(error, dispatch))
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const setInvoiced = (params, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    const currentSelectedLoad = getState()?.loads?.currentSelectedLoad;
    const payload = {
      loadId: params?.loadId,
    };
    if (currentSelectedLoad) {
      const { driversCost, totalRevenue } = getLoadBreakdownData(currentSelectedLoad);
      payload.companyRevenue = totalRevenue;
      if (currentSelectedLoad.dispatcherId) {
        const dispatcherId = currentSelectedLoad.dispatcherId;
        payload.dispatcherRevenue = {
          dispatcherId,
          revenue: 0, //It's 0 because we don't have math for disptacher right now.
        };
      }
      if (driversCost && Object.keys(driversCost)?.length) {
        payload.driversRevenues = Object.keys(driversCost).map(driverId => {
          const data = { driverId, revenue: 0 };
          if (driversCost?.[driverId]?.cost) data.revenue = driversCost[driverId].cost;
          return data;
        });
      }
    }
    axios.post('/api/loads/set-invoiced', { data: payload })
    .then(response => {
      if (response.status === 200 && response?.data?.data) {
        const newData = {
          ...response?.data?.data
        };
        dispatch(resetState());
        dispatch(setSuccessDialogMessage(response.data.messages[0]));
        dispatch(setCurrentSelectedLoad(newData));
        dispatch(invoicesResetState());
        dispatch(fetchNotifications({ refresh: true }));
        callback();
      } else {
          throw new Error('');
      }
    })
    .catch(error => {
      parseResponseError(error, dispatch);
      callback(error);
    })
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const setPaid = (params) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.post(
      '/api/loads/set-paid',
      { 
        data: { 
          loadId: params?.loadId
        } 
      }
    )
    .then(response => {
      if (response.status === 200 && response?.data?.data) {
        const newData = {
          ...response?.data?.data
        };
        dispatch(resetState());
        dispatch(setSuccessDialogMessage(response.data.messages[0]));
        dispatch(setCurrentSelectedLoad(newData));
        dispatch(invoicesResetState());
        dispatch(fetchNotifications({ refresh: true }));
      } else {
          throw new Error('');
      }
    })
    .catch(error => parseResponseError(error, dispatch))
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const resetAllStatuses = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const { loads } = getState();
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.post(
      '/api/loads/reset-all-statuses',
      { data: { loadId: loads?.currentSelectedLoad?.id } }
    )
    .then(response => {
      if (response.status === 200 && response?.data?.data) {
        const newData = {
          ...response?.data?.data
        };
        dispatch(resetState());
        dispatch(setSuccessDialogMessage(response.data.messages[0]));
        dispatch(setCurrentSelectedLoad(newData));
        dispatch(invoicesResetState());
        callback();
      } else {
          throw new Error('');
      }
    })
    .catch(error => {
      parseResponseError(error, dispatch);
      callback(error);
    })
    .finally(() => dispatch(setRequestBusy(false)));
  }))
};

export const fetchChatMessages = (callback = () => {}) => (dispatch, getState) => {

  const { loads } = getState();
  const LIMIT = 10;

  const currentSelectedChatId = loads?.currentSelectedLoad?.id
  const chatData = loads?.chats?.[currentSelectedChatId];
  const skip = chatData?.filters?.skip ?? 0;
  if (!chatData || chatData.requestBusy) return;

  dispatch(setChatsRequestBusy({ id: currentSelectedChatId, data: true }));

  dispatch(refreshToken(err => {
    if (err) return dispatch(setChatsRequestBusy({ id: currentSelectedChatId, data: false }));

    const queryParams = new URLSearchParams();
    queryParams.append('skip', skip);
    queryParams.append('limit', LIMIT);

    axios.get(`/api/loads/chats/${currentSelectedChatId}/messages?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.data?.rows?.length) {
            dispatch(unshiftIntoChatsData({ 
              id: currentSelectedChatId,
              data: response.data.data.rows.reverse()
            }));
            dispatch(setChatsFiltersSkip({
              id: currentSelectedChatId,
              data: skip + response.data.data.rows.length,
            }))
          } else {
            dispatch(setChatsHasMore({
              id: currentSelectedChatId,
              data: false,
            }))
          }
          dispatch(setChatsInitialFetch({ data: true, id: currentSelectedChatId }));
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => dispatch(setChatsRequestBusy({ id: currentSelectedChatId, data: false })))
  }));

}

export const attemptSendMessage = (data = {}, callback = () => {}) => (dispatch, getState) => {

  const { loads, currentLoggedUser } = getState();

  const currentSelectedLoadId = loads?.currentSelectedLoad?.id;
  if (!currentSelectedLoadId || !currentLoggedUser) return;

  const fd = new FormData();
  if (data.body) fd.append('body', data.body);

  const tempId = 'temp_' + v4();

  dispatch(pushIntoChatsData({
    id: currentSelectedLoadId,
    data: [{
      id: tempId,
      body: data.body ?? '',
      createdAt: new Date(),
      createdById: currentLoggedUser.id,
      createdBy: currentLoggedUser,
      loadId: currentSelectedLoadId,
      companyId: currentLoggedUser.companyId,
    }]
  }));

  dispatch(refreshToken(async (err) => {
    if (err) return;

    axios.post(
      `/api/loads/chats/${currentSelectedLoadId}/messages`,
      fd,
    )
    .then(response => {
      if (response.status === 200 && response?.data?.data) {
        dispatch(replaceChatsMessagesData({ 
          id: currentSelectedLoadId, 
          targetId: tempId,
          replaceData: {
            ...response?.data?.data ?? {},
            createdBy: currentLoggedUser,
          }
        }));
        dispatch(incrementChatsFiltersSkip({ 
          id: currentSelectedLoadId,
          value: 1
        }));
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => {
      parseResponseError(error, dispatch);
      callback(error);
    })
    .finally(() => dispatch(setRequestBusy(false)));

  }));

}

export const attemptClearChatNotifications = () => (dispatch, getState) => {

  const { loads } = getState();

  const currentSelectedLoadId = loads?.currentSelectedLoad?.id;
  if (!currentSelectedLoadId) return;

  dispatch(refreshToken(async (err) => {
    if (err) return;

    axios.post(`/api/loads/chats/${currentSelectedLoadId}/clear-notifications`)
      .then(() => {
        dispatch(updatePartialCurrentSelectedLoad({ 
          dispatcherChatNotificationsCount: 0 
        }));
      })
      .catch(() => {});

  }));

}


export default loadsSlice.reducer;
