import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const forceShowSpinnerSlice = createSlice({
  name: "forceShowSpinner",
  initialState,
  reducers: { 
    setForceShowSpinner: (state, action) => {
      state = action.payload;
      return state;
    }
  },
});

export const { setForceShowSpinner } = forceShowSpinnerSlice.actions;

export default forceShowSpinnerSlice.reducer;
