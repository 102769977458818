import React, { useState, useEffect, useRef, useCallback } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate
} from "react-router-dom";
import mainAxios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import axios from '../../axios/config';
import { Formik } from "formik";
import * as Icon from "@mui/icons-material";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import loadStopTypes from '../../keys/loadStopTypes';
import loadStatusTypes from '../../keys/loadStatusTypes';
import fullDrawerModalTypeKeys from "../../keys/fullDrawerModalTypeKeys";
import loadAdditionalFeeTypes from '../../keys/loadAdditionalFeeTypes';
import loadAdditionalFeeOperationTypes from "../../keys/loadAdditionalFeeOperationTypes";
import { setCurrentSelectedCompanyCustomer } from '../../redux/slices/companyCustomers';
import { setCurrentSelectedCompanyLocation } from '../../redux/slices/companyLocations';
import { setCurrentSelectedTruckDriver } from '../../redux/slices/truckDrivers';
import { setCurrentSelectedEquipment } from '../../redux/slices/equipments';
import { setCurrentSelectedUser } from '../../redux/slices/users';
import { getDirections } from "../../utils/googleApiHelpers";
import { 
  addLoad,
  fetchCurrentSelectedLoad,
  updateLoad,
  fetchAvailableDispatchers,
  fetchAvailableTrailers,
  fetchAvailableTruckDrivers,
  fetchCustomers,
  setFilterAvailableTruckDriversSearchValue,
  setFilterAvailableDispatchersSearchValue,
  setFilterCustomersSearchValue,
  setFilterLocationsSearchValue,
  setCustomersSkip,
  fetchLocations,
  setLocationsSkip,
  setAvailableTrailersSkip,
  setAvailableTruckDriversSkip,
  setAvailableDispatchersSkip,
  resetAvailableDispatchersState,
  resetAvailableTruckDriversState,
  resetAvailableTrailersState,
  resetLoadsLocationsState,
  resetLoadsCustomersState,
  setLocationsData,
  setCustomersData,
  setAvailableDispatchersData,
  setAvailableTruckDriversData
} from "../../redux/slices/loads";
import timezoneTypes from '../../keys/timezoneTypes';
import LoadMap from '../components/LoadMap';

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormControlLabel,
  Switch,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  IconButton,
  LinearProgress,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import FilePreview from "../components/FilePreview";
import DownloadIcon from '@mui/icons-material/Download';
import { useTheme } from "@mui/styles";
import { spacing } from "@mui/system";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import TimePicker from '@mui/lab/TimePicker';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import CompanyCustomerDetailsSection from "../components/CompanyCustomerDetailsSection";
import CompanyLocationDetailsSection from "../components/CompanyLocationDetailsSection";
import TruckDriverDetailsSection from "../components/TruckDriverDetailsSection";
import DispatcherDetailsSection from '../components/DispatcherDetailsSection';
import EquipmentDetails from "../components/EquipmentDetails";
import LoadBreakdown from "../components/LoadBreakdown";
import SelectList from '../components/SelectList';
import { getLoadStopsMetadata } from '../../utils/loadStopHelpers';
import { setErrorDialogMessage } from "../../redux/slices/messages";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  tripValue: "",
  tripAmount: "",
  driverId: "",
  driver: "",
  dispatcherId: "",
  dispatcher: "",
  isAcceptedByDriver: false,
  companyCustomerId: "",
  companyCustomer: "",
  uploadedFiles: [],
  status: null,
  directionsData: null,
  loadStopsData: [], // Array of LoadStop models.
  loadAdditionalFees: [], // Array of LoadAdditionalFee models.
});

const getDefaultLoadStopValues = () => ({
  fixedAppt: false,
  fixedApptStartDate: null,
  fixedApptEndDate: null,
  fixedApptStartTime: null,
  fixedApptEndTime: null,
  fixedApptTimezone: '-1',
  activity: '',
  handlingTime: '',
  notes: '',
  trailerId: null,
  driverAssistRequired: false,
  legData: null,
  legDistance: 0,
  type: '',
  companyLocationId: null
});

const getDefaultLoadAdditionalFeeValues = () => ({
  type: '-1',
  value: '',
  operationType: loadAdditionalFeeOperationTypes.addition.key
});

const getLoadStopsDataValidationValues = () => ({
  deliveryData: {
    companyLocationId: null,
    trailerId: null,
    fixedApptStartDate: null,
    fixedApptEndDate: null,
    fixedApptStartTime: null,
    fixedApptEndTime: null,
    fixedApptTimezone: null,
  },
  pickupData: {
    companyLocationId: null,
    trailerId: null,
    fixedApptStartDate: null,
    fixedApptEndDate: null,
    fixedApptStartTime: null,
    fixedApptEndTime: null,
    fixedApptTimezone: null,
  },
  additionalStopsData: [/* Array of objects which they look just like pickupData object above */],
});

function AddLoad({ isEdit }) {

  const navigate = useNavigate();
  const params = useParams();
  const theme = useTheme();
  const loadId = params && params.loadId ? params.loadId : null;
  const formikRef = useRef();
  const allowLoadStopDataValidationOnUpdate = useRef(false);
  const requestBusy = useSelector(state => state.requestBusy);
  const customersState = useSelector(state => state?.loads?.customers);
  const locationsState = useSelector(state => state?.loads?.locations);
  const availableTrailersState = useSelector(state => state?.loads?.availableTrailers);
  const availableTruckDriversState = useSelector(state => state?.loads?.availableTruckDrivers);
  const availableDispatchersState = useSelector(state => state?.loads?.availableDispatchers);
  const currentLoggedUser = useSelector(state => state?.currentLoggedUser);
  const currentSelectedLoad = useSelector(state => state?.loads?.currentSelectedLoad);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [filePreviewData, setFilePreviewData] = useState(null);
  const [deleteFileHandler, setDeleteFileHandler] = useState(null);
  const [showSearchCustomersSelectList, setShowSearchCustomersSelectList] = useState(false);
  const [showSearchPickupsSelectList, setShowSearchPickupsSelectList] = useState(false);
  const [showSearchDeliveriesSelectList, setShowSearchDeliveriesSelectList] = useState(false);
  const [showSearchStopsSelectList, setShowSearchStopsSelectList] = useState(false);
  const [showSearchAvailableTruckDriversSelectList, setShowSearchAvailableTruckDriversSelectList] = useState(false);
  const [showSearchAvailableDispatchersSelectList, setShowSearchAvailableDispatchersSelectList] = useState(false);
  const [searchStopsSelectListLoadStopIndex, setSearchStopsSelectListLoadStopIndex] = useState(null);
  const [showSearchAvailableTrailersSelectList, setShowSearchAvailableTrailersSelectList] = useState(false);
  const [searchAvailableTrailersSelectListLoadStopIndex, setSearchAvailableTrailersSelectListLoadStopIndex] = useState(null);
  const [deleteStopPending, setDeleteStopPending] = useState(null);
  
  // We are using custom validation for loadStopsData since it's too complex to handle it via formik YUP. So part of data is there and part of data validated is here.
  const [loadStopsDataValidation, setLoadStopsDataValidation] = useState(getLoadStopsDataValidationValues());

  const areLoadStopsDataValid = useCallback(() => {

    let isValid = true;

    if (formikRef?.current) {
      const form = formikRef.current;
      const loadStopsData = form?.values?.loadStopsData;
      const driver = form?.values?.driver;
      const {
        customerIndex,
        customerData,
        pickupIndex,
        pickupData,
        additionalStopsIndexes,
      } = getLoadStopsMetadata(loadStopsData);

      setLoadStopsDataValidation(() => {
        const newState = getLoadStopsDataValidationValues();
        if (customerData) {
          if (!customerData.companyLocationId) {
            newState.deliveryData.companyLocationId = 'Delivery is required';
            isValid = false;
          } else {
            if (!customerData.trailerId && !driver) {
              /*
              Uncomment in case you need to validte trailer when driver is not assigned (to require them).
              isValid = false;
              newState.customerData.trailerId = 'Trailer is required';
              */
            }
          }
        }

        if (customerIndex === null) {
          newState.deliveryData.companyLocationId = 'Delivery is required';
          isValid = false;
        }

        if (pickupData) {
          if (!pickupData.companyLocationId) {
            newState.pickupData.companyLocationId = 'Pickup is required';
            isValid = false;
          } else {
            if (!pickupData.trailerId && !driver) {
              /*
              Uncomment in case you need to validte trailer when driver is not assigned (to require them).
              isValid = false;
              newState.pickupData.trailerId = 'Trailer is required';
              */
            }
            if (pickupData.fixedAppt) {
              if (!pickupData.fixedApptStartDate) {
                newState.pickupData.fixedApptStartDate = 'Required';
                isValid = false;
              }
              if (!pickupData.fixedApptEndDate) {
                newState.pickupData.fixedApptEndDate = 'Required';
                isValid = false;
              }
              if (!pickupData.fixedApptStartTime) {
                newState.pickupData.fixedApptStartTime = 'Required';
                isValid = false;
              }
              if (!pickupData.fixedApptEndTime) {
                newState.pickupData.fixedApptEndTime = 'Required';
                isValid = false;
              }
              if (pickupData.fixedApptTimezone === '-1') {
                newState.pickupData.fixedApptTimezone = 'Required';
                isValid = false;
              }
            }
          }
        }

        if (pickupIndex === null) {
          newState.pickupData.companyLocationId = 'Pickup is required';
          isValid = false;
        }

        if (Array.isArray(additionalStopsIndexes) && additionalStopsIndexes.length) {
          newState.additionalStopsData = [];
          additionalStopsIndexes.forEach((index) => {
            const newValidationData = {};
            if (loadStopsData[index]) {
              const stopData = loadStopsData[index];
              if (!stopData.companyLocationId) {
                newValidationData.companyLocationId = 'Stop is required';
                isValid = false;
              } else {
                if (!stopData.trailerId && !driver) {
                  /*
                  Uncomment in case you need to validte trailer when driver is not assigned (to require them).
                  newValidationData.trailerId = 'Trailer is required';
                  isValid = false;
                  */
                }
                if (stopData.fixedAppt) {
                  if (!stopData.fixedApptStartDate) {
                    newValidationData.fixedApptStartDate = 'Required';
                    isValid = false;
                  }
                  if (!stopData.fixedApptEndDate) {
                    newValidationData.fixedApptEndDate = 'Required';
                    isValid = false;
                  }
                  if (!stopData.fixedApptStartTime) {
                    newValidationData.fixedApptStartTime = 'Required';
                    isValid = false;
                  }
                  if (!stopData.fixedApptEndTime) {
                    newValidationData.fixedApptEndTime = 'Required';
                    isValid = false;
                  }
                  if (stopData.fixedApptTimezone === '-1') {
                    newValidationData.fixedApptTimezone = 'Required';
                    isValid = false;
                  }
                }
              }
            }
            newState.additionalStopsData.push(newValidationData);
          });
        }

        return newState;

      });

    }

    return isValid;

  }, []);

  const yupConfig = {
    tripAmount: Yup.string().nullable().required('Required'),
    tripValue: Yup.string().nullable().required('Required'),
    loadAdditionalFees: Yup.array().of(Yup.object().shape({
      type: Yup.string().not(['-1'], 'Required'),
      value: Yup.string().nullable().required('Required'),
    })),
    companyCustomerId: Yup.string().required('Customer is required'),
    refNumber: Yup.string().nullable().max(250,"Ref Number must be at most 250 characters")
    //dispatcherId: Yup.string().required('Dispatcher is required'), In case they want dispatcher to be required, uncomment this.
  };

  if (isEdit && formikRef?.current?.values) {
    const status = formikRef.current.values.status;
    if (
      status !== null && 
      status !== loadStatusTypes.booked.key
    ) {
      yupConfig.driverId = Yup.string().required('Driver is required');
    }
  }

  useEffect(() => {
    if (loadId && isEdit) dispatch(fetchCurrentSelectedLoad(loadId));
  }, []);

  useEffect(() => {
    if (isEdit && currentSelectedLoad?.invoicedAt) setTimeout(() => navigate('/', { replace: true }), 0);
  }, [isEdit, currentSelectedLoad, navigate]);

  useEffect(() => {
    if (currentSelectedLoad) {
      let uploadedFiles = [];
      try {
        if (currentSelectedLoad && currentSelectedLoad.uploadedFiles) {
          if (typeof currentSelectedLoad.uploadedFiles === 'string') {
            uploadedFiles = JSON.parse(currentSelectedLoad.uploadedFiles);
          } else if (Array.isArray(currentSelectedLoad.uploadedFiles)) {
            uploadedFiles = JSON.parse(JSON.stringify(currentSelectedLoad.uploadedFiles));
          }
        }
      } catch (e) {}
      const data = JSON.parse(JSON.stringify(currentSelectedLoad));
        setInitialValues({ 
          ...data,
          loadStopsData: data?.loadStops ?? []
        });
      setUploadedFiles(uploadedFiles);
    }
  }, [currentSelectedLoad]);

  const handleDirections = useCallback(async () => {
    if (formikRef?.current) {
      const form = formikRef.current;
      const loadStopsData = form?.values?.loadStopsData;
      if (Array.isArray(loadStopsData) && loadStopsData?.length) {
        const params = {
          originPlaceId: null,
          waypointsPlacesIds: [],
          destinationPlaceId: null
        };
        loadStopsData.forEach((load) => {
          const loadType = load?.type;
          if (loadType === loadStopTypes.customer.key) {
            params.destinationPlaceId = load?.companyLocation?.addressPlaceId;
          } else if (loadType === loadStopTypes.stop.key) {
            if (load?.companyLocation?.addressPlaceId)
            params.waypointsPlacesIds.push(load?.companyLocation?.addressPlaceId);
          } else if (loadType === loadStopTypes.location.key) {
            params.originPlaceId = load?.companyLocation?.addressPlaceId;
          }
        });
        const directionsData = await getDirections(params);
        if (directionsData) {
          if (directionsData?.routes[0]?.overview_polyline?.points) {
            const loadStopsDataLength = loadStopsData.length;
            const googleMapLegsLenght = directionsData?.routes[0]?.legs?.length;
            if ((loadStopsDataLength - 1) === googleMapLegsLenght) {
              directionsData.routes[0].legs.forEach((leg, index) => {
                let legDistance = leg?.distance?.text;
                if (legDistance) legDistance = legDistance.split(' ')[0];
                legDistance = legDistance.replace(',', '');
                loadStopsData[index + 1] = {
                  ...loadStopsData[index + 1],
                  legData: leg,
                  legDistance: parseFloat(legDistance)
                };
              });
              form.handleChange({
                target: {
                  value: loadStopsData,
                  name: 'loadStopsData' 
                }
              });
            }
            form.handleChange({
              target: {
                value: directionsData,
                name: 'directionsData' 
              }
            });
          } else {
            if (params?.destinationPlaceId && params?.originPlaceId) {
              dispatch(setErrorDialogMessage('Not able to find full Google Maps Route.'));
            }
          }
        } else {
          if (params?.destinationPlaceId && params?.originPlaceId) {
            dispatch(setErrorDialogMessage('Not able to find full Google Maps Route.'));
          }
        }
      }
    }
  }, [dispatch]);

  const setDelivery = useCallback((id) => {
    if (id && formikRef?.current) {
      const form = formikRef.current
      const loadStopsData = form?.values?.loadStopsData;
      if (Array.isArray(loadStopsData)) {
        const targetLoadStopIndex = loadStopsData.map(item => item.type).indexOf(loadStopTypes.customer.key);
        const newData = {
          ...getDefaultLoadStopValues(),
          type: loadStopTypes.customer.key,
          companyLocationId: id,
        };
        const locationData = locationsState?.data.find(item => item.id === id);
        if (locationData) newData.companyLocation = locationData;
        if (targetLoadStopIndex === -1) loadStopsData.push(newData);
        else loadStopsData[targetLoadStopIndex] = { ...newData };
        form.handleChange({
          target: {
            value: [...loadStopsData],
            name: 'loadStopsData' 
          }
        });
        handleDirections();
      }
    }
  }, [locationsState, handleDirections]);

  const setPickup = useCallback((id) => {
    if (id && formikRef?.current) {
      const form = formikRef.current
      const loadStopsData = form?.values?.loadStopsData;
      if (Array.isArray(loadStopsData)) {
        const targetLoadStopIndex = loadStopsData.map(item => item.type).indexOf(loadStopTypes.location.key);
        const newData = {
          ...getDefaultLoadStopValues(),
          type: loadStopTypes.location.key,
          companyLocationId: id,
        };
        const locationData = locationsState?.data.find(item => item.id === id);
        if (locationData) newData.companyLocation = locationData;
        if (targetLoadStopIndex === -1) loadStopsData.unshift({ ...newData });
        else loadStopsData[targetLoadStopIndex] = { ...newData };
        form.handleChange({
          target: {
            value: [...loadStopsData],
            name: 'loadStopsData' 
          }
        });
        handleDirections();
      }
    }
  }, [locationsState, handleDirections]);

  const setStop = useCallback((id) => {
    if (id && formikRef?.current) {
      const form = formikRef.current
      const loadStopsData = form?.values?.loadStopsData;
      if (Array.isArray(loadStopsData)) {
        const targetLoadStopIndex = searchStopsSelectListLoadStopIndex;
        const newData = {
          ...getDefaultLoadStopValues(),
          type: loadStopTypes.stop.key,
          companyLocationId: id,
        };
        const locationData = locationsState?.data.find(item => item.id === id);
        if (locationData) newData.companyLocation = locationData;
        if (loadStopsData[targetLoadStopIndex] && loadStopsData[targetLoadStopIndex].type === loadStopTypes.stop.key) {
          loadStopsData[targetLoadStopIndex] = { ...newData };
        }
        form.handleChange({
          target: {
            value: [...loadStopsData],
            name: 'loadStopsData' 
          }
        });
        handleDirections();
      }
    }
  }, [locationsState, searchStopsSelectListLoadStopIndex, handleDirections]);

  const addStop = useCallback(() => {
    if (formikRef?.current) {
      const form = formikRef.current
      const loadStopsData = form?.values?.loadStopsData;
      const newData = {
        ...getDefaultLoadStopValues(),
        type: loadStopTypes.stop.key,
      }
      if (Array.isArray(loadStopsData)) {
        const lastStop = loadStopsData[loadStopsData.length -1];
        if (lastStop?.type === loadStopTypes.customer.key) {
          loadStopsData.splice(loadStopsData.length - 1, 0, newData); //If there is last stop "customer", push it then before that  loadStop. If not, push it as last loadStop of array.
        } else {
          loadStopsData.push(newData);
        }
      }
      form.handleChange({
        target: {
          value: [...loadStopsData],
          name: 'loadStopsData' 
        }
      });
      handleDirections();
    }
  }, [handleDirections]);

  const setAdditionalFee = useCallback((index = null, data = {}) => {
    if (formikRef?.current) {
      const form = formikRef.current;
      const loadAdditionalFeesData = form?.values?.loadAdditionalFees;
      if (index === null) {
        loadAdditionalFeesData.push({ ...getDefaultLoadAdditionalFeeValues() });
      } else {
        if (loadAdditionalFeesData[index]) {
          loadAdditionalFeesData[index] = { 
            ...loadAdditionalFeesData[index],
            ...data 
          }
        }
      }
      form.handleChange({
        target: {
          value: [...loadAdditionalFeesData],
          name: 'loadAdditionalFees' 
        }
      });
    }
  }, []);

  const handleRemoveAdditionalFee = useCallback((index) => {
    if (formikRef?.current) {
      const form = formikRef.current;
      const loadAdditionalFeesData = form?.values?.loadAdditionalFees;
      if (loadAdditionalFeesData[index]) loadAdditionalFeesData.splice(index, 1);
      form.handleChange({
        target: {
          value: [...loadAdditionalFeesData],
          name: 'loadAdditionalFees' 
        }
      });
    }
  }, [])

  const deleteStop = useCallback((index) => {
    if (formikRef?.current) {
      const form = formikRef.current
      const loadStopsData = form?.values?.loadStopsData;
      if (Array.isArray(loadStopsData)) loadStopsData.splice(index, 1);
      form.handleChange({
        target: {
          value: [...loadStopsData],
          name: 'loadStopsData' 
        }
      });
    }
    handleDirections();
  }, [handleDirections]);

  const updateLoadStop = useCallback((index = null, updateData = {}) => {
    if (index !== null && formikRef?.current) {
      const form = formikRef.current
      const loadStopsData = form?.values?.loadStopsData;
      if (Array.isArray(loadStopsData) && loadStopsData[index]) {
        loadStopsData[index] = {
          ...loadStopsData[index],
          ...updateData,
        };
        form.handleChange({
          target: {
            value: [...loadStopsData],
            name: 'loadStopsData' 
          }
        });
      }
    }
  }, []);

  const updateDriver = useCallback((id) => {
    const form = formikRef.current;
    if (id && form) {
      form.handleChange({
        target: {
          value: id,
          name: 'driverId' 
        }
      });
      const driverData = availableTruckDriversState?.data.find(item => item.id === id);
      if (driverData) {
        form.handleChange({
          target: {
            value: driverData,
            name: 'driver' 
          }
        });
      }
      window.setTimeout(() => form.validateForm(), 0);
    }
  }, [availableTruckDriversState]);

  const updateDispatchers = useCallback((id) => {
    const form = formikRef.current;
    if (id && form) {
      form.handleChange({
        target: {
          value: id,
          name: 'dispatcherId' 
        }
      });
      const dispatcherData = availableDispatchersState?.data.find(item => item.id === id);
      if (dispatcherData) {
        form.handleChange({
          target: {
            value: dispatcherData,
            name: 'dispatcher' 
          }
        });
      }
      window.setTimeout(() => form.validateForm(), 0);
    }
  }, [availableDispatchersState]);

  const updateCustomer = useCallback((id) => {
    const form = formikRef.current;
    if (id && form) {
      form.handleChange({
        target: {
          value: id,
          name: 'companyCustomerId' 
        }
      });
      const customerData = customersState?.data.find(item => item.id === id);
      if (customerData) {
        form.handleChange({
          target: {
            value: customerData,
            name: 'companyCustomer' 
          }
        });
      }
      window.setTimeout(() => form.validateForm(), 0);
    }
  }, [customersState]);

  const openSearchCustomersSelectList = useCallback(() => {
    setShowSearchCustomersSelectList(true);
    if (customersState?.data?.length === 0) dispatch(fetchCustomers());
  }, [customersState, dispatch]);

  const openSearchPickupSelectList = useCallback(() => {
    setShowSearchPickupsSelectList(true);
    if (locationsState?.data?.length === 0) dispatch(fetchLocations());
  }, [locationsState, dispatch]);

  const openSearchDeliverySelectList = useCallback(() => {
    setShowSearchDeliveriesSelectList(true);
    if (locationsState?.data?.length === 0) dispatch(fetchLocations());
  }, [locationsState, dispatch]);

  const openSearchStopsSelectList = useCallback((index) => {
    setShowSearchStopsSelectList(true);
    setSearchStopsSelectListLoadStopIndex(index);
    if (locationsState?.data?.length === 0) dispatch(fetchLocations());
  }, [locationsState, dispatch]);

  const openSearchAvailableTrailersSelectList = useCallback((index) => {
    setShowSearchAvailableTrailersSelectList(true);
    setSearchAvailableTrailersSelectListLoadStopIndex(index);
    if (availableTrailersState?.data?.length === 0) dispatch(fetchAvailableTrailers());
  }, [availableTrailersState, dispatch]);

  const openSearchAvailableTruckDriversSelectList = useCallback(() => {
    setShowSearchAvailableTruckDriversSelectList(true);
    if (availableTruckDriversState?.data?.length === 0) dispatch(fetchAvailableTruckDrivers());
  }, [availableTruckDriversState, dispatch]);

  const openSearchAvailableDispatchersSelectList = useCallback(() => {
    setShowSearchAvailableDispatchersSelectList(true);
    if (availableDispatchersState?.data?.length === 0) dispatch(fetchAvailableDispatchers());
  }, [availableDispatchersState, dispatch]);

  const filesRequestMetadata = useRef({});
  const fileDropAreaElem = useRef(null);

  useEffect(() => {
    uploadedFiles.forEach((file, index) => {
      if ((file && (file.uploadFinish || file.uploadFinish !== false)) || (filesRequestMetadata.current[file.requestMetadataId] && filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction)) return;
      const CancelToken = mainAxios.CancelToken;
      const source = CancelToken.source();
      const bodyFormData = new FormData();
      bodyFormData.append(
        'file', 
        filesRequestMetadata.current[file.requestMetadataId] && 
        filesRequestMetadata.current[file.requestMetadataId].requestPayload 
        ? 
        filesRequestMetadata.current[file.requestMetadataId].requestPayload 
        : 
          null
      );
      bodyFormData.append('bucketFolder', 'loads-files');

      axios.post(
        '/api/loads/upload-files', 
        bodyFormData,
        {
          cancelToken: source.token,
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / e.total);
            setUploadedFiles(prevState => {
              const uploadedFiles = [ ...prevState ];
              if (uploadedFiles[index]) uploadedFiles[index].uploadPercentages = progress;
              return [...uploadedFiles];
            });
          },
          timeout: 1000 * 60
        }
      ).then(res => {
        if (res.data.data) {
          setUploadedFiles(prevState => {
            const uploadedFiles = [ ...prevState ];
            if (uploadedFiles[index]) {
              uploadedFiles[index].urls = res.data.data;
              uploadedFiles[index].uploadFinish = true;
            }
            return [...uploadedFiles];
          });
        }
      }).catch((e) => {
        dispatch(setErrorDialogMessage('Error while uploading files.'));
        setUploadedFiles(prevState => {
          const uploadedFiles = [ ...prevState ];
          if (uploadedFiles[index]) uploadedFiles[index].errored = true;
          return [...uploadedFiles];
        });
      });
      filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction = source.cancel;
    });
  }, [uploadedFiles]);

  useEffect(() => {
    if (fileDropAreaElem.current) {
      const prevents = e => e.preventDefault();

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(e => {
        fileDropAreaElem.current.addEventListener(e, prevents);
      });

      fileDropAreaElem.current.addEventListener('drop', e => handleFilePicker(e));

    }
  }, []);

  const handleFilePicker = useCallback(e => {
    if (!e) return
    let files = [];
    if (e.target && e.target.files && e.target.files.length) files = [...e.target.files];
    else if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) files = [...e.dataTransfer.files];
    if (!files.length) return;

    const cleanValidatedFiles = [];
    files.forEach(file => {
      const whitelistFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
      const maxSize = 50000000; //50mb
      if (!whitelistFileTypes.find(type => type === file.type)) dispatch(setErrorDialogMessage('File can be only type of: JPG, PNG or PDF.'));
      else if (file.size > maxSize) dispatch(setErrorDialogMessage('Maximum size of the file is 50MB.'));
      else cleanValidatedFiles.push(file);
    });

    setUploadedFiles(prevState => {
      const uploadedFiles = [...prevState]; 

      cleanValidatedFiles.forEach(file => {
        const requestMetadataId = uuidv4() + Date.now();
        uploadedFiles.push({
          urls: {
            fullHdSize: null,
            thumbnailSize: null,
            file: null,
          },
          name: file.name,
          uploadFinish: false,
          uploadPercentages: 0,
          requestMetadataId,
          errored: false
        });
        filesRequestMetadata.current[requestMetadataId] = {
          requestPayload: file,
          requestCancelFunction: null
        };
      });

      return [ ...uploadedFiles ];
    });
  }, [dispatch]);

  const validationSchema = Yup.object().shape(yupConfig);

  const handleSubmit = useCallback(async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (!areLoadStopsDataValid()) return;
      let mappedUploadedFiles = [];
      if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length) {
        mappedUploadedFiles = uploadedFiles.map(item => ({ urls: item.urls, name: item.name, requestMetadataId: item.requestMetadataId, isPodFile: item.isPodFile, userId: currentLoggedUser.id, }));
      }
      if (values?.loadStopsData?.length) {
        values.loadStopsData.forEach((item, index) => {
          if (item.fixedApptTimezone === '-1') delete values.loadStopsData[index].fixedApptTimezone;
        });
      }
      if (isEdit) {
        const data = {
          loadData: {
            id: currentSelectedLoad && currentSelectedLoad.id ? currentSelectedLoad.id : null,
            tripValue: values.tripValue,
            tripAmount: values.tripAmount,
            driverId: values?.driverId !== '' ? values.driverId : null,
            dispatcherId: values?.dispatcherId !== '' ? values.dispatcherId :  null,
            directionsData: values.directionsData,
            uploadedFiles: JSON.stringify(mappedUploadedFiles),
            companyCustomerId: values.companyCustomerId,
            refNumber: values.refNumber ?? null,
          },
          loadStopsData: values.loadStopsData,
          loadAdditionalFees: values.loadAdditionalFees,
        };
        dispatch(updateLoad(data,err => {
          if (err) return;
          setSubmitting(false);
          window.setTimeout(() => navigate(-1), 0);
        }));
      } else {
        const data = {
          loadData: {
            tripValue: values.tripValue,
            tripAmount: values.tripAmount,
            driverId: values?.driverId !== '' ? values.driverId : null,
            dispatcherId: values?.dispatcherId !== '' ? values.dispatcherId :  null,
            directionsData: values.directionsData,
            uploadedFiles: JSON.stringify(mappedUploadedFiles),
            companyCustomerId: values.companyCustomerId,
            refNumber: values.refNumber ?? null,
          },
          loadStopsData: values.loadStopsData,
          loadAdditionalFees: values.loadAdditionalFees,
        };
        dispatch(addLoad(data, err => {
          if (err) return;
          setSubmitting(false);
          window.setTimeout(() => navigate(-1), 0);
        }));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  }, [currentSelectedLoad, isEdit, uploadedFiles, dispatch, navigate, areLoadStopsDataValid, currentLoggedUser]);

  return (
    <React.Fragment>
      <Helmet title={ isEdit ? 'Edit Load' : 'Add Load' }/>
      <Typography variant="h3" gutterBottom display="inline">
        { isEdit ? 'Edit Load' : 'Add Load' }
      </Typography>
      {
        isEdit
        ?
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
              Loads
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
              Load Details
            </Link>
            <Typography>Edit Load</Typography>
          </Breadcrumbs>
        :
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
              Loads
            </Link>
            <Typography>Add Load</Typography>
          </Breadcrumbs>
      }

      <Divider my={6} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
        validate={() => {
          if (allowLoadStopDataValidationOnUpdate.current) return areLoadStopsDataValid();
          return true;
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => {
        
          const {
            customerData,
            customerIndex,
            pickupData,
            pickupIndex,
            additionalStopsIndexes,
          } = getLoadStopsMetadata(values.loadStopsData);

          console.log(errors, values, '<< FORM VALUES !!');

          return (
            <>
              <form onSubmit={handleSubmit}>
                <Card mb={6}>
                  <CardContent>
                    <LoadMap 
                      width="100%"
                      height="400px"
                      polyline={values?.directionsData?.routes[0]?.overview_polyline?.points}
                      locationsData={values.loadStopsData}
                      truckDriver={values?.driver}
                    />
                  </CardContent>
                </Card>
                <Card mb={6}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                      Load Information
                    </Typography>
                    <div style={{ marginBottom: 20, display: 'flex' }}>
                      <TextField
                        name="refNumber"
                        label="Ref Number"
                        value={values.refNumber}
                        error={Boolean(touched.refNumber && errors.refNumber)}
                        helperText={touched.refNumber && errors.refNumber}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        style={{ flexBasis: 250, marginRight: 10 }}
                      />
                      <TextField
                        InputProps={{
                          startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                        }}
                        type="number"
                        name="tripAmount"
                        label="Trip Amount*"
                        value={values.tripAmount}
                        error={Boolean(touched.tripAmount && errors.tripAmount)}
                        helperText={touched.tripAmount && errors.tripAmount}
                        onBlur={handleBlur}
                        onChange={e => {
                          const value = e.target.value ? parseFloat(e.target.value) : null;
                          if (value < 0) return;
                          handleChange({ target: {value, name: 'tripAmount' }});
                        }}
                        variant="outlined"
                        my={2}
                        style={{ flexBasis: 250, marginRight: 10 }}
                        inputProps={{ min: 0, step: "any" }}
                      />
                      <TextField
                        InputProps={{
                          startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                        }}
                        type="number"
                        name="tripValue"
                        label="Trip Value*"
                        value={values.tripValue}
                        error={Boolean(touched.tripValue && errors.tripValue)}
                        helperText={touched.tripValue && errors.tripValue}
                        onBlur={handleBlur}
                        onChange={e => {
                          const value = e.target.value ? parseFloat(e.target.value) : null;
                          if (value < 0) return;
                          handleChange({ target: {value, name: 'tripValue' }});
                        }}
                        variant="outlined"
                        my={2}
                        style={{ flexBasis: 250 }}
                        inputProps={{ min: 0, step: "any" }}
                      />
                    </div>
                    <Divider />
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Customer Information
                        {
                          touched.companyCustomerId && 
                          errors.companyCustomerId ?
                          <div>
                            <Typography 
                              variant="p" 
                              style={{ 
                                fontSize: 13, 
                                fontWeight: 'normal',
                                color: theme?.palette?.error?.main
                              }}
                            >{errors.companyCustomerId}</Typography>
                          </div> : ''
                        }
                      </Typography>
                      {
                        values.companyCustomer
                        ?
                          <>
                            <CompanyCustomerDetailsSection data={values.companyCustomer} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button 
                                variant="contained"
                                onClick={openSearchCustomersSelectList}
                                style={{ width: 160 }}
                              >
                                Change Customer
                              </Button>
                            </div>
                          </>
                        :
                          <Button 
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={openSearchCustomersSelectList}
                          >
                            Set Customer
                          </Button>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Pickup
                        {
                          loadStopsDataValidation?.pickupData.companyLocationId ?
                          <div>
                            <Typography 
                              variant="p" 
                              style={{ 
                                fontSize: 13, 
                                fontWeight: 'normal',
                                color: theme?.palette?.error?.main
                              }}
                            >{loadStopsDataValidation.pickupData.companyLocationId}</Typography>
                          </div> : ''
                        }
                      </Typography>
                      {
                        pickupData
                        ?
                          <>
                            <CompanyLocationDetailsSection
                              data={pickupData.companyLocation}
                              rawLoadStopData={pickupData}
                              allowActionTimestampsEdit={isEdit}
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                              <Button 
                                variant="contained"
                                onClick={openSearchPickupSelectList}
                                style={{ width: 150 }}
                              >
                                Change Location
                              </Button>
                            </div>
                            <div>
                              <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                Trailer Information
                                <div>
                                  <Typography 
                                    variant="p" 
                                    style={{ 
                                      fontSize: 9, 
                                      fontWeight: 'normal',
                                      fontStyle: 'italic',
                                      color: '#000',
                                      opacity: 0.7,
                                      marginTop: -5
                                    }}
                                  >
                                    In case truck driver's trailer won't be used on this stop, select a specific one here.
                                  </Typography>
                                </div>
                                {
                                  loadStopsDataValidation?.pickupData.trailerId ?
                                  <div>
                                    <Typography 
                                      variant="p" 
                                      style={{ 
                                        fontSize: 13, 
                                        fontWeight: 'normal',
                                        color: theme?.palette?.error?.main
                                      }}
                                    >{loadStopsDataValidation.pickupData.trailerId}</Typography>
                                  </div> : ''
                                }
                                  </Typography>
                              {
                                pickupData?.trailerId
                                ?
                                  <>
                                    <EquipmentDetails
                                      data={pickupData?.trailer}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                      <Button 
                                        variant="contained" 
                                        onClick={() => openSearchAvailableTrailersSelectList(pickupIndex)}
                                        style={{ width: 150 }}
                                      >
                                        Change Trailer
                                      </Button>
                                    </div>
                                  </>
                                :
                                  <div style={{ marginTop: 15 }}>
                                    <Button 
                                      variant="contained" 
                                      onClick={() => openSearchAvailableTrailersSelectList(pickupIndex)}
                                      style={{ width: 150 }}
                                    >
                                      Set Specific Trailer
                                    </Button>
                                  </div>
                              }
                            </div>
                            <Typography variant="h6" gutterBottom style={{ marginTop: 15, marginBottom: 15 }}>
                              Additional Information
                            </Typography>
                            <div style={{ marginTop: 15 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={pickupData.fixedAppt}
                                    onChange={(e) => {
                                      updateLoadStop(pickupIndex, {
                                        fixedAppt: e.target.checked
                                      });
                                    }}
                                  />
                                }
                                label="Fixed Appt."
                              />
                            </div>
                            {
                              pickupData.fixedAppt &&
                              <div style={{ display: 'flex', marginTop: 5 }}>
                                <DesktopDatePicker
                                  label="Start Date*"
                                  inputFormat="MM/dd/yyyy"
                                  value={pickupData.fixedApptStartDate}
                                  fullWidth
                                  onChange={value => updateLoadStop(pickupIndex, {
                                    fixedApptStartDate: value
                                  })}
                                  renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params}  error={Boolean(loadStopsDataValidation?.pickupData?.fixedApptStartDate)}                     helperText={loadStopsDataValidation?.pickupData?.fixedApptStartDate} />}
                                  variant="outlined"
                                  my={2}
                                />
                                <TimePicker 
                                  renderInput={
                                    (params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} 
                                                  {...params} 
                                                  error={Boolean(loadStopsDataValidation?.pickupData?.fixedApptStartTime)} 
                                                  helperText={loadStopsDataValidation?.pickupData?.fixedApptStartTime} 
                                                />
                                }
                                  label="Start Time*"
                                  value={pickupData.fixedApptStartTime}
                                  onChange={value => updateLoadStop(pickupIndex, {
                                    fixedApptStartTime: value
                                  })}
                                />
                                <DesktopDatePicker
                                  label="End Date*"
                                  inputFormat="MM/dd/yyyy"
                                  value={pickupData.fixedApptEndDate}
                                  fullWidth
                                  onChange={value => updateLoadStop(pickupIndex, {
                                    fixedApptEndDate: value
                                  })}
                                  renderInput={(params) => <TextField 
                                                              my={2} 
                                                              style={{ flex: 1, marginLeft: 10 }}
                                                              {...params} 
                                                              error={Boolean(loadStopsDataValidation?.pickupData?.fixedApptEndDate)} helperText={loadStopsDataValidation?.pickupData?.fixedApptEndDate} 
                                                            />
                                  }
                                  variant="outlined"
                                  my={2}
                                />
                                <TimePicker 
                                  renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(loadStopsDataValidation?.pickupData?.fixedApptEndTime)} helperText={loadStopsDataValidation?.pickupData?.fixedApptEndTime} />}
                                  label="End Time*"
                                  value={pickupData.fixedApptEndTime}
                                  onChange={value => updateLoadStop(pickupIndex, {
                                    fixedApptEndTime: value
                                  })}
                                />
                                <div style={{ marginLeft: 10, marginBottom: 8, marginTop: 8, flex: 1 }}>
                                  <FormControl fullWidth>
                                    <InputLabel>Timezone*</InputLabel>
                                    <Select
                                      label="Timezone*"
                                      value={pickupData.fixedApptTimezone}
                                      error={Boolean(loadStopsDataValidation?.pickupData?.fixedApptTimezone)}
                                      helperText={loadStopsDataValidation?.pickupData?.fixedApptTimezone}
                                      onChange={e => updateLoadStop(pickupIndex, {
                                        fixedApptTimezone: e.target.value
                                      })}
                                      variant="outlined"
                                      my={2}
                                      name="type"
                                      style={{ width: 200 }}
                                    >
                                      <MenuItem value="-1">Select</MenuItem>
                                      {
                                        Object.keys(timezoneTypes).map(item => (
                                          <MenuItem value={timezoneTypes[item].key}>{timezoneTypes[item].display}</MenuItem>
                                        ))
                                      }
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            }
                            <div style={{ display: 'flex' }}>
                              <TextField
                                name="emergencyFirstName"
                                label="Activity"
                                value={pickupData.activity}
                                style={{ flex: 1 }}
                                error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                fullWidth
                                helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                onChange={e => updateLoadStop(pickupIndex, {
                                  activity: e.target.value
                                })}
                                variant="outlined"
                                my={2}
                              />
                              <TextField
                                name="emergencyFirstName"
                                label="Handling Time"
                                value={pickupData.handlingTime}
                                style={{ flex: 1, marginLeft: 10 }}
                                error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                fullWidth
                                helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                onChange={e => updateLoadStop(pickupIndex, {
                                  handlingTime: e.target.value
                                })}
                                variant="outlined"
                                my={2}
                              />
                              <TextField
                                name="emergencyFirstName"
                                label="Notes"
                                value={pickupData.notes}
                                style={{ flex: 1, marginLeft: 10 }}
                                error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                fullWidth
                                helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                onChange={e => updateLoadStop(pickupIndex, {
                                  notes: e.target.value
                                })}
                                variant="outlined"
                                my={2}
                              />
                            </div>
                            <div style={{ marginTop: 5 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={pickupData.driverAssistRequired}
                                    onChange={e => updateLoadStop(pickupIndex, {
                                      driverAssistRequired: e.target.checked
                                    })}
                                  />
                                }
                                label="Driver Assist Required"
                              />
                            </div>
                          </>
                        :
                          <Button 
                            variant="contained" 
                            startIcon={<SearchIcon />}
                            onClick={openSearchPickupSelectList}
                          >
                            Search Locations
                          </Button>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Additional Stops
                      </Typography>
                      {
                        additionalStopsIndexes.map((loadStopIndex, index) => {

                          const loadStopData = values?.loadStopsData?.length &&
                          values.loadStopsData[loadStopIndex] ? values.loadStopsData[loadStopIndex] : null;

                          return (
                            <div style={{ marginBottom: 20 }}>
                              <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                Stop #{index + 1}
                                {
                                  loadStopsDataValidation?.additionalStopsData[index]?.companyLocationId ?
                                  <div>
                                    <Typography 
                                      variant="p" 
                                      style={{ 
                                        fontSize: 13, 
                                        fontWeight: 'normal',
                                        color: theme?.palette?.error?.main
                                      }}
                                    >{loadStopsDataValidation?.additionalStopsData[index].companyLocationId}</Typography>
                                  </div> : ''
                                }
                              </Typography>
                              {
                                loadStopData
                                ?
                                  <>
                                    {
                                      loadStopData.companyLocation
                                      ?
                                        <>
                                          <CompanyLocationDetailsSection 
                                            data={loadStopData.companyLocation}
                                            rawLoadStopData={loadStopData}
                                            allowActionTimestampsEdit={isEdit}
                                          />
                                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                            <Button 
                                              variant="contained"
                                              onClick={() => openSearchStopsSelectList(loadStopIndex)}
                                              style={{ width: 150 }}
                                            >
                                              Change Location
                                            </Button>
                                          </div>
                                          <div>
                                            <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                              Trailer Information
                                              <div>
                                                <Typography 
                                                  variant="p" 
                                                  style={{ 
                                                    fontSize: 9, 
                                                    fontWeight: 'normal',
                                                    fontStyle: 'italic',
                                                    color: '#000',
                                                    opacity: 0.7,
                                                    marginTop: -5
                                                  }}
                                                >
                                                  In case truck driver's trailer won't be used on this stop, select a specific one here.
                                                </Typography>
                                              </div>
                                              {
                                                loadStopsDataValidation?.additionalStopsData[index]?.trailerId ?
                                                <div>
                                                  <Typography 
                                                    variant="p" 
                                                    style={{ 
                                                      fontSize: 13, 
                                                      fontWeight: 'normal',
                                                      color: theme?.palette?.error?.main
                                                    }}
                                                  >{loadStopsDataValidation?.additionalStopsData[index].trailerId}</Typography>
                                                </div> : ''
                                              }
                                            </Typography>
                                            {
                                              loadStopData?.trailerId
                                              ?
                                                <>
                                                  <EquipmentDetails
                                                    data={loadStopData?.trailer}
                                                  />
                                                  <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                                    <Button 
                                                      variant="contained" 
                                                      onClick={() => openSearchAvailableTrailersSelectList(loadStopIndex)}
                                                      style={{ width: 150 }}
                                                    >
                                                      Change Trailer
                                                    </Button>
                                                  </div>
                                                </>
                                              :
                                                <div style={{ marginTop: 15 }}>
                                                  <Button 
                                                    variant="contained" 
                                                    onClick={() => openSearchAvailableTrailersSelectList(loadStopIndex)}
                                                    style={{ width: 150 }}
                                                  >
                                                    Set Specific Trailer
                                                  </Button>
                                                </div>
                                            }
                                          </div>
                                          <Typography variant="h6" gutterBottom style={{ marginTop: 15, marginBottom: 15 }}>
                                            Additional Information
                                          </Typography>
                                          <div style={{ marginTop: 5 }}>
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={loadStopData.fixedAppt}
                                                  onChange={(e) => {
                                                    updateLoadStop(loadStopIndex, {
                                                      fixedAppt: e.target.checked
                                                    });
                                                  }}
                                                />
                                              }
                                              label="Fixed Appt."
                                            />
                                          </div>
                                          {
                                            loadStopData.fixedAppt &&
                                            <div style={{ display: 'flex', marginTop: 5 }}>
                                              <DesktopDatePicker
                                                label="Start Date*"
                                                inputFormat="MM/dd/yyyy"
                                                value={loadStopData.fixedApptStartDate}
                                                fullWidth
                                                onChange={value => updateLoadStop(loadStopIndex, {
                                                  fixedApptStartDate: value
                                                })}
                                                renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params}  error={Boolean(loadStopsDataValidation?.additionalStopsData[index]?.fixedApptStartDate)}                     helperText={loadStopsDataValidation?.additionalStopsData[index]?.fixedApptStartDate} />}
                                                variant="outlined"
                                                my={2}
                                              />
                                              <TimePicker 
                                                renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(loadStopsDataValidation?.additionalStopsData[index]?.fixedApptStartTime)} helperText={loadStopsDataValidation?.additionalStopsData[index]?.fixedApptStartTime} />}
                                                label="Start Time*"
                                                value={loadStopData.fixedApptStartTime}
                                                onChange={value => updateLoadStop(loadStopIndex, {
                                                  fixedApptStartTime: value
                                                })}
                                              />
                                              <DesktopDatePicker
                                                label="End Date*"
                                                inputFormat="MM/dd/yyyy"
                                                value={loadStopData.fixedApptEndDate}
                                                fullWidth
                                                onChange={value => updateLoadStop(loadStopIndex, {
                                                  fixedApptEndDate: value
                                                })}
                                                renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(loadStopsDataValidation?.additionalStopsData[index]?.fixedApptEndDate)} helperText={loadStopsDataValidation?.additionalStopsData[index]?.fixedApptEndDate} />}
                                                variant="outlined"
                                                my={2}
                                              />
                                              <TimePicker 
                                                renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(loadStopsDataValidation?.additionalStopsData[index]?.fixedApptEndTime)} helperText={loadStopsDataValidation?.additionalStopsData[index]?.fixedApptEndTime} />}
                                                label="End Time*"
                                                value={loadStopData.fixedApptEndTime}
                                                onChange={value => updateLoadStop(loadStopIndex, {
                                                  fixedApptEndTime: value
                                                })}
                                              />
                                              <div style={{ marginLeft: 10, marginBottom: 8, marginTop: 8, flex: 1 }}>
                                                <FormControl fullWidth>
                                                  <InputLabel>Timezone*</InputLabel>
                                                  <Select
                                                    label="Timezone*"
                                                    value={loadStopData.fixedApptTimezone}
                                                    error={Boolean(loadStopsDataValidation?.additionalStopsData[index]?.fixedApptTimezone)}
                                                    helperText={loadStopsDataValidation?.additionalStopsData[index]?.fixedApptTimezone}
                                                    onChange={e => updateLoadStop(loadStopIndex, {
                                                      fixedApptTimezone: e.target.value
                                                    })}
                                                    variant="outlined"
                                                    my={2}
                                                    name="type"
                                                    style={{ width: 200 }}
                                                  >
                                                    <MenuItem value="-1">Select</MenuItem>
                                                    {
                                                      Object.keys(timezoneTypes).map(item => (
                                                        <MenuItem value={timezoneTypes[item].key}>{timezoneTypes[item].display}</MenuItem>
                                                      ))
                                                    }
                                                  </Select>
                                                </FormControl>
                                              </div>
                                            </div>
                                          }
                                          <div style={{ display: 'flex' }}>
                                            <TextField
                                              name="emergencyFirstName"
                                              label="Activity"
                                              value={loadStopData.activity}
                                              style={{ flex: 1 }}
                                              error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                              fullWidth
                                              helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                              onChange={e => updateLoadStop(loadStopIndex, {
                                                activity: e.target.value
                                              })}
                                              variant="outlined"
                                              my={2}
                                            />
                                            <TextField
                                              name="emergencyFirstName"
                                              label="Handling Time"
                                              value={loadStopData.handlingTime}
                                              style={{ flex: 1, marginLeft: 10 }}
                                              error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                              fullWidth
                                              helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                              onChange={e => updateLoadStop(loadStopIndex, {
                                                handlingTime: e.target.value
                                              })}
                                              variant="outlined"
                                              my={2}
                                            />
                                            <TextField
                                              name="emergencyFirstName"
                                              label="Notes"
                                              value={loadStopData.notes}
                                              style={{ flex: 1, marginLeft: 10 }}
                                              error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                              fullWidth
                                              helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                              onChange={e => updateLoadStop(loadStopIndex, {
                                                notes: e.target.value
                                              })}
                                              variant="outlined"
                                              my={2}
                                            />
                                          </div>
                                          <div style={{ marginTop: 5 }}>
                                            <FormControlLabel
                                              control={
                                                <Switch
                                                  checked={loadStopData.driverAssistRequired}
                                                  onChange={e => updateLoadStop(loadStopIndex, {
                                                    driverAssistRequired: e.target.checked
                                                  })}
                                                />
                                              }
                                              label="Driver Assist Required"
                                            />
                                          </div>
                                          <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
                                            <Button 
                                              variant="outlined"
                                              color="error" 
                                              startIcon={<ClearIcon />}
                                              style={{ marginRight: 10 }}
                                              onClick={() => setDeleteStopPending(() => () => deleteStop(loadStopIndex))}
                                            >
                                              Remove
                                            </Button>
                                          </div>
                                        </>
                                      :
                                        <Button 
                                          variant="contained" 
                                          startIcon={<SearchIcon />}
                                          onClick={() => openSearchStopsSelectList(loadStopIndex)}
                                        >
                                          Search Locations
                                        </Button>
                                    }
                                  </>
                                :
                                  <Button 
                                    variant="contained" 
                                    startIcon={<SearchIcon />}
                                    onClick={() => openSearchStopsSelectList(loadStopIndex)}
                                  >
                                    Search Locations
                                  </Button>
                              }
                            </div>
                          );
                        })
                      }
                      {
                        additionalStopsIndexes.length < 8 &&
                        <div style={{ display: 'flex' }}>
                          <Button 
                            variant="contained" 
                            startIcon={<AddIcon />}
                            onClick={addStop}
                          >
                            Add Stop
                          </Button>
                        </div>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Delivery
                        {
                          loadStopsDataValidation?.deliveryData.companyLocationId ?
                          <div>
                            <Typography 
                              variant="p" 
                              style={{ 
                                fontSize: 13, 
                                fontWeight: 'normal',
                                color: theme?.palette?.error?.main
                              }}
                            >{loadStopsDataValidation.deliveryData.companyLocationId}</Typography>
                          </div> : ''
                        }
                      </Typography>
                      {
                        customerData
                        ?
                          <>
                            <CompanyLocationDetailsSection
                              data={customerData.companyLocation}
                              rawLoadStopData={customerData}
                              allowActionTimestampsEdit={isEdit}
                            />
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                              <Button 
                                variant="contained"
                                onClick={openSearchDeliverySelectList}
                                style={{ width: 150 }}
                              >
                                Change Location
                              </Button>
                            </div>
                            <div>
                              <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                                Trailer Information
                                <div>
                                  <Typography 
                                    variant="p" 
                                    style={{ 
                                      fontSize: 9, 
                                      fontWeight: 'normal',
                                      fontStyle: 'italic',
                                      color: '#000',
                                      opacity: 0.7,
                                      marginTop: -5
                                    }}
                                  >
                                    In case truck driver's trailer won't be used on this stop, select a specific one here.
                                  </Typography>
                                </div>
                                {
                                  loadStopsDataValidation?.deliveryData.trailerId ?
                                  <div>
                                    <Typography 
                                      variant="p" 
                                      style={{ 
                                        fontSize: 13, 
                                        fontWeight: 'normal',
                                        color: theme?.palette?.error?.main
                                      }}
                                    >{loadStopsDataValidation.deliveryData.trailerId}</Typography>
                                  </div> : ''
                                }
                                  </Typography>
                              {
                                customerData?.trailerId
                                ?
                                  <>
                                    <EquipmentDetails
                                      data={customerData?.trailer}
                                    />
                                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 15 }}>
                                      <Button 
                                        variant="contained" 
                                        onClick={() => openSearchAvailableTrailersSelectList(customerIndex)}
                                        style={{ width: 150 }}
                                      >
                                        Change Trailer
                                      </Button>
                                    </div>
                                  </>
                                :
                                  <div style={{ marginTop: 15 }}>
                                    <Button 
                                      variant="contained" 
                                      onClick={() => openSearchAvailableTrailersSelectList(customerIndex)}
                                      style={{ width: 150 }}
                                    >
                                      Set Specific Trailer
                                    </Button>
                                  </div>
                              }
                            </div>
                            <Typography variant="h6" gutterBottom style={{ marginTop: 15, marginBottom: 15 }}>
                              Additional Information
                            </Typography>
                            <div style={{ marginTop: 15 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={customerData.fixedAppt}
                                    onChange={(e) => {
                                      updateLoadStop(customerIndex, {
                                        fixedAppt: e.target.checked
                                      });
                                    }}
                                  />
                                }
                                label="Fixed Appt."
                              />
                            </div>
                            {
                              customerData.fixedAppt &&
                              <div style={{ display: 'flex', marginTop: 5 }}>
                                <DesktopDatePicker
                                  label="Start Date*"
                                  inputFormat="MM/dd/yyyy"
                                  value={customerData.fixedApptStartDate}
                                  fullWidth
                                  onChange={value => updateLoadStop(customerIndex, {
                                    fixedApptStartDate: value
                                  })}
                                  renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params}  error={Boolean(loadStopsDataValidation?.deliveryData?.fixedApptStartDate)}                     helperText={loadStopsDataValidation?.deliveryData?.fixedApptStartDate} />}
                                  variant="outlined"
                                  my={2}
                                />
                                <TimePicker 
                                  renderInput={
                                    (params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} 
                                                  {...params} 
                                                  error={Boolean(loadStopsDataValidation?.deliveryData?.fixedApptStartTime)} 
                                                  helperText={loadStopsDataValidation?.deliveryData?.fixedApptStartTime} 
                                                />
                                }
                                  label="Start Time*"
                                  value={customerData.fixedApptStartTime}
                                  onChange={value => updateLoadStop(customerIndex, {
                                    fixedApptStartTime: value
                                  })}
                                />
                                <DesktopDatePicker
                                  label="End Date*"
                                  inputFormat="MM/dd/yyyy"
                                  value={customerData.fixedApptEndDate}
                                  fullWidth
                                  onChange={value => updateLoadStop(customerIndex, {
                                    fixedApptEndDate: value
                                  })}
                                  renderInput={(params) => <TextField 
                                                              my={2} 
                                                              style={{ flex: 1, marginLeft: 10 }}
                                                              {...params} 
                                                              error={Boolean(loadStopsDataValidation?.deliveryData?.fixedApptEndDate)} helperText={loadStopsDataValidation?.deliveryData?.fixedApptEndDate} 
                                                            />
                                  }
                                  variant="outlined"
                                  my={2}
                                />
                                <TimePicker 
                                  renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(loadStopsDataValidation?.deliveryData?.fixedApptEndTime)} helperText={loadStopsDataValidation?.deliveryData?.fixedApptEndTime} />}
                                  label="End Time*"
                                  value={customerData.fixedApptEndTime}
                                  onChange={value => updateLoadStop(customerIndex, {
                                    fixedApptEndTime: value
                                  })}
                                />
                                <div style={{ marginLeft: 10, marginBottom: 8, marginTop: 8, flex: 1 }}>
                                  <FormControl fullWidth>
                                    <InputLabel>Timezone*</InputLabel>
                                    <Select
                                      label="Timezone*"
                                      value={customerData.fixedApptTimezone}
                                      error={Boolean(loadStopsDataValidation?.deliveryData?.fixedApptTimezone)}
                                      helperText={loadStopsDataValidation?.deliveryData?.fixedApptTimezone}
                                      onChange={e => updateLoadStop(customerIndex, {
                                        fixedApptTimezone: e.target.value
                                      })}
                                      variant="outlined"
                                      my={2}
                                      name="type"
                                      style={{ width: 200 }}
                                    >
                                      <MenuItem value="-1">Select</MenuItem>
                                      {
                                        Object.keys(timezoneTypes).map(item => (
                                          <MenuItem value={timezoneTypes[item].key}>{timezoneTypes[item].display}</MenuItem>
                                        ))
                                      }
                                    </Select>
                                  </FormControl>
                                </div>
                              </div>
                            }
                            <div style={{ display: 'flex' }}>
                              <TextField
                                name="emergencyFirstName"
                                label="Activity"
                                value={customerData.activity}
                                style={{ flex: 1 }}
                                error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                fullWidth
                                helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                onChange={e => updateLoadStop(customerIndex, {
                                  activity: e.target.value
                                })}
                                variant="outlined"
                                my={2}
                              />
                              <TextField
                                name="emergencyFirstName"
                                label="Handling Time"
                                value={customerData.handlingTime}
                                style={{ flex: 1, marginLeft: 10 }}
                                error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                fullWidth
                                helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                onChange={e => updateLoadStop(customerIndex, {
                                  handlingTime: e.target.value
                                })}
                                variant="outlined"
                                my={2}
                              />
                              <TextField
                                name="emergencyFirstName"
                                label="Notes"
                                value={customerData.notes}
                                style={{ flex: 1, marginLeft: 10 }}
                                error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                                fullWidth
                                helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                                onChange={e => updateLoadStop(customerIndex, {
                                  notes: e.target.value
                                })}
                                variant="outlined"
                                my={2}
                              />
                            </div>
                            <div style={{ marginTop: 5 }}>
                              <FormControlLabel
                                control={
                                  <Switch
                                    checked={customerData.driverAssistRequired}
                                    onChange={e => updateLoadStop(customerIndex, {
                                      driverAssistRequired: e.target.checked
                                    })}
                                  />
                                }
                                label="Driver Assist Required"
                              />
                            </div>
                          </>
                        :
                          <Button 
                            variant="contained" 
                            startIcon={<SearchIcon />}
                            onClick={openSearchDeliverySelectList}
                          >
                            Search Locations
                          </Button>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginBottom: 20, marginTop: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Driver
                        {
                          touched.driverId && 
                          errors.driverId ?
                          <div>
                            <Typography 
                              variant="p" 
                              style={{ 
                                fontSize: 13, 
                                fontWeight: 'normal',
                                color: theme?.palette?.error?.main
                              }}
                            >{errors.driverId}</Typography>
                          </div> : ''
                        }
                      </Typography>
                      {
                        values.driver
                        ? 
                          <>
                            <TruckDriverDetailsSection data={values.driver} load={values} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button 
                                variant="contained"
                                onClick={openSearchAvailableTruckDriversSelectList}
                                style={{ width: 150 }}
                              >
                                Change Driver
                              </Button>
                            </div>
                          </>
                        :
                          <Button 
                            variant="contained" startIcon={<AddIcon />}
                            onClick={openSearchAvailableTruckDriversSelectList}
                          >
                            Assign Driver
                          </Button>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Dispatcher
                        {
                          touched.dispatcherId && 
                          errors.dispatcherId ?
                          <div>
                            <Typography 
                              variant="p" 
                              style={{ 
                                fontSize: 13, 
                                fontWeight: 'normal',
                                color: theme?.palette?.error?.main
                              }}
                            >{errors.dispatcherId}</Typography>
                          </div> : ''
                        }
                      </Typography>
                      {
                        values.dispatcher
                        ?
                          <>
                            <DispatcherDetailsSection data={values.dispatcher} />
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                              <Button 
                                variant="contained"
                                onClick={openSearchAvailableDispatchersSelectList}
                                style={{ width: 160 }}
                              >
                                Change Dispatcher
                              </Button>
                            </div>
                          </>
                        :
                          <Button 
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={openSearchAvailableDispatchersSelectList}
                          >
                            Assign Dispatcher
                          </Button>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Additional Fees
                      </Typography>
                      {
                        values?.loadAdditionalFees.map((additionalFee, index) => (
                          <div style={{ display: 'flex', marginBottom: index === values.loadAdditionalFees.length - 1 ? 0 : 5 }}>
                            <div style={{ marginBottom: 8, marginTop: 8, flex: 2, marginRight: 15 }}>
                              <FormControl fullWidth>
                                <InputLabel>Type*</InputLabel>
                                <Select
                                  label="Type*"
                                  name={`loadAdditionalFees.${index}.type`}
                                  value={values.loadAdditionalFees[index].type}
                                  error={Boolean((touched && touched.loadAdditionalFees && touched.loadAdditionalFees[index] && touched.loadAdditionalFees[index].type) && (errors && errors.loadAdditionalFees && errors.loadAdditionalFees[index] && errors.loadAdditionalFees[index].type))}
                                  helperText={(touched && touched.loadAdditionalFees && touched.loadAdditionalFees[index] && touched.loadAdditionalFees[index].type) && (errors && errors.loadAdditionalFees && errors.loadAdditionalFees[index] && errors.loadAdditionalFees[index].type)}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    const value = e.target.value;
                                    setAdditionalFee(index, { type: value });
                                  }}
                                  variant="outlined"
                                  my={2}
                                >
                                  <MenuItem value="-1">Select</MenuItem>
                                  {
                                    Object.keys(loadAdditionalFeeTypes).map(item => (
                                      <MenuItem value={loadAdditionalFeeTypes[item].key}>{loadAdditionalFeeTypes[item].display}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            </div>
                            <TextField
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }}
                              type="number"
                              label="Value*"
                              name={`loadAdditionalFees.${index}.value`}
                              value={values.loadAdditionalFees[index].value}
                              error={Boolean((touched && touched.loadAdditionalFees && touched.loadAdditionalFees[index] && touched.loadAdditionalFees[index].value) && (errors && errors.loadAdditionalFees && errors.loadAdditionalFees[index] && errors.loadAdditionalFees[index].value))}
                              helperText={(touched && touched.loadAdditionalFees && touched.loadAdditionalFees[index] && touched.loadAdditionalFees[index].value) && (errors && errors.loadAdditionalFees && errors.loadAdditionalFees[index] && errors.loadAdditionalFees[index].value)}
                              onBlur={handleBlur}
                              onChange={e => {
                                const value = e.target.value ? parseFloat(e.target.value) : null;
                                if (value < 0) return;
                                setAdditionalFee(index, { value });
                              }}
                              variant="outlined"
                              my={2}
                              style={{ flex: 2 }}
                              inputProps={{ min: 0, step: "any" }}
                            />
                            
                            <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 15 }}>
                              <FormControl fullWidth>
                                <InputLabel>Operation Type*</InputLabel>
                                <Select
                                  label="Operation Type*"
                                  name={`loadAdditionalFees.${index}.operationType`}
                                  value={values.loadAdditionalFees[index].operationType}
                                  onBlur={handleBlur}
                                  onChange={e => {
                                    const value = e.target.value;
                                    setAdditionalFee(index, { operationType: value });
                                  }}
                                  variant="outlined"
                                  my={2}
                                >
                                  {
                                    Object.keys(loadAdditionalFeeOperationTypes).map(item => (
                                      <MenuItem value={loadAdditionalFeeOperationTypes[item].key}>{loadAdditionalFeeOperationTypes[item].display}</MenuItem>
                                    ))
                                  }
                                </Select>
                              </FormControl>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginLeft: 15 }}>
                              <div 
                                style={{ cursor: 'pointer' }}
                                onClick={() => handleRemoveAdditionalFee(index)}
                              >
                                <CloseIcon />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      {
                        values?.loadAdditionalFees?.length < 20 &&
                        <div style={{ display: 'flex', marginTop: 15 }}>
                          <Button 
                            variant="contained" 
                            startIcon={<AddIcon />}
                            onClick={() => setAdditionalFee()}
                          >
                            Add Fee
                          </Button>
                        </div>
                      }
                    </div>
                    <Divider />
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Files
                      </Typography>
                      {
                        uploadedFiles &&
                        Array.isArray(uploadedFiles) &&
                        uploadedFiles.length
                        ?
                        <div style={{ marginTop: 15, marginBottom: 25, width: '50%' }}>
                          {
                            uploadedFiles.map((file, index) => (
                              <Paper key={file.requestMetadataId ? file.requestMetadataId : index} style={{ padding: 15, backgroundColor: '#eee', marginBottom: 25, position: 'relative', }}>
                                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                  <div style={{ marginRight: 10 }}>
                                    <TextField
                                      disabled={file.uploadFinish === false}
                                      label="Name"
                                      fullWidth
                                      variant="outlined"
                                      size="small"
                                      value={file.name}
                                      onChange={e => {
                                        const value = e.target.value;
                                        setUploadedFiles(prevState => {
                                          const uploadedFiles = [...prevState];
                                          if (uploadedFiles && uploadedFiles[index]) uploadedFiles[index].name = value;
                                          return uploadedFiles;
                                        });
                                      }}
                                    />
                                  </div>
                                  <div style={{ display: 'flex' }}>
                                    {
                                      file.uploadFinish || file.uploadFinish !== false 
                                      ?
                                        <>
                                          <div>
                                            <IconButton onClick={() => {
                                              setFilePreviewData(() => ({
                                                url: file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : '',
                                                type: file && file.urls && file.urls.file ? 'pdf' : 'image'
                                              }));
                                            }}>
                                              <RemoveRedEyeIcon />
                                            </IconButton>
                                          </div>
                                          <div>
                                            <IconButton onClick={() => {
                                              let url = file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : null;
                                              if (!url) return;
                                              if (file.name) url += `&fileName=${file.name}`;
                                              const a = document.createElement('a');
                                              a.href = url;
                                              a.download = file.name;
                                              document.body.appendChild(a);
                                              a.click();
                                              document.body.removeChild(a);
                                            }}>
                                              <DownloadIcon />
                                            </IconButton>
                                          </div>
                                        </>
                                      :
                                        ''
                                    }
                                    <div>
                                      <IconButton onClick={() => {
                                        setDeleteFileHandler(() => () => {
                                          if (file.requestMetadataId && filesRequestMetadata.current[file.requestMetadataId] && filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction) {
                                            filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction();
                                            delete filesRequestMetadata.current[file.requestMetadataId];
                                          }
                                          setUploadedFiles(prevState => {
                                            const uploadedFiles = [...prevState];
                                            if (uploadedFiles && uploadedFiles[index]) uploadedFiles.splice(index, 1);
                                            return uploadedFiles;
                                          });
                                        });
                                      }}>
                                        <DeleteIcon />
                                      </IconButton>
                                    </div>
                                  </div>
                                </div>
                                {
                                  file.uploadFinish === false &&
                                  <div style={{ marginTop: 15 }}>
                                    <LinearProgress variant="determinate" color={file.errored ? 'error' : 'primary'} value={file.errored ? 100 : file.uploadPercentages} />
                                    {
                                      !file.errored &&
                                      <div style={{ marginTop: 5, textAlign: 'center' }}>
                                        {parseInt(file.uploadPercentages) === 100 ? 'Processing...' : 'Uploading...'}
                                      </div>
                                    }
                                  </div>
                                }
                                {
                                  file.isPodFile &&
                                  <div style={{ position: 'absolute', top: -10, left: 0, backgroundColor: theme.palette.primary.main, width: 40, height: 20, borderRadius: 20, transform: [{ rotate: '-30deg' }] }}>
                                    <div style={{ fontSize: 10, textAlign: 'center', position: 'relative', top: 2, color: '#fff', }}>POD</div>
                                  </div>
                                }
                              </Paper>
                            ))
                          }
                        </div>
                        :
                          ''
                      }
                      {
                        uploadedFiles &&
                        Array.isArray(uploadedFiles) &&
                        uploadedFiles.length < 50
                        ?
                          <div
                            ref={fileDropAreaElem}
                            style={{ 
                              border: '1px dashed #000', 
                              borderRadius: 4, 
                              padding: 20,
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              width: '70%',
                              margin: '0 auto',
                              marginTop: 20
                            }}
                          >
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                              <Icon.CloudUpload fontSize="large" />
                              <Typography style={{ marginLeft: 20, marginRight: 20 }}>Drag and Drop or browse the file.</Typography>
                            </div>
                            <div>
                              <input 
                                type="file" 
                                id="browseFilesElem" 
                                style={{ display: 'none' }}
                                onChange={handleFilePicker}
                              />
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                  const elem = document.getElementById('browseFilesElem');
                                  if (!elem) return;
                                  elem.click();
                                }}
                              >
                                Browse
                              </Button>
                            </div>
                          </div>
                        :
                          ''
                      }
                    </div>
                    <Divider />
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                      <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                        Breakdown
                      </Typography>
                      <LoadBreakdown load={values} />
                    </div>
                  </CardContent>
                </Card>
                
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  onClick={() => {
                    allowLoadStopDataValidationOnUpdate.current = true;
                  }}
                >
                  Save changes
                </Button>
              </form>
              {
                showSearchCustomersSelectList &&
                <SelectList 
                  title="Select Customer"
                  openModal={showSearchCustomersSelectList}
                  data={
                    customersState?.data 
                    ? 
                      customersState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.customerName} (${item.addressName})`
                      })) 
                    : 
                      []
                  }
                  selectedValue={values?.companyCustomerId}
                  handleClose={() => setShowSearchCustomersSelectList(false)}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setCustomersSkip(customersState?.filters?.skip + customersState?.filters?.limit));
                    dispatch(fetchCustomers());
                  }}
                  onChange={updateCustomer}
                  addEntityData={{
                    label: 'Add new Customer?',
                    type: fullDrawerModalTypeKeys.addCustomer.key,
                    closeCallback: () => {
                      dispatch(resetLoadsCustomersState());
                      dispatch(fetchCustomers());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedCompanyCustomer(null));
                    }
                  }}
                  showSearch={true}
                  searchValue={customersState?.filters?.searchValue}
                  searchValueOnChange={(value) => {
                    dispatch(setFilterCustomersSearchValue(value));
                  }}
                  searchValueTriggerHandler={() => {
                    dispatch(setCustomersData([]));
                    dispatch(setCustomersSkip(0));
                    dispatch(fetchCustomers());
                  }}
                />
              }
              {
                showSearchDeliveriesSelectList &&
                <SelectList 
                  title="Select Location"
                  openModal={showSearchDeliveriesSelectList}
                  data={
                    locationsState?.data 
                    ? 
                      locationsState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.companyName} (${item.addressName})`
                      })) 
                    : 
                      []
                  }
                  selectedValue={customerData?.companyLocationId}
                  handleClose={() => setShowSearchDeliveriesSelectList(false)}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setLocationsSkip(locationsState?.filters?.skip + locationsState?.filters?.limit));
                    dispatch(fetchLocations());
                  }}
                  onChange={setDelivery}
                  addEntityData={{
                    label: 'Add new Location?',
                    type: fullDrawerModalTypeKeys.addLocation.key,
                    closeCallback: () => {
                      dispatch(resetLoadsLocationsState());
                      dispatch(fetchLocations());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedCompanyLocation(null));
                    }
                  }}
                  showSearch={true}
                  searchValue={locationsState?.filters?.searchValue}
                  searchValueOnChange={(value) => {
                    dispatch(setFilterLocationsSearchValue(value));
                  }}
                  searchValueTriggerHandler={() => {
                    dispatch(setLocationsData([]));
                    dispatch(setLocationsSkip(0));
                    dispatch(fetchLocations());
                  }}
                />
              }
              {
                showSearchPickupsSelectList &&
                <SelectList 
                  title="Select Location"
                  openModal={showSearchPickupsSelectList}
                  data={
                    locationsState?.data 
                    ? 
                      locationsState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.companyName} (${item.addressName})`
                      })) 
                    : 
                      []
                  }
                  selectedValue={pickupData?.companyLocationId}
                  handleClose={() => setShowSearchPickupsSelectList(false)}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setLocationsSkip(locationsState?.filters?.skip + locationsState?.filters?.limit));
                    dispatch(fetchLocations());
                  }}
                  onChange={setPickup}
                  addEntityData={{
                    label: 'Add new Location?',
                    type: fullDrawerModalTypeKeys.addLocation.key,
                    closeCallback: () => {
                      dispatch(resetLoadsLocationsState());
                      dispatch(fetchLocations());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedCompanyLocation(null));
                    }
                  }}
                  showSearch={true}
                  searchValue={locationsState?.filters?.searchValue}
                  searchValueOnChange={(value) => {
                    dispatch(setFilterLocationsSearchValue(value));
                  }}
                  searchValueTriggerHandler={() => {
                    dispatch(setLocationsData([]));
                    dispatch(setLocationsSkip(0));
                    dispatch(fetchLocations());
                  }}
                />
              }
              {
                showSearchStopsSelectList &&
                <SelectList 
                  title="Select Location"
                  openModal={showSearchStopsSelectList}
                  data={
                    locationsState?.data 
                    ? 
                      locationsState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.companyName} (${item.addressName})`
                      })) 
                    : 
                      []
                  }
                  selectedValue={
                    searchStopsSelectListLoadStopIndex !== null
                      ?
                        values?.loadStopsData[searchStopsSelectListLoadStopIndex]?.companyLocationId
                      :
                        null
                  }
                  handleClose={() => {
                    setShowSearchStopsSelectList(false);
                    setSearchStopsSelectListLoadStopIndex(null);
                  }}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setLocationsSkip(locationsState?.filters?.skip + locationsState?.filters?.limit));
                    dispatch(fetchLocations());
                  }}
                  onChange={setStop}
                  addEntityData={{
                    label: 'Add new Location?',
                    type: fullDrawerModalTypeKeys.addLocation.key,
                    closeCallback: () => {
                      dispatch(resetLoadsLocationsState());
                      dispatch(fetchLocations());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedCompanyLocation(null));
                    }
                  }}
                  showSearch={true}
                  searchValue={locationsState?.filters?.searchValue}
                  searchValueOnChange={(value) => {
                    dispatch(setFilterLocationsSearchValue(value));
                  }}
                  searchValueTriggerHandler={() => {
                    dispatch(setLocationsData([]));
                    dispatch(setLocationsSkip(0));
                    dispatch(fetchLocations());
                  }}
                />
              }
              {
                showSearchAvailableTrailersSelectList &&
                <SelectList 
                  title="Select Trailer"
                  openModal={showSearchAvailableTrailersSelectList}
                  data={
                    availableTrailersState?.data 
                    ? 
                      availableTrailersState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.make}-${item.model}`
                      })) 
                    : 
                      []
                  }
                  selectedValue={
                    searchAvailableTrailersSelectListLoadStopIndex !== null
                      ?
                        values?.loadStopsData[searchAvailableTrailersSelectListLoadStopIndex]?.trailerId
                      :
                        null
                  }
                  handleClose={() => {
                    setShowSearchAvailableTrailersSelectList(false);
                    setSearchAvailableTrailersSelectListLoadStopIndex(null);
                  }}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setAvailableTrailersSkip(availableTrailersState?.filters?.skip + availableTrailersState?.filters?.limit));
                    dispatch(fetchAvailableTrailers());
                  }}
                  onChange={(trailerId) => {
                    const updateData = {
                      trailerId
                    };
                    const trailer = availableTrailersState?.data.find(item => item.id === trailerId);
                    if (trailer) updateData.trailer = trailer;
                    updateLoadStop(searchAvailableTrailersSelectListLoadStopIndex, updateData);
                  }}
                  addEntityData={{
                    label: 'Add new Trailer?',
                    type: fullDrawerModalTypeKeys.addEquipment.key,
                    closeCallback: () => {
                      dispatch(resetAvailableTrailersState());
                      dispatch(fetchAvailableTrailers());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedEquipment(null));
                    }
                  }}
                />
              }
              {
                showSearchAvailableTruckDriversSelectList &&
                <SelectList 
                  title="Select Truck Driver"
                  openModal={showSearchAvailableTruckDriversSelectList}
                  data={
                    availableTruckDriversState?.data 
                    ? 
                      availableTruckDriversState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.firstName} ${item.lastName}`
                      })) 
                    : 
                      []
                  }
                  selectedValue={values?.driverId}
                  handleClose={() => setShowSearchAvailableTruckDriversSelectList(false)}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setAvailableTruckDriversSkip(availableTruckDriversState?.filters?.skip + availableTruckDriversState?.filters?.limit));
                    dispatch(fetchAvailableTruckDrivers());
                  }}
                  onChange={updateDriver}
                  addEntityData={{
                    label: 'Add new Truck Driver?',
                    type: fullDrawerModalTypeKeys.addTruckDriver.key,
                    closeCallback: () => {
                      dispatch(resetAvailableTruckDriversState());
                      dispatch(fetchAvailableTruckDrivers());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedTruckDriver(null));
                    }
                  }}
                  showSearch={true}
                  searchValue={availableTruckDriversState?.filters?.searchValue}
                  searchValueOnChange={(value) => {
                    dispatch(setFilterAvailableTruckDriversSearchValue(value));
                  }}
                  searchValueTriggerHandler={() => {
                    dispatch(setAvailableTruckDriversData([]));
                    dispatch(setAvailableTruckDriversSkip(0));
                    dispatch(fetchAvailableTruckDrivers());
                  }}
                />
              }
              {
                showSearchAvailableDispatchersSelectList &&
                <SelectList 
                  title="Select Dispatcher"
                  openModal={showSearchAvailableDispatchersSelectList}
                  data={
                    availableDispatchersState?.data 
                    ? 
                      availableDispatchersState.data.map(item => ({ 
                        id: item.id, 
                        item: `${item.firstName} ${item.lastName}`
                      })) 
                    : 
                      []
                  }
                  selectedValue={values?.dispatcherId}
                  handleClose={() => setShowSearchAvailableDispatchersSelectList(false)}
                  loading={requestBusy}
                  handleLoadMore={() => {
                    dispatch(setAvailableDispatchersSkip(availableDispatchersState?.filters?.skip + availableDispatchersState?.filters?.limit));
                    dispatch(fetchAvailableDispatchers());
                  }}
                  onChange={updateDispatchers}
                  addEntityData={{
                    label: 'Add new Dispatcher?',
                    type: fullDrawerModalTypeKeys.addUser.key,
                    closeCallback: () => {
                      dispatch(resetAvailableDispatchersState());
                      dispatch(fetchAvailableDispatchers());
                    },
                    beforeEnterTrigger: () => {
                      dispatch(setCurrentSelectedUser(null));
                    }
                  }}
                  showSearch={true}
                  searchValue={availableDispatchersState?.filters?.searchValue}
                  searchValueOnChange={(value) => {
                    dispatch(setFilterAvailableDispatchersSearchValue(value));
                  }}
                  searchValueTriggerHandler={() => {
                    dispatch(setAvailableDispatchersData([]));
                    dispatch(setAvailableDispatchersSkip(0));
                    dispatch(fetchAvailableDispatchers());
                  }}
                />
              }
            </>
          )
        }}
      </Formik>
      <Dialog
        open={deleteFileHandler}
        onClose={() => setDeleteFileHandler(null)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteFileHandler(null)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            color="error"
            onClick={() => {
              const elem = document.getElementById('browseFilesElem');
              if (elem) elem.value = null;
              deleteFileHandler();
              setDeleteFileHandler(null);
            }} 
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
      {
        <Dialog
          open={deleteStopPending}
          onClose={() => setDeleteStopPending(null)}
        >
          <DialogTitle>
            Are you sure?
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete this stop?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button 
              onClick={() => setDeleteStopPending(null)} 
              color="primary"
            >
              No
            </Button>
            <Button 
              color="error" 
              onClick={() => {
                deleteStopPending();
                setDeleteStopPending(null);
              }}
            >
              Yes, delete
            </Button>
          </DialogActions>
        </Dialog>
      }
      { filePreviewData ? <FilePreview url={filePreviewData.url} type={filePreviewData.type} onClose={() => setFilePreviewData(null)} /> : '' }
    </React.Fragment>
  );
}

export default AddLoad;
