const driversState = [
  { key: 'a', text: 'Alabama', value: 'Alabama' },
  { key: 'al', text: 'Alaska', value: 'Alaska' },
  { key: 'am', text: 'American Samoa', value: 'American Samoa' },
  { key: 'ar', text: 'Arizona', value: 'Arizona' },
  { key: 'ark', text: 'Arkansas', value: 'Arkansas' },
  { key: 'cal', text: 'California', value: 'California' },
  { key: 'col', text: 'Colorado', value: 'Colorado' },
  { key: 'con', text: 'Connecticut', value: 'Connecticut' },
  { key: 'del', text: 'Delaware', value: 'Delaware' },
  { key: 'dis', text: 'District Of Columbia', value: 'District Of Columbia' },
  {
    key: 'fed',
    text: 'Federated States of Micronesia',
    value: 'Federated States of Micronesia',
  },
  { key: 'flo', text: 'Florida', value: 'Florida' },
  { key: 'geo', text: 'Georgia', value: 'Georgia' },
  { key: 'gua', text: 'Guam', value: 'Guam' },
  { key: 'haw', text: 'Hawaii', value: 'Hawaii' },
  { key: 'ida', text: 'Idaho', value: 'Idaho' },
  { key: 'ili', text: 'Illinois', value: 'Illinois' },
  { key: 'ind', text: 'Indiana', value: 'Indiana' },
  { key: 'iow', text: 'Iowa', value: 'Iowa' },
  { key: 'kan', text: 'Kansas', value: 'Kansas' },
  { key: 'ken', text: 'Kentucky', value: 'Kentucky' },
  { key: 'lou', text: 'Louisiana', value: 'Louisiana' },
  { key: 'mai', text: 'Maine', value: 'Maine' },
  { key: 'mar', text: 'Marshall Islands', value: 'Marshall Islands' },
  { key: 'mary', text: 'Maryland', value: 'Maryland' },
  { key: 'mass', text: 'Massachusetts', value: 'Massachusetts' },
  { key: 'mich', text: 'Michigan', value: 'Michigan' },
  { key: 'mine', text: 'Minnesota', value: 'Minnesota' },
  { key: 'miss', text: 'Mississippi', value: 'Mississippi' },
  { key: 'misso', text: 'Missouri', value: 'Missouri' },
  { key: 'mon', text: 'Montana', value: 'Montana' },
  { key: 'neb', text: 'Nebraska', value: 'Nebraska' },
  { key: 'nev', text: 'Nevada', value: 'Nevada' },
  { key: 'newh', text: 'New Hampshire', value: 'New Hampshire' },
  { key: 'newy', text: 'New Jersey', value: 'New Jersey' },
  { key: 'newm', text: 'New Mexico', value: 'New Mexico' },
  { key: 'newyo', text: 'New York', value: 'New York' },
  { key: 'noda', text: 'North Dakota', value: 'North Dakota' },
  {
    key: 'nor',
    text: 'Northern Mariana Islands',
    value: 'Northern Mariana Islands',
  },
  { key: 'ohi', text: 'Ohio', value: 'Ohio' },
  { key: 'okl', text: 'Oklahoma', value: 'Oklahoma' },
  { key: 'ore', text: 'Oregon', value: 'Oregon' },
  { key: 'pal', text: 'Palau', value: 'Palau' },
  { key: 'pen', text: 'Pennsylvania', value: 'Pennsylvania' },
  { key: 'por', text: 'Puerto Rico', value: 'Puerto Rico' },
  { key: 'rho', text: 'Rhode Island', value: 'Rhode Island' },
  { key: 'souca', text: 'South Carolina', value: 'South Carolina' },
  { key: 'souda', text: 'South Dakota', value: 'South Dakota' },
  { key: 'tenne', text: 'Tennessee', value: 'Tennessee' },
  { key: 'tex', text: 'Texas', value: 'Texas' },
  { key: 'uta', text: 'Utah', value: 'Utah' },
  { key: 'ver', text: 'Vermont', value: 'Vermont' },
  { key: 'vir', text: 'Virgin Islands', value: 'Virgin Islands' },
  { key: 'viri', text: 'Virginia', value: 'Virginia' },
  { key: 'wash', text: 'Washington', value: 'Washington' },
  { key: 'wes', text: 'West Virginia', value: 'West Virginia' },
  { key: 'wis', text: 'Wisconsin', value: 'Wisconsin' },
  { key: 'wyo', text: 'Wyoming', value: 'Wyoming' },
]

export default driversState
