export default {
  miscellaneous: { 
    key: 'miscellaneous', 
    display: 'Miscellaneous'
  },
  detention: { 
    key: 'detention', 
    display: 'Detention'
  },
  lumperFee: { 
    key: 'lumperFee', 
    display: 'Lumper Fee'
  },
  layOver: { 
    key: 'layOver', 
    display: 'Lay Over'
  },
  cashAdvance: { 
    key: 'cashAdvance', 
    display: 'Cash Advance'
  },
  fuelSurcharge: { 
    key: 'fuelSurcharge', 
    display: 'Fuel Surcharge'
  },
  additionalStop: { 
    key: 'additionalStop', 
    display: 'Additional Stop'
  },
  driverAssist: { 
    key: 'driverAssist', 
    display: 'Driver Assist'
  },
};
