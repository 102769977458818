import React, { useEffect } from "react";
import { Provider } from "react-redux";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { create } from "jss";
import { ThemeProvider } from "styled-components/macro";

import { StyledEngineProvider } from "@mui/styled-engine-sc";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import StylesProvider from "@mui/styles/StylesProvider";
import jssPreset from "@mui/styles/jssPreset";

import createTheme from "./theme";

import useTheme from "./hooks/useTheme";
import { store } from "./redux/store";

import LoadCache from "./components/LoadCache";
import Content from './Content';
import EFSLogin from "./components/HOC/EFSLogin";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {

  const { theme } = useTheme();

  useEffect(() => {
    if (document.getElementById('google-api-script-import')) return;
    const key = process.env.REACT_APP_GOOGLE_API_KEY;
    if (!key) return;
    const s = document.createElement('script');
    s.setAttribute('type', 'text/javascript');
    s.setAttribute('src', `https://maps.googleapis.com/maps/api/js?libraries=places,geometry&key=${key}`);
    s.setAttribute('id', 'google-api-script-import');
    document.head.append(s);
  }, []);

  return (
    <HelmetProvider>
      <Helmet
        titleTemplate="%s | Dispatch One"
        defaultTitle="Dispatch One"
      />
      <Provider store={store}>
        <StylesProvider jss={jss}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <StyledEngineProvider injectFirst>
              <MuiThemeProvider theme={createTheme(theme)}>
                <ThemeProvider theme={createTheme(theme)}>
                  <LoadCache>
                    <EFSLogin>
                      <Content />
                    </EFSLogin>
                  </LoadCache>
                </ThemeProvider>
              </MuiThemeProvider>
            </StyledEngineProvider>
          </LocalizationProvider>
        </StylesProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
