import React, { useState } from 'react';
import styled from  'styled-components/macro';
import CloseIcon from '@mui/icons-material/Close';
import loadStopTypes from '../../../../../keys/loadStopTypes';
import moment from 'moment';
import {
  Card,
  Typography,
  Divider
} from "@mui/material";

const MarkerDot = styled.div`
  cursor: pointer;
  width: 30px;
  height: 30px;
  background-color: #4782da;
  opacity: ${({ type }) => type === 'done' ? 0.7 : 1};
  border-radius: 50px;
  &:after {
    position: absolute;
    top: -10px;
    left: -10px;
    display: block;
    content: '';
    border-radius: 50px;
    width: 50px;
    height: 50px;
    background-color: #4782da;
    z-index: -1;
    opacity: 0.5;
  }
`;

const Marker = (props) => {

  const {
    locationData,
    onClick = () => {},
  } = props;

  const [showPopup, setShowPopup] = useState(false);

  let isDone = false;
  let showPickupNote = false;
  let showDeliveredNote = false;
  let showStopNote = false;

  if (locationData.type === loadStopTypes.location.key) {
    showPickupNote = true;
    if (locationData.pickedUpAt) isDone = true;
  } else if (locationData.type === loadStopTypes.stop.key) {
    showStopNote = true;
    if (locationData.arrivedAt && locationData.departuredAt) {
      isDone = true;
    }
  } else if (locationData.type === loadStopTypes.customer.key) {
    showDeliveredNote = true;
    if (locationData.deliveredAt) isDone = true;
  }

  const type = isDone ? 'done' : locationData.type;

  return (
    <div style={{ position: 'relative' }} onClick={onClick}>
      <MarkerDot type={type} onClick={() => setShowPopup(prevState => !prevState)} />
      {
        showPopup &&
        <Card style={{
          position: 'absolute',
          right: -120,
          top: -150,
          width: 200,
          height: 140,
          zIndex: 1000
        }}>
          <div style={{ padding: 10 }}>
            <CloseIcon 
              style={{ 
                cursor: 'pointer',
                width: 20,
                height: 20,
                position: 'absolute',
                right: 5,
                top: 5
              }} 
              onClick={() => setShowPopup(false)}
            />
            <Typography style={{ marginTop: 5, marginBottom: 5, fontSize: 17 }} variant='h6'>{loadStopTypes[locationData.type]?.display}</Typography>
            <Divider 
              style={{ marginBottom: 5 }}
            />
            <div style={{ marginBottom: 4 }}>
              <Typography variant='p'>Name: <span style={{ fontWeight: 'bold' }}>{locationData?.customerName || locationData?.companyName}</span></Typography>
            </div>
            <div>
              <Typography variant='p'>Address: <span style={{ fontWeight: 'bold' }}>{locationData?.addressName}</span></Typography>
            </div>
            {
              showPickupNote && isDone &&
              <div style={{ marginTop: 4 }}>
                <Typography variant='p'>Pickup: <span style={{ fontWeight: 'bold' }}>{moment(locationData.pickedUpAt).format("MM/DD/YYYY HH:mm")}</span></Typography>
              </div>
            }
            {
              showStopNote &&
              <>
                {
                  locationData.arrivedAt &&
                  <div style={{ marginTop: 4 }}>
                    <Typography variant='p'>Arrived At: <span style={{ fontWeight: 'bold' }}>{moment(locationData.arrivedAt).format("MM/DD/YYYY HH:mm")}</span></Typography>
                  </div>
                }
                {
                  locationData.departuredAt &&
                  <div style={{ marginTop: 4 }}>
                    <Typography variant='p'>Departured At: <span style={{ fontWeight: 'bold' }}>{moment(locationData.departuredAt).format("MM/DD/YYYY HH:mm")}</span></Typography>
                  </div>
                }
              </>
            }
            {
              showDeliveredNote && isDone && 
              <div style={{ marginTop: 4 }}>
                <Typography variant='p'>Delivered: <span style={{ fontWeight: 'bold' }}>{moment(locationData.deliveredAt).format("MM/DD/YYYY HH:mm")}</span></Typography>
              </div>
            }
          </div>
        </Card>
      }
    </div>
  );

};

export default Marker;
