import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

export const historyStackSlice = createSlice({
  name: "historyStack",
  initialState,
  reducers: { 
    pushIntoHistoryStack: (state, action) => {
      if (state.length === 10) state.splice(0, 1);
      state.push(action.payload);
      return state;
    }
  },
});

export const { pushIntoHistoryStack } = historyStackSlice.actions;

export default historyStackSlice.reducer;
