import React from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  Box as MuiBox,
  Drawer as MuiDrawer,
  ListItemButton,
} from "@mui/material";

import Footer from "./SidebarFooter";
import SidebarNav from "./SidebarNav";
import userRoles from "../../keys/userRoles";
import AemLogo from '../../vendor/aem-logo.png';

const Drawer = styled(MuiDrawer)`
  border-right: 0;

  > div {
    border-right: 0;
  }
`;

const Brand = styled(ListItemButton)`
  display: flex;
  font-size: ${(props) => props.theme.typography.h6.fontSize};
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
  color: ${(props) => props.theme.sidebar.header.color};
  background-color: ${(props) => props.theme.sidebar.header.background};
  font-family: ${(props) => props.theme.typography.fontFamily};
  cursor: pointer;
  align-items: center;
  padding-top: 20px;
  word-break: break-all;
  justify-content: center;

  &:hover {
    background-color: ${(props) => props.theme.sidebar.header.background};
  }
`;

const Sidebar = ({ items, showFooter = true, ...rest }) => {

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);

  let companyName, companyLogo;
  if (currentLoggedUser?.role) {
    if (currentLoggedUser?.role === userRoles.admin.key) {
      companyName = '';
      companyLogo = AemLogo;
    } else {
      companyName = currentLoggedUser?.company?.name;
      companyLogo = currentLoggedUser?.company?.logoPath;
    }
  }

  return (
    <Drawer variant="permanent" {...rest}>
      <Brand component={NavLink} to="/">
        {
          companyLogo &&
          <div style={{ width: currentLoggedUser?.role === userRoles.admin.key ? 50 : 30, height: currentLoggedUser?.role === userRoles.admin.key ? 50 : 30, marginRight: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={companyLogo} style={{ maxWidth: '100%' }} alt="" />
          </div>
        }
        <div>
          {companyName}
        </div>
      </Brand>
      <SidebarNav items={items} />
      {!!showFooter && <Footer />}
    </Drawer>
  );
};

export default Sidebar;
