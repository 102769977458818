import React, { useEffect, useRef } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";
import useTwoFactorAuthentication from '../../hooks/useTwoFactorAuthentication';

import { 
  Paper, 
  Typography ,
  Button,
  TextField as MuiTextField,
  IconButton
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import { 
  attemptLogout, 
  currentLoggedUserEnable2fa, 
  currentLoggedUserVerify2fa,
  currentLoggedUserReset2faViaMail
} from "../../redux/slices/currentLoggedUser";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessDialogMessage } from "../../redux/slices/messages";

import AemLogo from '../../vendor/aem-logo.png';
import * as Yup from "yup";
import { Formik } from "formik";

const Brand = styled.img`
  width: 150px;
  margin-bottom: 30px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 550px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const TextField = styled(MuiTextField)(spacing);

function TwoFactorAuthentication() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const smsInitiallySentOnce = useRef(false);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const shouldTrigger2FA = useTwoFactorAuthentication(currentLoggedUser);

  useEffect(() => {
    if (shouldTrigger2FA === false) setTimeout(() => navigate('/', { replace: true }), 50);
    if (!smsInitiallySentOnce.current && currentLoggedUser && shouldTrigger2FA) {
      dispatch(currentLoggedUserEnable2fa(currentLoggedUser?.phoneNumber2fa, true));
      smsInitiallySentOnce.current = true;
    } else {
      if (!currentLoggedUser) setTimeout(() => navigate('/login', { replace: true }), 50);
    }
  }, [currentLoggedUser, shouldTrigger2FA]);

  return (
    <React.Fragment>
      <Brand src={AemLogo} alt="" />
      <Wrapper>
        <Helmet title="Two-Factor Authentication" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Two-Factor Authentication
        </Typography>
        <Typography variant="body1" align="center">
          Enter a security code that we've sent you on your device.
        </Typography>

        <Formik
          initialValues={{
            code2fa: '',
          }}
          validationSchema={Yup.object({
            code2fa: Yup.string().min(7,"Code must be 7 characters.").max(7,"Code must be 7 characters.").required("Required")
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              dispatch(currentLoggedUserVerify2fa(values.code2fa, false));
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                margin="dense"
                name="code2fa"
                label="Code*"
                variant="outlined"
                fullWidth
                value={values.code2fa}
                error={Boolean(touched.code2fa && errors.code2fa)}
                helperText={touched.code2fa && errors.code2fa}
                onBlur={handleBlur}
                onChange={(e) => {
                  const value = e.target.value;
                  if (value.length === 8) return;
                  handleChange(e);
                }}
              />
              <div style={{ marginBottom: 15, marginTop: 10 }}>
                <Typography variant="body1" gutterBottom>
                  You didn't get the code?
                  <span 
                    style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 4 }}
                    onClick={() => {
                      dispatch(currentLoggedUserEnable2fa(currentLoggedUser?.phoneNumber2fa, true, (err) => {
                        if (err) return;
                        dispatch(setSuccessDialogMessage('Successfully sent'));
                      }));
                    }}
                  >Send again</span>
                </Typography>
                <Typography variant="body1">
                  Lost your device?
                  <span 
                    style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 4 }}
                    onClick={() => {
                      dispatch(currentLoggedUserReset2faViaMail((err) => {
                        if (err) return;
                        dispatch(setSuccessDialogMessage('Instructions are sent on your email.'));
                      }));
                    }}
                  >Click here</span>
                </Typography>
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Verify
              </Button>
            </form>
          )}
        </Formik>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(attemptLogout());
              setTimeout(() => navigate('/', { replace: true }), 50);
            }}
            style={{ marginTop: 10 }}
          >
            <LogoutIcon />
          </IconButton>
        </div>
      </Wrapper>
    </React.Fragment>
  );
}

export default TwoFactorAuthentication;
