export default {
  customer: { // End point. Also in code refered as "Delivery"
    key: 'customer', 
    display: 'Delivery'
  },
  location: {  // Start point
    key: 'location',
    display: 'Pickup'
  },
  stop: { // All stops in the middle
    key: 'stop', 
    display: 'Stop'
  }
};
