import React, { useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Dialog,
  IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { setRequestBusy } from '../../../redux/slices/requestBusy';
import { setErrorDialogMessage } from "../../../redux/slices/messages";

const FilePreview = ({ url, type = 'image', onClose = () => {} }) => {

  const dispatch = useDispatch();

  const scale = 0.8;
  const canvasElem = useRef(null);
  const pdfDoc = useRef(null);
  const [pageCount, setPageCount] = useState(null);
  const [pageNum, setPageNum] = useState(1);
  const [pageRendering, setPageRendering] = useState(false);
  const [pageNumPending, setPageNumPending] = useState(null);

  /**
   * Get page info from document, resize canvas accordingly, and render page.
   * @param num Page number.
   */
  const renderPage = num => {
    if (!pdfDoc.current) return;
    setPageRendering(true);
    // Using promise to fetch the page
    pdfDoc.current.getPage(num).then(function(page) {
      const viewport = page.getViewport({scale: scale});
      canvasElem.current.height = viewport.height;
      canvasElem.current.width = viewport.width;

      // Render PDF page into canvas context
      const renderContext = {
        canvasContext: canvasElem.current.getContext('2d'),
        viewport: viewport
      };
      const renderTask = page.render(renderContext);

      // Wait for rendering to finish
      renderTask.promise.then(function() {
        setPageRendering(false);
        if (pageNumPending !== null) {
          // New page rendering is pending
          renderPage(pageNumPending);
          setPageNumPending(null);
        }
      });
    }).catch(e => console.log(e));

    setPageNum(num);
  }

  /**
   * If another page rendering in progress, waits until the rendering is
   * finised. Otherwise, executes rendering immediately.
   */
  function queueRenderPage(num) {
    if (pageRendering) {
      setPageNumPending(num);
    } else {
      renderPage(num);
    }
  }

  useEffect(() => {
    if (url && type === 'pdf') {
      dispatch(setRequestBusy(true));
      try {
        window.pdfjsLib.getDocument(url).promise.then(function(pdfDoc_) {
          dispatch(setRequestBusy(false));
          pdfDoc.current = pdfDoc_;
          setPageCount(() => pdfDoc_.numPages);
          // Initial/first page rendering
          renderPage(pageNum);
        }).catch(() => {
          dispatch(setErrorDialogMessage('Error while fetching PDF'));
          dispatch(setRequestBusy(false));
        })
      } catch (e) {
        dispatch(setErrorDialogMessage('Error while fetching PDF'));
        dispatch(setRequestBusy(false));
      }
    }
  }, []);

  return (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="lg"
    >
      <div style={{ position: 'absolute', right: 5, top: 5 }}>
        <IconButton onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      {
        type === 'image' 
        ?
          <div>
            <img src={url} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
          </div>
        :
          <div>
            <canvas ref={canvasElem}></canvas>
            <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', width: '100%', padding: 10 }}>
              <div>
                <IconButton disabled={pageNum <= 1} onClick={() => {
                  if (pageNum <= 1) {
                    return;
                  }
                  setPageNum(prevState => {
                    const num = prevState - 1;
                    queueRenderPage(num);
                    return num;
                  });
                }}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
                <div>Page: {pageNum} / {pageCount}</div>
              <div>
                <IconButton disabled={pageNum >= (pdfDoc.current && pdfDoc.current.numPages)} onClick={() => {
                  if (pageNum >= pdfDoc.current.numPages) {
                    return;
                  }
                  setPageNum(prevState => {
                    const num = prevState + 1;
                    queueRenderPage(num);
                    return num;
                  });
                }}>
                  <ChevronRightIcon />
                </IconButton>
              </div>
            </div>
          </div>
      }
    </Dialog>
  );

}


export default FilePreview;
