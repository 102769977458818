import { setErrorDialogMessage } from '../slices/messages';

export const parseResponseError = (error, dispatch) => {
  let errors = [];
  if (
    error && 
    error.response && 
    error.response.data && 
    error.response.data.messages && 
    Array.isArray(error.response.data.messages) && 
    error.response.data.messages.length
  ) {
    errors = error.response.data.messages;
  } else {
    errors = ['Error']
  }
  const errorMessage = errors.join('. ');
  dispatch(setErrorDialogMessage(errorMessage));
};