import AdminDashboard from '../pages/components/dashboards/userDashboards/AdminDashboard';
import CompanyAdminDashboard from '../pages/components/dashboards/userDashboards/CompanyAdminDashboard';
import DispatcherDashboard from '../pages/components/dashboards/userDashboards/DispatcherDashboard';
import AccountingDashboard from '../pages/components/dashboards/userDashboards/AccountingDashboard';
import TruckDriverDashboard from '../pages/components/dashboards/userDashboards/TruckDriverDashboard';
import BrokerDashboard from '../pages/components/dashboards/userDashboards/BrokerDashboard';
import SafetyDashboard from '../pages/components/dashboards/userDashboards/SafetyDashboard';
import MaintenanceDashboard from '../pages/components/dashboards/userDashboards/MaintenanceDashboard';

export default {
  admin: {
    key: 'admin',
    display: 'Admin',
    dashboardComponent: (props = {}) => <AdminDashboard {...props} />
  },
  companyAdmin: {
    key: 'companyAdmin',
    display: 'Company Admin',
    dashboardComponent: (props = {}) => <CompanyAdminDashboard {...props} />
  },
  dispatcher: {
    key: 'dispatcher',
    display: 'Dispatcher',
    dashboardComponent: (props = {}) => <DispatcherDashboard {...props} />
  },
  accounting: {
    key: 'accounting',
    display: 'Accounting',
    dashboardComponent: (props = {}) => <AccountingDashboard {...props} />
  },
  truckDriver: {
    key: 'truckDriver',
    display: 'Truck Driver',
    dashboardComponent: (props = {}) => <TruckDriverDashboard {...props} />
  },
  broker: {
    key: 'broker',
    display: 'Broker',
    dashboardComponent: (props = {}) => <BrokerDashboard {...props} />
  },
  safety: {
    key: 'safety',
    display: 'Safety',
    dashboardComponent: (props = {}) => <SafetyDashboard {...props} />
  },
  maintenance: {
    key: 'maintenance',
    display: 'Maintenance',
    dashboardComponent: (props = {}) => <MaintenanceDashboard {...props} />
  }
};
