import React from 'react';

import { CircularProgress } from "@mui/material";

const WrapperWithLoader = ({ isLoading = false, children = <></> }) => {
  return (
    <div style={{ position: 'relative' }}>
      <div style={{ opacity: isLoading ? 0.5 : 1 }}>
        {children}
      </div>
      {
        isLoading &&
        <div style={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%',
          transform: 'translate(-50%, -50%)'
        }}>
          <CircularProgress color="primary" size={30} />
        </div>
      }
    </div>
  );
};

export default WrapperWithLoader;
