import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getStripePaymentMethod } from '../../redux/slices/companySettings';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button
} from "@mui/material";

const SetupBillingRequired = ({ children }) => {

  const [requireBilling, setRequireBilling] = useState(false);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const checkedOnce = useRef(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const company = currentLoggedUser?.company;

  useEffect(() => {
    if (company && !checkedOnce.current && company.loadBillingPrice) {
      if (
        !company.paymentGateaway ||
        !company.customerId
      ) {
        setRequireBilling(true);
      }
      dispatch(getStripePaymentMethod((err, data) => {
        if (err || !data) setRequireBilling(true);
      }));
    }
  }, [company, dispatch]);

  return (
    <>
      {children}
      {
        requireBilling &&
        <Dialog
          open={requireBilling}
          onClose={() => {}}
        >
          <DialogTitle>Billing setup required</DialogTitle>
          <DialogContent>
            In order to create new loads you must setup billing.
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary" 
              variant="contained"
              onClick={() => navigate('/settings/billing-integrations')}
            >
              Setup
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );

}

export default SetupBillingRequired;
