import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { refreshToken } from './currentLoggedUser';
import { resetState as resetTruckDriversMapState } from './truckDriversMap';
import userRoles from '../../keys/userRoles';
import { setShouldFetchAvailableEquipmentData } from './equipments';
import { resetAvailableTruckDriversState } from './loads';
import { fetchNotifications } from "./notifications";
import { resetState as resetUserFieldHistories } from './userFieldHistories';

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    firstName: '',
    lastName: '',
    isActive: '1',
    loginAccess: '1'
  },
  sorts: {
    registrationDate: 'desc'
  },
  total: 0,
  initialFetch: false,
  currentSelectedTruckDriver: null
});

export const truckDriversSlice = createSlice({
  name: "truckDrivers",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setFiltersFirstName: (state, action) => {
      state.filters.firstName = action.payload;
      return state;
    },
    setFiltersLastName: (state, action) => {
      state.filters.lastName = action.payload;
      return state;
    },
    setFiltersStatus: (state, action) => {
      state.filters.isActive = action.payload;
      return state;
    },
    setFiltersLoginAccess: (state, action) => {
      state.filters.loginAccess = action.payload;
      return state;
    },
    setSortsRegistrationDate: (state, action) => {
      state.sorts.registrationDate = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    setCurrentSelectedTruckDriver: (state, action) => {
      state.currentSelectedTruckDriver = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setData, 
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersFirstName,
  setFiltersLastName,
  setFiltersStatus,
  setFiltersLoginAccess,
  setSortsRegistrationDate,
  setInitialFetch,
  setCurrentSelectedTruckDriver,
  resetState
} = truckDriversSlice.actions;

export const fetchTruckDrivers = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.truckDrivers && state.truckDrivers.filters ? state.truckDrivers.filters : {};
  const sorts = state && state.truckDrivers && state.truckDrivers.sorts ? state.truckDrivers.sorts : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('role', userRoles.truckDriver.key);
  if (filters.firstName) queryParams.append('firstName', filters.firstName);
  if (filters.lastName) queryParams.append('lastName', filters.lastName);
  if (filters.isActive !== '-1') queryParams.append('isActive', filters.isActive);
  if (filters.loginAccess !== '-1') queryParams.append('loginAccess', filters.loginAccess);
  if (sorts.registrationDate) queryParams.append('registrationDateSort', sorts.registrationDate);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/users?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
      });
  }));
};

export const addTruckDriver = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post('/api/users/', { data })
      .then(response => {
          if (response.status === 201 && response.data && response.data.data){
              dispatch(resetState());
              dispatch(resetTruckDriversMapState());
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(setRequestBusy(false));
              dispatch(setShouldFetchAvailableEquipmentData(true));
              dispatch(resetAvailableTruckDriversState());
              dispatch(fetchNotifications({ refresh: true }));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const updateTruckDriver = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put('/api/users', { data })
      .then(response => {
          if (response.status === 200){
              const newData = {
                ...getState().truckDrivers.currentSelectedTruckDriver,
                ...data
              };
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(resetTruckDriversMapState());
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(setCurrentSelectedTruckDriver(newData));
              dispatch(setShouldFetchAvailableEquipmentData(true));
              dispatch(resetAvailableTruckDriversState());
              dispatch(fetchNotifications({ refresh: true }));
              dispatch(resetUserFieldHistories());
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const fetchCurrentSelectedTruckDriver = id => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/users/${id}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedTruckDriver(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
  }));
}; 

export const deactivateCurrentSelectedTruckDriver = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const currentSelectedTruckDriver = getState().truckDrivers.currentSelectedTruckDriver;
  const data = {
    id: currentSelectedTruckDriver.id,
    loginAccess: false,
    isActive: false
  }
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/users',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(setRequestBusy(false));
              dispatch(resetState());
              dispatch(setSuccessDialogMessage('User successfully deactivated'));
              dispatch(setCurrentSelectedTruckDriver({
                ...currentSelectedTruckDriver,
                ...data
              }));
              dispatch(resetAvailableTruckDriversState());
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }));
};

export const activateCurrentSelectedTruckDriver = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const currentSelectedTruckDriver = getState().truckDrivers.currentSelectedTruckDriver;
  const data = {
    id: currentSelectedTruckDriver.id,
    loginAccess: true,
    isActive: true
  }
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/users',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage('User successfully activated'));
              dispatch(setCurrentSelectedTruckDriver({
                ...currentSelectedTruckDriver,
                ...data
              }));
              dispatch(resetAvailableTruckDriversState());
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }));
};

export default truckDriversSlice.reducer;

