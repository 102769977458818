import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  addCompanyLocation,
  fetchCurrentSelectedCompanyLocation,
  updateCompanyLocation
} from "../../redux/slices/companyLocations";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { spacing } from "@mui/system";
import { v4 as uuidv4 } from 'uuid';
import { setCloseDrawer } from "../../redux/slices/fullDrawerModal";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  locationName: "",
  addressGeoData: null,
  commodityType: "",
  contacts: [],
});

const GoogleMapInputField = props => {

  const {
    name,
    label,
    error,
    helperText,
    onBlur,
    onChange,
  } = props;

  const googleAutoComplete = useRef(null);
  const googleAutoCompleteInput = useRef(null);

  useEffect(() => {
    if (googleAutoCompleteInput.current) {
      const inputElem = googleAutoCompleteInput.current.getElementsByTagName('input')[0];
      const { maps } = window.google ? window.google : {};
      if (!maps) return;
      googleAutoComplete.current = new maps.places.Autocomplete(inputElem);
      googleAutoComplete.current.addListener("place_changed", () => {
        const place = googleAutoComplete.current.getPlace();
        if (!place) return;
        onChange({ 
          target: {
            value: JSON.parse(JSON.stringify(place)),
            name: 'addressGeoData' 
          }
        });
      });
    }

    return () => {
      if (googleAutoComplete.current) window.google.maps.event.clearInstanceListeners(googleAutoComplete.current);
      googleAutoComplete.current = null;
    }
  }, []);
  
  return (
    <TextField
      name={name}
      label={label}
      key="address-input"
      id="googlePlacesAddressInputField"
      error={error}
      fullWidth
      helperText={helperText}
      onBlur={onBlur}
      onChange={e => {
        const inputElem = googleAutoCompleteInput.current.getElementsByTagName('input')[0];
        if (e.target.value === '' && inputElem) {
          onChange({ 
            target: {
              value: null,
              name: 'addressGeoData' 
            }
          });
          inputElem.value = '';
        } 
      }}
      variant="outlined"
      my={2}
      ref={googleAutoCompleteInput}
    />
  );
};

function AddCompanyLocation({ isEdit, fullDrawerModalMode }) {

  const dispatch = useDispatch();
  const formikRef = useRef();
  const currentSelectedCompanyLocation = useSelector(state => state.companyLocations.currentSelectedCompanyLocation);
  const { closeCallback } = useSelector(state => state.fullDrawerModal);
  const params = useParams();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [removeContactHandler, setRemoveContactHandler] = useState(null);

  const companyLocationId = params && params.companyLocationId ? params.companyLocationId : null;

  useEffect(() => {
    if (companyLocationId && isEdit) dispatch(fetchCurrentSelectedCompanyLocation(companyLocationId));
  }, []);

  useEffect(() => {
    if (currentSelectedCompanyLocation) {
      const values = { ...currentSelectedCompanyLocation };
      try {
        if (currentSelectedCompanyLocation.contacts) {
          if (typeof currentSelectedCompanyLocation.contacts === 'string') {
            values.contacts = JSON.parse(currentSelectedCompanyLocation.contacts);
          } else if (Array.isArray(currentSelectedCompanyLocation.contacts)) {
            values.contacts = JSON.parse(JSON.stringify(currentSelectedCompanyLocation.contacts));
          }
        }
      } catch (e) {}
      setInitialValues({ ...values });
      const googlePlacesAddressInputField = document.getElementById('googlePlacesAddressInputField');
      if (googlePlacesAddressInputField) googlePlacesAddressInputField.value = values && values.addressGeoData && values.addressGeoData.formatted_address ? values.addressGeoData.formatted_address : '';
    }
  }, [currentSelectedCompanyLocation]);

  const yupConfig = {
    companyName: Yup.string().min(3,"Company Name must be at least 3 characters").max(50,"Company Name must be at most 50 characters").required("Required"),
    addressGeoData: Yup.object().required("Required").nullable(),
    commodityType: Yup.string().min(3,"Commodity Type must be at least 3 characters").max(50,"Commodity Type must be at most 50 characters").nullable(),
    contacts: Yup.array().of(Yup.object().shape({
      firstName: Yup.string().min(2,"First Name must be at least 2 characters").max(50,"First Name must be at most 50 characters").required("Required"),
      lastName: Yup.string().min(2,"Last Name must be at least 2 characters").max(50,"Last Name must be at most 50 characters").required("Required"),
      role: Yup.string().min(2,"Role must be at least 2 characters").max(50,"Role must be at most 50 characters").required("Required"),
      email: Yup.string().email("Must be a valid email").min(3,"Email of Company must be at least 3 characters").max(255,"Email  must be at most 255 characters").required("Required"),
      phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
      phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
    }))
  };

  const validationSchema = Yup.object().shape(yupConfig);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (isEdit) {
        const data = {
          id: currentSelectedCompanyLocation && currentSelectedCompanyLocation.id ? currentSelectedCompanyLocation.id : null,
          companyName: values.companyName,
          addressGeoData: values.addressGeoData,
          contacts: values.contacts
        };

        if (values.commodityType) data.commodityType = values.commodityType;
        else data.commodityType = null;
        dispatch(updateCompanyLocation(data, err => {
            if (err) return;
            setSubmitting(false);
            if (fullDrawerModalMode) {
              if (closeCallback) closeCallback();
              dispatch(setCloseDrawer());
            } else {
              window.setTimeout(() => navigate(-1), 0);
            }
          }
        ));
      } else {
        const data = {
          companyName: values.companyName,
          addressGeoData: values.addressGeoData,
          contacts: values.contacts
        };

        if (values.commodityType) data.commodityType = values.commodityType;
        dispatch(addCompanyLocation(data, err => {
            if (err) return;
            resetForm();
            setSubmitting(false);
            if (fullDrawerModalMode) {
              if (closeCallback) closeCallback();
              dispatch(setCloseDrawer());
            } else {
              window.setTimeout(() => navigate(-1), 0);
            }
          }
        ));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {
        !fullDrawerModalMode && 
        <>
          <Helmet title={ isEdit ? 'Edit Company Location' : 'Add Company Location' } />
          <Typography variant="h3" gutterBottom display="inline">
            { isEdit ? 'Edit Company Location' : 'Add Company Location' }
          </Typography>
          {
            isEdit
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Company Locations
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Company Location Details
                </Link>
                <Typography>Edit Company Location</Typography>
              </Breadcrumbs>
            :
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to={""} onClick={() => navigate(-1)}>
                  Company Locations
                </Link>
                <Typography>Add Company Location</Typography>
              </Breadcrumbs>
          }

          <Divider my={6} />
        </>
      }

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Company Location Information
              </Typography>
              <form onSubmit={handleSubmit}>
                <TextField
                  name="companyName"
                  label="Company Name*"
                  fullWidth
                  value={values.companyName}
                  error={Boolean(touched.companyName && errors.companyName)}
                  helperText={touched.companyName && errors.companyName}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                /> 
                <GoogleMapInputField 
                  name="addressGeoData"
                  label="Address*"
                  error={Boolean(touched.addressGeoData && errors.addressGeoData)}
                  helperText={touched.addressGeoData && errors.addressGeoData}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  name="commodityType"
                  label="Commodity Type"
                  value={values.commodityType}
                  error={Boolean(touched.commodityType && errors.commodityType)}
                  fullWidth
                  helperText={touched.commodityType && errors.commodityType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  style={{ marginBottom: 20 }}
                />
                <Typography variant="h6" gutterBottom>
                  Contact(s)
                </Typography>
                <FieldArray
                  name="contacts"
                  render={arrayHelpers => {
                    const contacts = values.contacts;
                    return (
                      <>
                      {
                        contacts && contacts.length > 0 
                        ?
                        contacts.map((contact, index) => (
                          <div key={index} style={{ border: '1px solid #eee', borderRadius: 5, padding: 20, marginTop: 10, marginBottom: 20 }}>
                            <div style={{ display: 'flex' }}>
                              <TextField
                                name={`contacts.${index}.firstName`}
                                label="First Name*"
                                value={contacts[index].firstName}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].firstName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].firstName))}
                                style={{ flex: 1 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].firstName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].firstName)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].firstName = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              /> 
                              <TextField
                                name={`contacts.${index}.lastName`}
                                label="Last Name*"
                                value={contacts[index].lastName}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].lastName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].lastName))}
                                style={{ flex: 1, marginLeft: 10 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].lastName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].lastName)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].lastName = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              />
                            </div>
                            <TextField
                              name={`contacts.${index}.role`}
                              label="Role*"
                              value={contacts[index].role}
                              error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].role) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].role))}
                              style={{ flex: 1 }}
                              fullWidth
                              helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].role) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].role)}
                              onBlur={handleBlur}
                              onChange={e => {
                                const value = e.target.value;
                                const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                if (contactIndex !== -1) values.contacts[contactIndex].role = value;
                                handleChange({ 
                                  target: {
                                    value: values.contacts,
                                    name: 'contacts' 
                                  }
                                });
                              }}
                              variant="outlined"
                              my={2}
                            />
                            <TextField
                              name={`contacts.${index}.email`}
                              label="Email*"
                              value={contacts[index].email}
                              fullWidth
                              error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].email) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].email))}
                              style={{ flex: 1 }}
                              helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].email) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].email)}
                              onBlur={handleBlur}
                              onChange={e => {
                                const value = e.target.value;
                                const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                if (contactIndex !== -1) values.contacts[contactIndex].email = value;
                                handleChange({ 
                                  target: {
                                    value: values.contacts,
                                    name: 'contacts' 
                                  }
                                });
                              }}
                              variant="outlined"
                              my={2}
                            />
                            <div style={{ display: 'flex' }}>
                              <TextField
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                }}
                                name={`contacts.${index}.phoneNumber`}
                                label="Phone Number*"
                                value={contacts[index].phoneNumber}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumber) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumber))}
                                style={{ flex: 3 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumber) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumber)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].phoneNumber = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              /> 
                              <TextField
                                name={`contacts.${index}.phoneNumberExtension`}
                                label="Extension"
                                value={contacts[index].phoneNumberExtension}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumberExtension) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumberExtension))}
                                style={{ flex: 1, marginLeft: 10 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumberExtension) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumberExtension)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].phoneNumberExtension = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              />         
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                              <Button 
                                variant="outlined" 
                                color="error" 
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  setRemoveContactHandler(() => () => {
                                    arrayHelpers.remove(index);
                                    setRemoveContactHandler(null);
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        )) 
                        :
                        ''
                      }
                      </>
                    );
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={values.contacts.length >= 10}
                    onClick={() => {
                      if (values.contacts.length >= 10) return;
                      const value = [...values.contacts];
                      value.push({
                        id: uuidv4(),
                        firstName: '',
                        lastName: '',
                        role: '',
                        email: '',
                        phoneNumber: '',
                        phoneNumberExtension: ''
                      });
                      handleChange({ 
                        target: {
                          value, 
                          name: 'contacts' 
                        }
                      });
                    }}
                  >
                    Add New Contact
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Save Changes
                  </Button>
                </div>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
      <Dialog
        open={removeContactHandler}
        onClose={() => setRemoveContactHandler(null)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setRemoveContactHandler(null)} 
            color="primary"
          >
            No
          </Button>
          <Button color="error" onClick={removeContactHandler}>
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddCompanyLocation;
