import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { setErrorDialogMessage } from './messages';
import { refreshToken } from './currentLoggedUser';
import { parseResponseError } from '../helpers/errorHandlingHelpers';

const getInitialState = () => ({ //State for single load notes.
  loadId: null,
  data: [],
  filters: {
    limit: 10,
    skip: 0,
  },
  initialFetch: false,
  hasMore: true
});

export const loadNotesSlice = createSlice({
  name: "loadNotes",
  initialState: {},
  reducers: {
    setState: (state, action) => {
      if (!state[action.payload]) {
        state[action.payload] = {
          ...getInitialState(),
          loadId: action.payload
        }
      }
      return state;
    },
    concatData: (state, action) => {
      const loadId = action.payload.loadId;
      state[loadId].data = action.payload.data.concat(state[loadId].data);
      return state;
    },
    pushData: (state, action) => {
      const loadId = action.payload.loadId;
      state[loadId].data.push(action.payload.data);
      return state;
    },
    setInitialFetch: (state, action) => {
      const loadId = action.payload.loadId;
      state[loadId].initialFetch = action.payload.data;
      return state;
    },
    setHasMore: (state, action) => {
      const loadId = action.payload.loadId;
      state[loadId].hasMore = action.payload.data;
      return state;
    },
    setNextPage: (state, action) => {
      const loadId = action.payload.loadId;
      state[loadId].filters.skip = state[loadId].filters.skip + state[loadId].filters.limit;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setState,
  concatData,
  setInitialFetch,
  pushData,
  resetState,
  setNextPage,
  setHasMore
} = loadNotesSlice.actions;

export const fetchLoadNotes = (loadId, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state?.loadNotes[loadId]?.filters ?? {};
  const hasMore = state?.loadNotes[loadId]?.hasMore;
  if (!hasMore) return
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/loads/${loadId}/notes?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (!response.data.data.rows.length) dispatch(setHasMore({
            loadId,
            data: false
          }));
          dispatch(concatData({
            loadId,
            data: response.data.data.rows.reverse()
          }));
          dispatch(setInitialFetch({
            data: true,
            loadId
          }));
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const addLoadNote = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post(
        `/api/loads/${data?.loadId}/notes`,
        { data }
      )
      .then(response => {
        if (response.status === 201 && response.data && response.data.data){
          dispatch(pushData({
            data: response.data.data,
            loadId: data?.loadId
          }));
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
      .finally(() => dispatch(setRequestBusy(false)));
  
  }))
};

export default loadNotesSlice.reducer;

