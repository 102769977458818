import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from 'react-router-dom';
import { NavLink } from "react-router-dom";
import moment from 'moment';
import { 
  fetchEFSTransactions,
  setEFSTransactionsFiltersBegDate,
  setEFSTransactionsFiltersEndDate,
  setEFSTransactionsCurrentSelectedTransaction
} from "../../redux/slices/companySettings";
import { 
  useSelector,
  useDispatch
} from "react-redux";

import {
  Card,
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
  Button,
  Breadcrumbs,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const headCells = [
  { id: "invoiceId", alignment: "left", label: "ID" },
  { id: "firstName", alignment: "left", label: "First Name" },
  { id: "lastName", alignment: "left", label: "Last Name" },
  { id: "total", alignment: "left", label: "Total" },
  { id: "date", alignment: "left", label: "Date" },
  { id: "efsCardNumber", alignment: "left", label: "Card Number" },
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows, userId }) {
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <div>
      <Paper>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {
                rows.map((row, index) => {
                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        dispatch(setEFSTransactionsCurrentSelectedTransaction(row));
                        navigate(`/truck-drivers/truck-driver-details/${userId}/efs-transactions/efs-transaction-details`);
                      }} 
                      hover
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                     
                    >
                      <TableCell align="left">{row.invoice}</TableCell>
                      <TableCell align="left">{row.user?.firstName}</TableCell>
                      <TableCell align="left">{row.user?.lastName}</TableCell>
                      <TableCell align="left">{row.settleAmount} {row.billingCurrency}</TableCell>
                      <TableCell align="left">{row.transactionDate ? moment(row.transactionDate).format("MM/DD/YYYY HH:mm") : ''}</TableCell>
                      <TableCell align="left">{row.user?.efsCardNumber}</TableCell>
                      <TableCell padding="none" align="right">
                        
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length
          ?
            <div style={{ paddingBottom: 20 }}>
              <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
            </div>
          :
            ''
        }
      </Paper>
    </div>
  );
}

function TruckDriverEfsTransactions() {
  const navigate = useNavigate();
  const companySettings = useSelector(state => state.companySettings);
  const requestBusy = useSelector(state => state.requestBusy);
  const params = useParams();
  const EFSTransactionsData = companySettings?.integrations?.fuelCards?.EFS?.EFSTransactions?.data ?? [];
  const EFSTransactionsFilterBegDate = companySettings?.integrations?.fuelCards?.EFS?.EFSTransactions?.filters?.begDate ?? null;
  const EFSTransactionsFilterEndDate = companySettings?.integrations?.fuelCards?.EFS?.EFSTransactions?.filters?.endDate ?? null;

  const toMinDate = new Date(EFSTransactionsFilterBegDate);
  const toMaxDate = new Date(EFSTransactionsFilterBegDate);
  toMaxDate.setDate(toMaxDate.getDate() + 30);
  const fromMinDate = new Date(EFSTransactionsFilterBegDate);
  fromMinDate.setDate(fromMinDate.getDate() - 30)
  const fromMaxDate = new Date(EFSTransactionsFilterEndDate);

  const dispatch = useDispatch();

  const userId = params && params.userId ? params.userId : null;

  useEffect(() => {
    if (EFSTransactionsData && !EFSTransactionsData.length) dispatch(fetchEFSTransactions({
      begDate: EFSTransactionsFilterBegDate,
      endDate: EFSTransactionsFilterEndDate,
      truckDriverId: userId,
    }));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Customers" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            EFS Transactions
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-2, { replace: true })}>
              Truck Drivers
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1, { replace: true })}>
              Truck Driver Details
            </Link>
            <Typography>EFS Transactions</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Card>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10, marginTop: 20 }}>
          <div style={{ display: 'flex' }}>
            <DesktopDatePicker
              label="From"
              inputFormat="MM/dd/yyyy"
              value={EFSTransactionsFilterBegDate}
              fullWidth
              minDate={fromMinDate}
              maxDate={fromMaxDate}
              onChange={value => dispatch(setEFSTransactionsFiltersBegDate(value))}
              renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params} size="small" />}
              variant="outlined"
              my={2}
            />
            <DesktopDatePicker
              label="To"
              inputFormat="MM/dd/yyyy"
              value={EFSTransactionsFilterEndDate}
              fullWidth
              minDate={toMinDate}
              maxDate={toMaxDate}
              onChange={value => dispatch(setEFSTransactionsFiltersEndDate(value))}
              renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} size="small" />}
              variant="outlined"
              my={2}
            />
            <Button 
              variant="contained"
              color="primary"
              style={{ marginLeft: 10 }}
              disabled={requestBusy}
              onClick={() => {
                if (requestBusy) return;
                dispatch(fetchEFSTransactions({
                  begDate: EFSTransactionsFilterBegDate,
                  endDate: EFSTransactionsFilterEndDate,
                  truckDriverId: userId,
                }));
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Card>

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable userId={userId} rows={EFSTransactionsData} />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TruckDriverEfsTransactions;
