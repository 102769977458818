import AddTruckDrivers from '../pages/pages/AddTruckDriver';
import AddCompanyCustomer from '../pages/pages/AddCompanyCustomer';
import AddCompanyLocation from '../pages/pages/AddCompanyLocation';
import AddEquipment from '../pages/pages/AddEquipment';
import AddUser from '../pages/pages/AddUser';

export default {
  addTruckDriver: {
    key: "addTruckDriver",
    display: "Add Truck Driver",
    component: <AddTruckDrivers fullDrawerModalMode />
  },
  addCustomer: {
    key: "addCustomer",
    display: "Add Customer",
    component: <AddCompanyCustomer fullDrawerModalMode />
  },
  addLocation: {
    key: "addLocation",
    display: "Add Location",
    component: <AddCompanyLocation fullDrawerModalMode />
  },
  addEquipment: {
    key: "addEquipment",
    display: "Add Equipment",
    component: <AddEquipment fullDrawerModalMode />
  },
  addUser: {
    key: "addUser",
    display: "Add User",
    component: <AddUser fullDrawerModalMode />
  }
};
