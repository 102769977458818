export default {
  refrigerated: {
    key: 'refrigerated',
    display: 'Refrigerated'
  },
  flatbed: {
    key: 'flatbed',
    display: 'Flatbed'
  },
  dryVan: {
    key: 'dryVan',
    display: 'Dry Van'
  }
};
