import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { refreshToken } from './currentLoggedUser';

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 20,
    skip: 0,
    paymentType: '-1',
  },
  initialFetch: false,
  currentEditData: null,
  availablePaymentTypesData: [],
});

export const accountingPaymentTypesSlice = createSlice({
  name: "accountingPaymentTypes",
  initialState: getInitialState(),
  reducers: {
    pushIntoData: (state, action) => {
      state.data.push(action.payload);
      return state;
    },
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    concatData: (state, action) => {
      state.data = state.data.concat(action.payload);
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setFiltersPaymentType: (state, action) => {
      state.filters.paymentType = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    updateOneFromData: (state, action) => {
      state.data.forEach((item, index) => {
        if (item.id === action.payload.id) return state.data[index] = action.payload;
      });
      return state;
    },
    deleteOneFromData: (state, action) => {
      let indexDelete = -1;
      state.data.forEach((item, index) => {
        if (item.id === action.payload.id) return indexDelete = index;
      });
      if (indexDelete !== -1) state.data.splice(indexDelete, 1);
      return state;
    },
    setAvailablePaymentTypesData: (state, action) => {
      state.availablePaymentTypesData = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  pushIntoData,
  setData, 
  concatData,
  setFiltersSkip,
  setFiltersPaymentType,
  setInitialFetch,
  updateOneFromData,
  deleteOneFromData,
  setAvailablePaymentTypesData,
  resetState,
} = accountingPaymentTypesSlice.actions;

export const fetchAccountingPaymentTypes = (reset = true) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  if (reset) {
    dispatch(setFiltersSkip(0));
  }
  const state = getState();
  const filters = state && state.accountingPaymentTypes && state.accountingPaymentTypes.filters ? state.accountingPaymentTypes.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (filters.paymentType && filters.paymentType !== '-1') queryParams.append('paymentType', filters.paymentType);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/accounting-payment-types?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (reset) dispatch(setData([]));
          dispatch(concatData(response.data.data.rows));
          dispatch(setFiltersSkip(filters.limit));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
      });
  }));
};

export const addAccountingPaymentType = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post(
          '/api/accounting-payment-types',
          { data }
      )
      .then(response => {
          if (response.status === 201 && response.data && response.data.data){
              window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
              dispatch(setRequestBusy(false));
              dispatch(pushIntoData(response.data.data));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const updateAccountingPaymentType = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/accounting-payment-types',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(updateOneFromData(data));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const deleteAccountingPaymentType = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.delete(`/api/accounting-payment-types/${data.id}`)
      .then(response => {
          if (response.status === 200){
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(deleteOneFromData(data));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const fetchAvailablePaymentTypesData = (params = {}) => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    const queryParams = new URLSearchParams();
    queryParams.append('limit', 50);
    queryParams.append('skip', 0);
    if (params && params.paymentType && params.paymentType !== '-1') queryParams.append('paymentType', params.paymentType);
    axios.get(`/api/accounting-payment-types?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setAvailablePaymentTypesData(response.data.data.rows));
        }
      })
      .catch(() => {});
  }));
};

export default accountingPaymentTypesSlice.reducer;
