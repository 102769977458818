import React, { useEffect, useState, useRef } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  addAccountingPaymentType,
  fetchAccountingPaymentTypes,
  setFiltersPaymentType,
  updateAccountingPaymentType,
  deleteAccountingPaymentType
} from "../../redux/slices/accountingPaymentTypes";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import driverPaymentTypes from '../../keys/driverPaymentTypes';
import getUSDFormattedCurrency from '../../utils/getUSDFormattedCurrency';
import { 
  useLocation, 
  useNavigate,
  NavLink,
} from "react-router-dom";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  Breadcrumbs,
  Link,
  MenuItem,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Toolbar,
  Tooltip,
  IconButton,
  Drawer,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import {
  FilterList as FilterListIcon,
  Delete as DeleteIcon,
  Edit as EditIcon
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  description: "",
  paymentType: "-1",
  value: "",
  notes: ""
});

const validationSchema = Yup.object().shape({
  description: Yup.string().min(1,"Description must be at least 1 character").max(50,"Description must be at most 50 characters").required("Required"),
  paymentType: Yup.string().not(['-1'], 'Required'),
  value: Yup.number().required('Required'),
  notes: Yup.string().nullable().max(50,"Notes must be at most 250 characters")
});

function AccountingPaymentTypes() {

  const dispatch = useDispatch();
  const formikRef = useRef();
  const navigate = useNavigate();
  const historyLocation = useLocation();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [currentEditData, setCurrentEditData] = useState(null);
  const initialFetch = useSelector(state => state.accountingPaymentTypes.initialFetch);
  const data = useSelector(state => state.accountingPaymentTypes.data);
  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    paymentType: '-1',
  });
  const [removeItemHandler, setRemoveItemHandler] = useState(null);
  const fetchDebouncerValue = useRef(true);
  const fetchDebouncer = func => {
    if (fetchDebouncerValue.current) {
      fetchDebouncerValue.current = false;
      setTimeout(() => (fetchDebouncerValue.current = true), 500);
      func();
    }
  };

  const redirectFromTruckDriverDetails = historyLocation && historyLocation.state && historyLocation.state.redirectFromTruckDriverDetails ? true : false;

  useEffect(() => {
    if (currentEditData) setInitialValues({ ...currentEditData });
  }, [currentEditData]);

  useEffect(() => {
    if (!initialFetch) dispatch(fetchAccountingPaymentTypes());

    const scrollContainer = window;
    const checkIfScrollIsNearBottom = () => {
      const currentScroll = window.scrollY + window.innerHeight;
      const documentHeight = document.body.scrollHeight;
      const modifier = 100; 
      if (scrollContainer) {
        if(currentScroll + modifier > documentHeight) {
          fetchDebouncer(() => dispatch(fetchAccountingPaymentTypes(false)));
        }
      }
    };
    if (scrollContainer) scrollContainer.addEventListener('scroll', checkIfScrollIsNearBottom);
    return () => {
      if (scrollContainer) scrollContainer.removeEventListener('scroll', checkIfScrollIsNearBottom);
    }
  }, []);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (currentEditData) {
        const data = {
          id: currentEditData && currentEditData.id ? currentEditData.id : null,
          description: values.description, 
          paymentType: values.paymentType, 
          value: values.value, 
        };
        if (values.notes) data.notes = values.notes;
        else data.notes = null;
        dispatch(updateAccountingPaymentType(data, err => {
            if (err) return;
            setSubmitting(false);
            setCurrentEditData(null);
            setInitialValues(getInitialValues());
          }
        ));
      } else {
        const data = {
          description: values.description, 
          paymentType: values.paymentType, 
          value: values.value, 
        }
        if (values.notes) data.notes = values.notes;
        dispatch(addAccountingPaymentType(data, err => {
            if (err) return;
            resetForm();
            setSubmitting(false);
            setCurrentEditData(null);
            setInitialValues(getInitialValues());
          }
        ));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Payment Types" />
      <Typography variant="h3" gutterBottom display="inline">
        Payment Types
      </Typography>
      {
        redirectFromTruckDriverDetails &&
        <Breadcrumbs aria-label="Breadcrumb" mt={2}>
          <Link component={NavLink} to={""} onClick={() => navigate(-2)}>
            Truck Drivers
          </Link>
          <Link component={NavLink} to={""} onClick={() => navigate(-1)}>
            Truck Driver Details
          </Link>
          
          <Typography>Payment Types</Typography>
        </Breadcrumbs>
      }

      <Divider my={6} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Payment Type Information
              </Typography>
              
                <form onSubmit={handleSubmit}>
                  <div style={{ display: 'flex' }}>
                    <TextField
                      name="description"
                      label="Description*"
                      value={values.description}
                      error={Boolean(touched.description && errors.description)}
                      helperText={touched.description && errors.description}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      style={{ flex: 1 }}
                    />

                    <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginRight: 10, marginLeft: 10 }}>
                      <FormControl fullWidth>
                        <InputLabel>Type*</InputLabel>
                        <Select
                          label="Type*"
                          value={values.paymentType}
                          error={Boolean(touched.paymentType && errors.paymentType)}
                          helperText={touched.paymentType && errors.paymentType}
                          onBlur={handleBlur}
                          onChange={e => {
                            const value = e.target.value;
                            if (value === '-1') handleChange({ target: {value: '', name: 'value' }});
                            handleChange({ target: {value, name: 'paymentType' }});
                          }}
                          variant="outlined"
                          my={2}
                          name="paymentType"
                          
                        >
                          <MenuItem value="-1">Select</MenuItem>
                          {
                            Object.keys(driverPaymentTypes).map(item => (
                              <MenuItem value={driverPaymentTypes[item].key}>{driverPaymentTypes[item].display}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </div>

                    <TextField
                      {
                        ...(
                          values.paymentType === driverPaymentTypes.percent.key
                          ?
                            {
                              InputProps: {
                                endAdornment: <InputAdornment position="end">%</InputAdornment>
                              }
                            }
                          :
                            values.paymentType === driverPaymentTypes.perMile.key
                          ?
                            {
                              InputProps: {
                                startAdornment: <InputAdornment position="start">$</InputAdornment>
                              }
                            }
                          :
                            {}
                        )
                      }
                      disabled={values.paymentType === '-1'}
                      type="number"
                      name="value"
                      label="Value*"
                      value={values.value}
                      error={Boolean(touched.value && errors.value)}
                      helperText={touched.value && errors.value}
                      onBlur={handleBlur}
                      onChange={e => {
                        const value = e.target.value;
                        if (value < 0) return;
                        handleChange({ target: {value, name: 'value' }});
                      }}
                      variant="outlined"
                      my={2}
                      style={{ flex: 1, marginRight: 10 }}
                      inputProps={{ min: 0, step: "any" }}
                    />
                    <TextField
                      name="notes"
                      label="Notes"
                      value={values.notes}
                      error={Boolean(touched.notes && errors.notes)}
                      helperText={touched.notes && errors.notes}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                      style={{ flex: 1 }}
                    />
                  </div>
                
                  <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    {
                      currentEditData && 
                      <Button
                        variant="contained"
                        mt={3}
                        style={{ marginRight: 10 }}
                        onClick={() => {
                          setCurrentEditData(null);
                          setInitialValues(getInitialValues());
                        }}
                      >
                        Cancel
                      </Button>
                    }
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      mt={3}
                    >
                      {currentEditData ? 'Save changes' : 'Add'}
                    </Button>
                  </div>
                </form>
            </CardContent>
          </Card>
        )}
      </Formik>

      <Card mb={6}>
        <CardContent>
        <Toolbar>
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Tooltip title="Filter list">
              <IconButton disabled={currentEditData} style={{ opacity: currentEditData ? 0.5 : 1 }} onClick={() => setOpenFilter(true)} size="large">
                <FilterListIcon disabled={currentEditData} />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <TableHead>
              <TableRow>
                <TableCell>Description</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Value</TableCell>
                <TableCell>Notes</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {
                data.map(item => (
                  <TableRow key={item.id}>
                    <TableCell align="left">{item.description}</TableCell>
                    <TableCell align="left">{driverPaymentTypes[item.paymentType].display}</TableCell>
                    <TableCell align="left">
                      {
                        item.paymentType === driverPaymentTypes.percent.key
                        ?
                          item.value + '%'
                        :
                        item.paymentType === driverPaymentTypes.perMile.key
                        ?
                          getUSDFormattedCurrency(item.value)
                        :
                        ''
                      }
                    </TableCell>
                    <TableCell align="left">{item.notes}</TableCell>
                    <TableCell align="right">
                      <IconButton 
                        disabled={currentEditData} 
                        onClick={() => {
                          setCurrentEditData(item);
                          window.focus();
                          window.scrollTo({ top: 0, behavior: 'smooth' });
                        }} 
                        size="small" 
                        style={{ marginRight: 20, opacity: currentEditData ? 0.5 : 1 }}
                      >
                        <EditIcon disabled={currentEditData} />
                      </IconButton>
                      <IconButton 
                        disabled={currentEditData} 
                        color="error" 
                        onClick={() => setRemoveItemHandler(item)} 
                        size="small"
                        style={{ opacity: currentEditData ? 0.5 : 1 }}
                      >
                        <DeleteIcon disabled={currentEditData} color="error" />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
          {
            !data.length && initialFetch
            ?
              <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
            :
              ''
          }
        </CardContent>
      </Card>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <div style={{ padding: 15 }}>
          <Typography textAlign="center" variant="h3">
            Filters
          </Typography>
          <Divider paddingTop="15px" />
          <div style={{ marginTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel>Type</InputLabel>
              <Select
                label="Type"
                value={filterValues.paymentType}
                fullWidth
                onChange={e => setFilterValues(prevState => ({ ...prevState, paymentType: e.target.value }))}
                variant="outlined"
                style={{ 
                  width: 300,
                  display: 'block'
                }}
              >
                <MenuItem value="-1">Select</MenuItem>
                {
                  Object.keys(driverPaymentTypes).map(type => (
                    <MenuItem value={driverPaymentTypes[type].key}>{driverPaymentTypes[type].display}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <div style={{ 
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-around'
          }}>
            <Button 
              variant="text"
              color="secondary"
              onClick={() => {
                setFilterValues(() => ({
                  paymentType: '-1',
                }));
                dispatch(setFiltersPaymentType('-1'));
                dispatch(fetchAccountingPaymentTypes());
              }}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setFiltersPaymentType(filterValues.paymentType));
                dispatch(fetchAccountingPaymentTypes());
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Drawer>
      <Dialog
        open={removeItemHandler}
        onClose={() => setRemoveItemHandler(null)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this item?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setRemoveItemHandler(null)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            color="error" 
            onClick={() => dispatch(deleteAccountingPaymentType(removeItemHandler, err => {
              if (err) return;
              setRemoveItemHandler(null);
            }))}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AccountingPaymentTypes;
