import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import QbLogo  from '../../vendor/qb-logo.png';
import AddIcon from '@mui/icons-material/Add';
import { 
  enableQuickbooksIntegration, 
  disableQuickbooksIntegration,
  getQuickbooksLoginUri,
  processQuickbooksLoginRedirect,
  getQuickbooksProductById,
  getAllQuickbooksProducts,
  updateQuickbooksConfig
} from '../../redux/slices/companySettings';
import SelectList from '../components/SelectList';

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Typography,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";


const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);


const getInitialQuickbooksSetupValues = () => ({
  quickbooksClientId: '',
  quickbooksClientSecret: '',
});

const getInitialQuickbooksConfigValues = () => ({
  quickbooksProductItemId: '',
});

function IntegrationsAccounting() {
  const [initialQuickbooksSetupValues, setInitialQuickbooksSetupValues] = useState(getInitialQuickbooksSetupValues());
  const [initialQuickbooksConfigValues, setInitialQuickbooksConfigValues] = useState(getInitialQuickbooksConfigValues());
  const [showQuickbooksSetupModal, setShowQuickbooksSetupModal] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showDisableQuickbooksSetupModal, setShowDisableQuickbooksSetupModal] = useState(false);
  const [showQuickbooksConfigModal, setShowQuickbooksConfigModal] = useState(false);
  const [showSearchQuickbooksProductsSelectList, setShowSearchQuickbooksProductsSelectList] = useState(false);
  const [tempQuickbooksProductData, setTempQuickbooksProductData] = useState(null);
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const company = currentLoggedUser?.company;
  const requestBusy = useSelector(state => state.requestBusy);
  const companySettings = useSelector(state => state.companySettings);
  const quickbooksProductsData = companySettings?.integrations?.accounting?.quickbooks?.products?.data ?? [];
  const quickbooksProductsRequestBusy = companySettings?.integrations?.accounting?.quickbooks?.products?.requestBusy ?? false;

  useEffect(() => {
    const q = new URLSearchParams(window.location.search);
    // If these are passed on load, then that means it's coming from Quickbooks redirect.
    if (q && q.get('state') && q.get('code') && q.get('realmId')) {
      dispatch(processQuickbooksLoginRedirect({ redirectUrl: window.location.href }));
    }
    if (q && q.get('openQuickbooksConfig')) setShowQuickbooksConfigModal(true)
  }, []);

  useEffect(() => {
    if (!tempQuickbooksProductData && company?.quickbooksProductItemId) {
      dispatch(getQuickbooksProductById({ id: company.quickbooksProductItemId }, (err, data) => {
        if (err || !data) return;
        setTempQuickbooksProductData(data);
      }));
    }
  }, [tempQuickbooksProductData, company?.quickbooksProductItemId, dispatch]);

  useEffect(() => {
    setInitialQuickbooksConfigValues({
      quickbooksProductItemId: company?.quickbooksProductItemId
    });
  }, [company]);

  const openSearchQuickbooksProductsSelectList = useCallback(() => {
    setShowSearchQuickbooksProductsSelectList(true);
    if (quickbooksProductsData?.length === 0) dispatch(getAllQuickbooksProducts());
  }, [quickbooksProductsData, dispatch]);

  const handleGetQuickbooksLoginUri = () => {
    dispatch(getQuickbooksLoginUri((err, uri) => {
      if (err || !uri) return;
      window.location = uri;
    }));
  };
 
  const quickbooksSetupValidationSchema = Yup.object().shape({
    quickbooksClientId: Yup.string().required("Required").max(255,"Client Id must be at most 50 characters"),
    quickbooksClientSecret: Yup.string().required("Required").max(255,"Client Secret must be at most 50 characters"),
  });

  const handleSubmitQuickbooksSetup = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {

      const { 
        quickbooksClientId,
        quickbooksClientSecret,
      } = values;

      dispatch(enableQuickbooksIntegration({ quickbooksClientId, quickbooksClientSecret }, (err) => {
        setSubmitting(false);
        if (err) return;
        setShowQuickbooksSetupModal(false);
        handleGetQuickbooksLoginUri();
      }));
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const handleSubmitQuickbooksConfig = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {

      const { 
        quickbooksProductItemId,
      } = values;

      dispatch(updateQuickbooksConfig({ quickbooksProductItemId }, (err) => {
        setSubmitting(false);
        if (err) return;
        setShowQuickbooksConfigModal(false);
      }));
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Accounting Integrations" />

      <Typography variant="h3" gutterBottom display="inline">
        Accounting Integrations
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} textAlign="center" style={{ paddingTop: 24 }}  >
          <Card mb={6}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    Quickbooks
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link href="https://quickbooks.intuit.com" >quickbooks.intuit.com</Link>
                  </Typography>
                  <Paper mt={4}>
                    <div style={{ paddingTop: 10 }}>
                      {
                        company &&
                        !company.quickbooksClientId
                        ?
                          <Button
                            variant="contained"
                            color="primary"
                            disabled={requestBusy}
                            onClick={() => {
                              setShowQuickbooksSetupModal(true);
                            }}
                          >
                            Setup
                          </Button>
                        :
                          ''
                      }
                      {
                        company?.quickbooksClientId
                        ?
                          <div>
                            {
                              company.quickbooksIsOauth2Logged &&
                              company.quickbooksOauth2DataSaved
                              ?
                                <Button
                                  variant="contained"
                                  color="primary"
                                  onClick={() => setShowQuickbooksConfigModal(true)}
                                  style={{ marginRight: 10 }}
                                >
                                  Open Config
                                </Button>
                              :
                                <Button
                                  variant="contained"
                                  color="primary"
                                  disabled={requestBusy}
                                  onClick={handleGetQuickbooksLoginUri}
                                  style={{ marginRight: 10 }}
                                >
                                  Login
                                </Button>
                            }
                            <Button
                              variant="contained"
                              color="error"
                              disabled={requestBusy}
                              onClick={() => {
                                setShowDisableQuickbooksSetupModal(true);
                              }}
                            >
                              Disable
                            </Button>
                          </div>
                        :
                          ''
                      }
                    </div>
                  </Paper>
                  </div>
                  <div style={{ flex: 1, marginLeft: 10, }}>
                    <img src={QbLogo} style={{width: 90}} alt="" />
                  </div>
                </div>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
      
      <Dialog
        open={showQuickbooksSetupModal}
        onClose={() => setShowQuickbooksSetupModal(false)}
      >
        <Formik
          initialValues={initialQuickbooksSetupValues}
          validationSchema={quickbooksSetupValidationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmitQuickbooksSetup}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} style={{width: 600}}>
              <DialogTitle>Setup Quickbooks</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  name="quickbooksClientId"
                  label="Client ID*"
                  variant="outlined" 
                  fullWidth
                  value={values.quickbooksClientId}
                  error={Boolean(touched.quickbooksClientId && errors.quickbooksClientId)}
                  helperText={touched.quickbooksClientId && errors.quickbooksClientId}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  margin="dense"
                  name="quickbooksClientSecret"
                  label="Client Secret*"
                  variant="outlined" 
                  type="password"
                  fullWidth
                  value={values.quickbooksClientSecret}
                  error={Boolean(touched.quickbooksClientSecret && errors.quickbooksClientSecret)}
                  helperText={touched.quickbooksClientSecret && errors.quickbooksClientSecret}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button 
                  color="primary"
                  onClick={() => setShowQuickbooksSetupModal(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit" disabled={requestBusy}>
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={showDisableQuickbooksSetupModal}
        onClose={() => setShowDisableQuickbooksSetupModal(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <div>
              Are you sure you want to disable Quickbooks integration? You won't be able to ship invoices to the Quickbooks anymore.
            </div>
            <TextField
              type="password"
              name="password"
              label="Password"
              value={passwordConfirm}
              fullWidth
              onChange={e => setPasswordConfirm(e.target.value)}
              my={2}
            />
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary"
              onClick={() => setShowDisableQuickbooksSetupModal(false)}
            >
              Cancel
            </Button>
            <Button 
              color="error" 
              variant="contained"
              disabled={!passwordConfirm || requestBusy}
              onClick={() => {
                dispatch(disableQuickbooksIntegration({ password: passwordConfirm }, (err) => {
                  if (err) return;
                  setShowDisableQuickbooksSetupModal(false);
                }));
              }}
            >
              Disable
            </Button>
          </DialogActions>
      </Dialog>
      <Dialog
        open={showQuickbooksConfigModal}
        onClose={() => setShowQuickbooksConfigModal(false)}
        maxWidth={"md"}
        fullWidth={true}
      >
        <Formik
          initialValues={initialQuickbooksConfigValues}
          enableReinitialize={true}
          onSubmit={handleSubmitQuickbooksConfig}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Quickbooks Config</DialogTitle>
              <DialogContent>
                {
                  company?.quickbooksClientId &&
                  <>
                    <div style={{ marginBottom: 7 }}>
                      Client ID: <span style={{ fontWeight: 'bold' }}>{company?.quickbooksClientId}</span>
                    </div>
                    <Divider />
                  </>
                }
                <div style={{ marginBottom: 10 }}>
                  <Typography variant="body1" gutterBottom style={{ marginTop: 10, fontStyle: 'italic' }}>
                    Product is used as an article of each Load invoice. Please create one on the Quickbooks and pull it here.
                  </Typography>
                  {
                    !values.quickbooksProductItemId
                    ?
                      <Button 
                        variant="contained"
                        startIcon={<AddIcon />}
                        onClick={openSearchQuickbooksProductsSelectList}
                        size="small"
                        style={{ marginTop: 10 }}
                      >
                        Set Product
                      </Button>
                    :
                      <div>
                        <Typography mt={0.5}>
                          Product ID: <span style={{ fontWeight: 'bold' }}>{values.quickbooksProductItemId}</span>
                        </Typography>
                        <Typography mt={0.5}>
                          Product Name: <span style={{ fontWeight: 'bold' }}>{tempQuickbooksProductData?.Name}</span>
                        </Typography>
                        <div style={{ display: 'flex',  justifyContent: 'flex-end' }}>
                          <Button 
                            variant="outlined"
                            onClick={openSearchQuickbooksProductsSelectList}
                            size="small"
                            style={{ marginTop: 5 }}
                          >
                            Change Product
                          </Button>
                        </div>
                      </div>
                  }
                </div>
                <Divider />
              </DialogContent>
              <DialogActions>
                <Button 
                  color="primary"
                  onClick={() => setShowQuickbooksConfigModal(false)}
                >
                  Cancel
                </Button>
                <Button 
                  color="primary" 
                  variant="contained"
                  type="submit" 
                  disabled={requestBusy}
                >
                  Save
                </Button>
              </DialogActions>
              {
                showSearchQuickbooksProductsSelectList &&
                <SelectList 
                  title="Select Quickbooks Product"
                  openModal={showSearchQuickbooksProductsSelectList}
                  data={
                    quickbooksProductsData
                    ? 
                      quickbooksProductsData.map(item => ({ 
                        id: item.Id, 
                        item: `${item.Id} - ${item.Name}`
                      })) 
                    : 
                      []
                  }
                  selectedValue={values?.quickbooksProductItemId}
                  handleClose={() => setShowSearchQuickbooksProductsSelectList(false)}
                  loading={quickbooksProductsRequestBusy}
                  onChange={(id) => {
                    handleChange({
                      target: {
                        value: id,
                        name: 'quickbooksProductItemId' 
                      }
                    })
                    dispatch(getQuickbooksProductById({ id }, (err, data) => {
                      if (err || !data) return;
                      setTempQuickbooksProductData(data);
                    }));
                  }}
                />
              }
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default IntegrationsAccounting;