import React, { useEffect } from "react";
import styled from "styled-components/macro";
import moment from 'moment';
import { Helmet } from "react-helmet-async";
import { 
  fetchBilling,
  setFiltersLimit,
  setFiltersSkip,
} from "../../redux/slices/billing";
import companyPaymentGateawayTypes from "../../keys/companyPaymentGateawayTypes";
import getUSDFormattedCurrency from '../../utils/getUSDFormattedCurrency';
import { useNavigate, useParams, NavLink } from 'react-router-dom';
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Divider as MuiDivider,
  Grid,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Typography,
  Breadcrumbs,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";
import userRoles from "../../keys/userRoles";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "paymentGateaway", alignment: "left", label: "Payment Gateaway" },
  { id: "amountCharged", alignment: "left", label: "Amount Charged" },
  { id: "createdAt", alignment: "left", label: "Date" },
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
      {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(state => state.billing.filters.limit);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const rowsSkip = useSelector(state => state.billing.filters.skip);
  const rowsTotal = useSelector(state => state.billing.total);
  const initialFetch = useSelector(state => state.billing.initialFetch);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      dispatch(setFiltersSkip(rowsPerPage + rowsSkip));
    } else {
      dispatch(setFiltersSkip(rowsSkip - rowsPerPage));
    }
    dispatch(fetchBilling());
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setFiltersLimit(value));
    dispatch(setFiltersSkip(0));
    dispatch(fetchBilling());
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper>
        <Toolbar>
          <Spacer />
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {
                rows.map((row, index) => {
                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        if (currentLoggedUser?.role === userRoles.admin.key) {
                          navigate(`/companies/company-details/2/billing/invoice-details/${row.id}`);
                        } else {
                          navigate(`/billing/invoice-details/${row.id}`);
                        }
                      }} 
                      hover                      
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{companyPaymentGateawayTypes[row.paymentGateaway].display}</TableCell>
                      <TableCell align="left">{getUSDFormattedCurrency(row.amountCharged ?? 0)}</TableCell>
                      <TableCell align="left">{moment(row.createdAt ?? new Date()).format("MM/DD/YYYY")}</TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length && initialFetch
          ?
            <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
          :
            ''
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function Billing() {
  const billing = useSelector(state => state.billing);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  const companyId = params && params.companyId ? params.companyId : null;

  const isAdmin = currentLoggedUser?.role === userRoles.admin.key;

  useEffect(() => {
    if (billing && !billing.initialFetch) dispatch(fetchBilling({ companyId }));
  }, []);
 
  return (
    <React.Fragment>
      <Helmet title="Billing" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Billing
          </Typography>
          {
            isAdmin &&
            <Breadcrumbs aria-label="Breadcrumb" mt={2}>
              <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Companies
              </Link>
              <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Company Details
              </Link>
              <Typography>Billing</Typography>
            </Breadcrumbs>
          }
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable 
            rows={billing.data}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default Billing;
