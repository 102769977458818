import {
  Home,
} from "react-feather";
import * as Icon from "@mui/icons-material";
import userRoles from '../../keys/userRoles';

const pagesSection = [
  {
    href: "/dashboard",
    icon: Icon.Dashboard,
    title: "Dashboard",
    access: [
      userRoles.admin.key, 
      userRoles.companyAdmin.key, 
      userRoles.dispatcher.key, 
      userRoles.accounting.key, 
      userRoles.maintenance.key,
      userRoles.truckDriver.key,
      userRoles.broker.key,
      userRoles.safety.key,
    ]
  },
  {
    href: "/companies",
    icon: Home,
    title: "Companies",
    access: [userRoles.admin.key]
  },
  {
    href: "/users",
    icon: Icon.People,
    title: "Users",
    access: [userRoles.companyAdmin.key]
  },
  {
    href: "/truck-drivers",
    icon: Icon.LocalShipping,
    title: "Truck Drivers",
    access: [userRoles.companyAdmin.key, userRoles.dispatcher.key]
  },
  {
    href: "/loads",
    icon: Icon.Description,
    title: "Loads",
    access: [userRoles.companyAdmin.key, userRoles.dispatcher.key]
  },
  {
    href: "/drivers-map",
    icon: Icon.Map,
    title: "Drivers Map",
    access: [userRoles.companyAdmin.key, userRoles.dispatcher.key]
  },
  {
    href: "/equipment",
    icon: Icon.Build,
    title: "Equipment",
    access: [userRoles.companyAdmin.key, userRoles.maintenance.key, userRoles.safety.key]
  },
  {
    href: "/company-customers",
    icon: Icon.SupportAgent,
    title: "Customers",
    access: [userRoles.companyAdmin.key, userRoles.dispatcher.key]
  },
  {
    href: "/company-locations",
    icon: Icon.Business,
    title: "Locations",
    access: [userRoles.companyAdmin.key, userRoles.dispatcher.key]
  },
  {
    href: "/accounting/payment-types",
    icon: Icon.AccountBalance,
    title: "Accounting",
    access: [userRoles.companyAdmin.key, userRoles.accounting.key],
    children: [
      {
        href: "/accounting/payment-types",
        title: "Payment Types",
        access: [userRoles.companyAdmin.key, userRoles.accounting.key],
      },
      {
        href: "/accounting/invoices",
        title: "Invoices",
        access: [userRoles.companyAdmin.key, userRoles.accounting.key],
      },
    ],
  },
  {
    href: "/billing",
    icon: Icon.Receipt,
    title: "Billing",
    access: [userRoles.companyAdmin.key]
  },
  {
    href: "/settings",
    icon: Icon.Settings,
    title: "Settings",
    access: [userRoles.companyAdmin.key],
    children: [
      {
        href: "/settings/eld-integrations",
        title: "ELD Integrations",
        access: [userRoles.companyAdmin.key],
      },
      {
        href: "/settings/billing-integrations",
        title: "Billing Integrations",
        access: [userRoles.companyAdmin.key],
      },
      {
        href: "/settings/accounting-integrations",
        title: "Accounting Integrations",
        access: [userRoles.companyAdmin.key],
      },
      {
        href: "/settings/fuel-card-integrations",
        title: "Fuel Card Integrations",
        access: [userRoles.companyAdmin.key],
      },
      {
        href: "/settings/company-settings",
        title: "Company Settings",
        access: [userRoles.companyAdmin.key],
      },
    ],
  },
];

const navItems = [
  {
    title: "",
    pages: pagesSection,
  },
];

export default navItems;
