import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components/macro";
import { useDispatch, useSelector } from 'react-redux';
import timeHelper from '../../../utils/timeHelper';

import {
  Box,
  Grid,
  Card,
  TextField as MuiTextField,
  Typography,
  Avatar,
  Fab,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import SendIcon from "@mui/icons-material/Send";
import { 
  fetchLoadNotes, 
  addLoadNote,
  setNextPage
} from "../../../redux/slices/loadNotes";


const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const ChatContainer = styled.div`
  width: 100%;
  height: 490px;
`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: 430px;
  position: relative;
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: center;
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  width: 34px;
  height: 34px;
  bottom: 5px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(1)};
  ${(props) => props.theme.shadows[1]};
  width: 220px;
  word-break: break-all;
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

const ChatInput = styled(Grid)`
  height: 94px;
  padding: ${(props) => props.theme.spacing(5)};
`;

const NoMessagesBox = styled.div`
  text-align: center;
  margin-bottom: 0;
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0);
`;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="" src={avatar} />
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function LoadNotes() {

  const containerElem = useRef();
  const allLoadNotes = useSelector(state => state.loadNotes);
  const dispatch = useDispatch();
  const loads = useSelector(state => state.loads);
  const loadId = loads?.currentSelectedLoad?.id;
  const loadNotes = allLoadNotes[loadId];
  const [inputValue, setInputValue] = useState('');
  const requestBusy = useSelector(state => state.requestBusy);

  useEffect(() => {
    if (!loadNotes?.initialFetch) dispatch(fetchLoadNotes(loadId, () => {
      if (containerElem.current) containerElem.current.scrollTo(0, 9999999999999999);
    }));
    if (containerElem.current) containerElem.current.scrollTo(0, 9999999999999999);
  }, []);

  useEffect(() => {
    const handler = () => {
      const scrollTop = containerElem?.current?.scrollTop;
      if (scrollTop < 5 && loadNotes.hasMore) {
        dispatch(setNextPage({ loadId }));
        dispatch(fetchLoadNotes(loadId, () => {
          containerElem.current.scrollTo(0, 150);
        }));
      }
    };
    if (containerElem.current) {
      containerElem.current.addEventListener('scroll', handler);
    }
    return () => {
      containerElem?.current?.removeEventListener('scroll', handler);
    };
  }, [dispatch, loadId, loadNotes, loadNotes?.hasMore]);

  return (
    <ChatContainer container component={Card}>
      <ChatMessages ref={containerElem}>
        {
          loadNotes?.data?.map((note, key) => {
            return (
              <ChatMessageComponent
                key={note?.id || key}
                name={note?.createdBy?.firstName + ' ' + note?.createdBy?.lastName}
                avatar={note?.createdBy?.avatar}
                message={note.body}
                time={timeHelper.getTime(new Date(note?.createdAt))}
                position="left"
              />
            );
          })
        }
        {
          loadNotes?.initialFetch &&
          loadNotes?.data?.length === 0?
            <NoMessagesBox>
              No notes...
            </NoMessagesBox>
          :
            ''
        }
      </ChatMessages>
      <Divider />
      <ChatInput container>
        <Grid item style={{ flexGrow: 1 }}>
          <TextField 
            variant="outlined" 
            label="Type your note" 
            fullWidth 
            size="small"
            value={inputValue}
            onChange={e => {
              const value = e.target.value;
              if (value.length > 5000) return;
              setInputValue(value);
            }}
          />
        </Grid>
        <Grid item>
          <Box ml={2}>
            <Fab 
              style={{ position: 'relative', bottom: 2 }} 
              color="primary"
              aria-label="add" 
              size="small" 
              disabled={requestBusy || !inputValue.length}
              onClick={() => {
                if (requestBusy || !inputValue.length) return;
                dispatch(addLoadNote({
                  loadId,
                  loadNote: {
                    body: inputValue
                  }
                }, () => {
                  setInputValue('');
                  if (containerElem.current) containerElem.current.scrollTo(0, 9999999999999999)
                }));
              }}
            >
              <SendIcon />
            </Fab>
          </Box>
        </Grid>
      </ChatInput>
    </ChatContainer>
  );
}

export default LoadNotes;
