import React, { useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from '@mui/styles';

import userRoles from "../../keys/userRoles";
import regularExpressions from '../../utils/regularExpressions';

import { 
  currentLoggedUserEnable2fa,
  currentLoggedUserVerify2fa,
  currentLoggedUserDisable2fa,
  changePasswordProfile,
  uploadAvatar,
  deleteAvatar
} from '../../redux/slices/currentLoggedUser';

import {
  Avatar,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
} from "@mui/material";
import { 
  CloudUpload as MuiCloudUpload,
  Delete as MuiDeleteIcon
} from "@mui/icons-material";
import { spacing } from "@mui/system";
import { ReactComponent as Illustration2FA } from '../../vendor/2fa-illustration.svg';
import { ReactComponent as ChangePasswordIllustration } from '../../vendor/change-password-illustration.svg';
import { 
  setSuccessDialogMessage,
  setErrorDialogMessage 
} from "../../redux/slices/messages";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const CloudUpload = styled(MuiCloudUpload)(spacing);

const DeleteIcon = styled(MuiDeleteIcon)(spacing);

const BigAvatar = styled(Avatar)`
  width: 120px;
  height: 120px;
  margin: 0 auto ${(props) => props.theme.spacing(2)};
`;

const modes2fa = {
  ENABLE_2FA: "ENABLE_2FA",
  VERIFY_2FA: "VERIFY_2FA"
};

const formikModes = {
  PASSWORD_CHANGE: "PASSWORD_CHANGE",
  TWO_FACTOR_AUTHENTICATION: "TWO_FACTOR_AUTHENTICATION"
};

const getInitialValues = () => ({
  code2fa: '',
  phoneNumber2fa: '',
  password: '',
  confirmPassword: '',
  oldPassword: ''
});

function Profile() {

  const dispatch = useDispatch();
  const theme = useTheme();

  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [showDeleteAvatarModal, setShowDeleteAvatarModal] = useState(false);
  const [show2faModal, setShow2faModal] = useState(false);
  const [showPasswordChangeModal, setShowPasswordChangeModal] = useState(false);
  const [showDisable2fa, setShowDisable2fa] = useState(false);
  const [mode2fa, setMode2fa] = useState(null);
  const [formikMode, setFormikMode] = useState(null);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);

  let validationSchema; 
  if (formikMode === formikModes.PASSWORD_CHANGE) {
    validationSchema = Yup.object({
      password: Yup.string().matches(regularExpressions.PASSWORD, "Must be at least 8 characters, one number, one uppercase and one symbol")
      .max(255)
      .required("Required"),
    confirmPassword: Yup.string().required("Required").when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
      oldPassword: Yup.string().required('Required'),
    });
  } else if (formikMode === formikModes.TWO_FACTOR_AUTHENTICATION) {
    if (mode2fa === modes2fa.ENABLE_2FA) {
      validationSchema = Yup.object({
        phoneNumber2fa: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required")
      });
    } else if (mode2fa === modes2fa.VERIFY_2FA) {
      validationSchema = Yup.object({
        code2fa: Yup.string().min(7,"Code must be 7 characters.").max(7,"Code must be 7 characters.").required("Required")
      });
    }
  }

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (formikMode === formikModes.TWO_FACTOR_AUTHENTICATION) {
        if (mode2fa === modes2fa.ENABLE_2FA) {
          dispatch(currentLoggedUserEnable2fa(values.phoneNumber2fa, false, err => {
            if (err) return;
            setMode2fa(modes2fa.VERIFY_2FA);
          }));
        } else if (mode2fa === modes2fa.VERIFY_2FA) {
          dispatch(currentLoggedUserVerify2fa(values.code2fa, true, err => {
            if (err) return;
            setMode2fa(modes2fa.ENABLE_2FA);
            setShow2faModal(false);
          }));
        }
      } else if (formikMode === formikModes.PASSWORD_CHANGE) {
        dispatch(changePasswordProfile(values.password, values.oldPassword, err => {
          if (err) return;
          setShowPasswordChangeModal(false);
        }));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Profile" />

      <Typography variant="h3" gutterBottom display="inline">
        Profile
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} verticalAlign="middle" >
          <Card mb={6}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div>
                  <div style={{ marginBottom: 6 }}>
                    <Typography variant="body1" style={{ fontSize: '1.1em' }}>
                      First Name: <span style={{ fontWeight: 'bold' }}>{currentLoggedUser?.firstName}</span>          
                    </Typography>
                  </div>
                  <div style={{ marginBottom: 6 }}>
                    <Typography variant="body1" style={{ fontSize: '1.1em' }}>
                      Last Name: <span style={{ fontWeight: 'bold' }}>{currentLoggedUser?.lastName}</span>       
                    </Typography>
                  </div>
                  <div style={{ marginBottom: 6 }}>
                    <Typography variant="body1" style={{ fontSize: '1.1em' }}>
                      Email: <span style={{ fontWeight: 'bold' }}>{currentLoggedUser?.email}</span>               
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="body1" style={{ fontSize: '1.1em' }}>
                      Type: <span style={{ fontWeight: 'bold' }}>{currentLoggedUser ? userRoles[currentLoggedUser?.role].display : ''}</span>          
                    </Typography>
                  </div>
                </div>
                <div style={{ textAlign: 'center', width: 300 }}>
                  <BigAvatar
                    alt={currentLoggedUser?.firstName}
                    src={currentLoggedUser?.avatar}
                    style={{marginBottom: "10px"}}
                  />
                  <input
                    accept="image/*"
                    style={{ display: "none", marginTop: 6, marginBottom: 6  }}
                    id="raised-button-file"
                    type="file"
                    onChange={e => {
                      if (!e) return
                      let file;
                      if (e.target && e.target.files && e.target.files.length) file = e.target.files[0];
                      if (!file) return;
                  
                      const whitelistFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                      const maxSize = 10000000; //10mb
                      if (!whitelistFileTypes.find(type => type === file.type)) return dispatch(setErrorDialogMessage('File can be only type of: JPG or PNG.'));
                      else if (file.size > maxSize) return dispatch(setErrorDialogMessage('Maximum size of the file is 10MB.'));
                      dispatch(uploadAvatar(file, () => {
                        e.target.value = null;
                      }));
                    }}
                  />
                  {
                    currentLoggedUser?.avatar
                    ?
                    <Button 
                      variant="contained" 
                      color="error" 
                      component="span" 
                      marginBottom={2}
                      onClick={() => setShowDeleteAvatarModal(true)}
                    >
                      <DeleteIcon mr={2} /> Delete Avatar
                    </Button>
                    :
                    <label htmlFor="raised-button-file">
                      <Button variant="contained" color="primary" component="span" marginBottom={2}>
                        <CloudUpload mr={2} /> Upload
                      </Button>
                      <Typography variant="caption" display="block" gutterBottom>
                        For best results, use an image at least 128px by 128px in .jpg
                        format
                      </Typography>
                    </label>
                  }
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} textAlign="center" style={{ paddingTop: 0 }}  >
          <Card mb={6}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    Password Settings
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Take care of your password. You can change it anytime here.
                  </Typography>
                  <Paper mt={4}>
                    <div style={{ paddingTop: 10 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          setShowPasswordChangeModal(true);
                          setFormikMode(formikModes.PASSWORD_CHANGE);
                        }}
                      >
                        Change Password
                      </Button>
                    </div>
                  </Paper>
                  </div>
                  <div style={{ flex: 1, marginLeft: 10, color: theme?.palette?.primary?.main }}>
                    <ChangePasswordIllustration width={200} height={200} />
                  </div>
                </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6} textAlign="center" style={{ paddingTop: 0 }}>
          <Card mb={6} >
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ flex: 1, marginRight: 10, color: theme?.palette?.primary?.main }}>
                  <Illustration2FA width={200} height={200} />
                </div>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    2FA Settings
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    Add an extra layer of security to your Account by enabling our two-factor authentication - an entirely optional feature to your account.
                  </Typography>
                  {
                    currentLoggedUser && 
                    currentLoggedUser.is2faVerified &&
                    currentLoggedUser.phoneNumber2fa
                    ?
                      <Typography variant="body1" gutterBottom style={{ marginTop: 20, marginBottom: 20, fontWeight: 'bold' }}>
                        Phone Number:
                        {' ' + currentLoggedUser.phoneNumber2fa.replace(/./g,'*')}{
                          currentLoggedUser.phoneNumber2fa.slice(-2)
                        }
                      </Typography>
                    :
                      ''
                  }
                  <Paper mt={4}>
                    <div style={{ paddingTop: 10 }}>
                      {
                        currentLoggedUser && 
                        currentLoggedUser.is2faVerified
                        ?
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => setShowDisable2fa(true)}
                          >
                            Disable 2FA
                          </Button>
                        :
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              setShow2faModal(true);
                              setMode2fa(modes2fa.ENABLE_2FA);
                              setFormikMode(formikModes.TWO_FACTOR_AUTHENTICATION);
                            }}
                          >
                            Setup 2FA
                          </Button>
                      }
                    </div>
                  </Paper>
                </div>
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Dialog
        open={show2faModal}
        onClose={() => setShow2faModal(false)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              {
                mode2fa === modes2fa.ENABLE_2FA &&
                <>
                  <DialogTitle>2FA Settings</DialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ paddingBottom: 10 }}>
                      Provide us with Phone Number which will be used in 2FA. We will send you a code for verification shortly via SMS. 
                    </DialogContentText>
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+1</InputAdornment>
                      }}
                      margin="dense"
                      name="phoneNumber2fa"
                      label="Phone Number*"
                      variant="outlined"
                      fullWidth
                      value={values.phoneNumber2fa}
                      error={Boolean(touched.phoneNumber2fa && errors.phoneNumber2fa)}
                      helperText={touched.phoneNumber2fa && errors.phoneNumber2fa}
                      onBlur={handleBlur}
                      onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button 
                      color="primary"
                      onClick={() => setShow2faModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                      Send
                    </Button>
                  </DialogActions>
                </>
              }
              {
                mode2fa === modes2fa.VERIFY_2FA &&
                <>
                  <DialogTitle>2FA Verification</DialogTitle>
                  <DialogContent>
                    <DialogContentText style={{ paddingBottom: 5 }}>
                      Please enter your verification code here. 
                    </DialogContentText>
                    <TextField
                      margin="dense"
                      name="code2fa"
                      label="Code*"
                      variant="outlined"
                      fullWidth
                      value={values.code2fa}
                      error={Boolean(touched.code2fa && errors.code2fa)}
                      helperText={touched.code2fa && errors.code2fa}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (value.length === 8) return;
                        handleChange(e);
                      }}
                    />
                    <DialogContentText style={{ paddingTop: 5 }}>
                      You didn't get the code?
                      <span 
                        style={{ cursor: 'pointer', textDecoration: 'underline', marginLeft: 4 }}
                        onClick={() => {
                          dispatch(currentLoggedUserEnable2fa(values.phoneNumber2fa, false, (err) => {
                            if (err) return;
                            dispatch(setSuccessDialogMessage('Successfully sent'));
                          }));
                        }}
                      >Send again</span>
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button 
                      color="primary"
                      onClick={() => setShow2faModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button color="primary" variant="contained" type="submit">
                      Save
                    </Button>
                  </DialogActions>
                </>
              }
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={showDisable2fa}
        onClose={() => setShowDisable2fa(false)}
      >
        <DialogTitle>Disable 2FA</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingBottom: 10 }}>
            Are you sure you want to disable 2FA? You can't undo these changes. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            color="primary"
            onClick={() => setShowDisable2fa(false)}
          >
            Cancel
          </Button>
          <Button 
            color="error" 
            variant="contained" 
            type="submit"
            onClick={() => dispatch(currentLoggedUserDisable2fa((err) => {
              if (err) return;
              setShowDisable2fa(false);
            }))}
          >
            Yes, disable
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showDeleteAvatarModal}
        onClose={() => setShowDeleteAvatarModal(false)}
      >
        <DialogTitle>Delete Avatar</DialogTitle>
        <DialogContent>
          <DialogContentText style={{ paddingBottom: 10 }}>
            Are you sure you want to delete avatar?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            color="primary"
            onClick={() => setShowDeleteAvatarModal(false)}
          >
            Cancel
          </Button>
          <Button 
            color="error" 
            variant="contained" 
            type="submit"
            onClick={() => dispatch(deleteAvatar((err) => {
              if (err) return;
              setShowDeleteAvatarModal(false);
            }))}
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showPasswordChangeModal}
        onClose={() => setShowPasswordChangeModal(false)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <DialogTitle>Change Password</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  name="oldPassword"
                  label="Old Password*"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={values.oldPassword}
                  error={Boolean(touched.oldPassword && errors.oldPassword)}
                  helperText={touched.oldPassword && errors.oldPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  margin="dense"
                  name="password"
                  label="New Password*"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={values.password}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                <TextField
                  margin="dense"
                  name="confirmPassword"
                  label="Confirm New Password*"
                  variant="outlined"
                  type="password"
                  fullWidth
                  value={values.confirmPassword}
                  error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
              </DialogContent>
              <DialogActions>
                <Button 
                  color="primary"
                  onClick={() => setShowPasswordChangeModal(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
    </React.Fragment>
  );
}

export default Profile;