export default {
  adminNumberOfCreatedCompanies: {
    key: 'adminNumberOfCreatedCompanies',
  },
  adminYearRevenue: {
    key: 'adminYearRevenue',
  },
  adminNumberOfCreatedLoads: {
    key: 'adminNumberOfCreatedLoads',
  },
  companyUserNumberOfAvailableLoads: {
    key: 'companyUserNumberOfAvailableLoads',
  },
  companyUserNumberOfAvailableTruckDrivers: {
    key: 'companyUserNumberOfAvailableTruckDrivers',
  },
  companyUserNumberOfAvailableEquipment: {
    key: 'companyUserNumberOfAvailableEquipment',
  },
  companyUserNumberOfOilChangeExpiration: {
    key: 'companyUserNumberOfOilChangeExpiration',
  },
  companyUserNumberOfMedicalExpiration: {
    key: 'companyUserNumberOfMedicalExpiration',
  },
  companyUserYearRevenue: {
    key: 'companyUserYearRevenue',
  },
  companyUserTopTruckDriversByRevenue: {
    key: 'companyUserTopTruckDriversByRevenue',
  },
  companyUserTopDispatchersByLoadNumber: {
    key: 'companyUserTopDispatchersByLoadNumber',
  },
  companyUserTopDispatchersByRevenue: {
    key: 'companyUserTopDispatchersByRevenue',
  }
};
