import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  addCompany,
  fetchCurrentSelectedCompany,
  updateCompany
} from "../../redux/slices/companies";
import { 
  useDispatch,
  useSelector
} from "react-redux";

import {
  Alert as MuiAlert,
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  name: "",
  ownerFirstName: "",
  ownerLastName: "",
  loadBillingPrice: 0,
  email: "",
  phoneNumber: "",
  phoneNumberExtension: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  loginAccess: true
});

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3,"Company Name must be at least 3 characters").max(50,"Company Name  must be at most 50 characters").required("Required"),
  ownerFirstName: Yup.string().min(2,"Owner First Name must be at least 2 characters").max(50,"Owner First Name must be at most 50 characters").required("Required"),
  ownerLastName: Yup.string().min(2,"Owner Last Name must be at least 2 characters").max(50,"Owner Last Name must be at most 50 characters").required("Required"),
  phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
  phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
  address: Yup.string().min(3,"Address must be at least 3 characters").max(50,"Address must be at most 50 characters"),
  city: Yup.string().min(3,"City must be at least 3 characters").max(50,"City must be at most 50 characters"),
  state: Yup.string().min(2,"State must be at least 2 characters").max(50,"State must be at most 50 characters"),
  zip: Yup.string().min(3,"Zip must be at least 3 characters").max(20,"Zip must be at most 20 characters"),
  email: Yup.string().email("Must be a valid email").min(3,"Email must be at least 3 characters").max(255,"Email must be at most 255 characters").required("Required"),
  loginAccess: Yup.boolean().required("Required")
});

function AddCompanies({ isEdit }) {

  const dispatch = useDispatch();
  const currentSelectedCompany = useSelector(state => state.companies.currentSelectedCompany);
  const params = useParams();
  const [initialValues, setInitialValues] = useState(getInitialValues());

  const companyId = params && params.companyId ? params.companyId : null;

  useEffect(() => {
    if (companyId && isEdit) dispatch(fetchCurrentSelectedCompany(companyId));
  }, []);

  useEffect(() => {
    if (currentSelectedCompany) setInitialValues({ 
      ...currentSelectedCompany,
      phoneNumberExtension: currentSelectedCompany.phoneNumberExtension ? currentSelectedCompany.phoneNumberExtension : "",
      address: currentSelectedCompany.address ? currentSelectedCompany.address : "",
      city: currentSelectedCompany.city ? currentSelectedCompany.city : "",
      state: currentSelectedCompany.state ? currentSelectedCompany.state : "",
      zip: currentSelectedCompany.zip ? currentSelectedCompany.zip : "",
      loadBillingPrice: currentSelectedCompany.loadBillingPrice ? currentSelectedCompany.loadBillingPrice : 0,
    });
  }, [currentSelectedCompany]);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (isEdit) {
        const data = {
          id: currentSelectedCompany && currentSelectedCompany.id ? currentSelectedCompany.id : null,
          name: values.name,
          ownerFirstName: values.ownerFirstName, 
          ownerLastName: values.ownerLastName, 
          email: values.email, 
          phoneNumber: values.phoneNumber, 
          loginAccess: values.loginAccess
        };
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        else data.phoneNumberExtension = null;
        if (values.address) data.address = values.address;
        else data.address = null;
        if (values.city) data.city = values.city;
        else data.city = null;
        if (values.state) data.state = values.state;
        else data.state = null;
        if (values.zip) data.zip = values.zip;
        else data.zip = null;
        if (values.loadBillingPrice) data.loadBillingPrice = values.loadBillingPrice;
        else data.loadBillingPrice = 0;
        dispatch(updateCompany(data, err => {
            if (err) return;
            setSubmitting(false);
            window.setTimeout(() => navigate(-1), 0);
          }
        ));
      } else {
        const data = {
          name: values.name, 
          ownerFirstName: values.ownerFirstName,
          ownerLastName: values.ownerLastName, 
          email: values.email, 
          phoneNumber: values.phoneNumber,
          loginAccess: values.loginAccess
        }
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        if (values.address) data.address = values.address;
        if (values.city) data.city = values.city;
        if (values.state) data.state = values.state;
        if (values.zip) data.zip = values.zip;
        if (values.loadBillingPrice) data.loadBillingPrice = values.loadBillingPrice;
        else data.loadBillingPrice = 0;
        dispatch(addCompany(data, err => {
            if (err) return;
            resetForm();
            setSubmitting(false);
            window.setTimeout(() => navigate(-1), 0);
          }
        ));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet title={ isEdit ? 'Edit Company' : 'Add Company' } />
      <Typography variant="h3" gutterBottom display="inline">
        { isEdit ? 'Edit Company' : 'Add Company' }
      </Typography>
      {
        isEdit
        ?
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
              Companies
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
              Company Details
            </Link>
            <Typography>Edit Company</Typography>
          </Breadcrumbs>
        :
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link component={NavLink} to={""} onClick={() => navigate(-1)}>
              Companies
            </Link>
            <Typography>Add Company</Typography>
          </Breadcrumbs>
      }

      <Divider my={6} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Company Information
              </Typography>
              
                <form onSubmit={handleSubmit}>
              
                  <TextField
                    name="name"
                    label="Company Name*"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                
                  <TextField
                    name="ownerFirstName"
                    label="Owner First Name*"
                    value={values.ownerFirstName}
                    error={Boolean(touched.ownerFirstName && errors.ownerFirstName)}
                    fullWidth
                    helperText={touched.ownerFirstName && errors.ownerFirstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />

                  <TextField
                    name="ownerLastName"
                    label="Owner Last Name*"
                    value={values.ownerLastName}
                    error={Boolean(touched.ownerLastName && errors.ownerLastName)}
                    fullWidth
                    helperText={touched.ownerLastName && errors.ownerLastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  
                  <div style={{ display: 'flex' }}>
                    <TextField
                      name="email"
                      label="Email*"
                      value={values.email}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      variant="outlined"
                      my={2}
                      style={{ flex: 1 }}
                    />
                    <TextField
                      InputProps={{
                        startAdornment: (<InputAdornment position="start">$</InputAdornment>)
                      }}
                      name="loadBillingPrice"
                      label="Load Billing Price"
                      value={values.loadBillingPrice}
                      fullWidth
                      onChange={e => {
                        const value = e.target.value ? parseFloat(e.target.value) : null;
                        if (value < 0) return;
                        handleChange({ target: {value, name: 'loadBillingPrice' }});
                      }}
                      type="number"
                      variant="outlined"
                      my={2}
                      style={{ flex: 1, marginLeft: 10 }}
                    />
                  </div>

                  <div style={{ display: 'flex' }}>
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+1</InputAdornment>
                      }}
                      name="phoneNumber"
                      label="Phone Number*"
                      value={values.phoneNumber}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      style={{ flex: 3 }}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                    <TextField
                      name="phoneNumberExtension"
                      label="Extension"
                      value={values.phoneNumberExtension}
                      error={Boolean(touched.phoneNumberExtension && errors.phoneNumberExtension)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.phoneNumberExtension && errors.phoneNumberExtension}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                  </div>

                  <TextField
                    name="address"
                    label="Address"
                    value={values.address}
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <div style={{ display: 'flex' }}>
                    <TextField
                      name="city"
                      label="City"
                      value={values.city}
                      error={Boolean(touched.city && errors.city)}
                      style={{ flex: 3 }}
                      helperText={touched.city && errors.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                    <TextField
                      name="state"
                      label="State"
                      value={values.state}
                      error={Boolean(touched.state && errors.state)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.state && errors.state}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                    <TextField
                      name="zip"
                      label="Zip"
                      value={values.zip}
                      error={Boolean(touched.zip && errors.zip)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.zip && errors.zip}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                  </div>

                  <div style={{ marginBottom: 8, marginTop: 8 }}>
                    <FormControl fullWidth>
                      <InputLabel>Login Access*</InputLabel>
                      <Select
                        label="Login Access*"
                        value={values.loginAccess}
                        error={Boolean(touched.loginAccess && errors.loginAccess)}
                        fullWidth
                        helperText={touched.loginAccess && errors.loginAccess}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="loginAccess"
                        
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Save changes
                  </Button>
                </form>
            </CardContent>
          </Card>
        )}
      </Formik>


    </React.Fragment>
  );
}

export default AddCompanies;
