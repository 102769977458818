import { useEffect, useState } from "react";

const useTwoFactorAuthentication = (currentLoggedUser) => {

  const [shouldTrigger, setShouldTrigger] = useState(null);

  const whitelist = ['/reset-2fa'];
  
  useEffect(() => {
    const pathname = window.location.pathname;
    if (whitelist.find(item => item === pathname)) {
      setShouldTrigger(false);
    } else {
      if (currentLoggedUser) {
        const triggerDays = 30 * 86400000; // Every 30 days.
        const now = Date.now();
        const lastTimeAsked =  currentLoggedUser.lastTimeAsked2fa ? new Date(currentLoggedUser.lastTimeAsked2fa) : new Date();
        if ((now - lastTimeAsked.getTime()) > triggerDays) {
          setShouldTrigger(true);
        } else {
          setShouldTrigger(false);
        }
      }
    }
  }, [currentLoggedUser]);

  return shouldTrigger; 

};

export default useTwoFactorAuthentication;
