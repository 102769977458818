import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { 
  fetchLoads,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersStatus,
  setCurrentSelectedLoad,
} from "../../redux/slices/loads";
import loadsStatusTypes from "../../keys/loadStatusTypes";
import loadStopTypes from '../../keys/loadStopTypes';
import { useNavigate } from 'react-router-dom';
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import {
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Drawer,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background-color: ${(props) => props.bColor };
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "refNumber", alignment: "left", label: "Ref Number" },
  { id: "pickup", alignment: "left", label: "Pickup" },
  { id: "delivery", alignment: "left", label: "Delivery" },
  { id: "status", alignment: "left", label: "Status" },
  { id: "assignedDriver", alignment: "left", label: "Assigned Driver" }
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
      {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows, setOpenFilter }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(state => state.loads.filters.limit);
  const rowsSkip = useSelector(state => state.loads.filters.skip);
  const rowsTotal = useSelector(state => state.loads.total);
  const initialFetch = useSelector(state => state.loads.initialFetch);

  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      dispatch(setFiltersSkip(rowsPerPage + rowsSkip));
    } else {
      dispatch(setFiltersSkip(rowsSkip - rowsPerPage));
    }
    dispatch(fetchLoads());
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setFiltersLimit(value));
    dispatch(setFiltersSkip(0));
    dispatch(fetchLoads());
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper>
        <Toolbar>
          <Spacer />
          <div>
            <Tooltip title="Filter list">
              <IconButton onClick={() => setOpenFilter(true)} aria-label="Filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {
                rows.map((row, index) => {

                  let locationName, deliveryName;

                  row?.loadStops.forEach(stop => {
                    if (stop.type === loadStopTypes.customer.key) {
                      deliveryName = stop?.companyLocation?.companyName;
                    } else if (stop.type === loadStopTypes.location.key) {
                      locationName = stop?.companyLocation?.companyName;
                    }
                  });

                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        dispatch(setCurrentSelectedLoad(null));
                        navigate(`/loads/load-details/${row.id}`);
                      }} 
                      hover                      
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.refNumber}</TableCell>
                      <TableCell align="left">{locationName}</TableCell>
                      <TableCell align="left">{deliveryName}</TableCell>
                      <TableCell> 
                        { 
                          row.status && (
                            <Chip 
                              size="small"
                              mr={1}
                              mb={1}
                              label={loadsStatusTypes[row.status].display} 
                              bColor={loadsStatusTypes[row.status].color} 
                            />
                          )
                        }
                      </TableCell>
                      <TableCell align="left">{row?.driver?.firstName} {row?.driver?.lastName}</TableCell>
                      <TableCell padding="none" align="right">
                      </TableCell>
                    </TableRow>
                  );
                })
              }
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length && initialFetch
          ?
            <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
          :
            ''
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function Loads() {
  const navigate = useNavigate();
  const loads = useSelector(state => state.loads);
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    status: '-1'
  });

  useEffect(() => {
    if (loads && !loads.initialFetch) dispatch(fetchLoads());
  }, []);
 
  return (
    <React.Fragment>
      <Helmet title="Loads" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Loads
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(setCurrentSelectedLoad(null));
                navigate(`/loads/add`)
              }}
            >
              <AddIcon />
              Add Load
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable 
            setOpenFilter={setOpenFilter} 
            rows={loads.data}
          />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <div style={{ padding: 15 }}>
          <Typography textAlign="center" variant="h3">
            Filters
          </Typography>
          <Divider paddingTop="15px" />
          <div style={{ marginTop: 20 }}>
            <FormControl fullWidth>
              <InputLabel>Status</InputLabel>
              <Select
                label="Status"
                value={filterValues.status}
                fullWidth
                onChange={e => setFilterValues(prevState => ({ ...prevState, status: e.target.value }))}
                variant="outlined"
                style={{ 
                  width: 300,
                  display: 'block'
                }}
              >
                <MenuItem value="-1">Status</MenuItem>
                {
                  Object.keys(loadsStatusTypes).map(item => (
                    <MenuItem value={loadsStatusTypes[item].key}>{loadsStatusTypes[item].display}</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </div>
          <div style={{ 
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-around'
          }}>
            <Button 
              variant="text"
              color="secondary"
              onClick={() => {
                setFilterValues(() => ({
                  status: '-1',
                }));
                dispatch(setFiltersStatus('-1'));
                dispatch(setFiltersSkip(0));
                dispatch(fetchLoads());
              }}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setFiltersStatus(filterValues.status));
                dispatch(setFiltersSkip(0));
                dispatch(fetchLoads());
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default Loads;