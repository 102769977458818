import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  fetchCurrentSelectedCompany,
  updateCompany,
  uploadLogo
} from "../../redux/slices/companies";
import { setErrorDialogMessage } from '../../redux/slices/messages';
import { 
  useDispatch,
  useSelector
} from "react-redux";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  TextField as MuiTextField,
  Typography,
  InputAdornment
} from "@mui/material";
import { spacing } from "@mui/system";
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

const Divider = styled(MuiDivider)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  name: "",
  ownerFirstName: "",
  ownerLastName: "",
  email: "",
  phoneNumber: "",
  phoneNumberExtension: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  invoiceCustomMessage: "",
  logoPath: "",
});

const validationSchema = Yup.object().shape({
  name: Yup.string().min(3,"Company Name must be at least 3 characters").max(50,"Company Name  must be at most 50 characters").required("Required"),
  ownerFirstName: Yup.string().min(2,"Owner First Name must be at least 2 characters").max(50,"Owner First Name must be at most 50 characters").required("Required"),
  ownerLastName: Yup.string().min(2,"Owner Last Name must be at least 2 characters").max(50,"Owner Last Name must be at most 50 characters").required("Required"),
  phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
  phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
  address: Yup.string().min(3,"Address must be at least 3 characters").max(50,"Address must be at most 50 characters"),
  city: Yup.string().min(3,"City must be at least 3 characters").max(50,"City must be at most 50 characters"),
  state: Yup.string().min(2,"State must be at least 2 characters").max(50,"State must be at most 50 characters"),
  zip: Yup.string().min(3,"Zip must be at least 3 characters").max(20,"Zip must be at most 20 characters"),
  email: Yup.string().email("Must be a valid email").min(3,"Email must be at least 3 characters").max(255,"Email must be at most 255 characters").required("Required"),
});

function CompanyAdminCompanySettings() {

  const dispatch = useDispatch();
  const currentSelectedCompany = useSelector(state => state?.currentLoggedUser?.company);
  const [initialValues, setInitialValues] = useState(getInitialValues());

  useEffect(() => {
    if (currentSelectedCompany?.id) dispatch(fetchCurrentSelectedCompany(currentSelectedCompany.id));
  }, []);

  useEffect(() => {
    if (currentSelectedCompany) setInitialValues({ 
      ...currentSelectedCompany,
      phoneNumberExtension: currentSelectedCompany.phoneNumberExtension ? currentSelectedCompany.phoneNumberExtension : "",
      address: currentSelectedCompany.address ? currentSelectedCompany.address : "",
      city: currentSelectedCompany.city ? currentSelectedCompany.city : "",
      state: currentSelectedCompany.state ? currentSelectedCompany.state : "",
      zip: currentSelectedCompany.zip ? currentSelectedCompany.zip : "",
    });
  }, [currentSelectedCompany]);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
        const data = {
          id: currentSelectedCompany && currentSelectedCompany.id ? currentSelectedCompany.id : null,
          name: values.name,
          ownerFirstName: values.ownerFirstName, 
          ownerLastName: values.ownerLastName, 
          email: values.email, 
          phoneNumber: values.phoneNumber, 
        };
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        else data.phoneNumberExtension = null;
        if (values.address) data.address = values.address;
        else data.address = null;
        if (values.city) data.city = values.city;
        else data.city = null;
        if (values.state) data.state = values.state;
        else data.state = null;
        if (values.zip) data.zip = values.zip;
        else data.zip = null;
        if (values.invoiceCustomMessage) data.invoiceCustomMessage = values.invoiceCustomMessage;
        else data.invoiceCustomMessage = null;
        if (values.logoPath) data.logoPath = values.logoPath;
        else data.logoPath = null;
        dispatch(updateCompany(data));
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Company Settings" />
      <Typography variant="h3" gutterBottom display="inline">
        Company Settings
      </Typography>

      <Divider my={6} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Company Settings
              </Typography>
              
                <form onSubmit={handleSubmit}>
              
                  <TextField
                    name="name"
                    label="Company Name*"
                    value={values.name}
                    error={Boolean(touched.name && errors.name)}
                    fullWidth
                    helperText={touched.name && errors.name}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                
                  <TextField
                    name="ownerFirstName"
                    label="Owner First Name*"
                    value={values.ownerFirstName}
                    error={Boolean(touched.ownerFirstName && errors.ownerFirstName)}
                    fullWidth
                    helperText={touched.ownerFirstName && errors.ownerFirstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />

                  <TextField
                    name="ownerLastName"
                    label="Owner Last Name*"
                    value={values.ownerLastName}
                    error={Boolean(touched.ownerLastName && errors.ownerLastName)}
                    fullWidth
                    helperText={touched.ownerLastName && errors.ownerLastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />

                  <TextField
                    name="email"
                    label="Email*"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                    style={{ flex: 1 }}
                  />

                  <div style={{ display: 'flex' }}>
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+1</InputAdornment>
                      }}
                      name="phoneNumber"
                      label="Phone Number*"
                      value={values.phoneNumber}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      style={{ flex: 3 }}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                    <TextField
                      name="phoneNumberExtension"
                      label="Extension"
                      value={values.phoneNumberExtension}
                      error={Boolean(touched.phoneNumberExtension && errors.phoneNumberExtension)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.phoneNumberExtension && errors.phoneNumberExtension}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                  </div>

                  <TextField
                    name="address"
                    label="Address"
                    value={values.address}
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <div style={{ display: 'flex' }}>
                    <TextField
                      name="city"
                      label="City"
                      value={values.city}
                      error={Boolean(touched.city && errors.city)}
                      style={{ flex: 3 }}
                      helperText={touched.city && errors.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                    <TextField
                      name="state"
                      label="State"
                      value={values.state}
                      error={Boolean(touched.state && errors.state)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.state && errors.state}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                    <TextField
                      name="zip"
                      label="Zip"
                      value={values.zip}
                      error={Boolean(touched.zip && errors.zip)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.zip && errors.zip}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                  </div>

                  <div style={{ marginBottom: 10, marginTop: 10 }}>
                    <Typography variant="h6" gutterBottom style={{ marginBottom: 5 }}>
                      Logo
                    </Typography>
                    {
                      values?.logoPath &&
                      <div style={{ width: 150, height: 150 }}>
                        <img src={values?.logoPath} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                      </div>
                    }
                    <input
                      accept="image/*"
                      style={{ display: "none", marginTop: 6, marginBottom: 6  }}
                      id="raised-button-file"
                      type="file"
                      onChange={e => {
                        if (!e) return
                        let file;
                        if (e.target && e.target.files && e.target.files.length) file = e.target.files[0];
                        if (!file) return;
                    
                        const whitelistFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                        const maxSize = 10000000; //10mb
                        if (!whitelistFileTypes.find(type => type === file.type)) return dispatch(setErrorDialogMessage('File can be only type of: JPG or PNG.'));
                        else if (file.size > maxSize) return dispatch(setErrorDialogMessage('Maximum size of the file is 10MB.'));
                        dispatch(uploadLogo(file, (err, logoPath) => {
                          if (err || !logoPath) return;
                          handleChange({ 
                            target: {
                              value: logoPath,
                              name: 'logoPath' 
                            }
                          });
                          e.target.value = null;
                        }));
                      }}
                    />
                    {
                      values?.logoPath
                      ?
                      <Button 
                        variant="contained" 
                        color="error" 
                        component="span" 
                        marginBottom={2}
                        style={{ marginTop: 5 }}
                        onClick={() => {
                          handleChange({ 
                            target: {
                              value: null,
                              name: 'logoPath' 
                            }
                          });
                        }}
                      >
                        <DeleteIcon style={{ marginRight: 5 }} /> Delete Logo
                      </Button>
                      :
                      <label htmlFor="raised-button-file">
                        <Button variant="contained" color="primary" component="span" marginBottom={2}>
                          <CloudUploadIcon style={{ marginRight: 5 }} /> Upload
                        </Button>
                        <Typography variant="caption" display="block" gutterBottom>
                          For best results, use an image 300px by 300px in .jpg
                          format
                        </Typography>
                      </label>
                    }
                  </div>

                  <TextField
                    name="invoiceCustomMessage"
                    label="Invoice Custom Message"
                    value={values.invoiceCustomMessage}
                    error={Boolean(touched.invoiceCustomMessage && errors.invoiceCustomMessage)}
                    fullWidth
                    helperText={touched.invoiceCustomMessage && errors.invoiceCustomMessage}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    multiline
                    rows={5}
                  />

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Save changes
                  </Button>
                </form>
            </CardContent>
          </Card>
        )}
      </Formik>


    </React.Fragment>
  );
}

export default CompanyAdminCompanySettings;
