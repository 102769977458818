import { createSlice } from "@reduxjs/toolkit";

import axios from "../../axios/config";
import { refreshToken } from './currentLoggedUser';

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
  },
  total: 0,
  showTopSpinner: false,
  showBottomSpinner: false,
  shouldFetchSeen: false,
  initialFetch: false,
});

export const notificationsSlice = createSlice({
  name: "notifications",
  initialState: getInitialState(),
  reducers: {
    unshiftMergeData: (state, action) => {
      const lookup = {};
      state.data.forEach(notification => {
        lookup[notification.id] = notification;
      });
      if (state.data.length === 0) {
        state.data = action.payload;
      } else {
        action.payload.forEach((notification, index) => {
          if (lookup[notification.id]) {
            state.data[index] = notification;
          } else {
            state.data.unshift(notification);
          }
        });
      }
      return state;
    },
    pushMergeData: (state, action) => {
      const lookup = {};
      state.data.forEach(notification => {
        lookup[notification.id] = notification;
      });
      if (state.data.length === 0) {
        state.data = action.payload;
      } else {
        action.payload.forEach((notification, index) => {
          if (lookup[notification.id]) {
            state.data[index] = notification;
          } else {
            state.data.push(notification);
          }
        });
      }
      state.filters.skip += action.payload.length;
      return state;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setShowTopSpinner: (state, action) => {
      state.showTopSpinner = action.payload;
      return state;
    },
    setShowBottomSpinner: (state, action) => {
      state.showBottomSpinner = action.payload;
      return state;
    },
    setShouldFetchSeen: (state, action) => {
      state.shouldFetchSeen = action.payload;
      return state;
    },
    updateNotificationsSetSeen: (state, action) => {
      const now = new Date();
      action.payload.forEach(id => {
        state.data.forEach((notification, index) => {
          if (id === notification.id) {
            state.data[index].seenAt = now;
            state.data[index].updatedAt = now;
          }
        });
      })
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    resetState: () => getInitialState(),
  }
});

export const {
  unshiftMergeData,
  pushMergeData,
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setShowTopSpinner,
  setShowBottomSpinner,
  setShouldFetchSeen,
  updateNotificationsSetSeen,
  setInitialFetch,
  resetState
} = notificationsSlice.actions;

export const fetchNotifications = (params = {}) => (dispatch, getState) => {
  const { notifications } = getState();
  const {
    showTopSpinner,
    showBottomSpinner,
    filters,
    shouldFetchSeen,
    initialFetch
  } = notifications;
  if (params?.refresh && showTopSpinner) return;
  else if (showBottomSpinner) return;
  if (!initialFetch && params?.refresh) return;
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  if (params?.refresh) queryParams.append('skip', 0);
  else queryParams.append('skip', filters.skip);
  if (shouldFetchSeen && !params?.refresh) queryParams.append('showSeen', '1');

  if (params?.refresh) dispatch(setShowTopSpinner(true));
  else dispatch(setShowBottomSpinner(true));

  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/notifications?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (params?.refresh) {
            dispatch(unshiftMergeData(response.data.data.rows));
          } else {
            dispatch(setInitialFetch(true));
            dispatch(setTotal(response.data.data.total));
            dispatch(pushMergeData(response.data.data.rows))
          }
          if (!shouldFetchSeen && response.data.data.rows.length < filters.limit && !params?.refresh) {
            dispatch(setShouldFetchSeen(true));
            dispatch(setFiltersSkip(0));
            window.setTimeout(() => dispatch(fetchNotifications()), 0);
          }
        }
      })
      .catch(() => {})
      .finally(() => {
        if (params?.refresh) dispatch(setShowTopSpinner(false));
        else dispatch(setShowBottomSpinner(false));
      });
  }));
};

export const setSeen = (notificationIds = []) => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.post(`/api/notifications/set-seen`, { data: { notificationIds } })
      .then(response => {
        if (response.status === 200) {
          dispatch(updateNotificationsSetSeen(notificationIds));
        }
      })
      .catch(() => {});
  }));
}; 

export default notificationsSlice.reducer;
