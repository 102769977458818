import React from 'react';
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/styles';
import { Build, Description, LocalShipping } from "@mui/icons-material";
import styled from "styled-components";
import {
  Avatar as MuiAvatar,
  ListItem,
  ListItemAvatar,
  ListItemText,
  SvgIcon,
} from "@mui/material";

import { setSeen } from '../../redux/slices/notifications';
import { setCurrentSelectedLoad } from '../../redux/slices/loads';
import notificationTypes from '../../keys/notificationTypes';
import timeHelper from '../../utils/timeHelper';
import equipmentTypes from '../../keys/equipmentTypes';
import loadStatusTypes from '../../keys/loadStatusTypes';

const Avatar = styled(MuiAvatar)`
  background: ${(props) => props.theme.palette.primary.main};
`;

const NavbarNotification = ({ notification, handleClose }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentSelectedLoad = useSelector(state => state?.loads?.currentSelectedLoad);
  const theme = useTheme();

  const handleDeeplink = () => {
    if (handleClose) handleClose();
    if (notification?.id && !notification?.seenAt) dispatch(setSeen([notification?.id]));

    if (
      notification?.type === notificationTypes.annualTruckAndTrailerInspection.key ||
      notification?.type === notificationTypes.oilChangeExpiration.key ||
      notification?.type === notificationTypes.licenceRegistrationExpiration.key
    ) {
      navigate(`/equipment/equipment-details/${notification?.metadata?.deeplinkId}`);
    }

    if (
      notification?.type === notificationTypes.driverLicenceExpiration.key ||
      notification?.type === notificationTypes.driverMedicalExpiration.key ||
      notification?.type === notificationTypes.drivingRecordDateMVR.key
    ) {
      navigate(`/truck-drivers/truck-driver-details/${notification?.metadata?.deeplinkId}`);
    }

    if (notification?.type === notificationTypes.loadStatusChange.key) {
      const deeplinkId = notification?.metadata?.deeplinkId;
      if (deeplinkId && currentSelectedLoad?.id !== parseInt(deeplinkId)) {
        dispatch(setCurrentSelectedLoad(null));
      }
      navigate(`/loads/load-details/${deeplinkId}`);
    }

  };

  const title = notification?.type ? notificationTypes[notification.type].display : '';
  let description, icon;

  if (
    notification?.type === notificationTypes.annualTruckAndTrailerInspection.key ||
    notification?.type === notificationTypes.oilChangeExpiration.key ||
    notification?.type === notificationTypes.licenceRegistrationExpiration.key
  ) {
    icon = <Build />;
    if (notification?.type === notificationTypes.annualTruckAndTrailerInspection.key) {
      description = `Annual Inspection due for ${notification?.metadata?.type ? equipmentTypes[notification.metadata.type].display : ''} ${notification?.metadata?.make + ' ' + notification?.metadata?.model}`;
    }
    if (notification?.type === notificationTypes.oilChangeExpiration.key) {
      description = `Oil Change due for ${notification?.metadata?.type ? equipmentTypes[notification.metadata.type].display : ''} ${notification?.metadata?.make + ' ' + notification?.metadata?.model}`;
    }
    if (notification?.type === notificationTypes.licenceRegistrationExpiration.key) {
      description = `Registration Licence has expired for ${notification?.metadata?.type ? equipmentTypes[notification.metadata.type].display : ''} ${notification?.metadata?.make + ' ' + notification?.metadata?.model}`;
    }
  }

  if (
    notification?.type === notificationTypes.driverLicenceExpiration.key ||
    notification?.type === notificationTypes.driverMedicalExpiration.key ||
    notification?.type === notificationTypes.drivingRecordDateMVR.key
  ) {
    icon = <LocalShipping />;
    if (notification?.type === notificationTypes.driverLicenceExpiration.key) {
      description = `Driver Licence has expired for Truck Driver ${notification?.metadata?.firstName + ' ' + notification?.metadata?.lastName}`;
    }
    if (notification?.type === notificationTypes.driverMedicalExpiration.key) {
      description = `Medical Card has expired for Truck Driver ${notification?.metadata?.firstName + ' ' + notification?.metadata?.lastName}`;
    }
    if (notification?.type === notificationTypes.drivingRecordDateMVR.key) {
      description = `Last MVR ${moment(notification.showAt).format('MM/DD/YYYY')} for Truck Driver ${notification?.metadata?.firstName + ' ' + notification?.metadata?.lastName}`;
    }
  }

  if (notification?.type === notificationTypes.loadStatusChange.key) {
    icon = <Description />;
    description = `Load Status Changed for ${notification?.metadata?.refNumber ? ('Load Ref Number ' + notification.metadata.refNumber) : ('Load ID ' + notification?.metadata?.deeplinkId)} to the status ${loadStatusTypes[notification?.metadata?.status].display}`;
  }

  return (
    <ListItem 
      divider 
      onClick={handleDeeplink}
      style={{ cursor: 'pointer', display: 'block' }}
    >
      <div style={{ display: 'flex', alignItems: 'center', marginTop: 5 }}>
        <ListItemAvatar>
          <Avatar>
            {
              icon &&
              <SvgIcon fontSize="small">
                {icon}
              </SvgIcon>
            }
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            variant: "subtitle2",
            color: "textPrimary",
          }}
          secondary={description}
        />
      </div>
      {
        notification?.showAt &&
        <div style={{ textAlign: 'right', fontSize: '0.9em' }}>{timeHelper.getTime(new Date(notification.showAt), false)}</div>
      }
      {
        !notification?.seenAt &&
        <div style={{
          position: 'absolute',
          top: 5,
          right: 7,
          display: 'flex',
          alignItems: 'center',
          fontSize: '0.8em'
        }}>
          <div style={{
            color: theme?.palette?.primary?.main,
            fontWeight: 600,
            marginRight: 5
          }}>
            New
          </div>
          <div style={{
            backgroundColor: theme?.palette?.primary?.main,
            width: 10,
            height: 10,
            borderRadius: 100,
          }}></div>
        </div>
      }
    </ListItem>

  );
  
};

export default NavbarNotification;
