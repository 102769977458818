import * as React from "react";
import { useEffect, useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { darken } from "polished";
import { Search as SearchIcon } from "react-feather";
import { useDispatch, useSelector } from 'react-redux';
import { 
  fetchDashboardElasticSearchData,
  setData,
  setFiltersFrom,
  setFiltersQuery
} from '../../redux/slices/dashboardElasticSearch';
import { typeFetchDebouncer, fetchDebouncer } from '../../utils/debouncerHelpers';
import dashboardElasticSearchTypes from '../../keys/dashboardElasticSearchTypes';

import { 
  InputBase,
  ListItem,
  ListItemText,
  Card,
  CircularProgress
} from "@mui/material";

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  position: relative;
  width: 350px;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }
`;

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`;

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)};
    padding-right: ${(props) => props.theme.spacing(2.5)};
    padding-bottom: ${(props) => props.theme.spacing(2.5)};
    padding-left: ${(props) => props.theme.spacing(12)};
    width: 160px;
  }
`;

const MainSearch = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardElem = useRef();

  const dashboardElasticSearch = useSelector(state => state.dashboardElasticSearch);

  const handleClick = useCallback((item) => {
    dispatch(setData([]));
    dispatch(setFiltersFrom(0));
    dispatch(setFiltersQuery(''));
    let route;
    if (item) {
      if (item.type === dashboardElasticSearchTypes.customers.key) {
        route = `/company-customers/company-customer-details/${item.databaseId}`;
      }
      if (item.type === dashboardElasticSearchTypes.locations.key) {
        route = `/company-locations/company-location-details/${item.databaseId}`;
      }
      if (item.type === dashboardElasticSearchTypes.equipments.key) {
        route = `/equipment/equipment-details/${item.databaseId}`;
      }
      if (item.type === dashboardElasticSearchTypes.loads.key) {
        route = `/loads/load-details/${item.databaseId}`;
      }
      if (item.type === dashboardElasticSearchTypes.truckDrivers.key) {
        route = `/truck-drivers/truck-driver-details/${item.databaseId}`;
      }
    }

    if (route) navigate(route);
  }, [dispatch, navigate]);

  useEffect(() => {
    dispatch(setData([]));
    dispatch(setFiltersFrom(0));
    if (dashboardElasticSearch.filters.query !== '') {
      typeFetchDebouncer(() => dispatch(fetchDashboardElasticSearchData()));
    }
  }, [dashboardElasticSearch.filters.query, dispatch]);

  useEffect(() => {

    const scrollElem = cardElem.current;

    const scrollHandler = (e) => {
      if ((e.target.scrollHeight - e.target.scrollTop - 50) <= e.target.clientHeight) {
        if (!dashboardElasticSearch.requestBusy) {
          dispatch(setFiltersFrom(dashboardElasticSearch.filters.from + 20)); // + 20 IS BECAUSE LIMIT IS HARDCODED ON BACKEND.
          fetchDebouncer(() => dispatch(fetchDashboardElasticSearchData()));
        }
      }
    };
    
    if (scrollElem) {
      scrollElem.addEventListener('scroll', scrollHandler);
    }

    return () => {
      if (scrollElem) scrollElem.removeEventListener('scroll', scrollHandler);
    };
    
  }, [dashboardElasticSearch, dispatch]);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <Input 
        placeholder="Search"
        value={dashboardElasticSearch.filters.query}
        onChange={(e) => dispatch(setFiltersQuery(e.target.value))}
      />
      {
        dashboardElasticSearch?.requestBusy ||
        dashboardElasticSearch?.data?.lenght ||
        dashboardElasticSearch?.filters?.query !== ''
        ?
          <Card ref={cardElem} style={{ position: 'absolute', left: 0, width: 350, height: 250, overflowY: 'scroll' }}>
            {
              !dashboardElasticSearch.requestBusy &&
              dashboardElasticSearch?.filters?.query !== '' &&
              !dashboardElasticSearch?.data?.length 
              ?
                <div style={{ textAlign: 'center', padding: 20 }}>
                  No results... 
                </div>
              :
                ''
            }
            {
              dashboardElasticSearch.data.map((item, index) => {

                let body;
  
                if (item.type === dashboardElasticSearchTypes.customers.key) {
                  body = `${item.customerName} (${item.addressName})`;
                }
                if (item.type === dashboardElasticSearchTypes.locations.key) {
                  body = `${item.companyName} (${item.addressName})`;
                }
                if (item.type === dashboardElasticSearchTypes.equipments.key) {
                  body = `${item.make} ${item.model}`;
                }
                if (item.type === dashboardElasticSearchTypes.loads.key) {
                  body = `Load ID: ${item.databaseId}`;
                }
                if (item.type === dashboardElasticSearchTypes.truckDrivers.key) {
                  body = `${item.firstName} ${item.lastName}`;
                }

                return (
                  <ListItem 
                    button
                    onClick={() => handleClick(item)}
                    key={index}
                  > 
                    <ListItemText style={{paddingLeft: 10}}>
                      <div style={{ fontWeight: 'bold' }}>{dashboardElasticSearchTypes[item.type]?.display}</div>
                      <div>{body}</div>
                    </ListItemText>
                  </ListItem>
                );

              })
            }
            {
              dashboardElasticSearch.requestBusy && 
              <div style={{ display: 'flex', justifyContent: 'center', padding: 20 }}>
                <CircularProgress size={20} color="primary" />
              </div>
            }
          </Card>
        :
          ''
      }
    </Search>
  );
};

export default MainSearch;
