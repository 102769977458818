import React from 'react';
import {
  Divider,
  Typography,
} from "@mui/material";

const EquipmentDetails = (props) => {

  const { data } = props;

  return (
    <>
      <div>
        <div>
          <Typography mt={2}>
            ID: <span style={{ fontWeight: 'bold' }}>{data?.id}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Make & Model: <span style={{ fontWeight: 'bold' }}>{data?.make} {data?.model}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            VIN: <span style={{ fontWeight: 'bold' }}>{data?.vin}</span>
          </Typography>
        </div>
      </div>
    </>
  );

};

export default EquipmentDetails;
