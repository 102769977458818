import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { setErrorDialogMessage } from './messages';
import { parseResponseError } from '../helpers/errorHandlingHelpers';
import { refreshToken } from './currentLoggedUser';

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
  },
  total: 0,
  initialFetch: false,
  currentSelectedBilling: null
});

export const billingSlice = createSlice({
  name: "billing",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    resetState: () => getInitialState(),
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setCurrentSelectedBilling: (state, action) => {
      state.currentSelectedBilling = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
  }
});

export const {
  setData,
  resetState,
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setInitialFetch,
  setCurrentSelectedBilling
} = billingSlice.actions;

export const fetchBilling = (params = {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.billing && state.billing.filters ? state.billing.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (params?.companyId) queryParams.append('companyId', params.companyId);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/billing?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const fetchCurrentSelectedBilling = id => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/billing/${id}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedBilling(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {});
  }));
}; 

export default billingSlice.reducer;
