import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useNavigate,
  useParams
} from 'react-router-dom';
import { 
  fetchCurrentSelectedCompanyAdmin,
  activateCurrentSelectedCompanyAdmin,
  deactivateCurrentSelectedCompanyAdmin
} from '../../redux/slices/companyAdminUsers';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import userRoles from "../../keys/userRoles";

import {
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { spacing, display } from "@mui/system";
import { green, red } from "@mui/material/colors";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.nonActive && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function CompanyAdminDetails() {
  const navigate = useNavigate();
  const currentSelectedCompanyAdmin = useSelector(state => state.companyAdminUsers.currentSelectedCompanyAdmin);
  const params = useParams();
  const dispatch = useDispatch();

  const [showDeactivateCurrentAdminUserDialog, setShowDeactivateCurrentAdminUserDialog] = useState(false);
  const [showActivateCurrentAdminUserDialog, setShowActivateCurrentAdminUserDialog] = useState(false);

  const companyId = params && params.companyId ? params.companyId : null;
  const userId = params && params.userId ? params.userId : null;

  useEffect(() => {
    if (userId) dispatch(fetchCurrentSelectedCompanyAdmin(userId));
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Admin User Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Admin User Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-3)}>
                Companies
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                Company Details
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                Admin Users
            </Link>
            <Typography>Admin User Details</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              <Table>
                <TableBody>
                <TableRow>
                  <TableCell component="th" scope="row">
                      Type:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.role && userRoles[currentSelectedCompanyAdmin.role] && userRoles[currentSelectedCompanyAdmin.role].display ? userRoles[currentSelectedCompanyAdmin.role].display : ''}</TableCell>
                  </TableRow>
                <TableRow>
                    <TableCell>First Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.firstName ? currentSelectedCompanyAdmin.firstName : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                     Last Name:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.lastName ? currentSelectedCompanyAdmin.lastName : ''}</TableCell>
                  </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.email ? currentSelectedCompanyAdmin.email : ''}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th" scope="row">
                      Phone Number:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.phoneNumber ? currentSelectedCompanyAdmin.phoneNumber : ''} {currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.phoneNumberExtension ? `Ext: ${currentSelectedCompanyAdmin.phoneNumberExtension}` : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Status:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                    {currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.isActive && (
                          <Chip
                            
                            size="small"
                            
                            label="Active"
                            active={+true}
                          />
                        )}
                     {currentSelectedCompanyAdmin && !currentSelectedCompanyAdmin.isActive && (
                          <Chip
                            size="small"
                            
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Login Access:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                    {currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.loginAccess && (
                          <Chip
                            
                            size="small"
                            
                            label="Active"
                            active={+true}
                          />
                        )}
                     {currentSelectedCompanyAdmin && !currentSelectedCompanyAdmin.loginAccess && (
                          <Chip
                            size="small"
                            
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              <div style={{display: "flex", justifyContent: "space-between"}}>
              <Button onClick={() => navigate(`/companies/company-details/${companyId}/admin-users/edit/${userId}`)}
                    marginBottom={5}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    <Icon.Edit />
                    <span style={{marginLeft:5}}>Edit</span>
                </Button>
                {
                  currentSelectedCompanyAdmin && 
                  (
                    currentSelectedCompanyAdmin.loginAccess ||
                    currentSelectedCompanyAdmin.isActive 
                  )
                  ?
                    <Button 
                      variant="outlined" 
                      color="primary"
                      marginBottom={5}
                      mt={3}
                      marginLeft={5}
                      onClick={() => setShowDeactivateCurrentAdminUserDialog(true)}
                    >
                      <Icon.Close />
                        <span style={{marginLeft:5}}> Deactivate </span>
                    </Button>
                  :
                    <Button 
                      variant="outlined" 
                      color="primary"
                      marginBottom={5}
                      mt={3}
                      marginLeft={5}
                      onClick={() => setShowActivateCurrentAdminUserDialog(true)}
                    >
                      <Icon.Check />
                        <span style={{marginLeft:5}}> Activate </span>
                    </Button>
                }
                </div>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <Dialog
        open={showDeactivateCurrentAdminUserDialog}
        onClose={() => setShowDeactivateCurrentAdminUserDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowDeactivateCurrentAdminUserDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(deactivateCurrentSelectedCompanyAdmin(err => {
                  if (err) return;
                  setShowDeactivateCurrentAdminUserDialog(false);
                }
              ));
            }} 
          >
            Yes, deactivate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showActivateCurrentAdminUserDialog}
        onClose={() => setShowActivateCurrentAdminUserDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowActivateCurrentAdminUserDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(activateCurrentSelectedCompanyAdmin(err => {
                  if (err) return;
                  setShowActivateCurrentAdminUserDialog(false);
                }
              ));
            }} 
          >
            Yes, activate
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default CompanyAdminDetails;