import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';

import { pushIntoHistoryStack } from '../redux/slices/historyStack';

const useSetHistoryStack = () => {

  const location = useLocation();
  const dispatch = useDispatch();
  
  useEffect(() => {
    if (location && location.pathname) dispatch(pushIntoHistoryStack(location.pathname));
  }, [location, dispatch]);

  return null; 

};

export default useSetHistoryStack;
