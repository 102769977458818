import React, { useEffect, useState, useRef, useReducer } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  addEquipment,
  fetchCurrentSelectedEquipment,
  updateEquipment
} from "../../redux/slices/equipments";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import equipmentTypes from '../../keys/equipmentTypes';
import trailerTypes from '../../keys/trailerTypes';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { setCloseDrawer } from "../../redux/slices/fullDrawerModal";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  type: "-1",
  trailerType: "-1",
  equipmentNumber: "",
  year: "-1",
  make: "",
  model: "",
  vin: "",
  licencePlateNumber: "",
  licencePlateExpiration: "",
  inspectionStickerExpiration: "",
  oilChangeExpiration: "",
  yearPurchased: "-1",
  purchasedPrice: "",
  odometer: "",
  description: "",
  notes: "",
});

function AddEquipment({ isEdit, fullDrawerModalMode }) {

  const dispatch = useDispatch();
  const formikRef = useRef();
  const currentSelectedEquipment = useSelector(state => state.equipments.currentSelectedEquipment);
  const { closeCallback } = useSelector(state => state.fullDrawerModal);
  const params = useParams();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const equipmentId = params && params.equipmentId ? params.equipmentId : null;

  useEffect(() => {
    if (equipmentId && isEdit) dispatch(fetchCurrentSelectedEquipment(equipmentId));
  }, []);

  useEffect(() => {
    if (currentSelectedEquipment) setInitialValues({ ...currentSelectedEquipment });
  }, [currentSelectedEquipment]);

  const yupConfig = {
    type: Yup.string().not(['-1'], 'Required'),
  };

  if (formikRef && formikRef.current && formikRef.current.values) {
    const values = formikRef.current.values;

    if (values.type === equipmentTypes.trailer.key || values.type === equipmentTypes.truck.key) {
      yupConfig.equipmentNumber = Yup.string().min(3,"Equipment Number must be at least 3 characters").max(20,"Equipment Number must be at most 20 characters").required("Required");
      yupConfig.year = Yup.string().not(['-1'], 'Required');
      yupConfig.make = Yup.string().max(50,"Make must be at most 50 characters").required("Required");
      yupConfig.model = Yup.string().max(50,"Model must be at most 50 characters").required("Required");
      yupConfig.vin = Yup.string().min(17,"VIN must be 17 characters").max(17,"VIN must be 17 characters").required("Required");
      yupConfig.licencePlateNumber = Yup.string().min(5,"Licence Plate Number must be at least 5 characters").max(8,"Licence Plate Number must be at most 8 characters").required("Required");
      yupConfig.notes = Yup.string().max(3000,"Notes must be at most 3000 characters").nullable();

      if (values.type === equipmentTypes.trailer.key) {
        yupConfig.trailerType = Yup.string().not(['-1'], 'Required');
        yupConfig.inspectionStickerExpiration = Yup.string().required("Required");
      }
      if (values.type === equipmentTypes.truck.key) {
        yupConfig.yearPurchased = Yup.string().not(['-1'], 'Required');
        yupConfig.odometer = Yup.string().required("Required");
        yupConfig.licencePlateExpiration = Yup.string().required("Required");
        yupConfig.odometer = Yup.string().max(50,"Odometer must be at most 100 characters").nullable();
        yupConfig.description = Yup.string().max(250,"Description must be at most 250 characters").nullable();
      }
    }

  }

  const validationSchema = Yup.object().shape(yupConfig);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (isEdit) {
        const data = {
          id: currentSelectedEquipment && currentSelectedEquipment.id ? currentSelectedEquipment.id : null,
          type: values.type,
          equipmentNumber: values.equipmentNumber,
          year: values.year,
          make: values.make,
          model: values.model,
          vin: values.vin,
          licencePlateNumber: values.licencePlateNumber,
        };

        if (values.type === equipmentTypes.trailer.key) {
          data.trailerType = values.trailerType;
          data.inspectionStickerExpiration = values.inspectionStickerExpiration;
          if (values.licencePlateExpiration) {
            data.licencePlateExpiration = values.licencePlateExpiration; 
          } else {
            data.licencePlateExpiration = null;
          }
        } else {
          delete data.trailerType;
          delete data.inspectionStickerExpiration;
        }

        if (values.type === equipmentTypes.truck.key) {
          data.yearPurchased = values.yearPurchased;
          data.odometer = values.odometer;
          data.licencePlateExpiration = values.licencePlateExpiration;
          if (values.inspectionStickerExpiration) data.inspectionStickerExpiration = values.inspectionStickerExpiration;
          else delete data.inspectionStickerExpiration;
          if (values.oilChangeExpiration) data.oilChangeExpiration = values.oilChangeExpiration;
          else delete data.oilChangeExpiration;
        } else {
          delete data.yearPurchased;
          delete data.odometer;
        }

        if (values.purchasedPrice) data.purchasedPrice = values.purchasedPrice;
        else data.purchasedPrice = null;
        if (values.description) data.description = values.description;
        else data.description = null;
        if (values.notes) data.notes = values.notes;
        else data.notes = null;
        dispatch(updateEquipment(data, err => {
            if (err) return;
            setSubmitting(false);
            if (fullDrawerModalMode) {
              if (closeCallback) closeCallback();
              dispatch(setCloseDrawer());
            } else {
              window.setTimeout(() => navigate(-1), 0);
            }
          }
        ));
      } else {
        const data = {
          type: values.type,
          equipmentNumber: values.equipmentNumber,
          year: values.year,
          make: values.make,
          model: values.model,
          vin: values.vin,
          licencePlateNumber: values.licencePlateNumber,
        };

        if (values.type === equipmentTypes.trailer.key) {
          data.trailerType = values.trailerType;
          data.inspectionStickerExpiration = values.inspectionStickerExpiration;
          if (values.licencePlateExpiration) {
            data.licencePlateExpiration = values.licencePlateExpiration;
          } else {
            data.licencePlateExpiration = null;
          }
        } else {
          delete data.trailerType;
          delete data.inspectionStickerExpiration;
        }

        if (values.type === equipmentTypes.truck.key) {
          data.yearPurchased = values.yearPurchased;
          data.odometer = values.odometer;
          data.licencePlateExpiration = values.licencePlateExpiration;
          if (values.inspectionStickerExpiration) data.inspectionStickerExpiration = values.inspectionStickerExpiration;
          if (values.oilChangeExpiration) data.oilChangeExpiration = values.oilChangeExpiration;
        } else {
          delete data.yearPurchased;
          delete data.odometer;
        }

        if (values.purchasedPrice) data.purchasedPrice = values.purchasedPrice;
        if (values.description) data.description = values.description;
        if (values.notes) data.notes = values.notes;
        dispatch(addEquipment(data, err => {
            if (err) return;
            resetForm();
            setSubmitting(false);
            if (fullDrawerModalMode) {
              if (closeCallback) closeCallback();
              dispatch(setCloseDrawer());
            } else {
              window.setTimeout(() => navigate(-1), 0);
            }
          }
        ));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const navigate = useNavigate();

  const toPresentYears = [];
  const currentYear = parseInt((new Date()).getFullYear());
  for (let i = 2000; i <= currentYear; i++) toPresentYears.push(i);
  toPresentYears.sort((a, b) => {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  });

  return (
    <React.Fragment>
      {
        !fullDrawerModalMode && 
        <>
          <Helmet title={ isEdit ? 'Edit Equipment' : 'Add Equipment' } />
          <Typography variant="h3" gutterBottom display="inline">
            { isEdit ? 'Edit Equipment' : 'Add Equipment' }
          </Typography>
          {
            isEdit
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Equipment
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Equipment Details
                </Link>
                <Typography>Edit Equipment</Typography>
              </Breadcrumbs>
            :
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to={""} onClick={() => navigate(-1)}>
                  Equipment
                </Link>
                <Typography>Add Equipment</Typography>
              </Breadcrumbs>
          }

          <Divider my={6} />
        </>
      }

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Equipment Information
              </Typography>
              
                <form onSubmit={handleSubmit}>

                  <div style={{ display: 'flex' }}>
                    <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginRight: 10 }}>
                      <FormControl fullWidth>
                        <InputLabel>Equipment Type*</InputLabel>
                        <Select
                          label="Equipment Type*"
                          value={values.type}
                          error={Boolean(touched.type && errors.type)}
                          fullWidth
                          helperText={touched.type && errors.type}
                          onBlur={handleBlur}
                          onChange={e => {
                            const value = e.target.value;
                            handleChange({ target: {value, name: 'type' }});
                            window.setTimeout(() => forceUpdate(1), 100);
                          }}
                          variant="outlined"
                          my={2}
                          name="type"
                          
                        >
                          <MenuItem value="-1">Select</MenuItem>
                          {
                            Object.keys(equipmentTypes).map(item => (
                              <MenuItem value={equipmentTypes[item].key}>{equipmentTypes[item].display}</MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </div>
                    { values.type === '-1' ? <div style={{ flex: 1 }}></div> : '' }
                    {
                      values.type && values.type === equipmentTypes.trailer.key
                      ?
                        <div style={{ marginBottom: 8, marginTop: 8, flex: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel>Trailer Type*</InputLabel>
                            <Select
                              label="Trailer Type*"
                              value={values.trailerType}
                              error={Boolean(touched.trailerType && errors.trailerType)}
                              fullWidth
                              helperText={touched.trailerType && errors.trailerType}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                              name="trailerType"
                            >
                              <MenuItem value="-1">Select</MenuItem>
                              {
                                Object.keys(trailerTypes).map(item => (
                                  <MenuItem value={trailerTypes[item].key}>{trailerTypes[item].display}</MenuItem>
                                ))
                              }
                            </Select>
                          </FormControl>
                        </div>
                      :
                        ''
                    }
                    {
                      values.type && values.type === equipmentTypes.truck.key
                      ?
                        <div style={{ flex: 1 }}>
                          <TextField
                            name="equipmentNumber"
                            label="Equipment Number*"
                            value={values.equipmentNumber}
                            error={Boolean(touched.equipmentNumber && errors.equipmentNumber)}
                            fullWidth
                            helperText={touched.equipmentNumber && errors.equipmentNumber}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </div>
                      :
                        ''
                    }
                  </div>

                  {
                    values.type && values.type === equipmentTypes.trailer.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <TextField
                          name="equipmentNumber"
                          label="Equipment Number*"
                          value={values.equipmentNumber}
                          error={Boolean(touched.equipmentNumber && errors.equipmentNumber)}
                          fullWidth
                          helperText={touched.equipmentNumber && errors.equipmentNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          style={{ marginRight: 10, flex: 1 }}
                        />
                        <div style={{ marginBottom: 8, marginTop: 8, flex: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel>Year*</InputLabel>
                            <Select
                              label="Year*"
                              value={values.year}
                              error={Boolean(touched.year && errors.year)}
                              fullWidth
                              helperText={touched.year && errors.year}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                              name="year"
                            >
                              <MenuItem value="-1">Select</MenuItem>
                              {
                                toPresentYears.map(year => <MenuItem value={year}>{year}</MenuItem>)
                              }
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.truck.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginBottom: 8, marginTop: 8, marginRight: 10, flex: 1 }}>
                          <FormControl fullWidth>
                            <InputLabel>Year*</InputLabel>
                            <Select
                              label="Year*"
                              value={values.year}
                              error={Boolean(touched.year && errors.year)}
                              fullWidth
                              helperText={touched.year && errors.year}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                              name="year"
                            >
                              <MenuItem value="-1">Select</MenuItem>
                              {
                                toPresentYears.map(year => <MenuItem value={year}>{year}</MenuItem>)
                              }
                            </Select>
                          </FormControl>
                        </div>
                        <div style={{ flex: 1 }}>
                          <TextField
                            name="make"
                            label="Make*"
                            value={values.make}
                            error={Boolean(touched.make && errors.make)}
                            fullWidth
                            helperText={touched.make && errors.make}
                            onBlur={handleBlur}
                            onChange={handleChange}
                            variant="outlined"
                            my={2}
                          />
                        </div>
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.trailer.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <TextField
                          name="make"
                          label="Make*"
                          value={values.make}
                          error={Boolean(touched.make && errors.make)}
                          fullWidth
                          helperText={touched.make && errors.make}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          style={{ marginRight: 10 }}
                        />
                        <TextField
                          name="model"
                          label="Model*"
                          value={values.model}
                          error={Boolean(touched.model && errors.model)}
                          fullWidth
                          helperText={touched.model && errors.model}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                        />
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.truck.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <TextField
                          name="model"
                          label="Model*"
                          value={values.model}
                          error={Boolean(touched.model && errors.model)}
                          fullWidth
                          helperText={touched.model && errors.model}
                          onBlur={handleBlur}
                          onChange={handleChange}
                          variant="outlined"
                          my={2}
                          style={{ marginRight: 10 }}
                        />
                        <TextField
                          name="vin"
                          label="VIN*"
                          value={values.vin}
                          error={Boolean(touched.vin && errors.vin)}
                          fullWidth
                          helperText={touched.vin && errors.vin}
                          onBlur={handleBlur}
                          onChange={e => {
                            const value = e.target.value;
                            if (value && value.length > 17) return;
                            handleChange({ target: {value, name: 'vin' }});
                          }}
                          variant="outlined"
                          my={2}
                        />
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.trailer.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <TextField
                          name="vin"
                          label="VIN*"
                          value={values.vin}
                          error={Boolean(touched.vin && errors.vin)}
                          fullWidth
                          helperText={touched.vin && errors.vin}
                          onBlur={handleBlur}
                          onChange={e => {
                            const value = e.target.value;
                            if (value && value.length > 17) return;
                            handleChange({ target: {value, name: 'vin' }});
                          }}
                          variant="outlined"
                          my={2}
                          style={{ marginRight: 10 }}
                        />
                        <TextField
                          name="licencePlateNumber"
                          label="Licence Plate Number*"
                          value={values.licencePlateNumber}
                          error={Boolean(touched.licencePlateNumber && errors.licencePlateNumber)}
                          fullWidth
                          helperText={touched.licencePlateNumber && errors.licencePlateNumber}
                          onBlur={handleBlur}
                          onChange={e => {
                            const value = e.target.value;
                            if (value && value.length > 8) return;
                            handleChange({ target: {value, name: 'licencePlateNumber' }});
                          }}
                          variant="outlined"
                          my={2}
                        />
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.truck.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <TextField
                          name="licencePlateNumber"
                          label="Licence Plate Number*"
                          value={values.licencePlateNumber}
                          error={Boolean(touched.licencePlateNumber && errors.licencePlateNumber)}
                          fullWidth
                          helperText={touched.licencePlateNumber && errors.licencePlateNumber}
                          onBlur={handleBlur}
                          onChange={e => {
                            const value = e.target.value;
                            if (value && value.length > 8) return;
                            handleChange({ target: {value, name: 'licencePlateNumber' }});
                          }}
                          variant="outlined"
                          my={2}
                          style={{ marginRight: 10 }}
                        />
                        <DesktopDatePicker
                          label="Licence Plate Expiration*"
                          inputFormat="MM/dd/yyyy"
                          value={values.licencePlateExpiration}
                          fullWidth
                          onChange={value => handleChange({ target: {value, name: 'licencePlateExpiration' }})}
                          renderInput={(params) => <TextField my={2} fullWidth {...params} error={Boolean(touched.licencePlateExpiration && errors.licencePlateExpiration)} helperText={touched.licencePlateExpiration && errors.licencePlateExpiration}  />}
                          variant="outlined"
                          my={2}
                        />
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.trailer.key
                    ?
                      <div style={{ display: 'flex' }}>
                        <div style={{ marginRight: 10, flex: 1 }}>
                          <DesktopDatePicker
                            label="Licence Plate Expiration"
                            inputFormat="MM/dd/yyyy"
                            value={values.licencePlateExpiration}
                            fullWidth
                            onChange={value => handleChange({ target: {value, name: 'licencePlateExpiration' }})}
                            renderInput={(params) => <TextField my={2} fullWidth {...params} error={Boolean(touched.licencePlateExpiration && errors.licencePlateExpiration)} helperText={touched.licencePlateExpiration && errors.licencePlateExpiration}  />}
                            variant="outlined"
                            my={2}
                          />
                        </div>
                        <div style={{ flex: 1 }}>
                          <DesktopDatePicker
                            label="Inspection Sticker Expiration*"
                            inputFormat="MM/dd/yyyy"
                            value={values.inspectionStickerExpiration}
                            fullWidth
                            onChange={value => handleChange({ target: {value, name: 'inspectionStickerExpiration' }})}
                            renderInput={(params) => <TextField my={2} fullWidth {...params} error={Boolean(touched.inspectionStickerExpiration && errors.inspectionStickerExpiration)} helperText={touched.inspectionStickerExpiration && errors.inspectionStickerExpiration}  />}
                            variant="outlined"
                            my={2}
                          />
                        </div>
                      </div>
                    :
                      ''
                  }

                  {
                    values.type && values.type === equipmentTypes.truck.key
                    ?
                      <>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1, marginRight: 10 }}>
                            <DesktopDatePicker
                              label="Inspection Sticker Expiration"
                              inputFormat="MM/dd/yyyy"
                              value={values.inspectionStickerExpiration}
                              fullWidth
                              onChange={value => handleChange({ target: {value, name: 'inspectionStickerExpiration' }})}
                              renderInput={(params) => <TextField my={2} fullWidth {...params} error={Boolean(touched.inspectionStickerExpiration && errors.inspectionStickerExpiration)} helperText={touched.inspectionStickerExpiration && errors.inspectionStickerExpiration}  />}
                              variant="outlined"
                              my={2}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <DesktopDatePicker
                              label="Oil Change Expiration"
                              inputFormat="MM/dd/yyyy"
                              value={values.oilChangeExpiration}
                              fullWidth
                              onChange={value => handleChange({ target: {value, name: 'oilChangeExpiration' }})}
                              renderInput={(params) => <TextField my={2} fullWidth {...params} error={Boolean(touched.oilChangeExpiration && errors.oilChangeExpiration)} helperText={touched.oilChangeExpiration && errors.oilChangeExpiration}  />}
                              variant="outlined"
                              my={2}
                            />
                          </div>
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ marginBottom: 8, marginTop: 8, marginRight: 10, flex: 1 }}>
                            <FormControl fullWidth>
                              <InputLabel>Year Purchased*</InputLabel>
                              <Select
                                label="Year Purchased*"
                                value={values.yearPurchased}
                                error={Boolean(touched.yearPurchased && errors.yearPurchased)}
                                fullWidth
                                helperText={touched.yearPurchased && errors.yearPurchased}
                                onBlur={handleBlur}
                                onChange={handleChange}
                                variant="outlined"
                                my={2}
                                name="yearPurchased"
                              >
                                <MenuItem value="-1">Select</MenuItem>
                                {
                                  toPresentYears.map(year => <MenuItem value={year}>{year}</MenuItem>)
                                }
                              </Select>
                            </FormControl>
                          </div>
                            <TextField
                              name="purchasedPrice"
                              label="Purchased Price"
                              type="number"
                              value={values.purchasedPrice}
                              error={Boolean(touched.purchasedPrice && errors.purchasedPrice)}
                              style={{ flex: 1 }}
                              helperText={touched.purchasedPrice && errors.purchasedPrice}
                              onBlur={handleBlur}
                              onChange={e => {
                                if (e && e.target && (e.target.value !== null && e.target.value !== undefined && e.target.value < 0)) {
                                  e.target.value = 0;
                                }
                                handleChange(e);
                              }}
                              variant="outlined"
                              my={2}
                            /> 
                        </div>
                        <div style={{ display: 'flex' }}>
                          <div style={{ flex: 1, marginRight: 10 }}>
                            <TextField
                              name="odometer"
                              label="Odometer*"
                              type="number"
                              value={values.odometer}
                              fullWidth
                              error={Boolean(touched.odometer && errors.odometer)}
                              style={{ flex: 1 }}
                              helperText={touched.odometer && errors.odometer}
                              onBlur={handleBlur}
                              onChange={e => {
                                if (e && e.target && (e.target.value !== null && e.target.value !== undefined && e.target.value < 0)) {
                                  e.target.value = 0;
                                }
                                handleChange(e);
                              }}
                              variant="outlined"
                              my={2}
                            />
                          </div>
                          <div style={{ flex: 1 }}>
                            <TextField
                              name="description"
                              label="Description"
                              value={values.description}
                              error={Boolean(touched.description && errors.description)}
                              fullWidth
                              helperText={touched.description && errors.description}
                              onBlur={handleBlur}
                              onChange={handleChange}
                              variant="outlined"
                              my={2}
                            />
                          </div>
                        </div>
                      </>
                    :
                      ''
                  }

                  {
                    (values.type && values.type === equipmentTypes.truck.key) || (values.type && values.type === equipmentTypes.trailer.key)
                    ?
                      <TextField
                        name="notes"
                        label="Notes"
                        value={values.notes}
                        error={Boolean(touched.notes && errors.notes)}
                        fullWidth
                        helperText={touched.notes && errors.notes}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        multiline
                        rows={5}
                      />
                    :
                      ''
                  }

                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Save changes
                  </Button>
                </form>
            </CardContent>
          </Card>
        )}
      </Formik>


    </React.Fragment>
  );
}

export default AddEquipment;
