import moment from 'moment';

const timeHelper = {

  getTime: (time = Date.now(), showHours = true) => {
    const today = moment().startOf('day');
    const yesterday = moment().add(-1, 'day').startOf('day');
    return (
      time > Date.parse(today.toDate())
      ?
        'Today' + `${showHours ? ` at ${moment(time).format('HH:mm')}` : ''}`
      :
        time > Date.parse(yesterday.toDate())
        ?
          'Yesterday' + `${showHours ? ` at ${moment(time).format('HH:mm')}` : ''}`
        :
          moment(time).format(`MM/DD/YYYY${showHours ? ', HH:mm' : ''}`)
    );
  },

};

export default timeHelper;
