import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { parseResponseError } from '../helpers/errorHandlingHelpers';
import { refreshToken } from './currentLoggedUser';
import loadStatusTypes from "../../keys/loadStatusTypes";
import { setCurrentSelectedLoad } from "./loads";

const getInitialState = () => ({
    data: [],
    filters: {
      limit: 10,
      skip: 0,
      status: '-1',
    },
    total: 0,
    initialFetch: false,
  });
  
  export const invoicesSlice = createSlice({
      name: "invoices",
      initialState: getInitialState(),
      reducers: {
        setData: (state, action) => {
            state.data = action.payload;
            return state;
        },
        resetState: () => getInitialState(),
        setTotal: (state, action) => {
            state.total = action.payload;
            return state;
        },
        setFiltersLimit: (state, action) => {
            state.filters.limit = action.payload;
            return state;
        },
        setFiltersSkip: (state, action) => {
            state.filters.skip = action.payload;
            return state;
        },
        setFiltersStatus: (state, action) => {
            state.filters.status = action.payload;
            return state;
        },
        setInitialFetch: (state, action) => {
            state.initialFetch = action.payload;
            return state;
        },
      }
  });

  export const {
      setData,
      resetState,
      setTotal,
      setFiltersLimit,
      setFiltersSkip,
      setFiltersStatus,
      setInitialFetch,
  } = invoicesSlice.actions;

  export const fetchLoads = () => (dispatch, getState) => {
    if (getState().requestBusy) return;
    const state = getState();
    const filters = state && state.invoices && state.invoices.filters ? state.invoices.filters : {};
    dispatch(setRequestBusy(true));
    const queryParams = new URLSearchParams();
    queryParams.append('limit', filters.limit);
    queryParams.append('skip', filters.skip);
    if (filters.status === '-1') {
      queryParams.append('statuses', JSON.stringify([
        loadStatusTypes.delivered.key,
        loadStatusTypes.paid.key,
        loadStatusTypes.invoiced.key,
      ]));
    } else {
      queryParams.append('status', filters.status);
    }
    dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      axios.get(`/api/loads?${queryParams.toString()}`)
        .then(response => {
          if (response.status === 200 && response.data && response.data.data) {
            dispatch(setData(response.data.data.rows));
            dispatch(setTotal(response.data.data.total));
            dispatch(setInitialFetch(true));
            dispatch(setRequestBusy(false));
          } else {
            throw new Error('');
          }
        })
        .catch(error => parseResponseError(error, dispatch))
        .finally(() => dispatch(setRequestBusy(false)));
    }));
  };

  export const sendInvoicePdfEmail = (emailData = {}, pdfBlob, callback = () => {}) => (dispatch, getState) => {
    if (getState().requestBusy) return;
    dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      const fd = new FormData();
      if (emailData?.emailTo) fd.append('emailTo', emailData.emailTo);
      if (emailData?.emailSubject) fd.append('emailSubject', emailData.emailSubject);
      if (emailData?.emailBody) fd.append('emailBody', emailData.emailBody);
      fd.append('attachPod', emailData.attachPod ? '1' : '0');
      fd.append('loadId', emailData.loadId ?? '');
      if (pdfBlob) fd.append('pdf', pdfBlob, 'invoice.pdf');
      axios.post('/api/loads/send-invoice-email-pdf', fd)
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.messages) {
            window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
          }
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch)
        callback(error);
      })
      .finally(() => dispatch(setRequestBusy(false)));
    }))
  };

  export const updateInvoice = ({ id, invoiceDueDate }) => (dispatch, getState) => {
    if (getState().requestBusy) return;
    dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put('/api/loads/invoice', { data: { invoiceDueDate, id } })
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.messages) {
            window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
          }
          if (response?.data?.data) {
            const { loads } = getState();
            const currentSelectedLoad = loads?.currentSelectedLoad;
            dispatch(setCurrentSelectedLoad({
              ...currentSelectedLoad,
              ...response.data.data
            }));
          }
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch)
      })
      .finally(() => dispatch(setRequestBusy(false)));
    }))
  };

  export default invoicesSlice.reducer;
