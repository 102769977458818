import React from 'react';
import { useSelector } from 'react-redux';

import Stats from "../Stats";
import WrapperWithLoader from './components/WrapperWithLoader';

import { Grid } from "@mui/material";

const MaintenanceDashboard = () => {

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dashboards = useSelector(state => state.dashboards);
  const dashboard = dashboards?.[currentLoggedUser?.role];

  return (
    <Grid style={{ width: 'calc(100% - 10px)' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Available Equipment"
              amount={dashboard?.data?.companyUserNumberOfAvailableEquipment ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Oil Change Expiration"
              amount={dashboard?.data?.companyUserNumberOfOilChangeExpiration ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
      <Grid container spacing={6}>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <Stats
              title="Medical Expiration"
              amount={dashboard?.data?.companyUserNumberOfMedicalExpiration ?? 0}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl>
        </Grid>
      </Grid>
    </Grid>
  );

};

export default MaintenanceDashboard;
