export default {
  overTheRoad: {
    key: 'overTheRoad',
    display: 'Over The Road'
  },
  local: {
    key: 'local',
    display: 'Local'
  }
};
