import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate
} from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import { 
  addUser,
  fetchCurrentSelectedUser,
  updateUser
} from "../../redux/slices/users";
import userRoles from "../../keys/userRoles";
import { setCloseDrawer } from "../../redux/slices/fullDrawerModal";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  firstName: "",
  lastName: "",
  email: "",
  role: userRoles.companyAdmin.key,
  phoneNumber: "",
  phoneNumberExtension: "",
  brokerageName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  isActive: true,
  loginAccess: true
});

function AddUser({ isEdit, fullDrawerModalMode }) {
  const yupConfig = {
    firstName: Yup.string().min(2,"First Name must be at least 2 characters").max(50,"First Name must be at most 50 characters").required("Required"),
    lastName: Yup.string().min(2,"Last Name must be at least 2 characters").max(50,"Last Name must be at most 50 characters").required("Required"),
    email: Yup.string().email("Must be a valid email").min(3,"Email of Company must be at least 3 characters").max(255,"Email  must be at most 255 characters").required("Required"),
    phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
    phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
    brokerageName: Yup.string().min(3,"Brokerage Name must be at least 3 characters").max(50,"Brokerage Name must be at most 50 characters"),
    address: Yup.string().min(3,"Address must be at least 3 characters").max(50,"Address must be at most 50 characters"),
    city: Yup.string().min(3,"City must be at least 3 characters").max(50,"City must be at most 50 characters"),
    state: Yup.string().min(2,"State must be at least 2 characters").max(50,"State must be at most 50 characters"),
    zip: Yup.string().min(3,"Zip must be at least 3 characters").max(20,"Zip must be at most 20 characters"),
    isActive: Yup.boolean().required("Required"),
  };

  if (isEdit) {
    delete yupConfig.email;
  }
  const validationSchema = Yup.object().shape(yupConfig);

  const params = useParams();
  const currentSelectedUser = useSelector(state => state.users.currentSelectedUser);
  const { closeCallback } = useSelector(state => state.fullDrawerModal);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(getInitialValues());

  const userId = params && params.userId ? params.userId : null;

  useEffect(() => {
    if (userId && isEdit) dispatch(fetchCurrentSelectedUser(userId));
  }, []);

  useEffect(() => {
    if (currentSelectedUser) setInitialValues({ 
      ...currentSelectedUser,
      phoneNumberExtension: currentSelectedUser.phoneNumberExtension ? currentSelectedUser.phoneNumberExtension : "",
      brokerageName: currentSelectedUser.brokerageName ? currentSelectedUser.brokerageName : "",
      address: currentSelectedUser.address ? currentSelectedUser.address : "",
      city: currentSelectedUser.city ? currentSelectedUser.city : "",
      state: currentSelectedUser.state ? currentSelectedUser.state : "",
      zip: currentSelectedUser.zip ? currentSelectedUser.zip : "",
    });
  }, [currentSelectedUser]);


  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (isEdit) {
        const data = {
          id: currentSelectedUser && currentSelectedUser.id ? currentSelectedUser.id : null,
          isActive: values.isActive,
          loginAccess: values.loginAccess,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber
        };
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        else data.phoneNumberExtension = null;
        if (values.brokerageName) data.brokerageName = values.brokerageName;
        else data.brokerageName = null;
        if (values.address) data.address = values.address;
        else data.address = null;
        if (values.city) data.city = values.city;
        else data.city = null;
        if (values.state) data.state = values.state;
        else data.state = null;
        if (values.zip) data.zip = values.zip;
        else data.zip = null;
        dispatch(updateUser(data,err => {
          if (err) return;
          setSubmitting(false);
          if (fullDrawerModalMode) {
            if (closeCallback) closeCallback();
            dispatch(setCloseDrawer());
          } else {
            window.setTimeout(() => navigate(-1), 0);
          }
        }));
      } else {
        const data = {
          isActive: values.isActive,
          loginAccess: values.loginAccess,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          role: values.role
        };
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        if (values.brokerageName) data.brokerageName = values.brokerageName;
        if (values.address) data.address = values.address;
        if (values.city) data.city = values.city;
        if (values.state) data.state = values.state;
        if (values.zip) data.zip = values.zip;
        dispatch(addUser(data, err => {
          if (err) return;
          resetForm();
          setSubmitting(false);
          if (fullDrawerModalMode) {
            if (closeCallback) closeCallback();
            dispatch(setCloseDrawer());
          } else {
            window.setTimeout(() => navigate(-1), 0);
          }
        }));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {
        !fullDrawerModalMode && 
        <>
          <Helmet title={ isEdit ? 'Edit User' : 'Add User' }/>
          <Typography variant="h3" gutterBottom display="inline">
            { isEdit ? 'Edit User' : 'Add User' }
          </Typography>

          {
            isEdit
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Users
                </Link>
                <Typography>Edit User</Typography>
              </Breadcrumbs>
            :
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Users
                </Link>
                <Typography>Add User</Typography>
              </Breadcrumbs>
          }

          <Divider my={6} />
        </>
      }

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                Users Information
              </Typography>
              <form onSubmit={handleSubmit}>
                {
                  !isEdit
                  ?
                  <FormControl fullWidth style={{ marginBottom: 10 }}>
                    <InputLabel error={Boolean(touched.role && errors.role)}>Role*</InputLabel>
                    <Select
                      label="Role*"
                      value={values.role}
                      fullWidth
                      error={Boolean(touched.role && errors.role)}
                      helperText={touched.role && errors.role}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      name="role"
                    >
                      {
                        Object.keys(userRoles).map(role => {
                          if (role === userRoles.admin.key || role === userRoles.truckDriver.key) return '';
                          return <MenuItem value={userRoles[role].key}>{userRoles[role].display}</MenuItem>;
                        })
                      }
                    </Select>
                  </FormControl>
                  :
                  ''
                }
                    <TextField
                      name="firstName"
                      label="First Name*"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                    <TextField
                      name="lastName"
                      label="Last Name*"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                {
                  !isEdit &&
                  <TextField
                    name="email"
                    label="Email*"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />
                }

                <div style={{ display: 'flex' }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+1</InputAdornment>
                    }}
                    name="phoneNumber"
                    label="Phone Number*"
                    value={values.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    style={{ flex: 3 }}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                  <TextField
                    name="phoneNumberExtension"
                    label="Extension"
                    value={values.phoneNumberExtension}
                    error={Boolean(touched.phoneNumberExtension && errors.phoneNumberExtension)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.phoneNumberExtension && errors.phoneNumberExtension}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                </div>
                {
                  values.role === userRoles.broker.key &&
                  <TextField
                    name="brokerageName"
                    label="Brokerage Name"
                    value={values.brokerageName}
                    error={Boolean(touched.brokerageName && errors.brokerageName)}
                    fullWidth
                    helperText={touched.brokerageName && errors.brokerageName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                }
                {
                  values.role !== userRoles.companyAdmin.key &&
                  <>
                    <TextField
                      name="address"
                      label="Address"
                      value={values.address}
                      error={Boolean(touched.address && errors.address)}
                      fullWidth
                      helperText={touched.address && errors.address}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                    <div style={{ display: 'flex' }}>
                      <TextField
                        name="city"
                        label="City"
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        style={{ flex: 3 }}
                        helperText={touched.city && errors.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      /> 
                      <TextField
                        name="state"
                        label="State"
                        value={values.state}
                        error={Boolean(touched.state && errors.state)}
                        style={{ flex: 1, marginLeft: 10 }}
                        helperText={touched.state && errors.state}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="zip"
                        label="Zip"
                        value={values.zip}
                        error={Boolean(touched.zip && errors.zip)}
                        style={{ flex: 1, marginLeft: 10 }}
                        helperText={touched.zip && errors.zip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      /> 
                    </div>
                  </>
                }
                <div style={{ marginBottom: 16, marginTop: 8 }}>
                    <FormControl fullWidth>
                      <InputLabel>Status*</InputLabel>
                      <Select
                        label="Status*"
                        value={values.isActive}
                        error={Boolean(touched.isActive && errors.isActive)}
                        fullWidth
                        helperText={touched.isActive && errors.isActive}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="isActive"
                        
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8 }}>
                    <FormControl fullWidth>
                      <InputLabel>Login Access*</InputLabel>
                      <Select
                        label="Login Access*"
                        value={values.loginAccess}
                        error={Boolean(touched.loginAccess && errors.loginAccess)}
                        fullWidth
                        helperText={touched.loginAccess && errors.loginAccess}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="loginAccess"
                        
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default AddUser;