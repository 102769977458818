import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { disable2faViaEmail } from "../../redux/slices/currentLoggedUser";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessDialogMessage } from "../../redux/slices/messages";

function TwoFactorAuthentication() {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);

  useEffect(() => {

    const queryString = new URLSearchParams(window.location.search);
    const resetCode2fa = queryString.get('resetCode2fa');
    const userId = queryString.get('id');

    if (!resetCode2fa || !userId) {
      setTimeout(() => navigate('/', { replace: true }), 50);
    } else {
      dispatch(disable2faViaEmail(resetCode2fa, userId, (err) => {
        if (err) return;
        if (currentLoggedUser) {
          window.setTimeout(() => navigate('/profile', { replace: true }), 50);
          window.setTimeout(() => {
            dispatch(setSuccessDialogMessage('2FA successfully disabled. However, you can enable it anytime here again.'));
          }, 250);
        } else {
          window.setTimeout(() => navigate('/login', { replace: true }), 50);
          window.setTimeout(() => {
            dispatch(setSuccessDialogMessage('2FA successfully disabled. You can login now.'));
          }, 250);
        }
      }))
    }
  }, []);

  return <></>;
}

export default TwoFactorAuthentication;
