import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { refreshToken } from './currentLoggedUser';
import userRoles from "../../keys/userRoles";
import dashboardTypes from "../../keys/dashboardTypes";

const getInitialState = () => ({
  [userRoles?.admin?.key ?? 'admin']: {
    data: {
      [dashboardTypes.adminNumberOfCreatedCompanies.key]: 0,
      [dashboardTypes.adminNumberOfCreatedLoads.key]: 0,
      [dashboardTypes.adminYearRevenue.key]: [0,0,0,0,0,0,0,0,0,0,0,0],
    },
    fetchBatch1: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.adminNumberOfCreatedCompanies.key,
        dashboardTypes.adminNumberOfCreatedLoads.key,
        dashboardTypes.adminYearRevenue.key
      ]
    }
  },
  [userRoles?.companyAdmin?.key ?? 'companyAdmin']: {
    availableTruckDrivers: {
      data: [],
      filters: {
        limit: 10,
        skip: 0,
      },
      total: 0,
      initialFetch: false,
      requestBusy: false,
    },
    data: {
      [dashboardTypes.companyUserNumberOfAvailableLoads.key]: 0,
      [dashboardTypes.companyUserNumberOfAvailableTruckDrivers.key]: 0,
      [dashboardTypes.companyUserNumberOfAvailableEquipment.key]: 0,
      [dashboardTypes.companyUserNumberOfOilChangeExpiration.key]: 0,
      [dashboardTypes.companyUserNumberOfMedicalExpiration.key]: 0,
      [dashboardTypes.companyUserYearRevenue.key]: [0,0,0,0,0,0,0,0,0,0,0,0],
      [dashboardTypes.companyUserTopTruckDriversByRevenue.key]: [],
      [dashboardTypes.companyUserTopDispatchersByLoadNumber.key]: [],
      [dashboardTypes.companyUserTopDispatchersByRevenue.key]: [],
    },
    fetchBatch1: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserNumberOfAvailableLoads.key,
        dashboardTypes.companyUserNumberOfAvailableTruckDrivers.key,
        dashboardTypes.companyUserNumberOfAvailableEquipment.key,
        dashboardTypes.companyUserNumberOfOilChangeExpiration.key,
        dashboardTypes.companyUserNumberOfMedicalExpiration.key
      ]
    },
    fetchBatch2: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserYearRevenue.key,
      ]
    },
    fetchBatch3: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserTopTruckDriversByRevenue.key,
        dashboardTypes.companyUserTopDispatchersByLoadNumber.key,
        dashboardTypes.companyUserTopDispatchersByRevenue.key,
      ]
    }
  },
  [userRoles?.dispatcher?.key ?? 'dispatcher']: {
    availableTruckDrivers: {
      data: [],
      filters: {
        limit: 10,
        skip: 0,
      },
      total: 0,
      initialFetch: false,
      requestBusy: false,
    },
    data: {
      [dashboardTypes.companyUserNumberOfAvailableLoads.key]: 0,
      [dashboardTypes.companyUserNumberOfAvailableTruckDrivers.key]: 0,
      [dashboardTypes.companyUserNumberOfAvailableEquipment.key]: 0,
      [dashboardTypes.companyUserNumberOfOilChangeExpiration.key]: 0,
      [dashboardTypes.companyUserNumberOfMedicalExpiration.key]: 0,
      [dashboardTypes.companyUserTopDispatchersByLoadNumber.key]: [],
    },
    fetchBatch1: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserNumberOfAvailableLoads.key,
        dashboardTypes.companyUserNumberOfAvailableTruckDrivers.key,
        dashboardTypes.companyUserNumberOfAvailableEquipment.key,
      ]
    },
    fetchBatch2: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserNumberOfOilChangeExpiration.key,
        dashboardTypes.companyUserNumberOfMedicalExpiration.key,
        dashboardTypes.companyUserTopDispatchersByLoadNumber.key
      ]
    },
  },
  [userRoles?.accounting?.key ?? 'accounting']: {
    data: {
      [dashboardTypes.companyUserYearRevenue.key]: [0,0,0,0,0,0,0,0,0,0,0,0],
      [dashboardTypes.companyUserTopTruckDriversByRevenue.key]: [],
      [dashboardTypes.companyUserTopDispatchersByRevenue.key]: [],
    },
    fetchBatch1: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserYearRevenue.key,
        dashboardTypes.companyUserTopTruckDriversByRevenue.key,
        dashboardTypes.companyUserTopDispatchersByRevenue.key,
      ]
    },
  },
  [userRoles?.truckDriver?.key ?? 'truckDriver']: {},
  [userRoles?.broker?.key ?? 'broker']: {},
  [userRoles?.safety?.key ?? 'safety']: {},
  [userRoles?.maintenance?.key ?? 'maintenance']: {
    data: {
      [dashboardTypes.companyUserNumberOfAvailableEquipment.key]: 0,
      [dashboardTypes.companyUserNumberOfOilChangeExpiration.key]: 0,
      [dashboardTypes.companyUserNumberOfMedicalExpiration.key]: 0,
    },
    fetchBatch1: {
      lastFetch: null,
      requestBusy: false,
      fetchTypes: [
        dashboardTypes.companyUserNumberOfAvailableEquipment.key,
        dashboardTypes.companyUserNumberOfOilChangeExpiration.key,
        dashboardTypes.companyUserNumberOfMedicalExpiration.key,
      ]
    },
  },
});

export const dashboardsSlice = createSlice({
  name: "dashboards",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      const {
        data,
        dataType,
        userType,
      } = action?.payload ?? {};
      if (typeof state?.[userType]?.data?.[dataType] !== 'undefined') state[userType].data[dataType] = data;
      return state;
    },
    setLastFetch: (state, action) => {
      const {
        lastFetch,
        userType,
        batchIndex
      } = action?.payload ?? {};
      if (state?.[userType]?.[`fetchBatch${batchIndex}`]) state[userType][`fetchBatch${batchIndex}`].lastFetch = lastFetch;
      return state;
    },
    setRequestBusy: (state, action) => {
      const {
        requestBusy,
        userType,
        batchIndex
      } = action?.payload ?? {};
      if (state?.[userType]?.[`fetchBatch${batchIndex}`]) state[userType][`fetchBatch${batchIndex}`].requestBusy = requestBusy;
      return state;
    },
    setAvailableTruckDriversData: (state, action) => {
      const {
        userType,
        data
      } = action?.payload ?? {};
      if (state?.[userType]?.availableTruckDrivers) state[userType].availableTruckDrivers.data = data;
      return state;
    },
    setAvailableTruckDriversFiltersLimit: (state, action) => {
      const {
        userType,
        limit
      } = action?.payload ?? {};
      if (state?.[userType]?.availableTruckDrivers) state[userType].availableTruckDrivers.filters.limit = limit;
      return state;
    },
    setAvailableTruckDriversFiltersSkip: (state, action) => {
      const {
        userType,
        skip
      } = action?.payload ?? {};
      if (state?.[userType]?.availableTruckDrivers) state[userType].availableTruckDrivers.filters.skip = skip;
      return state;
    },
    setAvailableTruckDriversRequestBusy: (state, action) => {
      const {
        userType,
        requestBusy
      } = action?.payload ?? {};
      if (state?.[userType]?.availableTruckDrivers) state[userType].availableTruckDrivers.requestBusy = requestBusy;
      return state;
    },
    setAvailableTruckDriversInitialFetch: (state, action) => {
      const {
        userType,
        initialFetch
      } = action?.payload ?? {};
      if (state?.[userType]?.availableTruckDrivers) state[userType].availableTruckDrivers.initialFetch = initialFetch;
      return state;
    },
    setAvailableTruckDriversTotal: (state, action) => {
      const {
        userType,
        total
      } = action?.payload ?? {};
      if (state?.[userType]?.availableTruckDrivers) state[userType].availableTruckDrivers.total = total;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setData,
  setLastFetch,
  setRequestBusy,
  setAvailableTruckDriversData,
  setAvailableTruckDriversFiltersLimit,
  setAvailableTruckDriversFiltersSkip,
  setAvailableTruckDriversRequestBusy,
  setAvailableTruckDriversInitialFetch,
  setAvailableTruckDriversTotal,
  resetState
} = dashboardsSlice.actions;

export const fetchDashboards = () => (dispatch, getState) => {

  const fullState = getState();
  const userRole = fullState?.currentLoggedUser?.role;
  if (!userRole || !fullState?.dashboards?.[userRole]) return;

  const dashboardState = fullState?.dashboards?.[userRole];
  if (!Object.keys(dashboardState.data).length) return;

  const fetchBatchOptions = [];
  let fetchOptionsIncrementer = 1;
  while (dashboardState[`fetchBatch${fetchOptionsIncrementer}`]) {
    fetchBatchOptions.push({
      index: fetchOptionsIncrementer,
      ...dashboardState[`fetchBatch${fetchOptionsIncrementer}`],
    });
    fetchOptionsIncrementer++;
  }

  dispatch(refreshToken(async (err) => {
    if (err) return;

    for (let batch of fetchBatchOptions) {
      if (batch.requestBusy || (batch.lastFetch && (Date.now() - batch.lastFetch) < (60000 * 5))) return; //Cooldown every 5 minutes for fetch.
      if (batch?.fetchTypes?.length) {
        const queryParams = new URLSearchParams();
        queryParams.append('types', JSON.stringify(batch.fetchTypes));
        dispatch(setRequestBusy({
          requestBusy: true,
          userType: userRole,
          batchIndex: batch.index,
        }));
        try {
          const res = await axios.get(`/api/dashboards?${queryParams.toString()}`);
          if (res?.data?.data) {
            const data = res?.data?.data;
            Object.keys(data).forEach(key => {
              dispatch(setData({
                data: data[key],
                userType: userRole,
                dataType: key,
              }));
            });
            dispatch(setLastFetch({
              lastFetch: Date.now(),
              userType: userRole,
              batchIndex: batch.index,
            }));
          }
        } catch (e) {}
        dispatch(setRequestBusy({
          requestBusy: false,
          userType: userRole,
          batchIndex: batch.index,
        }));
      }
    }
    if (
      dashboardState?.availableTruckDrivers && 
      !dashboardState.availableTruckDrivers.initialFetch
    ) {
      dispatch(triggerFetchDashboardAvailableTruckDrivers());
    }

  }));
};

export const triggerFetchDashboardAvailableTruckDrivers = () => (dispatch, getState) => {

  const fullState = getState();
  const userRole = fullState?.currentLoggedUser?.role;
  if (!userRole || !fullState?.dashboards?.[userRole]) return;

  const dashboardState = fullState?.dashboards?.[userRole];
  if (!Object.keys(dashboardState.data).length) return;

  dispatch(refreshToken(async (err) => {
    if (err) return;

    if (dashboardState?.availableTruckDrivers) {
      if (dashboardState.availableTruckDrivers.requestBusy) return;
      dispatch(setAvailableTruckDriversRequestBusy({ requestBusy: true, userType: userRole }));
      const skip = dashboardState.availableTruckDrivers.filters.skip ?? 0;
      const limit = dashboardState.availableTruckDrivers.filters.limit ?? 0;
      try {
        const queryParams = new URLSearchParams();
        queryParams.append('limit', limit);
        queryParams.append('skip', skip);
        queryParams.append('role', userRoles.truckDriver.key);
        queryParams.append('isActive', 1);
        queryParams.append('loginAccess', 1);
        const res = await axios.get(`/api/users?${queryParams.toString()}`)
        if (res?.data?.data) {
          dispatch(setAvailableTruckDriversInitialFetch({ initialFetch: true, userType: userRole }));
          if (res?.data?.data?.rows) {
            dispatch(setAvailableTruckDriversData({ data: res.data.data.rows, userType: userRole }));
          }
          if (res?.data?.data?.total) {
            dispatch(setAvailableTruckDriversTotal({ total: res.data.data.total, userType: userRole }));
          }
        }
      } catch (e) {} finally {
        dispatch(setAvailableTruckDriversRequestBusy({ requestBusy: false, userType: userRole }));
      }
    }

  }));
};

export default dashboardsSlice.reducer;
