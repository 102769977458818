import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing, display} from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

function TruckDriverEfsTransactionDetails() {
  const navigate = useNavigate();

  const companySettings = useSelector(state => state.companySettings);
  const currentSelectedTransaction = companySettings?.integrations?.fuelCards?.EFS?.EFSTransactions?.currentSelectedTransaction ?? null;

  useEffect(() => {
    if (!currentSelectedTransaction) navigate('/');
  }, [currentSelectedTransaction, navigate]);

  return (
    <React.Fragment>
      <Helmet title="EFS Transaction Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            EFS Transaction Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-3, { replace: true })}>
              Truck Drivers
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-2, { replace: true })}>
              Truck Driver Details
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1, { replace: true })}>
              EFS Transactions
            </Link>
            <Typography>EFS Transaction Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>Invoice ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.invoice}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Transaction ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.transactionId}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Driver ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.user?.id}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>First Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.user?.firstName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Last Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.user?.lastName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Card Number:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.user?.efsCardNumber}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Net Amount:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.netTotal}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Total Amount:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.settleAmount}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Currency:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.billingCurrency}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.locationName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Location State:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.locationState}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Date:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTransaction?.transactionDate ? moment(currentSelectedTransaction.transactionDate).format("MM/DD/YYYY HH:mm") : ''}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TruckDriverEfsTransactionDetails;
