import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from "./requestBusy";
import { setCurrentLoggedUser, refreshToken} from "./currentLoggedUser";
import { setErrorDialogMessage, setSuccessDialogMessage } from "./messages";
import { parseResponseError } from '../helpers/errorHandlingHelpers';
import { setForceShowSpinner } from "./forceShowSpinner";
import { setCurrentSelectedLoad } from "./loads";

const EFSBegDate = new Date();
EFSBegDate.setDate(EFSBegDate.getDate() - 30);
EFSBegDate.setHours(0, 0, 0)
const EFSEndDate = new Date();
EFSEndDate.setHours(23, 59, 59)

const getInitialState = () => ({
  integrations: {
    elds: {
      eldSolutions: {
        vehicles: []
      },
    },
    accounting: {
      quickbooks: {
        data: [], //Customers data.
        filters: { //Customers filters.
          limit: 10,
          skip: 0,
        },
        initialFetch: false, //Customers initial fetch.
        requestBusy: false, // Customers request busy.
        tempQuickbooksDataPerAppCustomerId: {}, //These are indexed by local customer id, so we don't need to fetch all of them in order to show data for one specific.
        shipInvoiceRequestBusy: false,
        products: {
          data: [],
          filters: {
            limit: 10,
            skip: 0,
          },
          requestBusy: false,
          initialFetch: false,
        },
      }
    },
    fuelCards: {
      EFS: {
        forceLogin: false,
        EFSCardSummaries: {
          data: [],
        },
        EFSTransactions: {
          data: [],
          filters: {
            begDate: EFSBegDate,
            endDate: EFSEndDate,
          },
          currentSelectedTransaction: null,
        },
      }
    }
  },
});

export const companySettingsSlice = createSlice({
  name: "companySettings",
  initialState: getInitialState(),
  reducers: { 
    setEldSolutionsVehicles: (state, action) => {
      state.integrations.elds.eldSolutions.vehicles = action.payload;
      return state;
    },
    concatQuickbooksData: (state, action) => {
      state.integrations.accounting.quickbooks.data = state.integrations.accounting.quickbooks.data.concat(action.payload);
      return state;
    },
    setQuickbooksFiltersLimit: (state, action) => {
      state.integrations.accounting.quickbooks.filters.limit = action.payload;
      return state;
    },
    setQuickbooksFiltersSkip: (state, action) => {
      state.integrations.accounting.quickbooks.filters.skip = action.payload;
      return state;
    },
    setQuickbooksInitialFetch: (state, action) => {
      state.integrations.accounting.quickbooks.initialFetch = action.payload;
      return state;
    },
    setQuickbooksRequestBusy: (state, action) => {
      state.integrations.accounting.quickbooks.requestBusy = action.payload;
      return state;
    },
    insertTempQuickbooksDataPerAppCustomerId: (state, action) => {
      state.integrations.accounting.quickbooks.tempQuickbooksDataPerAppCustomerId[action.payload.localCustomerId] = action.payload.data;
      return state;
    },
    setQuickbooksShipInvoiceRequestBusy: (state, action) => {
      state.integrations.accounting.quickbooks.shipInvoiceRequestBusy = action.payload;
      return state;
    },
    setQuickbooksProductsRequestBusy: (state, action) => {
      state.integrations.accounting.quickbooks.products.requestBusy = action.payload;
      return state;
    },
    concatQuickbooksProductsData: (state, action) => {
      state.integrations.accounting.quickbooks.products.data = state.integrations.accounting.quickbooks.products.data.concat(action.payload);
      return state;
    },
    setQuickbooksProductsInitialFetch: (state, action) => {
      state.integrations.accounting.quickbooks.products.initialFetch = action.payload;
      return state;
    },
    setQuickbooksProductsFiltersLimit: (state, action) => {
      state.integrations.accounting.quickbooks.products.filters.limit = action.payload;
      return state;
    },
    setQuickbooksProductsFiltersSkip: (state, action) => {
      state.integrations.accounting.quickbooks.products.filters.skip = action.payload;
      return state;
    },
    setEFSCardSummariesData: (state, action) => {
      state.integrations.fuelCards.EFS.EFSCardSummaries.data = action.payload;
      return state;
    },
    setEFSForceLogin: (state, action) => {
      state.integrations.fuelCards.EFS.forceLogin = action.payload;
      return state;
    },
    setEFSTransactionsData: (state, action) => {
      state.integrations.fuelCards.EFS.EFSTransactions.data = action.payload;
      return state;
    },
    setEFSTransactionsCurrentSelectedTransaction: (state, action) => {
      state.integrations.fuelCards.EFS.EFSTransactions.currentSelectedTransaction = action.payload;
      return state;
    },
    setEFSTransactionsFiltersBegDate: (state, action) => {
      state.integrations.fuelCards.EFS.EFSTransactions.filters.begDate = action.payload;
      return state;
    },
    setEFSTransactionsFiltersEndDate: (state, action) => {
      state.integrations.fuelCards.EFS.EFSTransactions.filters.endDate = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  },
});

export const { 
  setEldSolutionsVehicles,
  concatQuickbooksData,
  setQuickbooksFiltersLimit,
  setQuickbooksFiltersSkip,
  setQuickbooksInitialFetch,
  setQuickbooksRequestBusy,
  insertTempQuickbooksDataPerAppCustomerId,
  setQuickbooksShipInvoiceRequestBusy,
  setQuickbooksProductsRequestBusy,
  concatQuickbooksProductsData,
  setQuickbooksProductsInitialFetch,
  setQuickbooksProductsFiltersLimit,
  setQuickbooksProductsFiltersSkip,
  setEFSCardSummariesData,
  setEFSForceLogin,
  setEFSTransactionsData,
  setEFSTransactionsCurrentSelectedTransaction,
  setEFSTransactionsFiltersBegDate,
  setEFSTransactionsFiltersEndDate,
  resetState
} = companySettingsSlice.actions;

export const enableEldIntegration = (accessToken, eldType, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/companies/settings/integrations/eld/enable', 
      {
        accessToken,
        eldType
      }
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const disableEldIntegration = (params = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/eld/disable', { data: { ...params } })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const getAllEldSolutionsVehicles = () => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get('/api/companies/settings/integrations/eld/eld-solutions/vehicles')
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setEldSolutionsVehicles(response.data.data));
        }
      })
      .catch(() => {})
  }));
};

export const selectPaymentGateaway = (params = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/billing/enable', { data: { ...params } })
    .then(response => {
      if (response.status === 200 && response.data && response.data.data) {
        const { currentLoggedUser } = getState();
        if (currentLoggedUser) {
          dispatch(setCurrentLoggedUser({
            ...currentLoggedUser,
            ...response.data.data
          }));
        }
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => {
      parseResponseError(error, dispatch);
      callback(error);
    })
    .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const disablePaymentGateaway = (params = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/billing/disable', { data: { ...params } })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const triggerStripeCustomerIdSetup = (params = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/billing/stripe/setup-customer')
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (response.data.data.updatedUser) {
            const { currentLoggedUser } = getState();
            if (currentLoggedUser) {
              dispatch(setCurrentLoggedUser({
                ...currentLoggedUser,
                ...response.data.data.updatedUser
              }));
            }
          }
          callback(null, response.data.data.clientSecret);
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const getStripePaymentMethod = (callback = () => {}) => (dispatch) => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get('/api/companies/settings/integrations/billing/stripe/get-payment-method')
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          callback(null, response.data.data);
        } else {
          throw new Error('');
        }
      })
      .catch((err) => callback(err))
  }));
};

export const enableQuickbooksIntegration = (data = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/companies/settings/integrations/quickbooks/enable', 
      { data }
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const disableQuickbooksIntegration = (data = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return  dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/quickbooks/disable', { data })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const getQuickbooksLoginUri = (callback = () => {}) => (dispatch) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return  dispatch(setRequestBusy(false));
    axios.get('/api/companies/settings/integrations/quickbooks/get-login-uri')
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          callback(null, response.data.data);
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const processQuickbooksLoginRedirect = (params = {}) => (dispatch, getState) => {
  const {
    redirectUrl
  } = params;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/quickbooks/redirect-callback', { data: { redirectUrl } })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          // We added this line below only to restrict user to click reload again and start te process again. Since at that moment we will have query string params there.
          window.history.replaceState(null, null, window.location.origin + '/settings/accounting-integrations');
        } else {
          throw new Error('');
        }
      })
      .catch(error => parseResponseError(error, dispatch))
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const quickbooksRefreshToken = (callback = () => {}) => (dispatch, getState) => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.post('/api/companies/settings/integrations/quickbooks/check-token')
      .then(response => {
        if (response.status === 200 && response.data.data) {
          const {
            isTokenValid,
            newCurrentLoggedUserData,
            refreshTokenExpired
          } = response.data.data;
          if (refreshTokenExpired) {
            window.setTimeout(() => dispatch(setErrorDialogMessage('Quickbooks login session has expired. Please login on Quickbooks integration page again to continue this action.')), 100);
          }
          if (!isTokenValid) throw new Error('');
          const { currentLoggedUser } = getState();
          if (currentLoggedUser && newCurrentLoggedUserData) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...newCurrentLoggedUserData
            }));
          }
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(callback);
  }));
};

export const getAllQuickbooksCustomers = () => (dispatch, getState) => {
  const { companySettings } = getState();
  if (companySettings?.integrations?.accounting?.quickbooks?.requestBusy) return;
  const filters = companySettings?.integrations?.accounting?.quickbooks?.filters ?? {};
  dispatch(setQuickbooksRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);

  dispatch(quickbooksRefreshToken(err => {
    if (err) return dispatch(setQuickbooksRequestBusy(false));
    axios.get(`/api/companies/settings/integrations/quickbooks/customers?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(concatQuickbooksData(response.data.data));
          dispatch(setQuickbooksInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
      .finally(() => dispatch(setQuickbooksRequestBusy(false)));
  }));
};

export const getQuickbooksCustomerById = (params = {}, callback = () => {}) => (dispatch) => {
  const {
    id,
    localCustomerId
  } = params;
  if (!id) return;
  dispatch(quickbooksRefreshToken(err => {
    if (err) return;
    axios.get(`/api/companies/settings/integrations/quickbooks/customers/${params?.id}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          if (localCustomerId) {
            dispatch(insertTempQuickbooksDataPerAppCustomerId({
              data: response.data.data,
              localCustomerId: localCustomerId
            }));
          }
          callback(null, response.data.data);
        } else {
          throw new Error('');
        }
      })
      .catch(callback);
  }));
};

export const shipInvoiceToTheQuickbooks = (params = {}) => (dispatch, getState) => {
  const { loadId } = params;
  if (!loadId) return;
  const { companySettings } = getState();
  if (companySettings?.integrations?.accounting?.quickbooks?.shipInvoiceRequestBusy) return;
  dispatch(setForceShowSpinner(true));
  dispatch(setQuickbooksShipInvoiceRequestBusy(true));
  dispatch(quickbooksRefreshToken(err => {
    if (err) {
      dispatch(setForceShowSpinner(false));
      dispatch(setQuickbooksShipInvoiceRequestBusy(false));
      return;
    }
    axios.post(`/api/companies/settings/integrations/quickbooks/invoice`, { data: { loadId } })
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.messages) {
            window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
          }
          if (response?.data?.data) {
            const { loads } = getState();
            const currentSelectedLoad = loads?.currentSelectedLoad;
            dispatch(setCurrentSelectedLoad({
              ...currentSelectedLoad,
              ...response.data.data
            }));
          }
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch)
      })
      .finally(() => {
        dispatch(setForceShowSpinner(false));
        dispatch(setQuickbooksShipInvoiceRequestBusy(false));
      });
  }));
};

export const getQuickbooksProductById = (params = {}, callback = () => {}) => (dispatch) => {
  const {
    id
  } = params;
  if (!id) return;
  dispatch(quickbooksRefreshToken(err => {
    if (err) return;
    axios.get(`/api/companies/settings/integrations/quickbooks/products/${params?.id}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          callback(null, response.data.data);
        } else {
          throw new Error('');
        }
      })
      .catch(callback);
  }));
};

export const getAllQuickbooksProducts = () => (dispatch, getState) => {
  const { companySettings } = getState();
  if (companySettings?.integrations?.accounting?.quickbooks?.products?.requestBusy) return;
  const filters = companySettings?.integrations?.accounting?.quickbooks?.products?.filters ?? {};
  dispatch(setQuickbooksProductsRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);

  dispatch(quickbooksRefreshToken(err => {
    if (err) return dispatch(setQuickbooksProductsRequestBusy(false));
    axios.get(`/api/companies/settings/integrations/quickbooks/products?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(concatQuickbooksProductsData(response.data.data));
          dispatch(setQuickbooksProductsInitialFetch(true));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
      .finally(() => dispatch(setQuickbooksProductsRequestBusy(false)));
  }));
};

export const updateQuickbooksConfig = (params = {}, callback = () => {}) => (dispatch, getState) => {
  const { quickbooksProductItemId } = params;
  const { requestBusy } = getState();
  if (requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(quickbooksRefreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.put(`/api/companies/settings/integrations/quickbooks/config`, { data: { quickbooksProductItemId } })
      .then(response => {
        if (response.status === 200) {
          if (response?.data?.messages) {
            window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
          }
          if (response?.data?.data) {
            const { currentLoggedUser } = getState();
            if (currentLoggedUser) {
              dispatch(setCurrentLoggedUser({
                ...currentLoggedUser,
                ...response.data.data
              }));
            }
          }
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => {
        dispatch(setRequestBusy(false));
      });
  }));
};

export const enableFuelCardIntegration = (params = {}, fuelCardType, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/companies/settings/integrations/fuel-cards/enable', 
      { fuelCardType }
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => {
        dispatch(setRequestBusy(false));
      });
  }));
};

export const disableFuelCardIntegration = (params = {}, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/fuel-cards/disable', { data: { ...params } })
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          if (currentLoggedUser) {
            dispatch(setCurrentLoggedUser({
              ...currentLoggedUser,
              ...response.data.data
            }));
          }
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => {
        dispatch(setRequestBusy(false));
      });
  }));
};

export const fetchEFSCardSummariesData = (callback = () => {}) => (dispatch) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.get('/api/companies/settings/integrations/fuel-cards/efs/card-summaries')
      .then(response => {
        if (
          response.status === 200 && 
          response.data && 
          response.data.response
        ) {
          if (response.data.response.loginFailed) {
            dispatch(setEFSForceLogin(true));
            const err = 'You need to login first to continue this action.';
            callback(err);
            throw {
              response: {
                data: {
                  messages: [err]
                }
              }
            };
          } else {
            dispatch(setEFSCardSummariesData(response.data.response.data ?? []));
            callback();
          }
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => {
        dispatch(setRequestBusy(false));
      });
  }));
};

export const attemptEFSLogin = (params = {}, callback = () => {}) => (dispatch) => {
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/companies/settings/integrations/fuel-cards/efs/login', { data: {...params} })
      .then(response => {
        if (response.status === 200) {
          dispatch(setEFSForceLogin(false));
          dispatch(setSuccessDialogMessage('Login successfull. Now you can continue with your EFS access.'))
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        callback(error);
        parseResponseError(error, dispatch);
      })
      .finally(() => {
        dispatch(setRequestBusy(false));
      });
  }));
};

export const fetchEFSTransactions = (params = {}, callback = () => {}) => (dispatch) => {
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('begDate', params.begDate.toISOString());
  queryParams.append('endDate', params.endDate.toISOString());
  if (params.truckDriverId) queryParams.append('truckDriverId', params.truckDriverId);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.get(`/api/companies/settings/integrations/fuel-cards/efs/transactions?${queryParams.toString()}`)
      .then(response => {
        if (
          response.status === 200 && 
          response.data && 
          response.data.response
        ) {
          if (response.data.response.loginFailed) {
            dispatch(setEFSForceLogin(true));
            const err = 'You need to login first to continue this action.';
            callback(err);
            throw {
              response: {
                data: {
                  messages: [err]
                }
              }
            };
          } else {
            dispatch(setEFSTransactionsData(response.data.response.data ?? []));
            callback();
          }
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        parseResponseError(error, dispatch);
        callback(error);
      })
      .finally(() => {
        dispatch(setRequestBusy(false));
      });
  }));
};

export default companySettingsSlice.reducer;
