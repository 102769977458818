import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import EldSolutionsLogo  from '../../vendor/EldSolutionsLogo.png';
import { enableEldIntegration, disableEldIntegration } from '../../redux/slices/companySettings';
import eldTypes from "../../keys/eldTypes";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Typography,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";


const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);


const getInitialValues = () => ({
  accessToken: ''
});

function IntegrationsEld() {
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [showEldSolutionsModal, setShowEldSolutionsModal] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showDisableEldSolutionsModal, setShowDisableEldSolutionsModal] = useState(false);
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
 
  const validationSchema = Yup.object().shape({
    accessToken: Yup.string().required("Required").max(50,"Access Token must be at most 50 characters"),
  });

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const eldTypeKey = showEldSolutionsModal ? eldTypes.eldSolutions.key : null;
      dispatch(enableEldIntegration(values.accessToken, eldTypeKey, (err) => {
        setSubmitting(false);
        if (err) return;
        setShowEldSolutionsModal(false);
      }));
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="ELD Integrations" />

      <Typography variant="h3" gutterBottom display="inline">
        ELD Integrations
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} textAlign="center" style={{ paddingTop: 24 }}  >
          <Card mb={6}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    ELD Solutions
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link href="https://www.eldsolutions.com/" >www.eldsolutions.com</Link>
                  </Typography>
                  <Paper mt={4}>
                    <div style={{ paddingTop: 10 }}>
                    {
                      currentLoggedUser &&
                      currentLoggedUser.company &&
                      currentLoggedUser.company.eldSolutionsEnabled
                      ?
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setShowDisableEldSolutionsModal(true);
                          }}
                        >
                          Disable
                        </Button>
                      :
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowEldSolutionsModal(true);
                          }}
                        >
                          Setup
                        </Button>
                    }
                    </div>
                  </Paper>
                  </div>
                  <div style={{ flex: 1, marginLeft: 10, }}>
                    <img src={EldSolutionsLogo} style={{width: 90}} alt="" />
                  </div>
                </div>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
      
      <Dialog
        open={showEldSolutionsModal}
        onClose={() => setShowEldSolutionsModal(false)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} style={{width: 600}}>
              <DialogTitle>Setup ELD Solutions</DialogTitle>
              <DialogContent>
                <TextField
                  margin="dense"
                  name="accessToken"
                  label="Access Token*"
                  variant="outlined" 
                  fullWidth
                  value={values.accessToken}
                  error={Boolean(touched.accessToken && errors.accessToken)}
                  helperText={touched.accessToken && errors.accessToken}
                  onBlur={handleBlur}
                  onChange={handleChange}
                />
                
              </DialogContent>
              <DialogActions>
                <Button 
                  color="primary"
                  onClick={() => setShowEldSolutionsModal(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Save
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={showDisableEldSolutionsModal}
        onClose={() => setShowDisableEldSolutionsModal(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <div>
              ELD will stop working accross whole app. Are you sure you want to disable this integration?
            </div>
            <TextField
              type="password"
              name="password"
              label="Password"
              value={passwordConfirm}
              fullWidth
              onChange={e => setPasswordConfirm(e.target.value)}
              my={2}
            />
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary"
              onClick={() => setShowDisableEldSolutionsModal(false)}
            >
              Cancel
            </Button>
            <Button 
              color="error" 
              variant="contained"
              disabled={!passwordConfirm}
              onClick={() => {
                dispatch(disableEldIntegration({ password: passwordConfirm }, (err) => {
                  if (err) return;
                  setShowDisableEldSolutionsModal(false);
                }));
              }}
            >
              Disable
            </Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default IntegrationsEld;