import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useNavigate,
  useParams
} from 'react-router-dom';
import { 
  fetchCurrentSelectedTruckDriver,
  activateCurrentSelectedTruckDriver,
  deactivateCurrentSelectedTruckDriver,
  setCurrentSelectedTruckDriver
} from '../../redux/slices/truckDrivers';
import { 
  fetchUserFieldHistories,
  getReduxId,
  setFiltersSkip,
} from '../../redux/slices/userFieldHistories';
import {
  useDispatch,
  useSelector
} from 'react-redux';
import userRoles from "../../keys/userRoles";
import moment from 'moment';

import {
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
  Chip as MuiChip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  Paper,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { spacing, display } from "@mui/system";
import { green, red } from "@mui/material/colors";
import driverOperationTypes from "../../keys/driverOperationTypes";
import driverPaymentTypes from "../../keys/driverPaymentTypes";
import driverTypes from "../../keys/driverTypes";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';
import FilePreview from "../components/FilePreview";
import ModalTableList from "../components/ModalTableList";
import EFSLogo from '../../vendor/EFSLogo.svg';

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.nonActive && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;


const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function TruckDriverDetails() {
  const navigate = useNavigate();
  const currentSelectedTruckDriver = useSelector(state => state.truckDrivers.currentSelectedTruckDriver);
  const efsEnabled = useSelector(state => state?.currentLoggedUser?.company?.efsEnabled);
  const userFieldHistories = useSelector(state => state.userFieldHistories);
  const historyStack = useSelector(state => state.historyStack);
  const params = useParams();
  const dispatch = useDispatch();

  const [showDeactivateUserDialog, setShowDeactivateUserDialog] = useState(false);
  const [showActivateUserDialog, setShowActivateUserDialog] = useState(false);
  const [showHistoryDateModalTableList, setShowHistoryDateModalTableList] = useState(false);
  const [showDeactivatedAtModalTableList, setShowDeactivatedAtModalTableList] = useState(false);
  const [filePreviewData, setFilePreviewData] = useState(null);

  const userId = params && params.userId ? params.userId : null;
  const userFiledHistoryReduxIdHireDate = getReduxId({ userId, field: 'hireDate' });
  const userFiledHistoryReduxIdDeactivatedAt = getReduxId({ userId, field: 'deactivatedAt' });

  const userFieldHistoryHireDateState = userFieldHistories?.[userFiledHistoryReduxIdHireDate];
  const userFieldHistoryDeactivatedAtState = userFieldHistories?.[userFiledHistoryReduxIdDeactivatedAt];

  const openHistoryDateModalTableList = useCallback(() => {
    setShowHistoryDateModalTableList(true);
    if (!userFieldHistoryHireDateState || userFieldHistoryHireDateState?.data?.length === 0) dispatch(fetchUserFieldHistories({ userId, field: 'hireDate' }));
  }, [userFieldHistoryHireDateState, dispatch, userId]);

  const openDeactivatedAtModalTableList = useCallback(() => {
    setShowDeactivatedAtModalTableList(true);
    if (!userFieldHistoryDeactivatedAtState || userFieldHistoryDeactivatedAtState?.data?.length === 0) dispatch(fetchUserFieldHistories({ userId, field: 'deactivatedAt' }));
  }, [userFieldHistoryDeactivatedAtState, dispatch, userId]);

  useEffect(() => {
    if (userId) dispatch(fetchCurrentSelectedTruckDriver(userId));
  }, [userId]);

  let uploadedFiles = [];
  try {
    if (currentSelectedTruckDriver && currentSelectedTruckDriver.uploadedFiles) {
      if (typeof currentSelectedTruckDriver.uploadedFiles === 'string') {
        uploadedFiles = JSON.parse(currentSelectedTruckDriver.uploadedFiles);
      } else if (Array.isArray(currentSelectedTruckDriver.uploadedFiles)) {
        uploadedFiles = currentSelectedTruckDriver.uploadedFiles;
      }
    }
  } catch (e) {}

  return (
    <React.Fragment>
      <Helmet title="Truck Driver Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Truck Driver Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link 
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (historyStack[historyStack.length - 2]?.indexOf('truck-drivers') === -1) {
                  navigate('/truck-drivers', { replace: true });
                } else {
                  navigate(-1);
                }
              }}
            >
              Truck Drivers
            </Link>
            <Typography>Truck Driver Details</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>

      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        {
          efsEnabled &&
          currentSelectedTruckDriver && currentSelectedTruckDriver.efsCardNumber && (
            <Button 
              variant="outlined" 
              onClick={() => navigate(`/truck-drivers/truck-driver-details/${currentSelectedTruckDriver.id}/efs-transactions`)}
              size="small"
            >
              <img src={EFSLogo} style={{width: 50 , marginRight: 5, }} alt="" />
                Show Transactions
              </Button>
          )
        }
      </div>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              <Table>
                <TableBody>
                <TableRow>
                    <TableCell component="th" scope="row">
                      Role:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.role && userRoles[currentSelectedTruckDriver.role] && userRoles[currentSelectedTruckDriver.role].display ? userRoles[currentSelectedTruckDriver.role].display : ''}</TableCell>
                  </TableRow>
                <TableRow>
                    <TableCell>First Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.firstName ? currentSelectedTruckDriver.firstName : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                     Last Name:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.lastName ? currentSelectedTruckDriver.lastName : ''}</TableCell>
                  </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Email:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.email ? currentSelectedTruckDriver.email : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    SSN#:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.ssn ? currentSelectedTruckDriver.ssn : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Driver Licence#:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.driverLicence ? currentSelectedTruckDriver.driverLicence : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Licence State:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.licenceState ? currentSelectedTruckDriver.licenceState : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Licence Expiration:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.licenceExpiration ? moment(currentSelectedTruckDriver.licenceExpiration).format("MM/DD/YYYY") : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Years of Experience:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.yearsOfExperience ? currentSelectedTruckDriver.yearsOfExperience : ''}</TableCell>
                </TableRow>
                {
                  currentSelectedTruckDriver && currentSelectedTruckDriver.contractorEIN 
                  ? 
                    <TableRow>
                      <TableCell component="th" scope="row">
                      Contractor EIN:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedTruckDriver.contractorEIN}</TableCell>
                    </TableRow>
                  : 
                    ''
                }
                <TableRow>
                  <TableCell component="th" scope="row">
                    Hire Date:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <div style={{ position: 'relative' }}>
                      {currentSelectedTruckDriver && currentSelectedTruckDriver.hireDate ? moment(currentSelectedTruckDriver.hireDate).format("MM/DD/YYYY") : ''}
                      <div style={{ position: 'absolute', right: 5, top: -5 }}>
                        <Button 
                          onClick={openHistoryDateModalTableList}
                          variant="text"
                          color="primary"
                          size="small"
                        >
                          Show History
                        </Button>
                      </div>
                    </div>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                  Termination Date:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.terminationDate ? moment(currentSelectedTruckDriver.terminationDate).format("MM/DD/YYYY") : ''}</TableCell>
                </TableRow>
                {
                  currentSelectedTruckDriver && currentSelectedTruckDriver.address
                  ?
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Address:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver.address}</TableCell>
                  </TableRow>
                  :
                    ''
                }
                {
                  currentSelectedTruckDriver && currentSelectedTruckDriver.city
                  ?
                  <TableRow>
                    <TableCell component="th" scope="row">
                      City:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver.city}</TableCell>
                  </TableRow>
                  :
                    ''
                }
                {
                  currentSelectedTruckDriver && currentSelectedTruckDriver.state
                  ?
                  <TableRow>
                    <TableCell component="th" scope="row">
                      State:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver.state}</TableCell>
                  </TableRow>
                  :
                    ''
                }
                {
                  currentSelectedTruckDriver && currentSelectedTruckDriver.zip
                  ?
                  <TableRow>
                    <TableCell component="th" scope="row">
                      Zip:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver.zip}</TableCell>
                  </TableRow>
                  :
                    ''
                }
                <TableRow>
                  <TableCell component="th" scope="row">
                    Phone Number:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.phoneNumber ? currentSelectedTruckDriver.phoneNumber : ''} {currentSelectedTruckDriver && currentSelectedTruckDriver.phoneNumberExtension ? `Ext: ${currentSelectedTruckDriver.phoneNumberExtension}` : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Emergency Phone Number:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.emergencyPhoneNumber ? currentSelectedTruckDriver.emergencyPhoneNumber : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Emergency First Name:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.emergencyFirstName ? currentSelectedTruckDriver.emergencyFirstName : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Emergency Last Name:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.emergencyLastName ? currentSelectedTruckDriver.emergencyLastName : ''}</TableCell>
                </TableRow>
                {
                  currentSelectedTruckDriver && currentSelectedTruckDriver.emergencyRelationship
                  ?
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Emergency Relationship:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedTruckDriver.emergencyRelationship}</TableCell>
                    </TableRow>
                  :
                    ''
                }
                <TableRow>
                  <TableCell component="th" scope="row">
                    Driver Type:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.driverType ? driverTypes[currentSelectedTruckDriver.driverType].display : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Payment Type:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.driverPaymentType ? driverPaymentTypes[currentSelectedTruckDriver.driverPaymentType].display : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Payment:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    <Link to={""} component={NavLink} onClick={() => {
                      window.setTimeout(() => navigate(`/accounting/payment-types`, { state: { redirectFromTruckDriverDetails: true } }), 0);
                    }}>
                      {currentSelectedTruckDriver && currentSelectedTruckDriver.accountingPaymentType ? currentSelectedTruckDriver.accountingPaymentType.description : ''}
                    </Link>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Operation Type:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.driverOperationType ? driverOperationTypes[currentSelectedTruckDriver.driverOperationType].display : ''}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Truck:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    {
                      currentSelectedTruckDriver && 
                      currentSelectedTruckDriver.driverSelectedTruck && 
                      currentSelectedTruckDriver.driverSelectedTruck.equipmentNumber 
                      ? 
                        <Link to={""} component={NavLink} onClick={() => {
                          dispatch(setCurrentSelectedTruckDriver(currentSelectedTruckDriver.driverSelectedTruck));
                          window.setTimeout(() => navigate(`/equipment/equipment-details/${currentSelectedTruckDriver.driverSelectedTruckId}`, { state: { readOnlyFromTruckDriver: true } }), 0);
                        }}>
                          {currentSelectedTruckDriver.driverSelectedTruck.equipmentNumber}
                        </Link>  
                      : 
                        ''
                    }
                    {
                      currentSelectedTruckDriver && 
                      currentSelectedTruckDriver.driverSelectedTruck && 
                      currentSelectedTruckDriver.driverSelectedTruck.model 
                      ? 
                        ', ' + currentSelectedTruckDriver.driverSelectedTruck.model 
                      : 
                        ''
                    }
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Trailer:
                  </TableCell>
                  <TableCell></TableCell>
                  <TableCell align="center">
                    {
                      currentSelectedTruckDriver && 
                      currentSelectedTruckDriver.driverSelectedTrailer && 
                      currentSelectedTruckDriver.driverSelectedTrailer.equipmentNumber 
                      ? 
                        <Link to={""} component={NavLink} onClick={() => {
                          dispatch(setCurrentSelectedTruckDriver(currentSelectedTruckDriver.driverSelectedTrailer));
                          window.setTimeout(() => navigate(`/equipment/equipment-details/${currentSelectedTruckDriver.driverSelectedTrailerId}`, { state: {readOnlyFromTruckDriver: true } }), 0);
                        }}>
                          {currentSelectedTruckDriver.driverSelectedTrailer.equipmentNumber}
                        </Link>  
                      : 
                        ''
                    }
                    {
                      currentSelectedTruckDriver && 
                      currentSelectedTruckDriver.driverSelectedTrailer && 
                      currentSelectedTruckDriver.driverSelectedTrailer.model 
                      ? 
                        ', ' + currentSelectedTruckDriver.driverSelectedTrailer.model 
                      : 
                        ''
                    }
                  </TableCell>
                </TableRow>
                  <TableRow>
                    <TableCell>Status:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                    {currentSelectedTruckDriver && currentSelectedTruckDriver.isActive && (
                          <Chip
                            
                            size="small"
                            
                            label="Active"
                            active={+true}
                          />
                        )}
                     {currentSelectedTruckDriver && !currentSelectedTruckDriver.isActive && (
                          <Chip
                            size="small"
                            
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Login Access:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                    {currentSelectedTruckDriver && currentSelectedTruckDriver.loginAccess && (
                          <Chip
                            
                            size="small"
                            
                            label="Active"
                            active={+true}
                          />
                        )}
                     {currentSelectedTruckDriver && !currentSelectedTruckDriver.loginAccess && (
                          <Chip
                            size="small"
                            
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                  {
                    currentSelectedTruckDriver?.deactivatedAt &&
                    <TableRow>
                      <TableCell component="th" scope="row">
                        Deactivated At:
                      </TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">
                        <div style={{ position: 'relative' }}>
                          {moment(currentSelectedTruckDriver.deactivatedAt).format("MM/DD/YYYY")}
                          <div style={{ position: 'absolute', right: 5, top: -5 }}>
                            <Button 
                              onClick={openDeactivatedAtModalTableList}
                              variant="text"
                              color="primary"
                              size="small"
                            >
                              Show History
                            </Button>
                          </div>
                        </div>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    currentSelectedTruckDriver && currentSelectedTruckDriver.eldDriverId && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                        ELD Driver ID:
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{currentSelectedTruckDriver.eldDriverId}</TableCell>
                      </TableRow>
                    )
                  }
                  {
                    efsEnabled &&
                    currentSelectedTruckDriver && currentSelectedTruckDriver.efsCardNumber && (
                      <TableRow>
                        <TableCell component="th" scope="row">
                        EFS Card Number:
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">
                          {currentSelectedTruckDriver.efsCardNumber}
                          <div style={{ position: 'relative' }}>
                            <div style={{ position: 'absolute', right: 5, top: -23 }}>
                              <Button 
                                onClick={() => navigate(`/truck-drivers/truck-driver-details/${currentSelectedTruckDriver.id}/efs-transactions`)}
                                variant="text"
                                color="primary"
                                size="small"
                              >
                                Show Transactions
                              </Button>
                            </div>
                          </div>
                        </TableCell>
                      </TableRow>
                    )
                  }
                  <TableRow>
                    <TableCell component="th" scope="row">
                    Last MVR:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.lastMVR ? moment(currentSelectedTruckDriver.lastMVR).format("MM/DD/YYYY") : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                    Drug Test Date:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.drugTestDate ? moment(currentSelectedTruckDriver.drugTestDate).format("MM/DD/YYYY") : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell component="th" scope="row">
                    Medical Card Expiration:
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedTruckDriver && currentSelectedTruckDriver.medicalCardExpiration ? moment(currentSelectedTruckDriver.medicalCardExpiration).format("MM/DD/YYYY") : ''}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {
                uploadedFiles.map((file, index) => (
                  <Paper key={index} style={{ padding: 15, backgroundColor: '#eee', width: '50%', marginTop: 15 }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ marginRight: 10 }}>
                        {file.name}
                      </div>
                      <div style={{ display: 'flex' }}>
                        <div>
                          <IconButton onClick={() => {
                            setFilePreviewData(() => ({
                              url: file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : '',
                              type: file && file.urls && file.urls.file ? 'pdf' : 'image'
                            }));
                          }}>
                            <RemoveRedEyeIcon />
                          </IconButton>
                        </div>
                        <div>
                          <IconButton onClick={() => {
                            let url = file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : null;
                            if (!url) return;
                            if (file.name) url += `&fileName=${file.name}`;
                            const a = document.createElement('a');
                            a.href = url;
                            a.download = file.name;
                            document.body.appendChild(a);
                            a.click();
                            document.body.removeChild(a);
                          }}>
                            <DownloadIcon />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </Paper>
                ))
              }
              <div style={{display: "flex", justifyContent: "space-between"}}>
              <Button onClick={() => navigate(`/truck-drivers/edit/${userId}`)}
                    marginBottom={5}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    <Icon.Edit />
                    <span style={{marginLeft:5}}>Edit</span>
                </Button>
                {
                  currentSelectedTruckDriver && 
                  (
                    currentSelectedTruckDriver.loginAccess ||
                    currentSelectedTruckDriver.isActive 
                  )
                  ?
                    <Button 
                      variant="outlined" 
                      color="primary"
                      marginBottom={5}
                      mt={3}
                      marginLeft={5}
                      onClick={() => setShowDeactivateUserDialog(true)}
                    >
                      <Icon.Close />
                        <span style={{marginLeft:5}}> Deactivate </span>
                    </Button>
                  :
                    <Button 
                      variant="outlined" 
                      color="primary"
                      marginBottom={5}
                      mt={3}
                      marginLeft={5}
                      onClick={() => setShowActivateUserDialog(true)}
                    >
                      <Icon.Check />
                        <span style={{marginLeft:5}}> Activate </span>
                    </Button>
                }
                </div>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <Dialog
        open={showDeactivateUserDialog}
        onClose={() => setShowDeactivateUserDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowDeactivateUserDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(deactivateCurrentSelectedTruckDriver(err => {
                  if (err) return;
                  setShowDeactivateUserDialog(false);
                }
              ));
            }} 
          >
            Yes, deactivate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showActivateUserDialog}
        onClose={() => setShowActivateUserDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate this User?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowActivateUserDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(activateCurrentSelectedTruckDriver(err => {
                  if (err) return;
                  setShowActivateUserDialog(false);
                }
              ));
            }} 
          >
            Yes, activate
          </Button>
        </DialogActions>
      </Dialog>
      {
        showHistoryDateModalTableList &&
        <ModalTableList
          title="Hire Date History"
          openModal={showHistoryDateModalTableList}
          columns={["First Name", "Last Name", "Hire Date"]}
          data={
            userFieldHistoryHireDateState?.data 
            ? 
              userFieldHistoryHireDateState.data.map(item => ({ 
                id: item.id, 
                item: [item?.user?.firstName, item?.user?.lastName, item?.hireDate ? moment(item.hireDate ).format("MM/DD/YYYY") : '']
              })) 
            : 
              []
          }
          handleClose={() => setShowHistoryDateModalTableList(false)}
          loading={userFieldHistoryHireDateState?.requestBusy}
          handleLoadMore={() => {
            dispatch(setFiltersSkip({ reduxId: userFiledHistoryReduxIdHireDate, skip: userFieldHistoryHireDateState?.filters?.skip + userFieldHistoryHireDateState?.filters?.limit }));
            dispatch(fetchUserFieldHistories({ userId, field: 'hireDate' }));
          }}
        />
      }
      {
        showDeactivatedAtModalTableList &&
        <ModalTableList
          title="Deactivated History"
          openModal={showDeactivatedAtModalTableList}
          columns={["First Name", "Last Name", "Deactivated Date"]}
          data={
            userFieldHistoryDeactivatedAtState?.data 
            ? 
            userFieldHistoryDeactivatedAtState.data.map(item => ({ 
                id: item.id, 
                item: [item?.user?.firstName, item?.user?.lastName, item?.deactivatedAt ? moment(item.deactivatedAt).format("MM/DD/YYYY") : '']
              })) 
            : 
              []
          }
          handleClose={() => setShowDeactivatedAtModalTableList(false)}
          loading={userFieldHistoryDeactivatedAtState?.requestBusy}
          handleLoadMore={() => {
            dispatch(setFiltersSkip({ reduxId: userFiledHistoryReduxIdDeactivatedAt, skip: userFieldHistoryDeactivatedAtState?.filters?.skip + userFieldHistoryDeactivatedAtState?.filters?.limit }));
            dispatch(fetchUserFieldHistories({ userId, field: 'deactivatedAt' }));
          }}
        />
      }
      { filePreviewData ? <FilePreview url={filePreviewData.url} type={filePreviewData.type} onClose={() => setFilePreviewData(null)} /> : '' }
    </React.Fragment>
  );
}

export default TruckDriverDetails;