import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  setEFSForceLogin,
  attemptEFSLogin
} from '../../redux/slices/companySettings';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField
} from "@mui/material";

const EFSLogin = ({ children }) => {

  const companySettings = useSelector(state => state.companySettings);
  const requestBusy = useSelector(state => state.requestBusy);
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const forceLogin = companySettings?.integrations?.fuelCards?.EFS?.forceLogin ?? false;
  const dispatch = useDispatch();

  const closeModal = () => {
    dispatch(setEFSForceLogin(false));
  };

  const handleLogin = () => {
    if (!user || !password) return;
    dispatch(attemptEFSLogin({ user, password }, (err) => {
      if (err) setPassword('');
    }));
  };

  return (
    <>
      {children}
      {
        forceLogin &&
        <Dialog
          open={forceLogin}
          onClose={closeModal}
        >
          <DialogTitle>EFS Login</DialogTitle>
          <DialogContent>
            <div style={{ marginBottom: 10 }}>
              Login to access EFS sections of the app. We might ask you again to enter your credentials if session expire. <span style={{ fontStyle: 'italic' }}>We do not store your credentials.</span>
            </div>
            <div>
              <TextField
                type="text"
                label="Username"
                value={user}
                fullWidth
                onChange={(e) => setUser(e?.target?.value ?? '')}
                style={{ marginBottom: 10 }}
              />
              <TextField
                type="password"
                label="Password"
                value={password}
                fullWidth
                onChange={(e) => setPassword(e?.target?.value ?? '')}
              />
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              variant="text"
              color="primary"
              onClick={closeModal}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              disabled={requestBusy || !user || !password}
              onClick={handleLogin}
            >
              Sign in
            </Button>
          </DialogActions>
        </Dialog>
      }
    </>
  );

}

export default EFSLogin;
