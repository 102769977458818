import axios from '../axios/config';

export const getDirections = (params = {}) => (
  new Promise((resolve) => {
    const originPlaceId = params.originPlaceId;
    const waypointsPlacesIds = params.waypointsPlacesIds ? params.waypointsPlacesIds : [];
    const destinationPlaceId = params.destinationPlaceId;

    if (!originPlaceId || !destinationPlaceId) return resolve();

    const queryParams = new URLSearchParams();
    queryParams.append('origin', `place_id:${originPlaceId}`);
    if (waypointsPlacesIds.length) {
      let wayPoints = '';
      waypointsPlacesIds.forEach((id, index) => {
        let stringParam = `place_id:${id}|`;
        if (index === (waypointsPlacesIds.length - 1)) stringParam = stringParam.slice(0, -1);
        wayPoints += stringParam;
      });
      queryParams.append('waypoints', wayPoints);
    }
    queryParams.append('destination', `place_id:${destinationPlaceId}`);
    axios.get(`/api/loads/get-directions?${queryParams.toString()}`)
      .then(res => resolve(res?.data?.data))
      .catch(resolve)   
  })
)