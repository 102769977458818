export default {
  perMile: {
    key: 'perMile',
    display: 'Per Mile'
  },
  percent: {
    key: 'percent',
    display: 'Percent'
  }
};
