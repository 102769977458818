import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate,
  useLocation
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import { 
  fetchCurrentSelectedEquipment,
  activateCurrentSelectedEquipment,
  deactivateCurrentSelectedEquipment
} from '../../redux/slices/equipments';
import equipmentTypes from '../../keys/equipmentTypes';
import trailerTypes from '../../keys/trailerTypes';
import moment from 'moment';

import {
  Chip as MuiChip,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { green, red } from "@mui/material/colors";
import { spacing, display} from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.nonActive && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;
const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function EquipmentDetails() {
  const navigate = useNavigate();
  const historyLocation = useLocation();
  const currentSelectedEquipment = useSelector(state => state.equipments.currentSelectedEquipment);
  const historyStack = useSelector(state => state.historyStack);
  const params = useParams();
  const dispatch = useDispatch();

  const [showDeactivateEquipmentDialog, setShowDeactivateEquipmentDialog] = useState(false);
  const [showActivateEquipmentDialog, setShowActivateEquipmentDialog] = useState(false);

  const equipmentId = params && params.equipmentId ? params.equipmentId : null;
  const readOnlyFromTruckDriver = historyLocation && historyLocation.state && historyLocation.state.readOnlyFromTruckDriver ? true : false;

  useEffect(() => {
    if (equipmentId) dispatch(fetchCurrentSelectedEquipment(equipmentId));
  }, [equipmentId]);

  return (
    <React.Fragment>
      <Helmet title="Equipment Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Equipment Details
          </Typography>
          {
            readOnlyFromTruckDriver
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Truck Drivers
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Truck Driver Details
                </Link>
                <Typography>Equipment Details</Typography>
              </Breadcrumbs>
            :
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link 
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    if (historyStack[historyStack.length - 2]?.indexOf('equipment') === -1) {
                      navigate('/equipment', { replace: true });
                    } else {
                      navigate(-1);
                    }
                  }}
                >
                  Equipment
                </Link>
                
                <Typography>Equipment Details</Typography>
              </Breadcrumbs>
          }
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.id ? currentSelectedEquipment.id : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Type:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.type ? equipmentTypes[currentSelectedEquipment.type].display : ''}</TableCell>
                  </TableRow>
                {
                  currentSelectedEquipment && currentSelectedEquipment.type === equipmentTypes.truck.key
                  ?
                  <>
                    <TableRow>
                      <TableCell>Equipment Number:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.equipmentNumber ? currentSelectedEquipment.equipmentNumber : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Year:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.year ? currentSelectedEquipment.year : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Make:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.make ? currentSelectedEquipment.make : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Model:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.model ? currentSelectedEquipment.model : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>VIN:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.vin ? currentSelectedEquipment.vin : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Licence Plate Number:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.licencePlateNumber ? currentSelectedEquipment.licencePlateNumber : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Licence Plate Expiration:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.licencePlateExpiration ? moment(currentSelectedEquipment.licencePlateExpiration).format("MM/DD/YYYY") : ''}</TableCell>
                    </TableRow>
                    {
                      currentSelectedEquipment?.inspectionStickerExpiration &&
                      <TableRow>
                        <TableCell>Inspection Sticker Expiration:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{moment(currentSelectedEquipment.inspectionStickerExpiration).format("MM/DD/YYYY")}</TableCell>
                      </TableRow>
                    }
                    {
                      currentSelectedEquipment?.oilChangeExpiration &&
                      <TableRow>
                        <TableCell>Oil Change Expiration:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{moment(currentSelectedEquipment.oilChangeExpiration).format("MM/DD/YYYY")}</TableCell>
                      </TableRow>
                    }
                    <TableRow>
                      <TableCell>Year Purchased:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.yearPurchased ? currentSelectedEquipment.yearPurchased : ''}</TableCell>
                    </TableRow>
                    {
                      currentSelectedEquipment && currentSelectedEquipment.purchasedPrice
                      ?
                      <TableRow>
                        <TableCell>Purchased Price:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{currentSelectedEquipment.purchasedPrice}</TableCell>
                      </TableRow>
                      :
                      ''
                    }
                    <TableRow>
                      <TableCell>Odometer:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.odometer ? currentSelectedEquipment.odometer : ''}</TableCell>
                    </TableRow>
                    {
                      currentSelectedEquipment && currentSelectedEquipment.description
                      ?
                      <TableRow>
                        <TableCell>Description:</TableCell>
                        <TableCell></TableCell>
                        <TableCell align="center">{currentSelectedEquipment.description}</TableCell>
                      </TableRow>
                      :
                      ''
                    }
                  </>
                  :
                  ''
                }
                {
                  currentSelectedEquipment && currentSelectedEquipment.type === equipmentTypes.trailer.key
                  ?
                  <>
                    <TableRow>
                      <TableCell>Trailer Type:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.trailerType ? trailerTypes[currentSelectedEquipment.trailerType].display : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Equipment Number:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.equipmentNumber ? currentSelectedEquipment.equipmentNumber : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Year:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.year ? currentSelectedEquipment.year : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Make:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.make ? currentSelectedEquipment.make : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Model:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.model ? currentSelectedEquipment.model : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>VIN:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.vin ? currentSelectedEquipment.vin : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Licence Plate Number:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.licencePlateNumber ? currentSelectedEquipment.licencePlateNumber : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Licence Plate Expiration:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.licencePlateExpiration ? moment(currentSelectedEquipment.licencePlateExpiration).format("MM/DD/YYYY") : ''}</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Inspection Sticker Expiration:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedEquipment && currentSelectedEquipment.inspectionStickerExpiration ? moment(currentSelectedEquipment.inspectionStickerExpiration).format("MM/DD/YYYY") : ''}</TableCell>
                    </TableRow>
                  </>
                  :
                  ''

                }
                  <TableRow>
                    <TableCell>Status:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">
                    {currentSelectedEquipment && currentSelectedEquipment.isActive && (
                          <Chip
                            
                            size="small"
                            
                            label="Active"
                            active={+true}
                          />
                        )}
                      {currentSelectedEquipment && !currentSelectedEquipment.isActive && (
                          <Chip
                            size="small"
                            
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {
                currentSelectedEquipment && currentSelectedEquipment.notes
                ?
                <div style={{ width: '100%' }}>
                  <div style={{ padding: 15, width: '100%' }}>
                    <div>Notes:</div>
                    <div>{currentSelectedEquipment.notes}</div>
                  </div>
                </div>
                :
                ''
              }
              {
                !readOnlyFromTruckDriver
                ?
                <div style={{display: "flex", justifyContent: "space-between"}}>
                  <Button 
                    onClick={() => {
                      navigate(`/equipment/edit/${currentSelectedEquipment && currentSelectedEquipment.id ? currentSelectedEquipment.id : ''}`);
                    }}
                    marginBottom={5}
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                    >
                      <Icon.Edit />
                    <span style={{marginLeft:5}}> Edit </span>
                  </Button>
                  {
                      currentSelectedEquipment && 
                      currentSelectedEquipment.isActive 
                      ?
                        <Button 
                          variant="outlined" 
                          color="primary"
                          marginBottom={5}
                          mt={3}
                          marginLeft={5}
                          onClick={() => setShowDeactivateEquipmentDialog(true)}
                        >
                          <Icon.Close />
                            <span style={{marginLeft:5}}> Deactivate </span>
                        </Button>
                      :
                        <Button 
                          variant="outlined" 
                          color="primary"
                          marginBottom={5}
                          mt={3}
                          marginLeft={5}
                          onClick={() => setShowActivateEquipmentDialog(true)}
                        >
                          <Icon.Check />
                            <span style={{marginLeft:5}}> Activate </span>
                        </Button>
                    }
                  </div>
                :
                  <div style={{ marginBottom: 20 }}></div>
              }
            </Card>
          </Shadow>
        </Grid>
      </Grid>
      <Dialog
        open={showDeactivateEquipmentDialog}
        onClose={() => setShowDeactivateEquipmentDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to deactivate this Equipment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowDeactivateEquipmentDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(deactivateCurrentSelectedEquipment(err => {
                  if (err) return;
                  setShowDeactivateEquipmentDialog(false);
                }
              ));
            }} 
          >
            Yes, deactivate
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={showActivateEquipmentDialog}
        onClose={() => setShowActivateEquipmentDialog(false)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to activate this Equipment?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setShowActivateEquipmentDialog(false)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            onClick={() => {
              dispatch(activateCurrentSelectedEquipment(err => {
                  if (err) return;
                  setShowActivateEquipmentDialog(false);
                }
              ));
            }} 
          >
            Yes, activate
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default EquipmentDetails;