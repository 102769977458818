import React from "react";

import AuthLayout from "./layouts/Auth";
import DashboardLayout from "./layouts/Dashboard";
import Login from "./pages/auth/Login";
import ResetPassword from "./pages/auth/ResetPassword";
import Companies from "./pages/pages/Companies";
import Dashboard from "./pages/pages/Dashboard";
import AddCompanies from "./pages/pages/AddCompanies";
import CompanyAdminUsers from "./pages/pages/CompanyAdminUsers";
import AddCompanyAdminUser from "./pages/pages/AddCompanyAdminUser";
import CompanyDetails from "./pages/pages/CompanyDetails";
import CompanyAdminDetails from "./pages/pages/CompanyAdminDetails";
import Users from "./pages/pages/Users";
import AddUser from "./pages/pages/AddUser";
import UserDetails from './pages/pages/UserDetails';
import TruckDrivers from './pages/pages/TruckDrivers';
import AddTruckDriver from './pages/pages/AddTruckDriver';
import TruckDriverDetails from './pages/pages/TruckDriverDetails';
import Equipments from './pages/pages/Equipments';
import AddEquipment from './pages/pages/AddEquipment';
import EquipmentDetails from './pages/pages/EquipmentDetails';
import SetNewPassword from "./pages/auth/SetNewPassword";
import CompanyCustomers from './pages/pages/CompanyCustomers';
import AddCompanyCustomer from './pages/pages/AddCompanyCustomer';
import CompanyCustomerDetails from './pages/pages/CompanyCustomerDetails';
import CompanyLocations from './pages/pages/CompanyLocations';
import CompanyLocationDetails from './pages/pages/CompanyLocationDetails';
import AddCompanyLocation from './pages/pages/AddCompanyLocation';
import AccountingPaymentTypes from './pages/pages/AccountingPaymentTypes';
import IntegrationsEld from './pages/pages/IntegrationsEld';
import IntegrationsBilling from './pages/pages/IntegrationsBilling';
import IntegrationsAccounting from './pages/pages/IntegrationsAccounting';
import IntegationsFuelCard from './pages/pages/IntegationsFuelCard';
import CompanyAdminCompanySettings from './pages/pages/CompanyAdminCompanySettings';
import TruckDriverEfsTransactions from './pages/pages/TruckDriverEfsTransactions';
import TruckDriverEfsTransactionDetails from "./pages/pages/TruckDriverEfsTransactionDetails";
import SetupBillingRequired from './components/HOC/SetupBillingRequired';
import Invoices from './pages/pages/Invoices';
import Billing from './pages/pages/Billing';
import BillingDetails from "./pages/pages/BillingDetails";
import Profile from './pages/pages/Profile';
import Loads from './pages/pages/Loads';
import DriversMap from './pages/pages/DriversMap';
import AddLoad from './pages/pages/AddLoad';
import LoadDetails from './pages/pages/LoadDetails';
import InvoiceDetails from './pages/pages/InvoiceDetails';
import TwoFactorAuthentication from './pages/auth/TwoFactorAuthentication';
import ResetTwoFactorAuthentication from './pages/auth/ResetTwoFactorAuthentication';
import Page404 from "./pages/auth/Page404";

const routes = [
  {
    path: '/',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Dashboard />,
      },
    ],
  },
  {
    path: '/companies',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Companies />,
      },
      {
        path: "/companies/add",
        element: <AddCompanies />,
      },
      {
        path: "/companies/edit/:companyId",
        element: <AddCompanies isEdit={true} />,
      },
      {
        path: "/companies/company-details/:companyId",
        element: <CompanyDetails />,
      },
      {
        path: "/companies/company-details/:companyId/admin-users",
        element: <CompanyAdminUsers />,
      },
      {
        path: "/companies/company-details/:companyId/billing",
        element: <Billing />,
      },
      {
        path: "/companies/company-details/:companyId/admin-users/admin-details/:userId",
        element: <CompanyAdminDetails />,
      },
      {
        path: "/companies/company-details/:companyId/billing/invoice-details/:billingId",
        element: <BillingDetails />,
      },
      {
        path: "/companies/company-details/:companyId/admin-users/add-company-admin-user",
        element: <AddCompanyAdminUser/>,
      },
      {
        path: "/companies/company-details/:companyId/admin-users/edit/:userId",
        element: <AddCompanyAdminUser isEdit={true} />,
      },
    ],
  },
  {
    path: '/users',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Users />,
      },
      {
        path: "/users/add",
        element: <AddUser />,
      },
      {
        path: "/users/user-details/:userId",
        element: <UserDetails />,
      },
      {
        path: "/users/edit/:userId",
        element: <AddUser isEdit={true} />,
      },
    ],
  },
  {
    path: '/truck-drivers',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <TruckDrivers />,
      },
      {
        path: "/truck-drivers/add",
        element: <AddTruckDriver />,
      },
      {
        path: "/truck-drivers/truck-driver-details/:userId",
        element: <TruckDriverDetails />,
      },
      {
        path: "/truck-drivers/edit/:userId",
        element: <AddTruckDriver isEdit={true} />,
      },
      {
        path: "/truck-drivers/truck-driver-details/:userId/efs-transactions",
        element: <TruckDriverEfsTransactions />,
      },
      {
        path: "/truck-drivers/truck-driver-details/:userId/efs-transactions/efs-transaction-details",
        element: <TruckDriverEfsTransactionDetails />,
      },
    ],
  },
  {
    path: '/loads',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Loads />,
      },
      {
        path: "/loads/add",
        element: (
          <SetupBillingRequired>
            <AddLoad />
          </SetupBillingRequired>
        ),
      },
      {
        path: "/loads/load-details/:loadId",
        element: <LoadDetails />,
      },
      {
        path: "/loads/edit/:loadId",
        element: <AddLoad isEdit={true} />,
      },
    ],
  },
  {
    path: '/drivers-map',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <DriversMap />,
      }
    ],
  },
  {
    path: '/equipment',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Equipments />,
      },
      {
        path: "/equipment/add",
        element: <AddEquipment />,
      },
      {
        path: "/equipment/equipment-details/:equipmentId",
        element: <EquipmentDetails />,
      },
      {
        path: "/equipment/edit/:equipmentId",
        element: <AddEquipment isEdit={true} />,
      },
    ],
  },
  {
    path: '/company-customers',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <CompanyCustomers />,
      },
      {
        path: "/company-customers/add",
        element: <AddCompanyCustomer />,
      },
      {
        path: "/company-customers/company-customer-details/:companyCustomerId",
        element: <CompanyCustomerDetails />,
      },
      {
        path: "/company-customers/edit/:companyCustomerId",
        element: <AddCompanyCustomer isEdit={true} />,
      },
    ],
  },
  {
    path: '/company-locations',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <CompanyLocations />,
      },
      {
        path: "/company-locations/add",
        element: <AddCompanyLocation />,
      },
      {
        path: "/company-locations/company-location-details/:companyLocationId",
        element: <CompanyLocationDetails />,
      },
      {
        path: "/company-locations/edit/:companyLocationId",
        element: <AddCompanyLocation isEdit={true} />,
      },
    ],
  },
  {
    path: '/accounting',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "/accounting/payment-types",
        element: <AccountingPaymentTypes />,
      },
      {
        path: "/accounting/invoices",
        element: <Invoices />,
      },
      {
        path: "/accounting/invoices/invoice-details/:loadId",
        element: <InvoiceDetails />,
      },
    ],
  },
  {
    path: '/billing',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Billing />,
      },
      {
        path: "/billing/invoice-details/:billingId",
        element: <BillingDetails />,
      },
    ],
  },
  {
    path: '/settings',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "/settings/eld-integrations",
        element: <IntegrationsEld />,
      },
      {
        path: "/settings/billing-integrations",
        element: <IntegrationsBilling />,
      },
      {
        path: "/settings/accounting-integrations",
        element: <IntegrationsAccounting />,
      },
      {
        path: "/settings/fuel-card-integrations",
        element: <IntegationsFuelCard />,
      },
      {
        path: "/settings/company-settings",
        element: <CompanyAdminCompanySettings />,
      },
    ],
  },
  {
    path: '/dashboard',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "/dashboard",
        element: <Dashboard />,
      },
    ],
  },
  { 
    path: '/login',
    element: (
      <AuthLayout />
      ),
      children: [
        {
          path:"",
          element: <Login />,
        },
      ],
  },
  {
    path: '/profile',
    element: (
      <DashboardLayout />
    ),
    children: [
      {
        path: "",
        element: <Profile />,
      },
    ],
  },
  { 
    path: '/reset-password',
    element: (
      <AuthLayout />
      ),
      children: [
        {
          path:"",
          element: <ResetPassword />,
        },
      ],
  },
  { 
    path: '/force-change-password',
    element: (
      <AuthLayout />
      ),
      children: [
        {
          path:"",
          element: <SetNewPassword />,
        },
      ],
  },
  {
    path: '/two-factor-authentication',
    element: (
      <AuthLayout />
      ),
      children: [
        {
          path:"",
          element: <TwoFactorAuthentication />,
        },
      ],
  },
  {
    path: '/reset-2fa',
    element: (
      <AuthLayout />
      ),
      children: [
        {
          path:"",
          element: <ResetTwoFactorAuthentication />,
        },
      ],
  },
  {
    path: "*",
    element: <AuthLayout />,
    children: [
      {
        path: "*",
        element: <Page404 />,
      },
    ],
  },
];


export default routes;
