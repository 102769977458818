import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { refreshToken } from './currentLoggedUser';

const getSingleUserInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
  },
  total: 0,
  initialFetch: false,
  requestBusy: false
});

const getInitialState = () => ({});

export const getReduxId = ({ userId, field }) => `${userId}_${field ?? ''}`;

export const userFieldHistoriesSlice = createSlice({
  name: "userFieldHistories",
  initialState: getInitialState(),
  reducers: {
    setInitialUserState: (state, action) => {
      state[action.payload.reduxId] = getSingleUserInitialState();
      return state;
    },
    setRequestBusy: (state, action) => {
      state[action.payload.reduxId].requestBusy = action.payload.requestBusy;
      return state;
    },
    concatData: (state, action) => {
      state[action.payload.reduxId].data = state[action.payload.reduxId].data.concat(action.payload.data);
      return state;
    },
    setTotal: (state, action) => {
      state[action.payload.reduxId].total = action.payload.total
      return state;
    },
    setFiltersLimit: (state, action) => {
      state[action.payload.reduxId].filters.limit = action.payload.limit;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state[action.payload.reduxId].filters.skip = action.payload.skip;
      return state;
    },
    setInitialFetch: (state, action) => {
      state[action.payload.reduxId].initialFetch = action.payload.initialFetch;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setInitialUserState,
  setRequestBusy,
  concatData, 
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setInitialFetch,
  resetState
} = userFieldHistoriesSlice.actions;

export const fetchUserFieldHistories = ({ userId, field }) => (dispatch, getState) => {
  if (!userId) return;

  const reduxId = getReduxId({ userId, field });

  if (!getState()?.userFieldHistories?.[reduxId]) dispatch(setInitialUserState({ reduxId }));
  const { userFieldHistories } = getState();
  const userFieldHistory = userFieldHistories[reduxId];
  if (userFieldHistory.requestBusy) return;

  const filters = userFieldHistory.filters ?? {};
  dispatch(setRequestBusy({ reduxId, requestBusy: true }));

  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  queryParams.append('userId', userId);
  if (field) queryParams.append('field', field);
  
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy({ reduxId, requestBusy: false }));
  
    axios.get(`/api/user-field-histories?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(concatData({ reduxId, data: response.data.data.rows }))
          dispatch(setTotal({ reduxId, total: response.data.data.total }));
          dispatch(setInitialFetch({ reduxId, initialFetch: true }));
        } else {
          throw new Error('');
        }
      })
      .catch()
      .finally(() => dispatch(setRequestBusy({ reduxId, requestBusy: false })));

  }));
};

export default userFieldHistoriesSlice.reducer;
