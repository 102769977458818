import React, { useCallback, useEffect, useRef } from "react";
import styled from "styled-components/macro";
import timeHelper from "../../../utils/timeHelper";

import {
  Box,
  Card,
  TextField as MuiTextField,
  Typography,
  Avatar,
  Breadcrumbs as MuiBreadcrumbs,
  Divider as MuiDivider,
} from "@mui/material";
import { spacing } from "@mui/system";
import { useSelector } from "react-redux";
import loadEventTypes from "../../../keys/loadEventTypes";
import loadStatusTypes from "../../../keys/loadStatusTypes"


const Divider = styled(MuiDivider)(spacing);

const ChatContainer = styled.div`
  width: 100%;
  height: 490px;
`;

const ChatMessages = styled.div`
  overflow-y: scroll;
  height: 490px;
`;

const ChatMessage = styled.div`
  margin: 30px;
  text-align: center;
`;

const ChatMessageInner = styled.div`
  display: inline-block;
`;

const ChatMessageTime = styled(Typography)`
  text-align: right;
  opacity: 0.8;
`;

const ChatMessageAvatar = styled(Avatar)`
  position: relative;
  width: 34px;
  height: 34px;
  bottom: 5px;
  margin-right: ${(props) => props.theme.spacing(2)};
`;

const ChatMessageBubble = styled.div`
  display: inline-block;
  margin-right: auto;
  background: ${(props) =>
    props.highlighted
      ? props.theme.palette.secondary.main
      : props.theme.palette.action.hover};
  color: ${(props) =>
    props.highlighted
      ? props.theme.palette.common.white
      : props.theme.palette.text.primary};
  border-radius: 3px;
  padding: ${(props) => props.theme.spacing(2)};
  margin-bottom: ${(props) => props.theme.spacing(.5)};
  ${(props) => props.theme.shadows[1]};
  width: 220px;
`;

const ChatMessageBubbleName = styled(Typography)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`;

function ChatMessageComponent({
  name,
  message,
  time,
  avatar,
  position = "left",
}) {
  return (
    <ChatMessage position={position}>
      <ChatMessageInner>
        <ChatMessageAvatar alt="Lucy Lavender" src={avatar} />
        <ChatMessageBubble highlighted={position === "right"}>
          <Box>
            <ChatMessageBubbleName variant="body1">
              {name}
            </ChatMessageBubbleName>
          </Box>
          <Typography variant="body2">{message}</Typography>
        </ChatMessageBubble>
        <ChatMessageTime variant="body2">{time}</ChatMessageTime>
      </ChatMessageInner>
    </ChatMessage>
  );
}

function LoadLog() {

  const containerElem = useRef();
  const isInitiallyScrolledBottom = useRef(false);
  const currentSelectedLoad = useSelector(state => state?.loads?.currentSelectedLoad);
  const loadEvents = currentSelectedLoad?.loadEvents ?? [];

  const getMessage = useCallback((event) => {
    if (event?.type === loadEventTypes.created) {
      return 'Load created';
    } 
    if (event?.type === loadEventTypes.edited) {
      return 'Load edited';
    }
    if (event?.type === loadEventTypes.statusChanged) {
      let metaData;
      try {
        if (event?.metadata) {
          metaData = event.metadata;
          if (typeof metaData === 'string') {
            metaData = JSON.parse(metaData);
          }
        }
      } catch(e) {}
      if (metaData?.status === loadStatusTypes.booked.key) {
        return `Load status changed to ${loadStatusTypes.booked.display}`;
      }
      if (metaData?.status === loadStatusTypes.assigned.key) {
        return `Load status changed to ${loadStatusTypes.assigned.display}`;
      }
      if (metaData?.status === loadStatusTypes.inTransit.key) {
        if (metaData?.loadStopId) {
          const loadStop = currentSelectedLoad?.loadStops?.find(item => item.id === metaData.loadStopId);
          if (loadStop) {
            return `Load status changed to ${loadStatusTypes.inTransit.display}. Location: ${loadStop?.companyLocation?.companyName}`;
          }
        }
        return `Load status changed to ${loadStatusTypes.inTransit.display}`;
      }
      if (metaData?.status === loadStatusTypes.delivered.key) {
        if (metaData?.loadStopId) {
          const loadStop = currentSelectedLoad?.loadStops?.find(item => item.id === metaData.loadStopId);
          if (loadStop) {
            return `Load status changed to ${loadStatusTypes.delivered.display}. Location: ${loadStop?.companyLocation?.companyName}`;
          }
        }
        return `Load status changed to ${loadStatusTypes.delivered.display}`;
      }
      return 'Load status changed';
    }
  }, [currentSelectedLoad]);
  

  useEffect(() => {
    if (containerElem?.current?.scrollTo) {
      if (loadEvents.length && !isInitiallyScrolledBottom.current) {
        isInitiallyScrolledBottom.current = true;
        containerElem.current.scrollTo(0, 9999999999999999); //I know it's a hacky way but I don't have time to calculate it properly. It will work for sure.
      }
    }
  }, [loadEvents]);

  return (
    <ChatContainer container component={Card}>
      <ChatMessages ref={containerElem}>
        {
          loadEvents.map((event, key) => {
            return (
              <ChatMessageComponent
                key={event?.id || key}
                name={event?.createdBy?.firstName + ' ' + event?.createdBy?.lastName}
                avatar={event?.createdBy?.avatar}
                message={getMessage(event)}
                time={timeHelper.getTime(new Date(event?.createdAt))}
                position="left"
              />
            );
          })
        }
      </ChatMessages>
      <Divider />
    </ChatContainer>
  );
}

export default LoadLog;
