import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

import LoadChat from '../LoadChat';
import LoadLog from '../LoadLog';
import LoadNotes from '../LoadNotes';

const TABS = {
  LOG: 'LOG',
  NOTES: 'NOTES',
  CHAT: 'CHAT',
};

const Tab = styled.div`
  padding: 10px;
  cursor: pointer;
  color: ${({ color }) => color ? color : '#fff'};
  font-size: 12px;
  letter-spacing: 1px;
  width: 100px;
  text-align: center;
  ${({ color, selected }) => selected ? `border-bottom: 1.5px solid ${color ? color : '#fff'}` : ''}
`;

const LoadDetailsAcivityTabs = ({ setFilePreviewData = () => {} }) => {

  const [selectedTab, setSelectedtab] = useState(TABS.LOG);
  const theme = useTheme();
  const requestBusy = useSelector(state => state.requestBusy);

  return (
    <div style={{ borderLeft: "1px solid rgba(0,0,0,0.12)", paddingBottom: 15 }}>
      <div style={{ height: 500 }}>
        {
          selectedTab === TABS.CHAT &&
          <LoadChat setFilePreviewData={setFilePreviewData} />
        }
        {
          selectedTab === TABS.NOTES &&
          <LoadNotes />
        }
        {
          selectedTab === TABS.LOG &&
          <LoadLog />
        }
      </div>
      <div style={{ display: 'flex', justifyContent: 'center'}}>
        <Tab 
          color={theme?.palette?.primary?.main}
          selected={selectedTab === TABS.LOG}
          onClick={() => {
            if (requestBusy) return;
            setSelectedtab(TABS.LOG)
          }}
        >
          Log
        </Tab>
        <Tab 
          color={theme?.palette?.primary?.main}
          selected={selectedTab === TABS.NOTES}
          onClick={() => {
            if (requestBusy) return;
            setSelectedtab(TABS.NOTES);
          }}
        >
          Notes
        </Tab>
        <Tab 
          color={theme?.palette?.primary?.main}
          selected={selectedTab === TABS.CHAT}
          onClick={() => {
            if (requestBusy) return;
            setSelectedtab(TABS.CHAT);
          }}
        >
          Driver Chat
        </Tab>
      </div>
    </div>
  );

};

export default LoadDetailsAcivityTabs; 