import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { refreshToken } from './currentLoggedUser';
import { resetLoadsCustomersState } from './loads';
import { parseResponseError } from '../helpers/errorHandlingHelpers';

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    customerName: '',
  },
  total: 0,
  initialFetch: false,
  currentSelectedCompanyCustomer: null,
});

export const companyCustomersSlice = createSlice({
  name: "companyCustomers",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setFiltersCustomerName: (state, action) => {
      state.filters.customerName = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    setCurrentSelectedCompanyCustomer: (state, action) => {
      state.currentSelectedCompanyCustomer = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setData,
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersCustomerName,
  setInitialFetch,
  setCurrentSelectedCompanyCustomer,
  resetState
} = companyCustomersSlice.actions;

export const fetchCompanyCustomers = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.companyCustomers && state.companyCustomers.filters ? state.companyCustomers.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (filters.customerName) queryParams.append('customerName', filters.customerName);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/company-customers?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
      });
  }));
};

export const addCompanyCustomer = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post(
          '/api/company-customers',
          { data }
      )
      .then(response => {
          if (response.status === 201 && response.data && response.data.data){
              dispatch(resetState());
              window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
              dispatch(setRequestBusy(false));
              dispatch(resetLoadsCustomersState());
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const updateCompanyCustomer = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/company-customers',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              const newData = {
                id: getState().companyCustomers.currentSelectedCompanyCustomer.id,
                ...data
              };
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(resetLoadsCustomersState());
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(setCurrentSelectedCompanyCustomer(newData));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const fetchCurrentSelectedCompanyCustomer = id => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/company-customers/${id}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedCompanyCustomer(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
  }));
}; 

export const uploadLogo = (payload, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));

  const bodyFormData = new FormData();
  bodyFormData.append('file', payload);

  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/company-customers/upload-logo', 
      bodyFormData, 
      {
        timeout: 1000 * 60
      }
    ).then(res => {
      if (res.data.data) {
        callback(null, res.data.data);
      } else {
        throw new Error('');
      }
    })      
    .catch(error => parseResponseError(error, dispatch))
    .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export default companyCustomersSlice.reducer;

