import React, { useEffect, useState, useRef, useCallback, useReducer } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { NavLink, useParams, useNavigate } from "react-router-dom";
import { Formik, FieldArray } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  addCompanyCustomer,
  fetchCurrentSelectedCompanyCustomer,
  updateCompanyCustomer,
  uploadLogo
} from "../../redux/slices/companyCustomers";
import { setErrorDialogMessage } from '../../redux/slices/messages';
import { 
  getAllQuickbooksCustomers,
  getQuickbooksCustomerById
} from '../../redux/slices/companySettings';
import { 
  useDispatch,
  useSelector
} from "react-redux";
import DeleteIcon from '@mui/icons-material/Delete';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@mui/icons-material/Add';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { spacing } from "@mui/system";
import { v4 as uuidv4 } from 'uuid';
import SelectList from '../components/SelectList';
import { setCloseDrawer } from "../../redux/slices/fullDrawerModal";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  customerName: "",
  mcNumber: "",
  addressGeoData: null,
  commodityType: "",
  contacts: [],
  logoPath: null,
  poBoxActive: false,
  poBox: '',
  city: '',
  state: '',
  zip: '',
});

const GoogleMapInputField = props => {

  const {
    name,
    label,
    error,
    helperText,
    onBlur,
    onChange,
  } = props;

  const googleAutoComplete = useRef(null);
  const googleAutoCompleteInput = useRef(null);

  useEffect(() => {
    if (googleAutoCompleteInput.current) {
      const inputElem = googleAutoCompleteInput.current.getElementsByTagName('input')[0];
      const { maps } = window.google ? window.google : {};
      if (!maps) return;
      googleAutoComplete.current = new maps.places.Autocomplete(inputElem);
      googleAutoComplete.current.addListener("place_changed", () => {
        const place = googleAutoComplete.current.getPlace();
        if (!place) return;
        onChange({ 
          target: {
            value: JSON.parse(JSON.stringify(place)),
            name: 'addressGeoData' 
          }
        });
      });
    }

    return () => {
      if (googleAutoComplete.current) window.google.maps.event.clearInstanceListeners(googleAutoComplete.current);
      googleAutoComplete.current = null;
    }
  }, []);
  
  return (
    <TextField
      name={name}
      label={label}
      key="address-input"
      id="googlePlacesAddressInputField"
      error={error}
      fullWidth
      helperText={helperText}
      onBlur={onBlur}
      onChange={e => {
        const inputElem = googleAutoCompleteInput.current.getElementsByTagName('input')[0];
        if (e.target.value === '' && inputElem) {
          onChange({ 
            target: {
              value: null,
              name: 'addressGeoData' 
            }
          });
          inputElem.value = '';
        } 
      }}
      variant="outlined"
      my={2}
      ref={googleAutoCompleteInput}
    />
  );
};

function AddCompanyCustomer({ isEdit, fullDrawerModalMode }) {

  const dispatch = useDispatch();
  const formikRef = useRef();
  const [, forceUpdate] = useReducer(x => x + 1, 0);
  const currentSelectedCompanyCustomer = useSelector(state => state.companyCustomers.currentSelectedCompanyCustomer);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const companySettings = useSelector(state => state.companySettings);
  const { closeCallback } = useSelector(state => state.fullDrawerModal);
  const company = currentLoggedUser.company;
  const params = useParams();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [removeContactHandler, setRemoveContactHandler] = useState(null);
  const [showSearchQuickbooksCustomersSelectList, setShowSearchQuickbooksCustomersSelectList] = useState(false);
  const [tempCustomerQuickbooksData, setTempCustomerQuickbooksData] = useState(null); //We have temp data also here (along with redux) because we don't always have local Customer ID but we still want to show quickbooks data.

  const companyCustomerId = params && params.companyCustomerId ? params.companyCustomerId : null;
  const quickbooksCustomersData = companySettings?.integrations?.accounting?.quickbooks?.data ?? [];
  const quickbooksCustomersRequestBusy = companySettings?.integrations?.accounting?.quickbooks?.requestBusy ?? false;
  const reduxTempCustomerQuickbooksData = companySettings?.integrations?.accounting?.quickbooks?.tempQuickbooksDataPerAppCustomerId ?? false;

  const finalCustomerQuickbooksData = tempCustomerQuickbooksData ? tempCustomerQuickbooksData : (reduxTempCustomerQuickbooksData && companyCustomerId && reduxTempCustomerQuickbooksData[companyCustomerId]) ? reduxTempCustomerQuickbooksData[companyCustomerId] : null;

  useEffect(() => {
    if (companyCustomerId && isEdit) dispatch(fetchCurrentSelectedCompanyCustomer(companyCustomerId));
  }, []);

  const openSearchQuickbooksCustomersSelectList = useCallback(() => {
    setShowSearchQuickbooksCustomersSelectList(true);
    if (quickbooksCustomersData?.length === 0) dispatch(getAllQuickbooksCustomers());
  }, [quickbooksCustomersData, dispatch]);

  useEffect(() => {
    if (currentSelectedCompanyCustomer?.quickbooksCustomerId) {
      if (reduxTempCustomerQuickbooksData && reduxTempCustomerQuickbooksData[currentSelectedCompanyCustomer.id]) return;
      dispatch(getQuickbooksCustomerById({
        id: currentSelectedCompanyCustomer?.quickbooksCustomerId,
        localCustomerId: currentSelectedCompanyCustomer.id
      }));
    }
  }, [currentSelectedCompanyCustomer?.quickbooksCustomerId, currentSelectedCompanyCustomer?.id, reduxTempCustomerQuickbooksData, dispatch]);

  useEffect(() => {
    if (currentSelectedCompanyCustomer) {
      const values = { ...currentSelectedCompanyCustomer };
      try {
        if (currentSelectedCompanyCustomer.contacts) {
          if (typeof currentSelectedCompanyCustomer.contacts === 'string') {
            values.contacts = JSON.parse(currentSelectedCompanyCustomer.contacts);
          } else if (Array.isArray(currentSelectedCompanyCustomer.contacts)) {
            values.contacts = JSON.parse(JSON.stringify(currentSelectedCompanyCustomer.contacts));
          }
        }
      } catch (e) {}
      setInitialValues({ ...values });
      const googlePlacesAddressInputField = document.getElementById('googlePlacesAddressInputField');
      if (googlePlacesAddressInputField) googlePlacesAddressInputField.value = values && values.addressGeoData && values.addressGeoData.formatted_address ? values.addressGeoData.formatted_address : '';
    }
  }, [currentSelectedCompanyCustomer]);

  const yupConfig = {
    customerName: Yup.string().min(3,"Customer Name must be at least 3 characters").max(50,"Customer Name must be at most 50 characters").required("Required"),
    mcNumber: Yup.string().min(3,"MC# must be at least 3 characters").max(50,"MC# must be at most 50 characters").required("Required"),
    commodityType: Yup.string().min(3,"Commodity Type must be at least 3 characters").max(50,"Commodity Type must be at most 50 characters").nullable(),
    poBoxActive: Yup.boolean(),
    addressGeoData: Yup.object().required("Required").nullable(),
    contacts: Yup.array().of(Yup.object().shape({
      firstName: Yup.string().min(2,"First Name must be at least 2 characters").max(50,"First Name must be at most 50 characters").required("Required"),
      lastName: Yup.string().min(2,"Last Name must be at least 2 characters").max(50,"Last Name must be at most 50 characters").required("Required"),
      role: Yup.string().min(2,"Role must be at least 2 characters").max(50,"Role must be at most 50 characters").required("Required"),
      email: Yup.string().email("Must be a valid email").min(3,"Email of Company must be at least 3 characters").max(255,"Email  must be at most 255 characters").required("Required"),
      phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
      phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
    }))
  };

  if (formikRef?.current?.values) {
    const { poBoxActive } = formikRef.current.values;
    if (poBoxActive) {
      delete yupConfig.addressGeoData;
      yupConfig.poBox = Yup.string().min(3,"PO Box must be at least 3 characters").max(50,"PO Box must be at most 50 characters").required("Required").nullable();
      yupConfig.city = Yup.string().min(3,"City must be at least 3 characters").max(50,"City must be at most 50 characters").required("Required").nullable();
      yupConfig.state = Yup.string().min(2,"State must be at least 2 characters").max(50,"State must be at most 50 characters").required("Required").nullable();
      yupConfig.zip = Yup.string().min(3,"Zip must be at least 3 characters").max(20,"Zip must be at most 20 characters").required("Required").nullable();
    } else {
      yupConfig.addressGeoData = Yup.object().required("Required").nullable();
    }
  }

  const validationSchema = Yup.object().shape(yupConfig);

  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (isEdit) {
        const data = {
          id: currentSelectedCompanyCustomer && currentSelectedCompanyCustomer.id ? currentSelectedCompanyCustomer.id : null,
          customerName: values.customerName,
          mcNumber: values.mcNumber,
          contacts: values.contacts,
          poBoxActive: values.poBoxActive,
        };

        if (values.commodityType) data.commodityType = values.commodityType;
        else data.commodityType = null;
        if (values.quickbooksCustomerId) data.quickbooksCustomerId = values.quickbooksCustomerId;
        else data.quickbooksCustomerId = null;
        if (values.logoPath) data.logoPath = values.logoPath;
        else data.logoPath = null;
        if (values.poBoxActive) {
          data.addressGeoData = null;
          data.poBox = values.poBox;
          data.city = values.city;
          data.state = values.state;
          data.zip = values.zip;
        } else {
          data.addressGeoData = values.addressGeoData;
          data.poBox = null;
          data.city = null;
          data.state = null;
          data.zip = null;
        }
        dispatch(updateCompanyCustomer(data, err => {
            if (err) return;
            setSubmitting(false);
            if (fullDrawerModalMode) {
              if (closeCallback) closeCallback();
              dispatch(setCloseDrawer());
            } else {
              window.setTimeout(() => navigate(-1), 0);
            }
          }
        ));
      } else {
        const data = {
          customerName: values.customerName,
          mcNumber: values.mcNumber,
          contacts: values.contacts,
          poBoxActive: values.poBoxActive,
        };

        if (values.commodityType) data.commodityType = values.commodityType;
        if (values.quickbooksCustomerId) data.quickbooksCustomerId = values.quickbooksCustomerId;
        if (values.logoPath) data.logoPath = values.logoPath;

        if (values.poBoxActive) {
          data.poBox = values.poBox;
          data.city = values.city;
          data.state = values.state;
          data.zip = values.zip;
        } else {
          data.addressGeoData = values.addressGeoData;
        }

        dispatch(addCompanyCustomer(data, err => {
            if (err) return;
            resetForm();
            setSubmitting(false);
            if (fullDrawerModalMode) {
              if (closeCallback) closeCallback();
              dispatch(setCloseDrawer());
            } else {
              window.setTimeout(() => navigate(-1), 0);
            }
          }
        ));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      {
        !fullDrawerModalMode &&
        <>
          <Helmet title={ isEdit ? 'Edit Customer' : 'Add Customer' } />
          <Typography variant="h3" gutterBottom display="inline">
            { isEdit ? 'Edit Customer' : 'Add Customer' }
          </Typography>
          {
            isEdit
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Customers
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Customer Details
                </Link>
                <Typography>Edit Customer</Typography>
              </Breadcrumbs>
            :
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link component={NavLink} to={""} onClick={() => navigate(-1)}>
                  Customers
                </Link>
                <Typography>Add Customer</Typography>
              </Breadcrumbs>
          }

          <Divider my={6} />
        </>
      }

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Customer Information
              </Typography>
              <form onSubmit={handleSubmit}>
                <div style={{ display: 'flex' }}>
                  <TextField
                    name="customerName"
                    label="Customer Name*"
                    value={values.customerName}
                    error={Boolean(touched.customerName && errors.customerName)}
                    style={{ flex: 1 }}
                    helperText={touched.customerName && errors.customerName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                  <TextField
                    name="mcNumber"
                    label="MC#*"
                    value={values.mcNumber}
                    error={Boolean(touched.mcNumber && errors.mcNumber)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.mcNumber && errors.mcNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </div>
                {
                  values.poBoxActive
                  ?
                    <div style={{ display: 'flex' }}>
                      <TextField
                        name="poBox"
                        label="P.O. Box*"
                        value={values.poBox}
                        error={Boolean(touched.poBox && errors.poBox)}
                        style={{ flex: 1 }}
                        helperText={touched.poBox && errors.poBox}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      /> 
                      <TextField
                        name="city"
                        label="City*"
                        value={values.city}
                        error={Boolean(touched.city && errors.city)}
                        style={{ flex: 1, marginLeft: 10 }}
                        helperText={touched.city && errors.city}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      /> 
                      <TextField
                        name="state"
                        label="State*"
                        value={values.state}
                        error={Boolean(touched.state && errors.state)}
                        style={{ flex: 1, marginLeft: 10 }}
                        helperText={touched.state && errors.state}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      />
                      <TextField
                        name="zip"
                        label="Zip*"
                        value={values.zip}
                        error={Boolean(touched.zip && errors.zip)}
                        style={{ flex: 1, marginLeft: 10 }}
                        helperText={touched.zip && errors.zip}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                      /> 
                      <div style={{ width: 150, marginLeft: 15, position: 'relative', top: 12 }}>
                        <FormControlLabel
                          label="P.O. Box?"
                          control={
                            <Checkbox
                              checked={values.poBoxActive}
                              onChange={(e) => {
                                handleChange({
                                  target: { 
                                    value: e?.target?.checked,
                                    name: 'poBoxActive'
                                  } 
                                });
                                window.setTimeout(() => forceUpdate(1), 100);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                  :
                    <div style={{ display: 'flex' }}>
                      <GoogleMapInputField 
                        name="addressGeoData"
                        label="Address*"
                        error={Boolean(touched.addressGeoData && errors.addressGeoData)}
                        helperText={touched.addressGeoData && errors.addressGeoData}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                      <div style={{ width: 150, marginLeft: 15, position: 'relative', top: 12 }}>
                        <FormControlLabel
                          label="P.O. Box?"
                          control={
                            <Checkbox
                              checked={values.poBoxActive}
                              onChange={(e) => {
                                handleChange({
                                  target: { 
                                    value: e?.target?.checked,
                                    name: 'poBoxActive'
                                  } 
                                });
                                window.setTimeout(() => forceUpdate(1), 100);
                              }}
                            />
                          }
                        />
                      </div>
                    </div>
                }
                <TextField
                  name="commodityType"
                  label="Commodity Type"
                  value={values.commodityType}
                  error={Boolean(touched.commodityType && errors.commodityType)}
                  fullWidth
                  helperText={touched.commodityType && errors.commodityType}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  my={2}
                  style={{ marginBottom: 20 }}
                />
                <div style={{ marginBottom: 20 }}>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                    Logo
                  </Typography>
                  {
                    values?.logoPath &&
                    <div style={{ width: 150, height: 150 }}>
                      <img src={values?.logoPath} alt="" style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </div>
                  }
                  <input
                    accept="image/*"
                    style={{ display: "none", marginTop: 6, marginBottom: 6  }}
                    id="raised-button-file"
                    type="file"
                    onChange={e => {
                      if (!e) return
                      let file;
                      if (e.target && e.target.files && e.target.files.length) file = e.target.files[0];
                      if (!file) return;
                  
                      const whitelistFileTypes = ['image/png', 'image/jpg', 'image/jpeg'];
                      const maxSize = 10000000; //10mb
                      if (!whitelistFileTypes.find(type => type === file.type)) return dispatch(setErrorDialogMessage('File can be only type of: JPG or PNG.'));
                      else if (file.size > maxSize) return dispatch(setErrorDialogMessage('Maximum size of the file is 10MB.'));
                      dispatch(uploadLogo(file, (err, logoPath) => {
                        if (err || !logoPath) return;
                        handleChange({ 
                          target: {
                            value: logoPath,
                            name: 'logoPath' 
                          }
                        });
                        e.target.value = null;
                      }));
                    }}
                  />
                  {
                    values?.logoPath
                    ?
                    <Button 
                      variant="contained" 
                      color="error" 
                      component="span" 
                      marginBottom={2}
                      onClick={() => {
                        handleChange({ 
                          target: {
                            value: null,
                            name: 'logoPath' 
                          }
                        });
                      }}
                    >
                      <DeleteIcon style={{ marginRight: 5 }} /> Delete Logo
                    </Button>
                    :
                    <label htmlFor="raised-button-file">
                      <Button variant="contained" color="primary" component="span" marginBottom={2}>
                        <CloudUploadIcon style={{ marginRight: 5 }} /> Upload
                      </Button>
                      <Typography variant="caption" display="block" gutterBottom>
                        For best results, use an image 300px by 300px in .jpg
                        format
                      </Typography>
                    </label>
                  }
                </div>
                {
                  company?.quickbooksIsOauth2Logged &&
                  company?.quickbooksOauth2DataSaved &&
                  <div style={{ marginBottom: 20 }}>
                    <Typography variant="h6" gutterBottom style={{ marginBottom: 15 }}>
                      Quickbooks Customer
                    </Typography>
                    {
                      !values?.quickbooksCustomerId
                      ?
                        <Button 
                          variant="contained"
                          startIcon={<AddIcon />}
                          onClick={openSearchQuickbooksCustomersSelectList}
                        >
                          Select Customer
                        </Button>
                      :
                        <div>
                          <div>
                            <Typography mt={2}>
                              ID: <span style={{ fontWeight: 'bold' }}>{values?.quickbooksCustomerId}</span>
                            </Typography>
                          </div>
                          <div>
                            <Typography mt={2}>
                              First Name: <span style={{ fontWeight: 'bold' }}>{finalCustomerQuickbooksData?.GivenName}</span>
                            </Typography>
                          </div>
                          <div>
                            <Typography mt={2}>
                              Last Name: <span style={{ fontWeight: 'bold' }}>{finalCustomerQuickbooksData?.FamilyName}</span>
                            </Typography>
                          </div>
                          <div>
                            <Typography mt={2}>
                              Company Name: <span style={{ fontWeight: 'bold' }}>{finalCustomerQuickbooksData?.CompanyName}</span>
                            </Typography>
                          </div>
                          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button 
                              variant="contained"
                              onClick={openSearchQuickbooksCustomersSelectList}
                              style={{ width: 160 }}
                            >
                              Change Customer
                            </Button>
                          </div>
                        </div>
                    }
                  </div>
                }
                <Typography variant="h6" gutterBottom>
                  Contact(s)
                </Typography>
                <FieldArray
                  name="contacts"
                  render={arrayHelpers => {
                    const contacts = values.contacts;
                    return (
                      <>
                      {
                        contacts && contacts.length > 0 
                        ?
                        contacts.map((contact, index) => (
                          <div key={index} style={{ border: '1px solid #eee', borderRadius: 5, padding: 20, marginTop: 10, marginBottom: 20 }}>
                            <div style={{ display: 'flex' }}>
                              <TextField
                                name={`contacts.${index}.firstName`}
                                label="First Name*"
                                value={contacts[index].firstName}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].firstName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].firstName))}
                                style={{ flex: 1 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].firstName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].firstName)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].firstName = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              /> 
                              <TextField
                                name={`contacts.${index}.lastName`}
                                label="Last Name*"
                                value={contacts[index].lastName}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].lastName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].lastName))}
                                style={{ flex: 1, marginLeft: 10 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].lastName) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].lastName)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].lastName = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              />
                            </div>
                            <TextField
                              name={`contacts.${index}.role`}
                              label="Role*"
                              value={contacts[index].role}
                              error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].role) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].role))}
                              style={{ flex: 1 }}
                              fullWidth
                              helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].role) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].role)}
                              onBlur={handleBlur}
                              onChange={e => {
                                const value = e.target.value;
                                const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                if (contactIndex !== -1) values.contacts[contactIndex].role = value;
                                handleChange({ 
                                  target: {
                                    value: values.contacts,
                                    name: 'contacts' 
                                  }
                                });
                              }}
                              variant="outlined"
                              my={2}
                            />
                            <TextField
                              name={`contacts.${index}.email`}
                              label="Email*"
                              value={contacts[index].email}
                              fullWidth
                              error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].email) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].email))}
                              style={{ flex: 1 }}
                              helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].email) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].email)}
                              onBlur={handleBlur}
                              onChange={e => {
                                const value = e.target.value;
                                const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                if (contactIndex !== -1) values.contacts[contactIndex].email = value;
                                handleChange({ 
                                  target: {
                                    value: values.contacts,
                                    name: 'contacts' 
                                  }
                                });
                              }}
                              variant="outlined"
                              my={2}
                            />
                            <div style={{ display: 'flex' }}>
                              <TextField
                                InputProps={{
                                  startAdornment: <InputAdornment position="start">+1</InputAdornment>
                                }}
                                name={`contacts.${index}.phoneNumber`}
                                label="Phone Number*"
                                value={contacts[index].phoneNumber}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumber) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumber))}
                                style={{ flex: 3 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumber) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumber)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].phoneNumber = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              /> 
                              <TextField
                                name={`contacts.${index}.phoneNumberExtension`}
                                label="Extension"
                                value={contacts[index].phoneNumberExtension}
                                error={Boolean((touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumberExtension) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumberExtension))}
                                style={{ flex: 1, marginLeft: 10 }}
                                helperText={(touched && touched.contacts && touched.contacts[index] && touched.contacts[index].phoneNumberExtension) && (errors && errors.contacts && errors.contacts[index] && errors.contacts[index].phoneNumberExtension)}
                                onBlur={handleBlur}
                                onChange={e => {
                                  const value = e.target.value;
                                  const contactIndex = values.contacts.map(item => item.id).indexOf(contact.id);
                                  if (contactIndex !== -1) values.contacts[contactIndex].phoneNumberExtension = value;
                                  handleChange({ 
                                    target: {
                                      value: values.contacts,
                                      name: 'contacts' 
                                    }
                                  });
                                }}
                                variant="outlined"
                                my={2}
                              />         
                            </div>
                            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
                              <Button 
                                variant="outlined" 
                                color="error" 
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  setRemoveContactHandler(() => () => {
                                    arrayHelpers.remove(index);
                                    setRemoveContactHandler(null);
                                  });
                                }}
                              >
                                Delete
                              </Button>
                            </div>
                          </div>
                        )) 
                        :
                        ''
                      }
                      </>
                    );
                  }}
                />
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    mt={3}
                    disabled={values.contacts.length >= 10}
                    onClick={() => {
                      if (values.contacts.length >= 10) return;
                      const value = [...values.contacts];
                      value.push({
                        id: uuidv4(),
                        firstName: '',
                        lastName: '',
                        role: '',
                        email: '',
                        phoneNumber: '',
                        phoneNumberExtension: ''
                      });
                      handleChange({ 
                        target: {
                          value, 
                          name: 'contacts' 
                        }
                      });
                    }}
                  >
                    Add New Contact
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    mt={3}
                  >
                    Save Changes
                  </Button>
                </div>
              </form>
            </CardContent>
            {
              showSearchQuickbooksCustomersSelectList &&
              <SelectList 
                title="Select Quickbooks Customer"
                openModal={showSearchQuickbooksCustomersSelectList}
                data={
                  quickbooksCustomersData
                  ? 
                    quickbooksCustomersData.map(item => ({ 
                      id: item.Id, 
                      item: `${item.Id} - ${item.GivenName} ${item.FamilyName} ${item.CompanyName ? ('(' + item.CompanyName + ')') : ''}`
                    })) 
                  : 
                    []
                }
                selectedValue={values?.quickbooksCustomerId}
                handleClose={() => setShowSearchQuickbooksCustomersSelectList(false)}
                loading={quickbooksCustomersRequestBusy}
                onChange={(id) => {
                  handleChange({
                    target: {
                      value: id,
                      name: 'quickbooksCustomerId' 
                    }
                  });
                  dispatch(getQuickbooksCustomerById({ id, localCustomerId: currentSelectedCompanyCustomer?.id ?? null }, (err, data) => {
                    if (err || !data) return;
                    setTempCustomerQuickbooksData(data);
                  }));
                }}
              />
            }
          </Card>
        )}
      </Formik>
      <Dialog
        open={removeContactHandler}
        onClose={() => setRemoveContactHandler(null)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this contact?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setRemoveContactHandler(null)} 
            color="primary"
          >
            No
          </Button>
          <Button color="error" onClick={removeContactHandler}>
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default AddCompanyCustomer;
