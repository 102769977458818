import React from 'react';
import {
  Typography,
  Avatar
} from "@mui/material";

const DispatcherDetailsSection = (props) => {

  const { data } = props;

  return (
    <>
      <div>
        <div>
          <Avatar src={data?.avatar ?? null}  />
        </div>
        <div>
          <Typography mt={2}>
            ID: <span style={{ fontWeight: 'bold' }}>{data.id}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            First Name: <span style={{ fontWeight: 'bold' }}>{data.firstName}</span>
          </Typography>
        </div>
        <div>
          <Typography mt={2}>
            Last Name: <span style={{ fontWeight: 'bold' }}>{data.lastName}</span>
          </Typography>
        </div>
      </div>
    </>
  );

};

export default DispatcherDetailsSection;
