import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector,
} from "react-redux";

import userRoles from "../../keys/userRoles";
import { fetchDashboards } from '../../redux/slices/dashboards';

import {
  Grid,
  Divider as MuiDivider,
  Typography as MuiTypography,
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Typography = styled(MuiTypography)(spacing);

function Dashboard() {
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchDashboards());
  }, []);

  return (
    <React.Fragment>
    <Helmet title="Dashboard" />

    <Grid justifyContent="space-between" container spacing={10}>
      <Grid item>
        <Typography variant="h3" gutterBottom display="inline">
          Company Dashboard
        </Typography>
      </Grid>
    </Grid>

    <Divider my={12} />

    {
      currentLoggedUser?.role && 
      userRoles[currentLoggedUser.role] &&
      userRoles[currentLoggedUser.role].dashboardComponent()
    }


    </React.Fragment>
  );
}

export default Dashboard;
