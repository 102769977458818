import { createSlice } from "@reduxjs/toolkit";

const initialState = false;

export const requestBusySlice = createSlice({
  name: "requestBusy",
  initialState,
  reducers: { 
    setRequestBusy: (state, action) => {
      state = action.payload;
      return state;
    }
  },
});

export const { setRequestBusy } = requestBusySlice.actions;

export default requestBusySlice.reducer;
