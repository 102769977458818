import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import stripeLogo  from '../../vendor/stripe-logo.png';
import { 
  selectPaymentGateaway, 
  disablePaymentGateaway,
  triggerStripeCustomerIdSetup,
  getStripePaymentMethod
} from '../../redux/slices/companySettings';
import companyPaymentGateawayTypes from "../../keys/companyPaymentGateawayTypes";
import StripeCustomerForm from '../components/StripeCustomerForm';
import StripePaymentCheckMessage from "../components/StripePaymentCheckMessage";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Typography,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";


const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function IntegrationsBilling() {
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showDisableStripeModal, setShowDisableStripeModal] = useState(false);
  const [stripeClientSecret, setStripeClientSecret] = useState(null);
  const [stripePaymentCheckClientSecret, setStripePaymentCheckClientSecret] = useState(null);
  const triggeredPaymentMethodFetch = useRef(false);
  const paymentCheckShowedOnce = useRef(false);
  const dispatch = useDispatch();
  const [paymentMethodData, setPaymentMethodData] = useState();

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);

  const { 
    paymentGateaway,
    customerId
  } = currentLoggedUser?.company ?? {};

  useEffect(() => {
    const clientSecret = new URLSearchParams(window.location.search).get(
      'setup_intent_client_secret'
    );

    if (clientSecret && !paymentCheckShowedOnce.current) {
      setStripePaymentCheckClientSecret(clientSecret);
      paymentCheckShowedOnce.current = true;
    }

  }, []);

  useEffect(() => {
    if (paymentGateaway === companyPaymentGateawayTypes.stripe.key && customerId && !triggeredPaymentMethodFetch.current) {
      triggeredPaymentMethodFetch.current = true;
      dispatch(getStripePaymentMethod((err, data) => {
        if (err) return;
        setPaymentMethodData(data);
      }));
    } 
  }, [paymentGateaway, customerId, dispatch]);

  const handleStripeCardInfo = () => {
    dispatch(triggerStripeCustomerIdSetup(null, (err, clientSecret) => {
      if (!err && clientSecret) setStripeClientSecret(clientSecret);
    }));
  };

  return (
    <React.Fragment>
      <Helmet title="Billing Integrations" />

      <Typography variant="h3" gutterBottom display="inline">
        Billing Integrations
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} textAlign="center" style={{ paddingTop: 24 }}  >
          <Card mb={6}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    Stripe
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link href="https://www.stripe.com/" >www.stripe.com</Link>
                  </Typography>
                  <Paper mt={4}>
                    {
                      paymentMethodData &&
                      <>
                        <div>
                          Card: <span style={{ fontWeight: 'bold' }}>{paymentMethodData?.card?.brand ?? ''}</span>
                        </div>
                        <div>
                          Card Numbers: <span style={{ fontWeight: 'bold' }}>***** - {paymentMethodData?.card?.last4 ?? ''}</span>
                        </div>
                        <div>
                          Card Expire: <span style={{ fontWeight: 'bold' }}>{paymentMethodData?.card?.exp_month ?? ''} / {paymentMethodData?.card?.exp_year ?? ''}</span>
                        </div>
                      </>
                    }
                    <div style={{ paddingTop: 10 }}>
                    {
                      paymentGateaway === companyPaymentGateawayTypes.stripe.key
                      ?
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <Button
                            variant="contained"
                            color="primary"
                            onClick={handleStripeCardInfo}
                            style={{ marginRight: 10 }}
                          >
                            {
                              customerId ? 'Update Card Info' : 'Set Card Info'
                            }
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => {
                              setShowDisableStripeModal(true);
                            }}
                          >
                            Disable
                          </Button>
                        </div>
                      :
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            dispatch(selectPaymentGateaway(
                              { 
                                paymentGateaway: companyPaymentGateawayTypes.stripe.key
                              }, 
                              (err) => {
                                if (err) return;
                                handleStripeCardInfo();
                              }
                            ));
                          }}
                        >
                          Setup
                        </Button>
                    }
                    </div>
                  </Paper>
                  </div>
                  <div style={{ flex: 1, marginLeft: 10, }}>
                    <img src={stripeLogo} style={{width: 90}} alt="" />
                  </div>
                </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      
      <Dialog
        open={showDisableStripeModal}
        onClose={() => setShowDisableStripeModal(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <div>
              While billing is disabled, you won't be able to create loads. If there are any unpaid loads, your account will be locked. You still want to proceed?
            </div>
            <TextField
              type="password"
              name="password"
              label="Password"
              value={passwordConfirm}
              fullWidth
              onChange={e => setPasswordConfirm(e.target.value)}
              my={2}
            />
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary"
              onClick={() => setShowDisableStripeModal(false)}
            >
              Cancel
            </Button>
            <Button 
              color="error" 
              variant="contained"
              disabled={!passwordConfirm}
              onClick={() => {
                dispatch(disablePaymentGateaway({ password: passwordConfirm }, (err) => {
                  if (err) return;
                  setPaymentMethodData(null);
                  setShowDisableStripeModal(false);
                }));
              }}
            >
              Disable
            </Button>
          </DialogActions>
      </Dialog>
      {
        stripeClientSecret &&
        <Dialog
          open={stripeClientSecret}
          onClose={() => setStripeClientSecret(null)}
        >
          <DialogTitle>Provide Payment Info</DialogTitle>
          <DialogContent>
            <Elements 
              stripe={stripePromise} 
              options={{
                clientSecret: stripeClientSecret,
              }}
            >
              <StripeCustomerForm 
                setStripeClientSecret={setStripeClientSecret}
              />
            </Elements>
          </DialogContent>
        </Dialog>
      }
      {
        stripePaymentCheckClientSecret &&
        <Elements 
          stripe={stripePromise} 
          options={{
            clientSecret: stripePaymentCheckClientSecret,
          }}
        >
          <StripePaymentCheckMessage 
            stripePaymentCheckClientSecret={stripePaymentCheckClientSecret}
            setStripePaymentCheckClientSecret={setStripePaymentCheckClientSecret}
          />
        </Elements>
      }
    </React.Fragment>
  );
}

export default IntegrationsBilling;