import { getDriversPaymentTypePrice, getTotalMiles } from './loadStopHelpers';
import loadAdditionalFeeOperationTypes from '../keys/loadAdditionalFeeOperationTypes';

export const getTotalAdditionalFeePrice = (load = {}) => {
  if (load?.loadAdditionalFees) {
    return load?.loadAdditionalFees.reduce((acc, current) => {
      if (current.operationType === loadAdditionalFeeOperationTypes.subtraction.key) {
        return acc - parseFloat(current?.value ? current.value : 0);
      } else if (!current.operationType || current.operationType === loadAdditionalFeeOperationTypes.addition.key) {
        return acc + parseFloat(current?.value ? current.value : 0);
      }
      return 0;
    }, 0);
  }
  return 0;
};

export const getTotalDriversCost = (driversCost = {}) => {
  let total = 0;
  Object.keys(driversCost).forEach(id => {
    const d = parseFloat(driversCost[id]?.cost ?? 0);
    if (d > 0) total = d + total;
  });
  return total;
};

export const getLoadBreakdownData = (load = {}) => {
  const loadStops = load?.loadStopsData || load?.loadStops || [];
  const driversCost = getDriversPaymentTypePrice({ 
    tripAmount: load?.tripAmount ? load.tripAmount : 0,
    loadStops,
    currentAssignedDriver: load?.driver,
  });
  const totalDriversCost = getTotalDriversCost(driversCost);
  const totalAdditionalFeePrice = getTotalAdditionalFeePrice(load);
  const totalMiles = getTotalMiles(loadStops);
  const fullCosts = totalDriversCost + totalAdditionalFeePrice;
  const tripAmount = (load?.tripAmount ? parseFloat(load?.tripAmount) : 0);
  const totalRevenue = fullCosts < 0 ? tripAmount + Math.abs(fullCosts) : tripAmount - fullCosts;

  return {
    driversCost,
    totalDriversCost,
    totalAdditionalFeePrice,
    totalMiles,
    fullCosts,
    tripAmount,
    totalRevenue: totalRevenue ?? 0,
  };
};