import React from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import getUSDFormattedCurrency from '../../../../utils/getUSDFormattedCurrency';

import LineChart from '../LineChart';
import DoughnutChart from '../DoughnutChart';
import WrapperWithLoader from './components/WrapperWithLoader';

import { Grid } from "@mui/material";

const AccountingDashboard = () => {

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dashboards = useSelector(state => state.dashboards);
  const dashboard = dashboards?.[currentLoggedUser?.role];
  const navigate = useNavigate();

  return (
    <Grid style={{ width: 'calc(100% - 10px)' }}>
      <Grid container spacing={6}>
        <Grid item xs={12} lg={12}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <LineChart
              title="Year Revenue"
              label="Sales ($)"
              lineData={dashboard?.data?.companyUserYearRevenue ? [...dashboard.data.companyUserYearRevenue] : []}
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} lg={4}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <DoughnutChart
              title="Top Truck Drivers"
              onItemClick={(item) => navigate(`/truck-drivers/truck-driver-details/${item?.user?.id}`)}
              columns={[{0: 'Name', 1: 'Revenue ($)'}]}
              showNoResults={dashboard?.fetchBatch1?.lastFetch && dashboard?.data?.companyUserTopTruckDriversByRevenue?.length === 0}
              circleData={
                dashboard.data.companyUserTopTruckDriversByRevenue 
                ? 
                  dashboard.data.companyUserTopTruckDriversByRevenue.map(data => data?.revenue ? parseFloat(data.revenue).toFixed(2) : 0)
                :
                  []
              }
              rowsData={
                dashboard?.data?.companyUserTopTruckDriversByRevenue 
                ? 
                  dashboard.data.companyUserTopTruckDriversByRevenue.map(data => ({
                    metaData: data,
                    0: `${data?.user?.firstName} ${data?.user?.lastName}`,
                    1: data?.revenue ? getUSDFormattedCurrency(data.revenue) : 0
                  }))
                :
                  []
                }
            />
          </WrapperWithLoader>
        </Grid>
        <Grid item xs={12} lg={4}>
          <WrapperWithLoader isLoading={dashboard?.fetchBatch1?.requestBusy}>
            <DoughnutChart
              title="Top Dispatcher (By Revenue)"
              onItemClick={(item) => navigate(`/users/user-details/${item?.dispatcher?.id}`)}
              columns={[{0: 'Name', 1: 'Revenue ($)'}]}
              showNoResults={dashboard?.fetchBatch1?.lastFetch && dashboard?.data?.companyUserTopDispatchersByRevenue?.length === 0}
              circleData={
                dashboard?.data?.companyUserTopDispatchersByRevenue
                ?
                  dashboard.data.companyUserTopDispatchersByRevenue.map(data => data?.revenue ? parseFloat(data.revenue).toFixed(2) :  0) 
                : 
                  []
                }
              rowsData={
                dashboard?.data?.companyUserTopDispatchersByRevenue
                ?
                  dashboard.data.companyUserTopDispatchersByRevenue.map(data => ({
                    metaData: data,
                    0: `${data?.dispatcher?.firstName} ${data?.dispatcher?.lastName}`,
                    1:  data?.revenue ? getUSDFormattedCurrency(data.revenue) : 0
                  })) 
                : 
                  []
              }
            />
          </WrapperWithLoader>
        </Grid>
      </Grid>
    </Grid>
  );

};

export default AccountingDashboard;
