import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import { 
  fetchCompanyLocations,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersCompanyName,
  setCurrentSelectedCompanyLocation,
} from "../../redux/slices/companyLocations";
import { 
  useSelector,
  useDispatch
} from "react-redux";

import {
  Button,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Toolbar,
  Tooltip,
  Typography,
  Drawer,
  TextField,
} from "@mui/material";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "customerName", alignment: "left", label: "Company Name" },
  { id: "address", alignment: "left", label: "Address" },
];

const EnhancedTableHead = () => {
  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows, setOpenFilter }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(state => state.companyLocations.filters.limit);
  const rowsSkip = useSelector(state => state.companyLocations.filters.skip);
  const rowsTotal = useSelector(state => state.companyLocations.total);
  const initialFetch = useSelector(state => state.companyLocations.initialFetch);
  
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      dispatch(setFiltersSkip(rowsPerPage + rowsSkip));
    } else {
      dispatch(setFiltersSkip(rowsSkip - rowsPerPage));
    }
    dispatch(fetchCompanyLocations());
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setFiltersLimit(value));
    dispatch(setFiltersSkip(0));
    dispatch(fetchCompanyLocations());
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper>
        <Toolbar>
          <Spacer />
          <div>
            <Tooltip title="Filter list">
              <IconButton onClick={() => setOpenFilter(true)} aria-label="Filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {
                rows.map((row, index) => {
                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      onClick={() => {
                       dispatch(setCurrentSelectedCompanyLocation(row));
                       navigate(`/company-locations/company-location-details/${row.id}`);
                       }} 
                      hover
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                     
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.companyName}</TableCell>
                      <TableCell align="left">{row.addressGeoData && row.addressGeoData.formatted_address ? row.addressGeoData.formatted_address : ''}</TableCell>
                      <TableCell padding="none" align="right">
                        
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length && initialFetch
          ?
            <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
          :
            ''
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function CompanyLocations() {
  const navigate = useNavigate();
  const companyLocations = useSelector(state => state.companyLocations);
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    companyName: '',
  });

  useEffect(() => {
    if (companyLocations && !companyLocations.initialFetch) dispatch(fetchCompanyLocations());
  }, []);

  return (
    <React.Fragment>
      <Helmet title="Company Locations" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Company Locations
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(setCurrentSelectedCompanyLocation(null));
                navigate('/company-locations/add');
              }}
            >
              <AddIcon />
              Add Company Location
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable setOpenFilter={setOpenFilter} rows={companyLocations.data} />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <div style={{ padding: 15 }}>
          <Typography textAlign="center" variant="h3">
            Filters
          </Typography>
          <Divider paddingTop="15px" />
          <TextField
            label="Company Name"
            value={filterValues.companyName}
            fullWidth
            onChange={e => setFilterValues(prevState => ({ ...prevState, companyName: e.target.value }))}
            variant="outlined"
            style={{ 
              marginTop: 20,
              width: 300,
              display: 'block',
              marginBottom: 20
            }}
          />
          <div style={{ 
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-around'
          }}>
            <Button 
              variant="text"
              color="secondary"
              onClick={() => {
                setFilterValues(() => ({
                  companyName: '',
                }));
                dispatch(setFiltersCompanyName(''));
                dispatch(setFiltersSkip(0));
                dispatch(fetchCompanyLocations());
              }}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setFiltersCompanyName(filterValues.companyName));
                dispatch(setFiltersSkip(0));
                dispatch(fetchCompanyLocations());
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default CompanyLocations;
