import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate
} from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import { 
  addCompanyAdmin,
  fetchCurrentSelectedCompanyAdmin,
  updateCompanyAdmin
} from "../../redux/slices/companyAdminUsers";
import regularExpressions from "../../utils/regularExpressions";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment
} from "@mui/material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);

const getInitialValues = () => ({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  phoneNumberExtension: "",
  password: "",
  confirmPassword: "",
  isActive: true,
  loginAccess: true
});

function AddCompanyAdminUsers({ isEdit }) {
  const yupConfig = {
    firstName: Yup.string().min(2,"First Name must be at least 2 characters").max(50,"First Name must be at most 50 characters").required("Required"),
    lastName: Yup.string().min(2,"Last Name must be at least 2 characters").max(50,"Last Name must be at most 50 characters").required("Required"),
    email: Yup.string().email("Must be a valid email").min(3,"Email of Company must be at least 3 characters").max(255,"Email  must be at most 255 characters").required("Required"),
    phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
    phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
    password: Yup.string().matches(regularExpressions.PASSWORD, "Must be at least 8 characters, one number, one uppercase and one symbol")
      .max(255)
      .required("Required"),
    confirmPassword: Yup.string().required("Required").when("password", {
      is: (val) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf(
        [Yup.ref("password")],
        "Both password need to be the same"
      ),
    }),
    isActive: Yup.boolean().required("Required"),
    loginAccess: Yup.boolean().required("Required"),
  };
  if (isEdit) {
    delete yupConfig.password;
    delete yupConfig.confirmPassword;
    delete yupConfig.email;
  }
  const validationSchema = Yup.object().shape(yupConfig);

  const params = useParams();
  const currentSelectedCompanyAdmin = useSelector(state => state.companyAdminUsers.currentSelectedCompanyAdmin);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(getInitialValues());

  const companyId = params && params.companyId ? params.companyId : null;
  const userId = params && params.userId ? params.userId : null;

  useEffect(() => {
    if (userId && isEdit) dispatch(fetchCurrentSelectedCompanyAdmin(userId));
  }, []);

  useEffect(() => {
    if (currentSelectedCompanyAdmin) setInitialValues({ 
      ...currentSelectedCompanyAdmin,
      phoneNumberExtension: currentSelectedCompanyAdmin.phoneNumberExtension ? currentSelectedCompanyAdmin.phoneNumberExtension : "",
    });
  }, [currentSelectedCompanyAdmin]);


  const handleSubmit = async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      if (isEdit) {
        const data = {
          id: currentSelectedCompanyAdmin && currentSelectedCompanyAdmin.id ? currentSelectedCompanyAdmin.id : null,
          isActive: values.isActive,
          loginAccess: values.loginAccess,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber
        };
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        else data.phoneNumberExtension = null;
        dispatch(updateCompanyAdmin(data, err => {
            if (err) return;
            setSubmitting(false);
            window.setTimeout(() => navigate(-1), 0);
          }
        ));
      } else {
        const data = {
          password: values.password,
          isActive: values.isActive,
          loginAccess: values.loginAccess,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          companyId
        };
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        dispatch(addCompanyAdmin(data, err => {
            if (err) return;
            resetForm();
            setSubmitting(false);
            window.setTimeout(() => navigate(-1), 0);
          }
        ));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  const navigate = useNavigate();

  return (
    <React.Fragment>
      <Helmet title={ isEdit ? 'Edit Admin User' : 'Add Admin User' }/>
      <Typography variant="h3" gutterBottom display="inline">
        { isEdit ? 'Edit Admin User' : 'Add Admin User' }
      </Typography>

      {
        isEdit
        ?
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-4)}>
              Companies
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-3)}>
              Company Details
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
              Admin Users
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
              Admin User Details
            </Link>
            <Typography>Edit Admin User</Typography>
          </Breadcrumbs>
        :
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link to={""} component={NavLink} onClick={() => navigate(-3)}>
              Companies
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
              Company Details
            </Link>
            <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
              Admin Users
            </Link>
            <Typography>Add Admin User</Typography>
          </Breadcrumbs>
      }

      <Divider my={6} />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          status,
        }) => (
          <Card mb={6}>
            <CardContent>
              <Typography variant="h6" gutterBottom>
                Users Information
              </Typography>
              <form onSubmit={handleSubmit}>
                    <TextField
                      name="firstName"
                      label="First Name*"
                      value={values.firstName}
                      error={Boolean(touched.firstName && errors.firstName)}
                      fullWidth
                      helperText={touched.firstName && errors.firstName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                    <TextField
                      name="lastName"
                      label="Last Name*"
                      value={values.lastName}
                      error={Boolean(touched.lastName && errors.lastName)}
                      fullWidth
                      helperText={touched.lastName && errors.lastName}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    />
                {
                  !isEdit &&
                  <TextField
                    name="email"
                    label="Email*"
                    value={values.email}
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    variant="outlined"
                    my={2}
                  />
                }

                  <div style={{ display: 'flex' }}>
                    <TextField
                      InputProps={{
                        startAdornment: <InputAdornment position="start">+1</InputAdornment>
                      }}
                      name="phoneNumber"
                      label="Phone Number*"
                      value={values.phoneNumber}
                      error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                      style={{ flex: 3 }}
                      helperText={touched.phoneNumber && errors.phoneNumber}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                    <TextField
                      name="phoneNumberExtension"
                      label="Extension"
                      value={values.phoneNumberExtension}
                      error={Boolean(touched.phoneNumberExtension && errors.phoneNumberExtension)}
                      style={{ flex: 1, marginLeft: 10 }}
                      helperText={touched.phoneNumberExtension && errors.phoneNumberExtension}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      variant="outlined"
                      my={2}
                    /> 
                  </div>

                {
                  !isEdit
                  ?
                    <>
                      <TextField
                        name="password"
                        label="Password*"
                        value={values.password}
                        error={Boolean(touched.password && errors.password)}
                        fullWidth
                        helperText={touched.password && errors.password}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                        my={2}
                      />

                      <TextField
                        name="confirmPassword"
                        label="Confirm password*"
                        value={values.confirmPassword}
                        error={Boolean(
                          touched.confirmPassword && errors.confirmPassword
                        )}
                        fullWidth
                        helperText={touched.confirmPassword && errors.confirmPassword}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="password"
                        variant="outlined"
                        my={2}
                      />
                    </>
                  :
                    ''
                }

                <div style={{ marginBottom: 16, marginTop: 8 }}>
                    <FormControl fullWidth>
                      <InputLabel>Status*</InputLabel>
                      <Select
                        label="Status*"
                        value={values.isActive}
                        error={Boolean(touched.isActive && errors.isActive)}
                        fullWidth
                        helperText={touched.isActive && errors.isActive}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="isActive"
                        
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8 }}>
                    <FormControl fullWidth>
                      <InputLabel>Login Access*</InputLabel>
                      <Select
                        label="Login Access*"
                        value={values.loginAccess}
                        error={Boolean(touched.loginAccess && errors.loginAccess)}
                        fullWidth
                        helperText={touched.loginAccess && errors.loginAccess}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="loginAccess"
                        
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>

                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                >
                  Save changes
                </Button>
              </form>
            </CardContent>
          </Card>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default AddCompanyAdminUsers;