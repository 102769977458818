import React, { useEffect, useState } from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from 'react-router-dom';
import { 
  fetchCompanies,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersName,
  setSortsRegistrationDate,
  setFiltersLoginAccess,
  setCurrentSelectedCompany
} from "../../redux/slices/companies";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import moment from 'moment';
import userRoles from '../../keys/userRoles';

import {
  Button,
  Chip as MuiChip,
  Divider as MuiDivider,
  Grid,
  IconButton,
  Paper as MuiPaper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
  Drawer,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import { green, red } from "@mui/material/colors";
import {
  Add as AddIcon,
  FilterList as FilterListIcon,
} from "@mui/icons-material";
import { spacing } from "@mui/system";

const Divider = styled(MuiDivider)(spacing);

const Paper = styled(MuiPaper)(spacing);

const Chip = styled(MuiChip)`
  ${spacing};

  background: ${(props) => props.active && green[500]};
  background: ${(props) => props.nonActive && red[500]};
  color: ${(props) => props.theme.palette.common.white};
`;

const Spacer = styled.div`
  flex: 1 1 100%;
`;

const headCells = [
  { id: "id", alignment: "left", label: "ID" },
  { id: "name", alignment: "left", label: "Name" },
  { id: "date", alignment: "left", label: "Registration Date" },
  { id: "loginAccess", alignment: "left", label: "Login Access" },
  { id: "email", alignment: "left", label: "Email" },
  { id: "method", alignment: "left", label: "Phone Number" },
  { id: "actions", alignment: "left", label: "Address" },
];

const EnhancedTableHead = () => {
  const dispatch = useDispatch();
  const registrationDateSort = useSelector(state => state.companies.sorts.registrationDate);

  return (
    <TableHead>
      <TableRow>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.alignment}
            padding={headCell.disablePadding ? "none" : "normal"}
          >
            {
              headCell.label === 'Registration Date'
              ?
                <TableSortLabel
                  direction={registrationDateSort}
                  onClick={() => {
                    if (registrationDateSort === 'asc') dispatch(setSortsRegistrationDate('desc'));
                    else dispatch(setSortsRegistrationDate('asc'));
                    dispatch(fetchCompanies());
                  }}
                >
                  {headCell.label}
                </TableSortLabel>
              :
                headCell.label
            }
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

function EnhancedTable({ rows, setOpenFilter }) {
  const [page, setPage] = React.useState(0);
  const rowsPerPage = useSelector(state => state.companies.filters.limit);
  const rowsSkip = useSelector(state => state.companies.filters.skip);
  const rowsTotal = useSelector(state => state.companies.total);
  const initialFetch = useSelector(state => state.companies.initialFetch);
  
  const dispatch = useDispatch();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (newPage > page) {
      dispatch(setFiltersSkip(rowsPerPage + rowsSkip));
    } else {
      dispatch(setFiltersSkip(rowsSkip - rowsPerPage));
    }
    dispatch(fetchCompanies());
  };

  const handleChangeRowsPerPage = (event) => {
    const value = parseInt(event.target.value);
    dispatch(setFiltersLimit(value));
    dispatch(setFiltersSkip(0));
    dispatch(fetchCompanies());
    setPage(0);
  };

  const navigate = useNavigate();

  return (
    <div>
      <Paper>
        <Toolbar>
          <Spacer />
          <div>
            <Tooltip title="Filter list">
              <IconButton onClick={() => setOpenFilter(true)} aria-label="Filter list" size="large">
                <FilterListIcon />
              </IconButton>
            </Tooltip>
          </div>
        </Toolbar>
        <TableContainer>
          <Table
            aria-labelledby="tableTitle"
            size={"medium"}
            aria-label="enhanced table"
          >
            <EnhancedTableHead />
            <TableBody>
              {
                rows.map((row, index) => {
                  return (
                    <TableRow
                      style={{cursor: "pointer"}}
                      onClick={() => {
                       dispatch(setCurrentSelectedCompany(row));
                       navigate(`/companies/company-details/${row.id}`);
                       }} 
                      hover
                      tabIndex={-1}
                      key={`${row.id}-${index}`}
                     
                    >
                      <TableCell align="left">{row.id}</TableCell>
                      <TableCell align="left">{row.name}</TableCell>
                      <TableCell align="left">{moment(row.createdAt).format("MM/DD/YYYY")}</TableCell>
                      <TableCell>
                        {row.loginAccess && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Active"
                            active={+true}
                          />
                        )}
                        {!row.loginAccess && (
                          <Chip
                            size="small"
                            mr={1}
                            mb={1}
                            label="Not Active"
                            nonActive={+true}
                          />
                        )}
                      </TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.phoneNumber}</TableCell>
                      <TableCell align="left">{row.address}</TableCell>
                      <TableCell padding="none" align="right">
                        
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        {
          !rows.length && initialFetch
          ?
            <Typography marginTop="20px" textAlign="center" width="100%">No results...</Typography>
          :
            ''
        }
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rowsTotal}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </div>
  );
}

function Companies() {
  const navigate = useNavigate();
  const companies = useSelector(state => state.companies);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const dispatch = useDispatch();

  const [openFilter, setOpenFilter] = useState(false);
  const [filterValues, setFilterValues] = useState({
    name: '',
    loginAccess: '-1'
  });

  useEffect(() => {
    if (companies && !companies.initialFetch) dispatch(fetchCompanies());
  }, []);

  if (!currentLoggedUser || currentLoggedUser.role !== userRoles.admin.key) return '';

  return (
    <React.Fragment>
      <Helmet title="Companies" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Companies
          </Typography>
        </Grid>
        <Grid item>
          <div>
            <Button 
              variant="contained" 
              color="primary" 
              onClick={() => {
                dispatch(setCurrentSelectedCompany(null));
                navigate('/companies/add');
              }}
            >
              <AddIcon />
              Add Company
            </Button>
          </div>
        </Grid>
      </Grid>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <EnhancedTable setOpenFilter={setOpenFilter} rows={companies.data} />
        </Grid>
      </Grid>
      <Drawer
        anchor="right"
        open={openFilter}
        onClose={() => setOpenFilter(false)}
      >
        <div style={{ padding: 15 }}>
          <Typography textAlign="center" variant="h3">
            Filters
          </Typography>
          <Divider paddingTop="15px" />
          <TextField
            label="Name"
            value={filterValues.name}
            fullWidth
            onChange={e => setFilterValues(prevState => ({ ...prevState, name: e.target.value }))}
            variant="outlined"
            style={{ 
              marginTop: 20,
              marginBottom: 20,
              width: 300,
              display: 'block'
            }}
          />
          <FormControl fullWidth>
            <InputLabel>Login Access</InputLabel>
            <Select
              label="Login Access"
              value={filterValues.loginAccess}
              fullWidth
              onChange={e => setFilterValues(prevState => ({ ...prevState, loginAccess: e.target.value }))}
              variant="outlined"
              style={{ 
                width: 300,
                display: 'block'
              }}
            >
              <MenuItem value="-1">Select</MenuItem>
              <MenuItem value="1">Active</MenuItem>
              <MenuItem value="0">Not Active</MenuItem>
            </Select>
          </FormControl>
          <div style={{ 
            marginTop: 20,
            display: 'flex',
            justifyContent: 'space-around'
          }}>
            <Button 
              variant="text"
              color="secondary"
              onClick={() => {
                setFilterValues(() => ({
                  name: '',
                  loginAccess: '-1'
                }));
                dispatch(setFiltersName(''));
                dispatch(setFiltersLoginAccess('-1'));
                dispatch(setFiltersSkip(0));
                dispatch(fetchCompanies());
              }}
            >
              Reset
            </Button>
            <Button 
              variant="contained"
              color="primary"
              onClick={() => {
                dispatch(setFiltersName(filterValues.name));
                dispatch(setFiltersLoginAccess(filterValues.loginAccess));
                dispatch(setFiltersSkip(0));
                dispatch(fetchCompanies());
              }}
            >
              Search
            </Button>
          </div>
        </div>
      </Drawer>
    </React.Fragment>
  );
}

export default Companies;
