import routes from "./routes";
import {
  Slide,
  Snackbar,
  CircularProgress,
  Alert
} from "@mui/material";
import { 
  useSelector,
  useDispatch
} from "react-redux";
import { useRoutes } from "react-router-dom";

import FullDrawerModal from './pages/components/FullDrawerModal';

import {
  setErrorDialogMessage,
  setSuccessDialogMessage,
  setInfoDialogMessage
} from './redux/slices/messages';
import useSetHistoryStack from './hooks/useSetHistoryStack';

const Content = () => {

  const routesContent = useRoutes(routes);

  const dispatch = useDispatch();

  const messages = useSelector(state => state.messages);
  const fullDrawerModal = useSelector(state => state.fullDrawerModal);
  const requestBusy = useSelector(state => state.requestBusy);
  const forceShowSpinner = useSelector(state => state.forceShowSpinner);
  useSetHistoryStack();

  return (
    <>
      {routesContent}
      {
        (requestBusy || forceShowSpinner) && 
        <div style={{ 
          position: 'fixed', 
          left: 0, 
          top: 0, 
          zIndex: 100000000,
          width: '100%',
          height: '100%'
        }}>
          <div style={{
            position:'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}>
            <CircularProgress color="primary" />
          </div>
        </div>
      }
      {
        messages.errorDialogMessage &&
        <Snackbar
          open={messages.errorDialogMessage}
          anchorOrigin={{ 
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => dispatch(setErrorDialogMessage(''))}
          autoHideDuration={6000}
          TransitionComponent={props => <Slide {...props} direction="up" />}
          key={1}
        >
          <Alert severity="error" style={{fontSize: "medium"}}>{messages.errorDialogMessage}</Alert>
        </Snackbar>
      }
      {
        messages.successDialogMessage &&
        <Snackbar
          open={messages.successDialogMessage}
          anchorOrigin={{ 
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => dispatch(setSuccessDialogMessage(''))}
          autoHideDuration={6000}
          TransitionComponent={props => <Slide {...props} direction="up" />}
          key={2}
        >
          <Alert severity="success" style={{fontSize: "medium"}}>{messages.successDialogMessage}</Alert>
        </Snackbar>
      }
      {
        messages.infoDialogMessage &&
        <Snackbar
          open={messages.infoDialogMessage}
          anchorOrigin={{ 
            vertical: 'bottom',
            horizontal: 'center',
          }}
          onClose={() => dispatch(setInfoDialogMessage(''))}
          autoHideDuration={6000}
          TransitionComponent={props => <Slide {...props} direction="up" />}
          key={3}
        >
          <Alert severity="info" style={{fontSize: "medium"}}>{messages.infoDialogMessage}</Alert>
        </Snackbar>
      }
      <FullDrawerModal open={fullDrawerModal?.show} />
    </>
  );
};

export default Content;
