import { 
  useState, 
  useEffect 
} from 'react';
import axios from '../axios/config';
import { getCurrentLoggedUser } from '../utils/cacheHelper';
import { 
  setCurrentLoggedUser,
  fetchCurrentLoggedUser
} from '../redux/slices/currentLoggedUser';
import { useDispatch } from 'react-redux'

const LoadCache = ({ children }) => {

  const dispatch = useDispatch();

  const [cacheLoaded, setCacheLoaded] = useState({
    currentLoggedUserLoaded: false
  });

  useEffect(() => {
    const currentLoggedUserData = getCurrentLoggedUser();
    if (currentLoggedUserData) {
      axios.defaults.headers.common['Authorization'] = `Bearer ${currentLoggedUserData.bearerToken}`;
      dispatch(setCurrentLoggedUser(currentLoggedUserData));
      dispatch(fetchCurrentLoggedUser());
    }
    setCacheLoaded(prevState => ({ 
      ...prevState, 
      currentLoggedUserLoaded: true,
    }));
  }, []);

  if (cacheLoaded.currentLoggedUserLoaded) return children;
  return '';
};

export default LoadCache;
