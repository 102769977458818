import { useEffect } from 'react';
import { useStripe } from '@stripe/react-stripe-js';
import { useDispatch } from 'react-redux';

import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from '../../../redux/slices/messages';


const StripePaymentCheckMessage = (props) => {

  const {
    stripePaymentCheckClientSecret,
    setStripePaymentCheckClientSecret
  } = props;

  const dispatch = useDispatch();
  const stripe = useStripe();


  useEffect(() => {
    if (!stripe || !stripePaymentCheckClientSecret) return;

    stripe
    .retrieveSetupIntent(stripePaymentCheckClientSecret)
    .then(({setupIntent}) => {
      if (setStripePaymentCheckClientSecret) setStripePaymentCheckClientSecret(null);
      switch (setupIntent.status) {
        case 'succeeded':
          dispatch(setSuccessDialogMessage('Payment method successfully saved!'));
          break;

        case 'processing':
          dispatch(setSuccessDialogMessage('Payment method successfully saved but still processing on Stripe side.'));
          break;

        case 'requires_payment_method':
          dispatch(setErrorDialogMessage('Failed to process your payment details. Please, try again.'));
          break;
        
        default:
          break;
      }
    });
  }, [stripe, setStripePaymentCheckClientSecret, stripePaymentCheckClientSecret]);

  return ''
  
};

export default StripePaymentCheckMessage;
