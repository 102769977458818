import React, { useEffect } from "react";
import styled from "styled-components/macro";
import {
  useParams,
  useNavigate,
  NavLink
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import moment from 'moment';
import getUSDFormattedCurrency from '../../utils/getUSDFormattedCurrency';
import AemLogo from '../../vendor/aem-logo.png';

import {
  Chip as MuiChip,
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography as MuiTypography,
  CardContent,
  Button,
  TableHead,
  Table,
  TableCell,
  TableRow,
  TableBody
} from "@mui/material";
import { spacing, display} from "@mui/system";
import { fetchCurrentSelectedBilling } from "../../redux/slices/billing";
import userRoles from "../../keys/userRoles";
import companyPaymentGateawayTypes from "../../keys/companyPaymentGateawayTypes";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;

const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Typography = styled(MuiTypography)(display);

function BillingDetails() {
  const navigate = useNavigate();
  const currentSelectedBilling = useSelector(state => state.billing.currentSelectedBilling);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const historyStack = useSelector(state => state.historyStack);
  const params = useParams();
  const dispatch = useDispatch();

  const billingId = params && params.billingId ? params.billingId : null;

  useEffect(() => {
    if (billingId) dispatch(fetchCurrentSelectedBilling(billingId));
  }, []);

  if (!currentSelectedBilling) return '';

  return (
    <React.Fragment>
      <Helmet title="Billing Invoice Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Billing Invoice Details
          </Typography>
          {
            currentLoggedUser?.role === userRoles.admin.key
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-3)}>
                  Companies
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Company Details
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Billing
                </Link>
                <Typography>Billing Invoice Details</Typography>
              </Breadcrumbs>
            :
              historyStack[historyStack.length - 2] &&
              historyStack[historyStack.length - 2]?.indexOf('/billing') !== -1 &&
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link 
                  style={{ cursor: 'pointer' }}
                  onClick={() => navigate(-1)}
                >
                  Billing
                </Link>
                <Typography>Billing Invoice Details</Typography>
              </Breadcrumbs>
          }
        </Grid>
        {
          currentLoggedUser?.role === userRoles.admin.key &&
          currentSelectedBilling?.paymentGateawayResponse?.id &&
          <Grid item>
            <div>
              <Button 
                variant="text" 
                color="primary" 
                onClick={() => {
                  if (process.env.NODE_ENV === 'production') { 
                    window.open(`https://dashboard.stripe.com/payments/${currentSelectedBilling.paymentGateawayResponse.id}`, '_blank');
                  } else {   
                    window.open(`https://dashboard.stripe.com/test/payments/${currentSelectedBilling.paymentGateawayResponse.id}`, '_blank');
                  }
                }}
                style={{ marginRight: 20 }}
              >
                Open Stripe Details
              </Button>
            </div>
          </Grid>
        }
      </Grid>

      <Divider my={6} />

      <Shadow>
        <Card mb={6}>
          <CardContent style={{ paddingBottom: 20 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 15, alignItems: 'center', }}>
              <div>
              <Typography variant="body2">
                <>
                  <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                  AEM Business Solutions LLC
                  </span>
                  <br />
                </>
                <>
                  11116 South Town Square,
                  <br />
                </>
                <>
                  Suite 105, St. Louis, MO 63123
                  <br />
                </>
                <Link href={`mailto:accounting@aembs.com`}>
                  accounting@aembs.com
                </Link>
                <br />
                <Link href={`https://aembs.com`} target="_blank">
                  https://aembs.com
                </Link>
              </Typography>
              </div>
              <div>
                <img src={AemLogo} alt="" />
              </div>
            </div>
            <Divider />

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20, marginTop: 10 }}>
              <div style={{ marginRight: 10 }}>
                <Typography variant="caption"><span style={{ fontSize: 16 }}>BILL TO</span></Typography>
                <Typography variant="body2">
                  {
                    currentSelectedBilling?.company?.name &&
                    <>
                      <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                      {currentSelectedBilling.company.name}
                        <br />
                      </span>
                    </>
                  }
                  {
                    currentSelectedBilling?.company?.address &&
                    <>
                      {currentSelectedBilling.company.address}
                      <br />
                    </>
                  }
                  {
                    currentSelectedBilling?.company?.city &&
                    <>
                      {currentSelectedBilling.company.city}
                      <br />
                    </>
                  }
                  {
                    currentSelectedBilling?.company?.state &&
                    <>
                      {currentSelectedBilling.company.state}
                      <br />
                    </>
                  }
                  {
                    currentSelectedBilling?.company?.zip &&
                    <>
                      {currentSelectedBilling.company.zip}
                      <br />
                    </>
                  }
                  {
                    currentSelectedBilling?.company?.email &&
                    <Link href={`mailto:${currentSelectedBilling.company.email}`}>
                      {currentSelectedBilling.company.email}
                    </Link>
                  }
                </Typography>
              </div>
              <div style={{ width: 250 }}>
                <Typography variant="caption">Invoice #:</Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: 10 }}>{currentSelectedBilling?.id}</Typography>
                <Typography variant="caption">Date: </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold', marginBottom: 10 }}>{currentSelectedBilling?.createdAt ? moment(currentSelectedBilling.createdAt).format("MM/DD/YYYY") : ''}</Typography>
                <Typography variant="caption">Payment Gateaway: </Typography>
                <Typography variant="body2" style={{ fontWeight: 'bold' }}>{currentSelectedBilling?.paymentGateaway ? companyPaymentGateawayTypes[currentSelectedBilling.paymentGateaway].display : ''}</Typography>
              </div>
            </div>
            <Table style={{ marginTop: 20 }}>
              <TableHead>
                <TableRow>
                  <TableCell>Number of Loads</TableCell>
                  <TableCell align="right">Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell 
                    component="th" 
                    scope="row" 
                  >
                    {currentSelectedBilling?.numberOfLoads}
                  </TableCell>
                  <TableCell align="right">{getUSDFormattedCurrency(currentSelectedBilling?.amountCharged ? currentSelectedBilling.amountCharged : 0)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: 'none' }}></TableCell>
                  <TableCell align="right" style={{ borderBottom: 'none' }}><span style={{ marginRight: 50 }}>Total: </span> 
                  <span style={{ fontWeight: 'bold' }}>
                  {getUSDFormattedCurrency(currentSelectedBilling?.amountCharged ? currentSelectedBilling.amountCharged : 0)}
                    </span></TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Shadow>
    </React.Fragment>
  );
}

export default BillingDetails;
