import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { Formik } from "formik";
import * as Yup from "yup";
import EFSLogo  from '../../vendor/EFSLogo.svg';
import { enableFuelCardIntegration, disableFuelCardIntegration } from '../../redux/slices/companySettings';
import fuelCardTypes from "../../keys/fuelCardTypes";

import {
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Grid,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField as MuiTextField,
  Typography,
  Link
} from "@mui/material";
import { spacing } from "@mui/system";


const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);


const getInitialValues = () => ({
});

function IntegationsFuelCard() {
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [showEFSModal, setShowEFSModal] = useState(false);
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [showDisableEFSModal, setShowDisableEFSModal] = useState(false);
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
 
  const validationSchema = Yup.object().shape({});

  const handleSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting }
  ) => {
    try {
      const fuelCardType = showEFSModal ? fuelCardTypes.efs.key : null;
      dispatch(enableFuelCardIntegration({}, fuelCardType, (err) => {
        setSubmitting(false);
        if (err) return;
        setShowEFSModal(false);
      }));
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  };

  return (
    <React.Fragment>
      <Helmet title="Fuel Card Integrations" />

      <Typography variant="h3" gutterBottom display="inline">
        Fuel Card Integrations
      </Typography>

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12} md={6} textAlign="center" style={{ paddingTop: 24 }}  >
          <Card mb={6}>
            <CardContent>
              <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                <div style={{ textAlign: 'center', flex: 1 }}>
                  <Typography variant="h6" gutterBottom>
                    EFS
                  </Typography>
                  <Typography variant="body1" gutterBottom>
                    <Link href="https://www.efsllc.com/" >www.efsllc.com</Link>
                  </Typography>
                  <Paper mt={4}>
                    <div style={{ paddingTop: 10 }}>
                    {
                      currentLoggedUser &&
                      currentLoggedUser.company &&
                      currentLoggedUser.company.efsEnabled
                      ?
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setShowDisableEFSModal(true);
                          }}
                        >
                          Disable
                        </Button>
                      :
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            setShowEFSModal(true);
                          }}
                        >
                          Setup
                        </Button>
                    }
                    </div>
                  </Paper>
                  </div>
                  <div style={{ flex: 1, marginLeft: 10, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <img src={EFSLogo} style={{width: 150}} alt="" />
                  </div>
                </div>
            </CardContent>
          </Card>
        </Grid>
        
      </Grid>
      
      <Dialog
        open={showEFSModal}
        onClose={() => setShowEFSModal(false)}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          enableReinitialize={true}
          onSubmit={handleSubmit}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            touched,
            values,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit} style={{width: 600}}>
              <DialogTitle>Setup EFS</DialogTitle>
              <DialogContent>
                Click "Enable" to enable EFS Integration.
              </DialogContent>
              <DialogActions>
                <Button 
                  color="primary"
                  onClick={() => setShowEFSModal(false)}
                >
                  Cancel
                </Button>
                <Button color="primary" variant="contained" type="submit">
                  Enable
                </Button>
              </DialogActions>
            </form>
          )}
        </Formik>
      </Dialog>
      <Dialog
        open={showDisableEFSModal}
        onClose={() => setShowDisableEFSModal(false)}
      >
        <DialogTitle>Are you sure?</DialogTitle>
          <DialogContent>
            <div>
              EFS will stop working accross whole app. Are you sure you want to disable this integration?
            </div>
            <TextField
              type="password"
              name="password"
              label="Password"
              value={passwordConfirm}
              fullWidth
              onChange={e => setPasswordConfirm(e.target.value)}
              my={2}
            />
          </DialogContent>
          <DialogActions>
            <Button 
              color="primary"
              onClick={() => setShowDisableEFSModal(false)}
            >
              Cancel
            </Button>
            <Button 
              color="error" 
              variant="contained"
              disabled={!passwordConfirm}
              onClick={() => {
                dispatch(disableFuelCardIntegration({ password: passwordConfirm }, (err) => {
                  if (err) return;
                  setShowDisableEFSModal(false);
                }));
              }}
            >
              Disable
            </Button>
          </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default IntegationsFuelCard;