import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { refreshToken } from './currentLoggedUser';

const getInitialState = () => ({
  data: [],
  path: 'dashboard',
  filters: {
    from: 0,
    query: '',
  },
  requestBusy: false,
});

export const dashboardElasticSearchSlice = createSlice({
  name: "dashboardElasticSearch",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    concatData: (state, action) => {
      state.data = state.data.concat(action.payload);
      return state;
    },
    setFiltersFrom: (state, action) => {
      state.filters.from = action.payload;
      return state;
    },
    setFiltersQuery: (state, action) => {
      state.filters.query = action.payload;
      return state;
    },
    setRequestBusy: (state, action) => {
      state.requestBusy = action.payload;
      return state;
    },
    resetState: () => getInitialState(),
  }
});

export const { 
  setData,
  concatData,
  setFiltersFrom,
  setFiltersQuery,
  setRequestBusy,
  resetState,
} = dashboardElasticSearchSlice.actions;

export const fetchDashboardElasticSearchData = () => (dispatch, getState) => {
  const { dashboardElasticSearch } = getState();
  const filters = dashboardElasticSearch.filters ? dashboardElasticSearch.filters : {};
  const path = dashboardElasticSearch.path ? dashboardElasticSearch.path : null;
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('from', filters.from);
  queryParams.append('query', filters.query);
  dispatch(refreshToken(err => {
    if (err) throw new Error('');
    axios.get(`/api/elastic-search/${path}?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(concatData(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};


export default dashboardElasticSearchSlice.reducer;
