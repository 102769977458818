import React, { useState, useEffect, useRef, useCallback, useMemo } from "react";
import * as Yup from "yup";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate
} from "react-router-dom";
import { Formik } from "formik";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from "react-redux";
import { 
  addTruckDriver,
  fetchCurrentSelectedTruckDriver,
  updateTruckDriver
} from "../../redux/slices/truckDrivers";
import SelectList from '../components/SelectList';
import { fetchAvailableEquipmentData } from '../../redux/slices/equipments';
import { fetchAvailablePaymentTypesData } from '../../redux/slices/accountingPaymentTypes';
import { 
  getAllEldSolutionsVehicles,
  fetchEFSCardSummariesData
} from '../../redux/slices/companySettings';
import driverLicenceStates from '../../keys/driverLicenceStates';
import userRoles from "../../keys/userRoles";
import driverOperationTypes from "../../keys/driverOperationTypes";
import driverPaymentTypes from "../../keys/driverPaymentTypes";
import driverTypes from "../../keys/driverTypes";
import * as Icon from "@mui/icons-material";
import mainAxios from 'axios';
import axios from "../../axios/config";
import { v4 as uuidv4 } from 'uuid';
import FilePreview from "../components/FilePreview";
import { setCloseDrawer } from "../../redux/slices/fullDrawerModal";

import {
  Breadcrumbs as MuiBreadcrumbs,
  Button as MuiButton,
  Card as MuiCard,
  CardContent,
  Divider as MuiDivider,
  Link,
  TextField as MuiTextField,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  LinearProgress,
  IconButton,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from "@mui/material";
import { spacing } from "@mui/system";
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { IMaskInput } from 'react-imask';
import AddIcon from '@mui/icons-material/Add';
import { setErrorDialogMessage } from "../../redux/slices/messages";
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import DownloadIcon from '@mui/icons-material/Download';

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Card = styled(MuiCard)(spacing);

const TextField = styled(MuiTextField)(spacing);

const Button = styled(MuiButton)(spacing);


const getInitialValues = () => ({
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: "",
  phoneNumberExtension: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  ssn: "",
  driverLicence: "",
  hireDate: null,
  terminationDate: null,
  licenceState: "-1",
  licenceExpiration: null,
  lastMVR: null,
  drugTestDate: null,
  medicalCardExpiration: null,
  yearsOfExperience: '',
  contractorEIN: "",
  isActive: true,
  loginAccess: true,
  emergencyPhoneNumber: "",
  emergencyFirstName: "",
  emergencyLastName: "",
  emergencyRelationship: "",
  driverType: "-1",
  driverPaymentType: driverPaymentTypes.perMile.key,
  accountingPaymentTypeId: "-1",
  driverOperationType: "-1",
  driverSelectedTruckId: "-1",
  driverSelectedTrailerId: "-1",
  eldDriverId: "-1",
  efsCardNumber: '',
});

function AddTruckDriver({ isEdit, fullDrawerModalMode }) {

  const companySettings = useSelector(state => state.companySettings);
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const requestBusy = useSelector(state => state.requestBusy);

  const formikRef = useRef();

  const eldSolutionsEnabled = currentLoggedUser && currentLoggedUser.company && currentLoggedUser.company.eldSolutionsEnabled ? true : false;
  const efsEnabled = currentLoggedUser?.company?.efsEnabled ?? false;
  const eldSolutionsVehiclesData = companySettings && companySettings.integrations && companySettings.integrations.elds && companySettings.integrations.elds.eldSolutions && companySettings.integrations.elds.eldSolutions && companySettings.integrations.elds.eldSolutions.vehicles ? companySettings.integrations.elds.eldSolutions.vehicles : [];
  const EFSCardSummariesData = companySettings?.integrations?.fuelCards?.EFS?.EFSCardSummaries?.data ?? [];

  const yupConfig = {
    emergencyPhoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").nullable(),
    emergencyFirstName: Yup.string().min(2,"First Name must be at least 2 characters").max(50,"First Name must be at most 50 characters").nullable(),
    emergencyLastName: Yup.string().min(2,"Last Name must be at least 2 characters").max(50,"Last Name must be at most 50 characters").nullable(),
    emergencyRelationship: Yup.string().min(2,"Relationship must be at least 2 characters").max(50,"Relationship must be at most 50 characters").nullable(),
    firstName: Yup.string().min(2,"First Name must be at least 2 characters").max(50,"First Name must be at most 50 characters").required("Required"),
    lastName: Yup.string().min(2,"Last Name must be at least 2 characters").max(50,"Last Name must be at most 50 characters").required("Required"),
    email: Yup.string().email("Must be a valid email").min(3,"Email of Company must be at least 3 characters").max(255,"Email  must be at most 255 characters").required("Required"),
    phoneNumber: Yup.string().min(3,"Phone Number must be at least 3 characters").max(50,"Phone Number must be at most 50 characters").required("Required"),
    phoneNumberExtension: Yup.string().min(1,"Extension must be at least 1 characters").max(20,"Extension must be at most 20 characters"),
    address: Yup.string().min(3,"Address must be at least 3 characters").max(50,"Address must be at most 50 characters").required("Required"),
    state: Yup.string().min(2,"State must be at least 2 characters").max(50,"State must be at most 50 characters").required("Required"),
    zip: Yup.string().min(3,"Zip must be at least 3 characters").max(20,"Zip must be at most 20 characters").required("Required"),
    city: Yup.string().min(3,"City must be at least 3 characters").max(50,"City must be at most 50 characters").required("Required"),
    isActive: Yup.boolean().required("Required"),
    ssn: Yup.string().min(11,"SSN must be 11 characters").max(11,"SSN must 11 be characters").required("Required"),
    driverLicence: Yup.string().min(3,"Driver licence must be at least 3 characters").max(20,"Driver licence must be at most 20 characters").required("Required"),
    hireDate: Yup.string().required("Required").nullable(),
    licenceState: Yup.string().not(['-1'], 'Required'),
    driverType: Yup.string().not(['-1'], 'Required'),
    driverPaymentType: Yup.string().not(['-1'], 'Required'),
    accountingPaymentTypeId: Yup.string().not(['-1'], 'Required'),
    driverOperationType: Yup.string().not(['-1'], 'Required'),
    driverSelectedTruckId: Yup.string().not(['-1'], 'Required'),
    driverSelectedTrailerId: Yup.string().not(['-1'], 'Required'),
    licenceExpiration: Yup.string().required("Required").nullable(),
    lastMVR: Yup.string().required("Required").nullable(),
    drugTestDate: Yup.string().required("Required").nullable(),
    medicalCardExpiration: Yup.string().required("Required").nullable(),
    yearsOfExperience: Yup.string().required("Required"),
    contractorEIN: Yup.string().min(10,"Contractor EIN must 10 characters").max(10,"Contractor EIN must 10 characters").nullable(),
    eldDriverId: Yup.string().not(['-1'], 'Required'),
  };

  if (isEdit) delete yupConfig.email;
  if (!eldSolutionsEnabled) delete yupConfig.eldDriverId;

  const validationSchema = Yup.object().shape(yupConfig);

  const params = useParams();
  const currentSelectedTruckDriver = useSelector(state => state.truckDrivers.currentSelectedTruckDriver);
  const availableEquipmentData = useSelector(state => state.equipments.availableEquipmentData);
  const availablePaymentTypesData = useSelector(state => state.accountingPaymentTypes.availablePaymentTypesData);
  const { closeCallback } = useSelector(state => state.fullDrawerModal);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(getInitialValues());
  const [filePreviewData, setFilePreviewData] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showSearchEFSCardsSelectList, setShowSearchEFSCardsSelectList] = useState(false);
  const disableAvailablePaymentTypesInitialFetch = useRef(false);

  const userId = params && params.userId ? params.userId : null;

  const navigate = useNavigate();

  useEffect(() => {
    if (userId && isEdit) dispatch(fetchCurrentSelectedTruckDriver(userId));
    dispatch(fetchAvailableEquipmentData());
    if (!isEdit && !disableAvailablePaymentTypesInitialFetch.current) {
      disableAvailablePaymentTypesInitialFetch.current = true;
      dispatch(fetchAvailablePaymentTypesData({ paymentType: driverPaymentTypes.perMile.key }));
    }
    if (
      eldSolutionsEnabled &&
      !eldSolutionsVehiclesData.length
    ) {
      dispatch(getAllEldSolutionsVehicles());
    }
  }, []);

  const openSearchEFSCardsSelectList = useCallback(() => {
    setShowSearchEFSCardsSelectList(true);
    if (EFSCardSummariesData?.length === 0) {
      dispatch(fetchEFSCardSummariesData((err) => {
        if (err) setShowSearchEFSCardsSelectList(false);
      }));
    }
  }, [EFSCardSummariesData, dispatch]);

  const updateEFSCardNumber = useCallback((efsCardNumber) => {
    const form = formikRef.current;
    if (efsCardNumber && form) {
      form.handleChange({
        target: {
          value: efsCardNumber,
          name: 'efsCardNumber' 
        }
      });
      window.setTimeout(() => form.validateForm(), 0);
    }
  }, []);

  const [deleteFileHandler, setDeleteFileHandler] = useState(null);

  useEffect(() => {
    if (currentSelectedTruckDriver) {
      let uploadedFiles = [];
      try {
        if (currentSelectedTruckDriver && currentSelectedTruckDriver.uploadedFiles) {
          if (typeof currentSelectedTruckDriver.uploadedFiles === 'string') {
            uploadedFiles = JSON.parse(currentSelectedTruckDriver.uploadedFiles);
          } else if (Array.isArray(currentSelectedTruckDriver.uploadedFiles)) {
            uploadedFiles = JSON.parse(JSON.stringify(currentSelectedTruckDriver.uploadedFiles));
          }
        }
      } catch (e) {}
      setInitialValues({ 
        ...currentSelectedTruckDriver,
        phoneNumberExtension: currentSelectedTruckDriver.phoneNumberExtension ? currentSelectedTruckDriver.phoneNumberExtension : "",
        emergencyRelationship: currentSelectedTruckDriver.emergencyRelationship ? currentSelectedTruckDriver.emergencyRelationship : "",
      });
      if (!disableAvailablePaymentTypesInitialFetch.current) {
        disableAvailablePaymentTypesInitialFetch.current = true;
        dispatch(fetchAvailablePaymentTypesData({ paymentType: currentSelectedTruckDriver.driverPaymentType ? currentSelectedTruckDriver.driverPaymentType : driverPaymentTypes.perMile.key }));
      }
      setUploadedFiles(uploadedFiles);
    }
  }, [currentSelectedTruckDriver]);


  const handleSubmit = useCallback(async (
    values,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      let mappedUploadedFiles = [];
      if (uploadedFiles && Array.isArray(uploadedFiles) && uploadedFiles.length) {
        mappedUploadedFiles = uploadedFiles.map(item => ({ urls: item.urls, name: item.name }));
      }
      if (isEdit) {
        const data = {
          id: currentSelectedTruckDriver && currentSelectedTruckDriver.id ? currentSelectedTruckDriver.id : null,
          isActive: values.isActive,
          loginAccess: values.loginAccess,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          ssn: values.ssn,
          driverLicence: values.driverLicence,
          hireDate: values.hireDate,
          licenceState: values.licenceState,
          driverType: values.driverType,
          driverPaymentType: values.driverPaymentType,
          accountingPaymentTypeId: values.accountingPaymentTypeId,
          driverOperationType: values.driverOperationType,
          driverSelectedTruckId: values.driverSelectedTruckId,
          driverSelectedTrailerId: values.driverSelectedTrailerId,
          licenceExpiration: values.licenceExpiration,
          lastMVR: values.lastMVR,
          drugTestDate: values.drugTestDate,
          medicalCardExpiration: values.medicalCardExpiration,
          yearsOfExperience: values.yearsOfExperience,
          address: values.address,
          city: values.city,
          zip: values.zip,
          state: values.state,
          uploadedFiles: JSON.stringify(mappedUploadedFiles)
        };
        if (values.emergencyPhoneNumber) data.emergencyPhoneNumber = values.emergencyPhoneNumber;
        else data.emergencyPhoneNumber = null;
        if (values.emergencyFirstName) data.emergencyFirstName = values.emergencyFirstName;
        else data.emergencyFirstName = null;
        if (values.emergencyLastName) data.emergencyLastName = values.emergencyLastName;
        else data.emergencyLastName = null;
        if (values.emergencyRelationship) data.emergencyRelationship = values.emergencyRelationship;
        else data.emergencyRelationship = null;
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExtension;
        else data.phoneNumberExtension = null;
        if (values.contractorEIN) data.contractorEIN = values.contractorEIN;
        else data.contractorEIN = null;
        if (values.terminationDate) data.terminationDate = values.terminationDate;
        else data.terminationDate = null;
        if (values.eldDriverId && values.eldDriverId !== '-1') data.eldDriverId = values.eldDriverId;
        else data.eldDriverId = null;
        if (values.efsCardNumber) data.efsCardNumber = values.efsCardNumber;
        else data.efsCardNumber = null;
        dispatch(updateTruckDriver(data,err => {
          if (err) return;
          setSubmitting(false);
          if (fullDrawerModalMode) {
            if (closeCallback) closeCallback();
            dispatch(setCloseDrawer());
          } else {
            window.setTimeout(() => navigate(-1), 0);
          }
        }));
      } else {
        const data = {
          isActive: values.isActive,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          phoneNumber: values.phoneNumber,
          role: userRoles.truckDriver.key,
          ssn: values.ssn,
          driverLicence: values.driverLicence,
          hireDate: values.hireDate,
          licenceState: values.licenceState,
          driverType: values.driverType,
          driverPaymentType: values.driverPaymentType,
          accountingPaymentTypeId: values.accountingPaymentTypeId,
          driverOperationType: values.driverOperationType,
          driverSelectedTruckId: values.driverSelectedTruckId,
          driverSelectedTrailerId: values.driverSelectedTrailerId,
          licenceExpiration: values.licenceExpiration,
          lastMVR: values.lastMVR,
          drugTestDate: values.drugTestDate,
          medicalCardExpiration: values.medicalCardExpiration,
          yearsOfExperience: values.yearsOfExperience,
          address: values.address,
          city: values.city,
          zip: values.zip,
          state: values.state,
          uploadedFiles: JSON.stringify(mappedUploadedFiles)
        };
        if (values.emergencyPhoneNumber) data.emergencyPhoneNumber = values.emergencyPhoneNumber;
        if (values.emergencyFirstName) data.emergencyFirstName = values.emergencyFirstName;
        if (values.emergencyLastName) data.emergencyLastName = values.emergencyLastName;
        if (values.emergencyRelationship) data.emergencyRelationship = values.emergencyRelationship;
        if (values.phoneNumberExtension) data.phoneNumberExtension = values.phoneNumberExt;
        if (values.terminationDate) data.terminationDate = values.terminationDate;
        if (values.contractorEIN) data.contractorEIN = values.contractorEIN;
        if (values.eldDriverId && values.eldDriverId !== '-1') data.eldDriverId = values.eldDriverId;
        if (values.efsCardNumber) data.efsCardNumber = values.efsCardNumber;
        dispatch(addTruckDriver(data, err => {
          if (err) return;
          resetForm();
          setSubmitting(false);
          if (fullDrawerModalMode) {
            if (closeCallback) closeCallback();
            dispatch(setCloseDrawer());
          } else {
            window.setTimeout(() => navigate(-1), 0);
          }
        }));
      }
    } catch (error) {
      setStatus({ sent: false });
      setErrors({ submit: error.message });
      setSubmitting(false);
    }
  }, [currentSelectedTruckDriver, uploadedFiles, isEdit, dispatch, navigate, fullDrawerModalMode, closeCallback]);

  const TextMaskCustom = useMemo(() => React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, name, ...other } = props;
    return (
      <IMaskInput
        {...other}
        mask={ name === 'contractorEIN' ? "xx-xxxxxxx" : "xxx-xx-xxxx"}
        definitions={{ 'x': true }}
        inputRef={ref}
        onAccept={(value) => onChange({ target: { name: props.name, value } })}
        overwrite
      />
    );
  }), []);

  const filesRequestMetadata = useRef({});
  const fileDropAreaElem = useRef(null);

  useEffect(() => {
    uploadedFiles.forEach((file, index) => {
      if ((file && (file.uploadFinish || file.uploadFinish !== false)) || (filesRequestMetadata.current[file.requestMetadataId] && filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction)) return;
      const CancelToken = mainAxios.CancelToken;
      const source = CancelToken.source();
      const bodyFormData = new FormData();
      bodyFormData.append(
        'file', 
        filesRequestMetadata.current[file.requestMetadataId] && 
        filesRequestMetadata.current[file.requestMetadataId].requestPayload 
        ? 
        filesRequestMetadata.current[file.requestMetadataId].requestPayload 
        : 
          null
      );
      bodyFormData.append('bucketFolder', 'truck-drivers-files');

      axios.post(
        '/api/users/upload-files', 
        bodyFormData,
        {
          cancelToken: source.token,
          onUploadProgress: e => {
            const progress = Math.round((e.loaded * 100) / e.total);
            setUploadedFiles(prevState => {
              const uploadedFiles = [ ...prevState ];
              if (uploadedFiles[index]) uploadedFiles[index].uploadPercentages = progress;
              return [...uploadedFiles];
            });
          },
          timeout: 1000 * 60
        }
      ).then(res => {
        if (res.data.data) {
          setUploadedFiles(prevState => {
            const uploadedFiles = [ ...prevState ];
            if (uploadedFiles[index]) {
              uploadedFiles[index].urls = res.data.data;
              uploadedFiles[index].uploadFinish = true;
            }
            return [...uploadedFiles];
          });
        }
      }).catch((e) => {
        dispatch(setErrorDialogMessage('Error while uploading files.'));
        setUploadedFiles(prevState => {
          const uploadedFiles = [ ...prevState ];
          if (uploadedFiles[index]) uploadedFiles[index].errored = true;
          return [...uploadedFiles];
        });
      });
      filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction = source.cancel;
    });
  }, [uploadedFiles]);

  useEffect(() => {
    if (fileDropAreaElem.current) {
      const prevents = e => e.preventDefault();

      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(e => {
        fileDropAreaElem.current.addEventListener(e, prevents);
      });

      fileDropAreaElem.current.addEventListener('drop', e => handleFilePicker(e));

    }
  }, []);

  const handleFilePicker = useCallback(e => {
    if (!e) return
    let files = [];
    if (e.target && e.target.files && e.target.files.length) files = [...e.target.files];
    else if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length) files = [...e.dataTransfer.files];
    if (!files.length) return;

    const cleanValidatedFiles = [];
    files.forEach(file => {
      const whitelistFileTypes = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf'];
      const maxSize = 50000000; //50mb
      if (!whitelistFileTypes.find(type => type === file.type)) dispatch(setErrorDialogMessage('File can be only type of: JPG, PNG or PDF.'));
      else if (file.size > maxSize) dispatch(setErrorDialogMessage('Maximum size of the file is 50MB.'));
      else cleanValidatedFiles.push(file);
    });

    setUploadedFiles(prevState => {
      const uploadedFiles = [...prevState]; 

      cleanValidatedFiles.forEach(file => {
        const requestMetadataId = uuidv4() + Date.now();
        uploadedFiles.push({
          urls: {
            fullHdSize: null,
            thumbnailSize: null,
            file: null,
          },
          name: file.name,
          uploadFinish: false,
          uploadPercentages: 0,
          requestMetadataId,
          errored: false
        });
        filesRequestMetadata.current[requestMetadataId] = {
          requestPayload: file,
          requestCancelFunction: null
        };
      });

      return [ ...uploadedFiles ];
    });
  }, [dispatch]);

  return (
    <React.Fragment>
      {
        !fullDrawerModalMode &&
        <>
          <Helmet title={ isEdit ? 'Edit Truck Driver' : 'Add Truck Driver' }/>
          <Typography variant="h3" gutterBottom display="inline">
            { isEdit ? 'Edit Truck Driver' : 'Add Truck Driver' }
          </Typography>

          {
            isEdit
            ?
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-2)}>
                  Truck Drivers
                </Link>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                  Truck Driver Details
                </Link>
                <Typography>Edit Truck Driver</Typography>
              </Breadcrumbs>
            :
              <Breadcrumbs aria-label="Breadcrumb" mt={2}>
                <Link to={""} component={NavLink} onClick={() => navigate(-1)}>
                Truck Drivers
                </Link>
                <Typography>Add Truck Driver</Typography>
              </Breadcrumbs>
          }

          <Divider my={6} />
        </>
      }

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form onSubmit={handleSubmit}>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                  Driver Information
                </Typography>
                <div style={{ display: 'flex' }}>
                  <TextField
                    name="firstName"
                    label="First Name*"
                    value={values.firstName}
                    style={{ flex: 1 }}
                    error={Boolean(touched.firstName && errors.firstName)}
                    fullWidth
                    helperText={touched.firstName && errors.firstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="lastName"
                    label="Last Name*"
                    value={values.lastName}
                    style={{ flex: 1, marginLeft: 10 }}
                    error={Boolean(touched.lastName && errors.lastName)}
                    fullWidth
                    helperText={touched.lastName && errors.lastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="ssn"
                    label="SSN#*"
                    value={values.ssn}
                    error={Boolean(touched.ssn && errors.ssn)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.ssn && errors.ssn}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  /> 
                  <TextField
                    name="driverLicence"
                    label="Driver Licence#*"
                    value={values.driverLicence}
                    error={Boolean(touched.driverLicence && errors.driverLicence)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.driverLicence && errors.driverLicence}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                  <div style={{ marginBottom: 8, marginTop: 8, marginLeft: 10, flex: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel>Licence State*</InputLabel>
                      <Select
                        label="Licence State*"
                        value={values.licenceState}
                        error={Boolean(touched.licenceState && errors.licenceState)}
                        fullWidth
                        helperText={touched.licenceState && errors.licenceState}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="licenceState"
                      >
                        <MenuItem value="-1">Select</MenuItem>
                        {
                          driverLicenceStates.map(item => <MenuItem value={item.value}>{item.text}</MenuItem>)
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <DesktopDatePicker
                    label="Licence Expiration*"
                    inputFormat="MM/dd/yyyy"
                    fullWidth
                    value={values.licenceExpiration} 
                    onChange={value => handleChange({ target: {value, name: 'licenceExpiration' }})}
                    renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params} helperText={touched.licenceExpiration && errors.licenceExpiration} error={Boolean(touched.licenceExpiration && errors.licenceExpiration)} />}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="yearsOfExperience"
                    label="Years of Experience*"
                    type="number"
                    value={values.yearsOfExperience}
                    error={Boolean(touched.yearsOfExperience && errors.yearsOfExperience)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.yearsOfExperience && errors.yearsOfExperience}
                    onBlur={handleBlur}
                    onChange={e => {
                      if (e && e.target && (e.target.value !== null && e.target.value !== undefined && e.target.value < 0)) {
                        e.target.value = 0;
                      }
                      handleChange(e);
                    }}
                    variant="outlined"
                    my={2}
                  /> 
                  <TextField
                    name="contractorEIN"
                    label="Contractor EIN"
                    value={values.contractorEIN}
                    error={Boolean(touched.contractorEIN && errors.contractorEIN)}
                    style={{ flex: 1, marginLeft: 10, marginRight: 10 }}
                    helperText={touched.contractorEIN && errors.contractorEIN}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                    InputProps={{
                      inputComponent: TextMaskCustom,
                    }}
                  />
                  <DesktopDatePicker
                    label="Hire Date*"
                    inputFormat="MM/dd/yyyy"
                    value={values.hireDate}
                    fullWidth
                    onChange={value => handleChange({ target: {value, name: 'hireDate' }})}
                    renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params} error={Boolean(touched.hireDate && errors.hireDate)} helperText={touched.hireDate && errors.hireDate} />}
                    variant="outlined"
                    my={2}
                  />
                  <DesktopDatePicker
                    label="Termination Date"
                    inputFormat="MM/dd/yyyy"
                    value={values.terminationDate}
                    fullWidth
                    onChange={value => handleChange({ target: {value, name: 'terminationDate' }})}
                    renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(touched.terminationDate && errors.terminationDate)} helperText={touched.terminationDate && errors.terminationDate} />}
                    variant="outlined"
                    my={2}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <TextField
                    name="address"
                    label="Address*"
                    value={values.address}
                    style={{ flex: 2.04 }}
                    error={Boolean(touched.address && errors.address)}
                    fullWidth
                    helperText={touched.address && errors.address}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="city"
                    label="City*"
                    value={values.city}
                    error={Boolean(touched.city && errors.city)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.city && errors.city}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                  <TextField
                    name="state"
                    label="State*"
                    value={values.state}
                    error={Boolean(touched.state && errors.state)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.state && errors.state}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="zip"
                    label="Zip*"
                    value={values.zip}
                    error={Boolean(touched.zip && errors.zip)}
                    style={{ flex: 1, marginLeft: 10 }}
                    helperText={touched.zip && errors.zip}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                </div>
                <div style={{ display: 'flex' }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+1</InputAdornment>
                    }}
                    name="phoneNumber"
                    label="Phone Number*"
                    value={values.phoneNumber}
                    error={Boolean(touched.phoneNumber && errors.phoneNumber)}
                    style={{ flex: 1.5 }}
                    helperText={touched.phoneNumber && errors.phoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                  <TextField
                    name="phoneNumberExtension"
                    label="Extension"
                    value={values.phoneNumberExtension}
                    error={Boolean(touched.phoneNumberExtension && errors.phoneNumberExtension)}
                    style={{ marginLeft: 10 }}
                    helperText={touched.phoneNumberExtension && errors.phoneNumberExtension}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  {
                    !isEdit &&
                    <TextField
                      name="email"
                      label="Email*"
                      value={values.email}
                      style={{ flex: 1.5, marginLeft: 10 }}
                      error={Boolean(touched.email && errors.email)}
                      fullWidth
                      helperText={touched.email && errors.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      type="email"
                      variant="outlined"
                      my={2}
                    />
                  }
                </div>
              </CardContent>
            </Card>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                  Emergency Contact
                </Typography>
                <div style={{ display: 'flex' }}>
                  <TextField
                    InputProps={{
                      startAdornment: <InputAdornment position="start">+1</InputAdornment>
                    }}
                    name="emergencyPhoneNumber"
                    label="Phone Number"
                    value={values.emergencyPhoneNumber}
                    error={Boolean(touched.emergencyPhoneNumber && errors.emergencyPhoneNumber)}
                    style={{ flex: 1 }}
                    helperText={touched.emergencyPhoneNumber && errors.emergencyPhoneNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  /> 
                  <TextField
                    name="emergencyFirstName"
                    label="First Name"
                    value={values.emergencyFirstName}
                    style={{ flex: 1, marginLeft: 10 }}
                    error={Boolean(touched.emergencyFirstName && errors.emergencyFirstName)}
                    fullWidth
                    helperText={touched.emergencyFirstName && errors.emergencyFirstName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="emergencyLastName"
                    label="Last Name"
                    value={values.emergencyLastName}
                    style={{ flex: 1, marginLeft: 10 }}
                    error={Boolean(touched.emergencyLastName && errors.emergencyLastName)}
                    fullWidth
                    helperText={touched.emergencyLastName && errors.emergencyLastName}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                  <TextField
                    name="emergencyRelationship"
                    label="Relationship"
                    value={values.emergencyRelationship}
                    style={{ flex: 1, marginLeft: 10 }}
                    error={Boolean(touched.emergencyRelationship && errors.emergencyRelationship)}
                    fullWidth
                    helperText={touched.emergencyRelationship && errors.emergencyRelationship}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    my={2}
                  />
                </div>
              </CardContent>
            </Card>
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                  Company Information
                </Typography>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel>Driver Type*</InputLabel>
                      <Select
                        label="Driver Type*"
                        value={values.driverType}
                        error={Boolean(touched.driverType && errors.driverType)}
                        fullWidth
                        helperText={touched.driverType && errors.driverType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="driverType"
                      >
                        <MenuItem value="-1">Select</MenuItem>
                        {
                          Object.keys(driverTypes).map(item => (
                            <MenuItem value={driverTypes[item].key}>{driverTypes[item].display}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 10 }}>
                    <FormControl fullWidth>
                      <InputLabel>Payment Type*</InputLabel>
                      <Select
                        label="Payment Type*"
                        value={values.driverPaymentType}
                        error={Boolean(touched.driverPaymentType && errors.driverPaymentType)}
                        fullWidth
                        helperText={touched.driverPaymentType && errors.driverPaymentType}
                        onBlur={handleBlur}
                        onChange={e => {
                          const value = e.target.value;
                          handleChange({ target: {value, name: 'driverPaymentType' }});
                          handleChange({ target: {value: '-1', name: 'accountingPaymentTypeId' }});
                          window.setTimeout(() => dispatch(fetchAvailablePaymentTypesData({ paymentType: value })), 0);
                        }}
                        variant="outlined"
                        my={2}
                        name="driverPaymentType"
                      >
                        {
                          Object.keys(driverPaymentTypes).map(item => (
                            <MenuItem value={driverPaymentTypes[item].key}>{driverPaymentTypes[item].display}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 10 }}>
                    <FormControl fullWidth>
                      <InputLabel>Payment*</InputLabel>
                      <Select
                        label="Payment*"
                        value={values.accountingPaymentTypeId}
                        error={Boolean(touched.accountingPaymentTypeId && errors.accountingPaymentTypeId)}
                        fullWidth
                        helperText={touched.accountingPaymentTypeId && errors.accountingPaymentTypeId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="accountingPaymentTypeId"
                      >
                        {
                          currentSelectedTruckDriver &&
                          currentSelectedTruckDriver.accountingPaymentType &&
                          currentSelectedTruckDriver.accountingPaymentType.paymentType === values.driverPaymentType
                          ?
                            <MenuItem value={currentSelectedTruckDriver.accountingPaymentTypeId}>
                              {
                                currentSelectedTruckDriver &&
                                currentSelectedTruckDriver.accountingPaymentType &&
                                currentSelectedTruckDriver.accountingPaymentType.description
                                ?
                                currentSelectedTruckDriver.accountingPaymentType.description
                                :
                                ''
                              }
                            </MenuItem>
                          :
                            !currentSelectedTruckDriver
                            ?
                            <MenuItem value="-1">Select</MenuItem>
                            :
                            <></>
                        }
                        {
                          availablePaymentTypesData
                          ?
                          availablePaymentTypesData.map(item => {
                            if (currentSelectedTruckDriver && currentSelectedTruckDriver.accountingPaymentTypeId && item.id === currentSelectedTruckDriver.accountingPaymentTypeId) return <></>;
                            return <MenuItem value={item.id}>{item.description}</MenuItem>
                          })
                          :
                            ''
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 10 }}>
                    <FormControl fullWidth>
                      <InputLabel>Operation Type*</InputLabel>
                      <Select
                        label="Operation Type*"
                        value={values.driverOperationType}
                        error={Boolean(touched.driverOperationType && errors.driverOperationType)}
                        fullWidth
                        helperText={touched.driverOperationType && errors.driverOperationType}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="driverOperationType"
                      >
                        <MenuItem value="-1">Select</MenuItem>
                        {
                          Object.keys(driverOperationTypes).map(item => (
                            <MenuItem value={driverOperationTypes[item].key}>{driverOperationTypes[item].display}</MenuItem>
                          ))
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 10 }}>
                    <FormControl fullWidth>
                      <InputLabel>Truck*</InputLabel>
                      <Select
                        label="Truck*"
                        value={values.driverSelectedTruckId}
                        error={Boolean(touched.driverSelectedTruckId && errors.driverSelectedTruckId)}
                        fullWidth
                        helperText={touched.driverSelectedTruckId && errors.driverSelectedTruckId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="driverSelectedTruckId"
                      >
                        {
                          currentSelectedTruckDriver
                          ?
                            <MenuItem value={currentSelectedTruckDriver.driverSelectedTruckId}>
                              {
                                currentSelectedTruckDriver &&
                                currentSelectedTruckDriver.driverSelectedTruck &&
                                currentSelectedTruckDriver.driverSelectedTruck.equipmentNumber
                                ?
                                `${currentSelectedTruckDriver.driverSelectedTruck.equipmentNumber}`
                                :
                                ''
                              }
                              {
                                currentSelectedTruckDriver &&
                                currentSelectedTruckDriver.driverSelectedTruck &&
                                currentSelectedTruckDriver.driverSelectedTruck.model
                                ?
                                ' ' + currentSelectedTruckDriver.driverSelectedTruck.model
                                :
                                ''
                              }
                            </MenuItem>
                          :
                            <MenuItem value="-1">Select</MenuItem>
                        }
                        {
                          availableEquipmentData && availableEquipmentData.trucks
                          ?
                          availableEquipmentData.trucks.map(item => (
                            <MenuItem value={item.id}>{item.equipmentNumber + ' ' + item.model}</MenuItem>
                          ))
                          :
                            ''
                        }
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 10 }}>
                    <FormControl fullWidth>
                      <InputLabel>Trailer*</InputLabel>
                      <Select
                        label="Truck*"
                        value={values.driverSelectedTrailerId}
                        error={Boolean(touched.driverSelectedTrailerId && errors.driverSelectedTrailerId)}
                        fullWidth
                        helperText={touched.driverSelectedTrailerId && errors.driverSelectedTrailerId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="driverSelectedTrailerId"
                      >
                        {
                          currentSelectedTruckDriver
                          ?
                            <MenuItem value={currentSelectedTruckDriver.driverSelectedTrailerId}>
                              {
                                currentSelectedTruckDriver &&
                                currentSelectedTruckDriver.driverSelectedTrailer &&
                                currentSelectedTruckDriver.driverSelectedTrailer.equipmentNumber
                                ?
                                `${currentSelectedTruckDriver.driverSelectedTrailer.equipmentNumber}`
                                :
                                ''
                              }
                              {
                                currentSelectedTruckDriver &&
                                currentSelectedTruckDriver.driverSelectedTrailer &&
                                currentSelectedTruckDriver.driverSelectedTrailer.model
                                ?
                                ' ' + currentSelectedTruckDriver.driverSelectedTrailer.model
                                :
                                ''
                              }
                            </MenuItem>
                          :
                            <MenuItem value="-1">Select</MenuItem>
                        }
                        {
                          availableEquipmentData && availableEquipmentData.trailers
                          ?
                          availableEquipmentData.trailers.map(item => (
                            <MenuItem value={item.id}>{item.equipmentNumber + ' ' + item.model}</MenuItem>
                          ))
                          :
                            ''
                        }
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1 }}>
                    <FormControl fullWidth>
                      <InputLabel>Status*</InputLabel>
                      <Select
                        label="Status*"
                        value={values.isActive}
                        error={Boolean(touched.isActive && errors.isActive)}
                        fullWidth
                        helperText={touched.isActive && errors.isActive}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="isActive"
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                  <div style={{ marginBottom: 8, marginTop: 8, flex: 1, marginLeft: 8 }}>
                    <FormControl fullWidth>
                      <InputLabel>Login Access*</InputLabel>
                      <Select
                        label="Login Access*"
                        value={values.loginAccess}
                        error={Boolean(touched.loginAccess && errors.loginAccess)}
                        fullWidth
                        helperText={touched.loginAccess && errors.loginAccess}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="loginAccess"
                        
                      >
                        <MenuItem value={true}>Active</MenuItem>
                        <MenuItem value={false}>Not Active</MenuItem>
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </CardContent>
            </Card>

            {
              eldSolutionsEnabled && 
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                    ELD Information
                  </Typography>
                  <FormControl fullWidth>
                      <InputLabel>ELD Driver ID*</InputLabel>
                      <Select
                        label="ELD Driver ID*"
                        value={values.eldDriverId}
                        error={Boolean(touched.eldDriverId && errors.eldDriverId)}
                        fullWidth
                        helperText={touched.eldDriverId && errors.eldDriverId}
                        onBlur={handleBlur}
                        onChange={handleChange}
                        variant="outlined"
                        my={2}
                        name="eldDriverId"
                      >
                        <MenuItem value="-1">Select</MenuItem>
                        {
                          eldSolutionsVehiclesData.map(item => <MenuItem value={item.vehicleName}>{`${item.vehicleName} - ${item.make} ${item.model}`}</MenuItem>)
                        }
                      </Select>
                  </FormControl>
                </CardContent>
              </Card>
            }
            {
              efsEnabled && 
              <Card mb={6}>
                <CardContent>
                  <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                    EFS Information
                  </Typography>
                  <div style={{ marginBottom: 10 }}>
                    <Typography variant="p">
                      Card Number: <span style={values?.efsCardNumber ? { fontWeight: 'bold' } : { fontStyle: 'italic' }}>{ values?.efsCardNumber ? values?.efsCardNumber : 'none' }</span>
                    </Typography>
                  </div>
                  <div>
                    <Button 
                      variant="contained"
                      startIcon={<AddIcon />}
                      size="small"
                      onClick={openSearchEFSCardsSelectList}
                      disabled={requestBusy}
                    >
                      Set Card
                    </Button>
                  </div>
                </CardContent>
              </Card>
            }
            <Card mb={6}>
              <CardContent>
                <Typography variant="h6" gutterBottom style={{ marginBottom: 20 }}>
                  Other Information
                </Typography>
                <div style={{ display: 'flex' }}>
                  <DesktopDatePicker
                    label="Last MVR*"
                    inputFormat="MM/dd/yyyy"
                    value={values.lastMVR}
                    fullWidth
                    onChange={value => handleChange({ target: {value, name: 'lastMVR' }})}
                    renderInput={(params) => <TextField my={2} style={{ flex: 1 }} {...params}  error={Boolean(touched.lastMVR && errors.lastMVR)}                     helperText={touched.lastMVR && errors.lastMVR} />}
                    variant="outlined"
                    my={2}
                  />
                  <DesktopDatePicker
                    label="Drug Test Date*"
                    inputFormat="MM/dd/yyyy"
                    value={values.drugTestDate}
                    fullWidth
                    onChange={value => handleChange({ target: {value, name: 'drugTestDate' }})}
                    renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(touched.drugTestDate && errors.drugTestDate)} helperText={touched.drugTestDate && errors.drugTestDate} />}
                    variant="outlined"
                    my={2}
                  />
                  <DesktopDatePicker
                    label="Medical Card Expiration*"
                    inputFormat="MM/dd/yyyy"
                    value={values.medicalCardExpiration}
                    fullWidth
                    onChange={value => handleChange({ target: {value, name: 'medicalCardExpiration' }})}
                    renderInput={(params) => <TextField my={2} style={{ flex: 1, marginLeft: 10 }} {...params} error={Boolean(touched.medicalCardExpiration && errors.medicalCardExpiration)} helperText={touched.medicalCardExpiration && errors.medicalCardExpiration} />}
                    variant="outlined"
                    my={2}
                  />
                </div>
                {
                  uploadedFiles &&
                  Array.isArray(uploadedFiles) &&
                  uploadedFiles.length
                  ?
                  <div style={{ marginTop: 15, marginBottom: 25, width: '50%' }}>
                    {
                      uploadedFiles.map((file, index) => (
                        <Paper key={file.requestMetadataId ? file.requestMetadataId : index} style={{ padding: 15, backgroundColor: '#eee', marginBottom: 25 }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <div style={{ marginRight: 10 }}>
                              <TextField
                                disabled={file.uploadFinish === false}
                                label="Name"
                                fullWidth
                                variant="outlined"
                                size="small"
                                value={file.name}
                                onChange={e => {
                                  const value = e.target.value;
                                  setUploadedFiles(prevState => {
                                    const uploadedFiles = [...prevState];
                                    if (uploadedFiles && uploadedFiles[index]) uploadedFiles[index].name = value;
                                    return uploadedFiles;
                                  });
                                }}
                              />
                            </div>
                            <div style={{ display: 'flex' }}>
                              {
                                file.uploadFinish || file.uploadFinish !== false 
                                ?
                                  <>
                                    <div>
                                      <IconButton onClick={() => {
                                        setFilePreviewData(() => ({
                                          url: file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : '',
                                          type: file && file.urls && file.urls.file ? 'pdf' : 'image'
                                        }));
                                      }}>
                                        <RemoveRedEyeIcon />
                                      </IconButton>
                                    </div>
                                    <div>
                                      <IconButton onClick={() => {
                                        let url = file && file.urls && file.urls.file ? file.urls.file : file && file.urls && file.urls.fullHdSize ? file.urls.fullHdSize : null;
                                        if (!url) return;
                                        if (file.name) url += `&fileName=${file.name}`;
                                        const a = document.createElement('a');
                                        a.href = url;
                                        a.download = file.name;
                                        document.body.appendChild(a);
                                        a.click();
                                        document.body.removeChild(a);
                                      }}>
                                        <DownloadIcon />
                                      </IconButton>
                                    </div>
                                  </>
                                :
                                  ''
                              }
                              <div>
                                <IconButton onClick={() => {
                                  setDeleteFileHandler(() => () => {
                                    if (file.requestMetadataId && filesRequestMetadata.current[file.requestMetadataId] && filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction) {
                                      filesRequestMetadata.current[file.requestMetadataId].requestCancelFunction();
                                      delete filesRequestMetadata.current[file.requestMetadataId];
                                    }
                                    setUploadedFiles(prevState => {
                                      const uploadedFiles = [...prevState];
                                      if (uploadedFiles && uploadedFiles[index]) uploadedFiles.splice(index, 1);
                                      return uploadedFiles;
                                    });
                                  });
                                }}>
                                  <DeleteIcon />
                                </IconButton>
                              </div>
                            </div>
                          </div>
                          {
                            file.uploadFinish === false &&
                            <div style={{ marginTop: 15 }}>
                              <LinearProgress variant="determinate" color={file.errored ? 'error' : 'primary'} value={file.errored ? 100 : file.uploadPercentages} />
                              {
                                !file.errored &&
                                <div style={{ marginTop: 5, textAlign: 'center' }}>
                                  {parseInt(file.uploadPercentages) === 100 ? 'Processing...' : 'Uploading...'}
                                </div>
                              }
                            </div>
                          }
                        </Paper>
                      ))
                    }
                  </div>
                  :
                    ''
                }
                {
                  uploadedFiles &&
                  Array.isArray(uploadedFiles) &&
                  uploadedFiles.length < 50
                  ?
                    <div
                      ref={fileDropAreaElem}
                      style={{ 
                        border: '1px dashed #000', 
                        borderRadius: 4, 
                        padding: 20,
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: '70%',
                        margin: '0 auto',
                        marginTop: 20
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon.CloudUpload fontSize="large" />
                        <Typography style={{ marginLeft: 20, marginRight: 20 }}>Drag and Drop driver licence or browse the file.</Typography>
                      </div>
                      <div>
                        <input 
                          type="file" 
                          id="browseFilesElem" 
                          style={{ display: 'none' }}
                          onChange={handleFilePicker}
                        />
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => {
                            const elem = document.getElementById('browseFilesElem');
                            if (!elem) return;
                            elem.click();
                          }}
                        >
                          Browse
                        </Button>
                      </div>
                    </div>
                  :
                    ''
                }
              </CardContent>
            </Card>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              mt={3}
            >
              Save changes
            </Button>
            {
              showSearchEFSCardsSelectList &&
              <SelectList 
                title="Select Card"
                openModal={showSearchEFSCardsSelectList}
                data={
                  EFSCardSummariesData?.length
                  ? 
                    EFSCardSummariesData.map(item => ({ 
                      id: item.cardNumber, 
                      item: item.cardNumber
                    })) 
                  : 
                    []
                }
                selectedValue={values?.efsCardNumber}
                handleClose={() => setShowSearchEFSCardsSelectList(false)}
                onChange={updateEFSCardNumber}
              />
            }
          </form>
        )}
      </Formik>
      <Dialog
        open={deleteFileHandler}
        onClose={() => setDeleteFileHandler(null)}
      >
        <DialogTitle>
          Are you sure?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={() => setDeleteFileHandler(null)} 
            color="primary"
          >
            No
          </Button>
          <Button 
            color="error"
            onClick={() => {
              const elem = document.getElementById('browseFilesElem');
              if (elem) elem.value = null;
              deleteFileHandler();
              setDeleteFileHandler(null);
            }} 
          >
            Yes, delete
          </Button>
        </DialogActions>
      </Dialog>
      { filePreviewData ? <FilePreview url={filePreviewData.url} type={filePreviewData.type} onClose={() => setFilePreviewData(null)} /> : '' }
    </React.Fragment>
  );
}

export default AddTruckDriver;