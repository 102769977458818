import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  errorDialogMessage: '',
  successDialogMessage: '',
  infoDialogMessage: ''
};

export const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: { 
    setErrorDialogMessage: (state, action) => {
      state.errorDialogMessage = action.payload;
      return state;
    },
    setSuccessDialogMessage: (state, action) => {
      state.successDialogMessage = action.payload;
      return state;
    },
    setInfoDialogMessage: (state, action) => {
      state.infoDialogMessage = action.payload;
      return state;
    }
  },
});

export const { 
  setErrorDialogMessage,
  setSuccessDialogMessage,
  setInfoDialogMessage
} = messagesSlice.actions;

export default messagesSlice.reducer;
