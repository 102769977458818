import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { fetchCurrentLoggedUser, refreshToken } from './currentLoggedUser';
import { parseResponseError } from '../helpers/errorHandlingHelpers';
import userRoles from "../../keys/userRoles";

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    name: '',
    loginAccess: '-1'
  },
  sorts: {
    registrationDate: 'desc'
  },
  total: 0,
  initialFetch: false,
  currentSelectedCompany: null
});

export const companiesSlice = createSlice({
  name: "companies",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setFiltersName: (state, action) => {
      state.filters.name = action.payload;
      return state;
    },
    setFiltersLoginAccess: (state, action) => {
      state.filters.loginAccess = action.payload;
      return state;
    },
    setSortsRegistrationDate: (state, action) => {
      state.sorts.registrationDate = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    setCurrentSelectedCompany: (state, action) => {
      state.currentSelectedCompany = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setData, 
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersName,
  setFiltersStatus,
  setFiltersLoginAccess,
  setSortsRegistrationDate,
  setInitialFetch,
  setCurrentSelectedCompany,
  resetState
} = companiesSlice.actions;

export const fetchCompanies = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.companies && state.companies.filters ? state.companies.filters : {};
  const sorts = state && state.companies && state.companies.sorts ? state.companies.sorts : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (filters.name) queryParams.append('name', filters.name);
  if (filters.loginAccess !== '-1') queryParams.append('loginAccess', filters.loginAccess);
  if (sorts.registrationDate) queryParams.append('registrationDateSort', sorts.registrationDate);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/companies?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
      });
  }));
};

export const addCompany = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post(
          '/api/companies',
          { data }
      )
      .then(response => {
          if (response.status === 201 && response.data && response.data.data){
              dispatch(resetState());
              window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
              dispatch(setRequestBusy(false));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const updateCompany = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/companies',
          { data }
      )
      .then(response => {
          if (response.status === 200) {
              dispatch(resetState());
              if (getState?.currentLoggedUser?.role === userRoles.admin.key) {
                const newData = {
                  id: getState().companies.currentSelectedCompany.id,
                  ...data
                };
                dispatch(setCurrentSelectedCompany(newData));
              } else {
                dispatch(fetchCurrentLoggedUser());
              }
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const fetchCurrentSelectedCompany = id => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/companies/${id}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedCompany(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
  }));
}; 

export const deactivateCompany = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const currentSelectedCompany = getState().companies.currentSelectedCompany;
  const data = {
    id: currentSelectedCompany.id,
    loginAccess: false,
  }
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/companies',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage('Company successfully deactivated'));
              dispatch(setCurrentSelectedCompany({
                ...currentSelectedCompany,
                ...data
              }));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }));
};

export const activateCompany = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const currentSelectedCompany = getState().companies.currentSelectedCompany;
  const data = {
    id: currentSelectedCompany.id,
    loginAccess: true,
  }
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/companies',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              dispatch(resetState());
              dispatch(setRequestBusy(false));
              dispatch(setSuccessDialogMessage('Company successfully activated'));
              dispatch(setCurrentSelectedCompany({
                ...currentSelectedCompany,
                ...data
              }));
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }));
};

export const uploadLogo = (payload, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));

  const bodyFormData = new FormData();
  bodyFormData.append('file', payload);

  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/companies/upload-logo', 
      bodyFormData, 
      {
        timeout: 1000 * 60
      }
    ).then(res => {
      if (res.data.data) {
        callback(null, res.data.data);
      } else {
        throw new Error('');
      }
    })      
    .catch(error => parseResponseError(error, dispatch))
    .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export default companiesSlice.reducer;

