import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from "./requestBusy";
import { setErrorDialogMessage, setSuccessDialogMessage } from "./messages";
import jwt_decode from 'jwt-decode';
import {
  unsetCurrentLoggedUser as unsetCachedCurrentLoggedUser,
  setCurrentLoggedUser as setCachedCurrentLoggedUser
} from '../../utils/cacheHelper';
import userRoles from "../../keys/userRoles";
import { resetState as resetCompaniesState } from './companies';
import { resetState as resetCompanyAdminsState } from './companyAdminUsers';
import { resetState as resetTruckDriversState } from './truckDrivers';
import { resetState as resetUsersState } from './users';
import { resetState as resetEquipmentState } from './equipments';
import { resetState as resetCompanyLocations } from './companyLocations';
import { resetState as resetCompanyCustomers } from './companyCustomers';
import { resetState as resetAccountingPaymentTypes } from './accountingPaymentTypes';
import { resetState as resetCompanySettings } from './companySettings';
import { resetState as resetLoads } from './loads';
import { resetState as resetInvoices } from './invoices';
import { resetState as resetBilling } from './billing';
import { resetState as resetNotifications } from './notifications';
import { resetState as resetUserFieldHistories } from './userFieldHistories';
import { resetState as resetDashboards } from './dashboards';

const initialState = null;

export const currentLoggedUserSlice = createSlice({
  name: "currentLoggedUser",
  initialState,
  reducers: {
    setCurrentLoggedUser: (state, action) => {
      setCachedCurrentLoggedUser(action.payload);
      state = action.payload;
      return state;
    },
    unsetCurrentLoggedUser: state => {
      unsetCachedCurrentLoggedUser();
      state = initialState;
      return state;
    }
  }
});

export const { 
  setCurrentLoggedUser, 
  unsetCurrentLoggedUser
} = currentLoggedUserSlice.actions;

export const attemptLogin = (email, password, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  axios.post(
    '/api/users/login', 
    {
      data: {
        email,
        password
      }
    }
  )
    .then(response => {
      if (response.status === 200 && response.data && response.data.data) {
        dispatch(setRequestBusy(false));
        if (response.data.data?.user?.role === userRoles.truckDriver.key) return dispatch(setErrorDialogMessage('This account should be used only on Mobile App.'));
        if (response.data.data.bearerToken) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.bearerToken}`;
          if (response.data.data.user) response.data.data.user.bearerToken = response.data.data.bearerToken;
        }
        if (response.data.data.user) {
          dispatch(setCurrentLoggedUser(response.data.data.user));
        }
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => { 
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      dispatch(setRequestBusy(false));
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(errorMessage);
    })
};

export const refreshToken = (callback = () => {}) => (dispatch, getState) => {
  const currentLoggedUser = getState().currentLoggedUser;
  if (!currentLoggedUser || !currentLoggedUser.bearerToken) return dispatch(setRequestBusy(false));
  const decodedToken = jwt_decode(currentLoggedUser.bearerToken);
  if (!decodedToken.exp || Date.now() >= (decodedToken.exp * 1000)) {
    axios.get('/api/users/refresh-token')
      .then(response => {
        if (response.status === 200 && response.data.data) {
          if (response.data.data.bearerToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.bearerToken}`;
            if (response.data.data.user) response.data.data.user.bearerToken = response.data.data.bearerToken;
          }
          if (response.data.data.user) {
            dispatch(setCurrentLoggedUser(response.data.data.user));
          }
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => {
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        }
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
        dispatch(setRequestBusy(false));
        dispatch(attemptLogout());
      });
  } else {
    callback();
  }
};

export const attemptLogout = () => dispatch => {
  axios.defaults.headers.common['Authorization'] = '';
  dispatch(unsetCurrentLoggedUser());
  window.setTimeout(() => {
    dispatch(resetCompaniesState());
    dispatch(resetCompanyAdminsState());
    dispatch(resetTruckDriversState());
    dispatch(resetUsersState());
    dispatch(resetEquipmentState());
    dispatch(resetCompanyCustomers());
    dispatch(resetCompanyLocations());
    dispatch(resetAccountingPaymentTypes());
    dispatch(resetCompanySettings())
    dispatch(resetLoads());
    dispatch(resetInvoices());
    dispatch(resetBilling());
    dispatch(resetNotifications());
    dispatch(resetUserFieldHistories());
    dispatch(resetDashboards());
  }, 100);
}

export const fetchCurrentLoggedUser = () => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get('/api/users/get-current-logged-user')
    .then(response => {
      if (response.status === 200 && response.data) {
        if (response.data.data.bearerToken) {
          axios.defaults.headers.common['Authorization'] = `Bearer ${response.data.data.bearerToken}`;
          if (response.data.data.user) response.data.data.user.bearerToken = response.data.data.bearerToken;
        }
        if (response.data.data.user) {
          dispatch(setCurrentLoggedUser(response.data.data.user));
        }
      } else {
        throw new Error('');
      }
    })
    .catch(() => {});
  }));
};

export const changePassword = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  const {
    password,
    email,
    resetCode
  } = data;
  const bodyData = {
    data: {
      password
    }
  };
  let endpoint = '/api/users/change-password?forceChangePassword=1';
  if (resetCode && email) {
    bodyData.data.resetCode = resetCode;
    bodyData.data.email = email;
    endpoint = '/api/users/reset-change-password';
  }
  axios.post(endpoint, bodyData)
    .then(response => {
      if (response.status === 200) {
        dispatch(setRequestBusy(false));
        dispatch(fetchCurrentLoggedUser());
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => { 
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      dispatch(setRequestBusy(false));
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(errorMessage);
    })
};

export const changePasswordProfile = (password, oldPassword, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/users/change-password', {
      data: {
        password,
        oldPassword
      }
    })
      .then(response => {
        if (response.status === 200) {
          dispatch(setRequestBusy(false));
          if (response.data && response.data.messages && response.data.messages[0]) {
            window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          }
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
      })
  }));
};

export const uploadAvatar = (payload, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));

  const bodyFormData = new FormData();
  bodyFormData.append('file', payload);

  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/users/upload-avatar', 
      bodyFormData, 
      {
        timeout: 1000 * 60
      }
    ).then(res => {
      dispatch(setRequestBusy(false));
      if (res.data.data) {
        const { currentLoggedUser } = getState();
        dispatch(setCurrentLoggedUser({
          ...currentLoggedUser,
          ...res.data.data
        }));
        callback();
      } else {
        throw new Error('');
      }
    }).catch((error) => {
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      dispatch(setRequestBusy(false));
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(errorMessage);
    });
  }));
};

export const deleteAvatar = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));

  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/users/delete-avatar').then(res => {
      dispatch(setRequestBusy(false));
      if (res.data.data) {
        const { currentLoggedUser } = getState();
        dispatch(setCurrentLoggedUser({
          ...currentLoggedUser,
          ...res.data.data
        }));
        callback();
      } else {
        throw new Error('');
      }
    }).catch((error) => {
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      dispatch(setRequestBusy(false));
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(error);
    });
  }));
};

export const currentLoggedUserReset2faViaMail = (callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return  dispatch(setRequestBusy(false));
    axios.post('/api/users/reset-2fa-via-email').then(res => {
      dispatch(setRequestBusy(false));
      if (res.data.data) {
        const { currentLoggedUser } = getState();
        dispatch(setCurrentLoggedUser({
          ...currentLoggedUser,
          ...res.data.data
        }));
        callback();
      } else {
        throw new Error('');
      }
    }).catch((error) => {
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      dispatch(setRequestBusy(false));
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(error);
    });
  }));
};

export const sendResetPassword = (email, callback = () => {}) => (dispatch, getState) => {
  const { requestBusy } = getState();
  if (requestBusy) return;
  dispatch(setRequestBusy(true));
  axios.post(
    '/api/users/reset-password', 
    {
      data: {
        email
      }
    }
  )
    .then(response => {
      if (response.status === 200) {
        dispatch(setSuccessDialogMessage('Instructions are sent on email.'));
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => { 
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(errorMessage);
    })
    .finally(() => dispatch(setRequestBusy(false)));
};

export const currentLoggedUserEnable2fa = (phoneNumber2fa, onlyVerification = false, callback = () => {}) => (dispatch, getState) => {
  const { requestBusy } = getState();
  if (requestBusy) return;
  dispatch(setRequestBusy(true));
  const queryString = new URLSearchParams();
  if (onlyVerification) queryString.append('onlyVerification', '1');
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      `/api/users/enable-2fa?${queryString.toString()}`, 
      {
        data: {
          phoneNumber2fa
        }
      }
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          dispatch(setCurrentLoggedUser({
            ...currentLoggedUser,
            ...response.data.data
          }));
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const currentLoggedUserVerify2fa = (code2fa, showSuccessMessage, callback = () => {}) => (dispatch, getState) => {
  const { requestBusy } = getState();
  if (requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post(
      '/api/users/verify-2fa', 
      {
        data: {
          code2fa
        }
      }
    )
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          dispatch(setCurrentLoggedUser({
            ...currentLoggedUser,
            ...response.data.data
          }));
          if (showSuccessMessage) window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const currentLoggedUserDisable2fa = (callback = () => {}) => (dispatch, getState) => {
  const { requestBusy } = getState();
  if (requestBusy) return;
  dispatch(setRequestBusy(true));
  dispatch(refreshToken(err => {
    if (err) return dispatch(setRequestBusy(false));
    axios.post('/api/users/disable-2fa')
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          const { currentLoggedUser } = getState();
          dispatch(setCurrentLoggedUser({
            ...currentLoggedUser,
            ...response.data.data
          }));
          window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 200);
          callback();
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
        callback(errorMessage);
      })
      .finally(() => dispatch(setRequestBusy(false)));
  }));
};

export const disable2faViaEmail = (resetCode2fa, userId, callback = () => {}) => (dispatch, getState) => {
  dispatch(setRequestBusy(true));
  axios.post('/api/users/disable-2fa-via-email', { data: {resetCode2fa, userId} })
    .then(response => {
      if (response.status === 200 && response.data && response.data.data) {
        const { currentLoggedUser } = getState();
        if (currentLoggedUser) {
          dispatch(setCurrentLoggedUser({
            ...currentLoggedUser,
            ...response.data.data
          }));
        }
        callback();
      } else {
        throw new Error('');
      }
    })
    .catch(error => { 
      let errors = [];
      if (
        error && 
        error.response && 
        error.response.data && 
        error.response.data.messages && 
        Array.isArray(error.response.data.messages) && 
        error.response.data.messages.length
      ) {
        errors = error.response.data.messages;
      } else {
        errors = ['Error']
      }
      const errorMessage = errors.join('. ');
      dispatch(setErrorDialogMessage(errorMessage));
      callback(errorMessage);
    })
    .finally(() => dispatch(setRequestBusy(false)));
};

export default currentLoggedUserSlice.reducer;
