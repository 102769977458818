import React from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import reduceChildRoutes from "./reduceChildRoutes";

const SidebarNavList = (props) => {
  const { pages, depth } = props;
  const router = useLocation();
  const currentRoute = router.pathname;
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);

  const mutatedPages = [...pages].filter(page => page.access.find(item => item === currentLoggedUser?.role));

  const childRoutes = mutatedPages.reduce(
    (items, page) => reduceChildRoutes({ items, page, currentRoute, depth }),
    []
  );

  return <React.Fragment>{childRoutes}</React.Fragment>;
};

export default SidebarNavList;
