import { configureStore } from "@reduxjs/toolkit";
import currentLoggedUser from "./slices/currentLoggedUser";
import messages from "./slices/messages";
import requestBusy from "./slices/requestBusy";
import companies from "./slices/companies";
import companyAdminUsers from "./slices/companyAdminUsers";
import users from "./slices/users";
import truckDrivers from "./slices/truckDrivers";
import truckDriversMap from "./slices/truckDriversMap";
import equipments from "./slices/equipments";
import companyLocations from './slices/companyLocations';
import companyCustomers from './slices/companyCustomers';
import accountingPaymentTypes from './slices/accountingPaymentTypes';
import companySettings from './slices/companySettings';
import loadNotes from './slices/loadNotes';
import forceShowSpinner from './slices/forceShowSpinner';
import dashboardElasticSearch from './slices/dashboardElasticSearch';
import historyStack from './slices/historyStack';
import loads from './slices/loads';
import invoices from './slices/invoices';
import billing from './slices/billing';
import notifications from './slices/notifications';
import userFieldHistories from "./slices/userFieldHistories";
import fullDrawerModal from './slices/fullDrawerModal';
import dashboards from './slices/dashboards';
import logger from 'redux-logger';

const middleware = [];

if (process.env.NODE_ENV === 'development') middleware.push(logger);

export const store = configureStore({
  reducer: {
    currentLoggedUser,
    messages,
    requestBusy,
    companies,
    companyAdminUsers,
    users,
    truckDrivers,
    truckDriversMap,
    equipments,
    companyLocations,
    companyCustomers,
    accountingPaymentTypes,
    companySettings,
    loads,
    loadNotes,
    forceShowSpinner,
    dashboardElasticSearch,
    historyStack,
    invoices,
    billing,
    notifications,
    userFieldHistories,
    fullDrawerModal,
    dashboards
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middleware),
});
