import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { spacing } from "@mui/system";

import { 
  Paper, 
  Typography ,
  Button,
  TextField as MuiTextField,
  IconButton
} from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';

import { attemptLogout, changePassword } from "../../redux/slices/currentLoggedUser";
import { useDispatch, useSelector } from "react-redux";
import { setSuccessDialogMessage } from "../../redux/slices/messages";

import AemLogo from '../../vendor/aem-logo.png';
import * as Yup from "yup";
import { Formik } from "formik";
import regularExpressions from "../../utils/regularExpressions";

const Brand = styled.img`
  width: 150px;
  margin-bottom: 30px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};
  width: 550px;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

const TextField = styled(MuiTextField)(spacing);

function SetNewPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const queryString = new URLSearchParams(window.location.search);
  const resetCode = queryString.get('resetCode');
  const email = queryString.get('email');
  if ((currentLoggedUser && !currentLoggedUser.forceSetNewPassword) && !resetCode && !email) {
    window.setTimeout(() => navigate('/', { replace: true }), 0);
    return <></>;
  }
  return (
    <React.Fragment>
      <Brand src={AemLogo} alt="" />
      <Wrapper>
        <Helmet title="Set New Password" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
          Set Password
        </Typography>
        <Typography component="h2" variant="body1" align="center">
          Set your new password
        </Typography>

        <Formik
          initialValues={{
            password: '',
            confirmPassword: ''
          }}
          validationSchema={Yup.object().shape({
            password: Yup.string().matches(regularExpressions.PASSWORD, "Must be at least 8 characters, one number, one uppercase and one symbol").max(255).required("Required"),
            confirmPassword: Yup.string().required("Required").when("password", {
              is: (val) => (val && val.length > 0 ? true : false),
              then: Yup.string().oneOf(
                [Yup.ref("password")],
                "Both password need to be the same"
              ),
            }),
          })}
          onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
            try {
              dispatch(changePassword({ password: values.password, resetCode, email }, err => {
                if (err) return;
                window.setTimeout(() => navigate('/', { replace: true }), 0);
                window.setTimeout(() => dispatch(setSuccessDialogMessage('Password successfully changed.')), 500);
              }));
            } catch (error) {
              const message = error.message || "Something went wrong";
              setStatus({ success: false });
              setErrors({ submit: message });
              setSubmitting(false);
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            touched,
            values,
          }) => (
            <form onSubmit={handleSubmit}>
              <TextField
                type="password"
                name="password"
                label="Password"
                value={values.password}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                onBlur={handleBlur}
                onChange={handleChange}
                my={3}
              />
              <TextField
                type="password"
                name="confirmPassword"
                label="Confirm Password"
                value={values.confirmPassword}
                error={Boolean(touched.confirmPassword && errors.confirmPassword)}
                fullWidth
                helperText={touched.confirmPassword && errors.confirmPassword}
                onBlur={handleBlur}
                onChange={handleChange}
                style={{ marginBottom: 20 }}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                Save
              </Button>
            </form>
          )}
        </Formik>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <IconButton
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            onClick={() => {
              dispatch(attemptLogout());
              setTimeout(() => navigate('/', { replace: true }), 50);
            }}
            style={{ marginTop: 10 }}
          >
            <LogoutIcon />
          </IconButton>
        </div>
      </Wrapper>
    </React.Fragment>
  );
}

export default SetNewPassword;
