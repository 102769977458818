import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  show: false,
  type: null,
  closeCallback: null
};

export const fullDrawerModalSlice = createSlice({
  name: "fullDrawerModal",
  initialState,
  reducers: { 
    setShowFullDrawerModal: (state, action) => {
      state.show = action.payload;
      return state;
    },
    setFullDrawerModalType: (state, action) => {
      state.type = action.payload;
      return state;
    },
    setFullDrawerCloseCallback: (state, action) => {
      state.closeCallback = action.payload;
      return state;
    },
    setCloseDrawer: state => {
      state.show = false;
      state.type = null;
      state.closeCallback = null;
      return state;
    }
  },
});

export const { 
  setShowFullDrawerModal,
  setFullDrawerModalType,
  setFullDrawerCloseCallback,
  setCloseDrawer
} = fullDrawerModalSlice.actions;

export default fullDrawerModalSlice.reducer;
