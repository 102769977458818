import React, { useMemo } from 'react';

import getUSDFormattedCurrency from '../../../utils/getUSDFormattedCurrency';
import { getLoadBreakdownData } from '../../../utils/loadHelpers';
import loadAdditionalFeeTypes from '../../../keys/loadAdditionalFeeTypes';
import loadAdditionalFeeOperationTypes from '../../../keys/loadAdditionalFeeOperationTypes';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Divider
} from "@mui/material";

const LoadBreakdown = ({ load }) => {

  const {
    driversCost,
    totalDriversCost,
    totalAdditionalFeePrice,
    totalMiles,
    fullCosts,
    totalRevenue
  } = useMemo(() => getLoadBreakdownData(load), [load]);

  return (
    <div>
      {
        load?.driver &&
        <Table size="small" style={{ marginBottom: 10 }}>
          <TableHead>
            <TableRow>
              <TableCell>Driver(s)</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              Object.keys(driversCost).map(id => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {driversCost[id]?.driverData?.firstName} {driversCost[id]?.driverData?.lastName}
                  </TableCell>
                  <TableCell align="right">{
                    driversCost[id]?.description
                  }</TableCell>
                </TableRow>
              ))
            }
            <TableRow>
              <TableCell component="th" scope="row" style={{ border: 'none' }}>
              </TableCell>
              <TableCell align="right" style={{ border: 'none' }}>{getUSDFormattedCurrency(totalDriversCost)}</TableCell> 
            </TableRow>
          </TableBody>
        </Table>
      }
      {
        load?.loadAdditionalFees?.length ?
        <Table size="small" style={{ marginBottom: 10 }}>
          <TableHead>
            <TableRow>
              <TableCell>Additional Fee(s)</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {
              load?.loadAdditionalFees?.map(fee => (
                <TableRow>
                  <TableCell component="th" scope="row">
                    {loadAdditionalFeeTypes[fee?.type]?.display}{' '}{`(${fee?.operationType ? loadAdditionalFeeOperationTypes[fee.operationType].display : loadAdditionalFeeOperationTypes.addition.display})`}
                  </TableCell>
                  <TableCell align="right">{getUSDFormattedCurrency(fee?.value ? fee.value : 0)}</TableCell>
                </TableRow>
              ))
            }
            <TableRow>
              <TableCell component="th" scope="row" style={{ border: 'none' }}>
              </TableCell>
              <TableCell align="right" style={{ border: 'none' }}>{getUSDFormattedCurrency(totalAdditionalFeePrice)}</TableCell> 
            </TableRow>
          </TableBody>
        </Table> : ''
      }
      <div style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: 15 }}>
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
            <Typography variant="p">
              Trip Amount:
            </Typography>
            <Typography variant="p" style={{ fontWeight: 'bold' }}>
              {getUSDFormattedCurrency(load?.tripAmount ?? 0)}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
            <Typography variant="p">
              Trip Value:
            </Typography>
            <Typography variant="p" style={{ fontWeight: 'bold' }}>
              {getUSDFormattedCurrency(load?.tripValue ?? 0)}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
            <Typography variant="p">
              Total Miles:
            </Typography>
            <Typography variant="p" style={{ fontWeight: 'bold' }}>
              {totalMiles}
            </Typography>
          </div>
          <div style={{ width: 200, paddingTop: 10, paddingBottom: 10 }}>
            <Divider />
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
            <Typography variant="p">
              Costs:
            </Typography>
            <Typography variant="p" style={{ fontWeight: 'bold' }}>{}
              {getUSDFormattedCurrency(fullCosts < 0 ? 0 : fullCosts)}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between', width: 200 }}>
            <Typography variant="p">
              Revenue:
            </Typography>
            <Typography variant="p" style={{ fontWeight: 'bold' }}>{}
              {getUSDFormattedCurrency(totalRevenue < 0 ? 0 : totalRevenue)}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );

};

export default LoadBreakdown;
