import React from 'react';
import styled from 'styled-components';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import CloseIcon from '@mui/icons-material/Close';
import {
  Card as MuiCard,
  Typography,
  Divider
} from "@mui/material";
import {  spacing } from "@mui/system";

const Card = styled(MuiCard)(spacing);

const Marker = (props) => {
    const {text, firstName, lastName, makeModel, eldId, openPopup, handleSelectMarker, itemId, handleUnselectedMarker} = props;

    return ( 
      <div
        style={{ position: 'relative', color: 'black', cursor: 'pointer'}}
        text={text}
      >
      <LocalShippingIcon 
        onClick={() => handleSelectMarker(itemId)}
        style={{fontSize: 40}} 
       />  
        
       {openPopup===true ?( 
        <div style={{ 
          position: 'absolute',
          bottom: 50, 
          left: -80,
          width: 200,
          height: 130,
          zIndex: 1000
        }}> 
          <Card>
            <div style={{ padding: 10 }}>
              <CloseIcon 
                style={{ 
                  cursor: 'pointer',
                  width: 20,
                  height: 20,
                  position: 'absolute',
                  right: 5,
                  top: 5
                }} 
                onClick={() =>  handleUnselectedMarker(itemId)}
              />
              <Typography style={{ marginTop: 5, marginBottom: 5, fontSize: 17 }} variant='h6'>Truck Driver</Typography>
              <Divider 
                style={{ marginBottom: 5 }}
              />
              <div style={{ marginBottom: 4 }}>
                <Typography variant='p'>First Name: <span style={{ fontWeight: 'bold' }}>{firstName}</span></Typography>
              </div>
              <div style={{ marginBottom: 4 }}>
                <Typography variant='p'>Last Name: <span style={{ fontWeight: 'bold' }}>{lastName}</span></Typography>
              </div>
              <div style={{ marginBottom: 4 }}>
                <Typography variant='p'>Make & Model: <span style={{ fontWeight: 'bold' }}>{makeModel}</span></Typography>
              </div>
              <div style={{ marginBottom: 4 }}>
                <Typography variant='p'>Eld Id: <span style={{ fontWeight: 'bold' }}>{eldId}</span></Typography>
              </div>
            </div>
          </Card>
        </div>) : ('')}    
       </div>      
    );
}

export default Marker;
