import React from 'react';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "@mui/material";

import { setErrorDialogMessage } from '../../../redux/slices/messages';
import { setRequestBusy } from '../../../redux/slices/requestBusy';


const StripeCustomerForm = ({ setStripeClientSecret }) => {

  const dispatch = useDispatch();
  const requestBusy = useSelector(state => state.requestBusy);
  const stripe = useStripe();
  const elements = useElements();

  const handleSubmit = async () => {

    if (!stripe || !elements) return;

    dispatch(setRequestBusy(true));

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: process.env.NODE_ENV === 'production' ? `${process.env.REACT_APP_BASE_URL}/settings/billing-integrations` : `${process.env.REACT_APP_DEV_BASE_URL}/settings/billing-integrations`,
      }
    });

    if (error) dispatch(setErrorDialogMessage(error.message));
    dispatch(setRequestBusy(false));
  };

  return (
    <>
      <PaymentElement />
      <div style={{ marginTop: 15, display: 'flex', justifyContent: 'flex-end' }}>
        <Button 
          color="primary"
          onClick={() => setStripeClientSecret(null)}
          style={{ marginRight: 10 }}
          disabled={requestBusy}
        >
          Cancel
        </Button>
        <Button 
          color="primary" 
          variant="contained"
          onClick={handleSubmit}
          disabled={!stripe || requestBusy}
        >
          Save
        </Button>
      </div>
    </>
  );
  
};

export default StripeCustomerForm;
