import React, { useState } from 'react';
import moment from 'moment';
import { DesktopDateTimePicker } from '@mui/x-date-pickers/DesktopDateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from "@mui/material";
import {
  Check as CheckIcon,
  Close as CloseIcon,
  Edit as EditIcon
} from "@mui/icons-material";

const LoadActionTimestampDetails = ({ value, onChange = () => {}, allowEdit = false }) => {

  const [showEditMode, setShowEditMode] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  return (
    <div style={{ display: 'inline-block' }}>
      {value && !showEditMode ? <span style={{ fontWeight: 'bold' }}>{moment(value).format("MM/DD/YYYY HH:mm")}</span> : ''}
      {
        allowEdit
        ?
          (
            showEditMode
            ?
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDateTimePicker
                    value={localValue} 
                    onChange={value => setLocalValue(value)}
                    renderInput={(params) => <TextField size="small" my={2} {...params} />}
                    variant="outlined"
                    my={2}
                  />
                </LocalizationProvider>
                <CheckIcon 
                  style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }}
                  onClick={() => {
                    setShowEditMode(false);
                    onChange(localValue.toString());
                  }}
                />
                <CloseIcon 
                  style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }}
                  onClick={() => setShowEditMode(false)}
                />
              </div>
            :
              <EditIcon 
                style={{ fontSize: 15, cursor: 'pointer', marginLeft: 10 }}
                onClick={() => setShowEditMode(true)}
              />
          )
        :
          ''
      }
    </div>
  );

};

export default LoadActionTimestampDetails;
