import { createSlice } from "@reduxjs/toolkit";
import axios from "../../axios/config";
import { setRequestBusy } from './requestBusy';
import { 
  setErrorDialogMessage,
  setSuccessDialogMessage
} from './messages';
import { refreshToken } from './currentLoggedUser';
import { resetLoadsLocationsState } from '../slices/loads';

const getInitialState = () => ({
  data: [],
  filters: {
    limit: 10,
    skip: 0,
    companyName: '',
  },
  total: 0,
  initialFetch: false,
  currentSelectedCompanyLocation: null,
});

export const companyLocationsSlice = createSlice({
  name: "companyLocations",
  initialState: getInitialState(),
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
      return state;
    },
    setTotal: (state, action) => {
      state.total = action.payload;
      return state;
    },
    setFiltersLimit: (state, action) => {
      state.filters.limit = action.payload;
      return state;
    },
    setFiltersSkip: (state, action) => {
      state.filters.skip = action.payload;
      return state;
    },
    setFiltersCompanyName: (state, action) => {
      state.filters.companyName = action.payload;
      return state;
    },
    setInitialFetch: (state, action) => {
      state.initialFetch = action.payload;
      return state;
    },
    setCurrentSelectedCompanyLocation: (state, action) => {
      state.currentSelectedCompanyLocation = action.payload;
      return state;
    },
    resetState: () => getInitialState()
  }
});

export const { 
  setData,
  setTotal,
  setFiltersLimit,
  setFiltersSkip,
  setFiltersCompanyName,
  setInitialFetch,
  setCurrentSelectedCompanyLocation,
  resetState
} = companyLocationsSlice.actions;

export const fetchCompanyLocations = () => (dispatch, getState) => {
  if (getState().requestBusy) return;
  const state = getState();
  const filters = state && state.companyLocations && state.companyLocations.filters ? state.companyLocations.filters : {};
  dispatch(setRequestBusy(true));
  const queryParams = new URLSearchParams();
  queryParams.append('limit', filters.limit);
  queryParams.append('skip', filters.skip);
  if (filters.companyName) queryParams.append('companyName', filters.companyName);
  dispatch(refreshToken(err => {
    if (err) return dispatch(setErrorDialogMessage('Error'));
    axios.get(`/api/company-locations?${queryParams.toString()}`)
      .then(response => {
        if (response.status === 200 && response.data && response.data.data) {
          dispatch(setData(response.data.data.rows));
          dispatch(setTotal(response.data.data.total));
          dispatch(setInitialFetch(true));
          dispatch(setRequestBusy(false));
        } else {
          throw new Error('');
        }
      })
      .catch(error => { 
        let errors = [];
        if (
          error && 
          error.response && 
          error.response.data && 
          error.response.data.messages && 
          Array.isArray(error.response.data.messages) && 
          error.response.data.messages.length
        ) {
          errors = error.response.data.messages;
        } else {
          errors = ['Error']
        }
        dispatch(setRequestBusy(false));
        const errorMessage = errors.join('. ');
        dispatch(setErrorDialogMessage(errorMessage));
      });
  }));
};

export const addCompanyLocation = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.post(
          '/api/company-locations',
          { data }
      )
      .then(response => {
          if (response.status === 201 && response.data && response.data.data){
              dispatch(resetState());
              window.setTimeout(() => dispatch(setSuccessDialogMessage(response.data.messages[0])), 100);
              dispatch(setRequestBusy(false));
              dispatch(resetLoadsLocationsState());
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const updateCompanyLocation = (data = {}, callback = () => {}) => (dispatch, getState) => {
  if (getState().requestBusy) return;
  dispatch(refreshToken(err => {
      if (err) return dispatch(setErrorDialogMessage('Error'));
      dispatch(setRequestBusy(true));
      axios.put(
          '/api/company-locations',
          { data }
      )
      .then(response => {
          if (response.status === 200){
              const newData = {
                id: getState().companyLocations.currentSelectedCompanyLocation.id,
                ...data
              };
              dispatch(setRequestBusy(false));
              dispatch(resetState());
              dispatch(setSuccessDialogMessage(response.data.messages[0]));
              dispatch(setCurrentSelectedCompanyLocation(newData));
              dispatch(resetLoadsLocationsState());
              callback();
          } else {
              throw new Error('');
          }
      })
      .catch(error => {
          let errors = [];
          if (
              error &&
              error.response &&
              error.response.data &&
              error.response.data.messages &&
              Array.isArray(error.response.data.messages) &&
              error.response.data.messages.length
          ) {
              errors = error.response.data.messages;
          } else{
              errors = ['Error']
          }
          dispatch(setRequestBusy(false));
          const errorMessage = errors.join('.  ');
          dispatch(setErrorDialogMessage(errorMessage));
          callback(errorMessage);
      })
  
  }))
};

export const fetchCurrentSelectedCompanyLocation = id => dispatch => {
  dispatch(refreshToken(err => {
    if (err) return;
    axios.get(`/api/company-locations/${id}`)
      .then(response => {
        if (response.status === 200 && response.data.data) {
          dispatch(setCurrentSelectedCompanyLocation(response.data.data));
        } else {
          throw new Error('');
        }
      })
      .catch(() => {})
  }));
}; 

export default companyLocationsSlice.reducer;

