import React from "react";
import styled from "styled-components/macro";
import { Helmet } from "react-helmet-async";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import { Paper, Typography } from "@mui/material";

import AemLogo from '../../vendor/aem-logo.png';
import SignInComponent from "../../components/auth/SignIn";

const Brand = styled.img`
  width: 150px;
  margin-bottom: 30px;
`;

const Wrapper = styled(Paper)`
  padding: ${(props) => props.theme.spacing(6)};

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)};
  }
`;

function Login() {

  const currentLoggedUser = useSelector(state => state.currentLoggedUser);
  const navigate = useNavigate();
  if (currentLoggedUser) {
    navigate('/', { replace: true });
    return '';
  }

  return (
    <React.Fragment>
      <Brand src={AemLogo} alt="" />
      <Wrapper>
        <Helmet title="Sign In" />

        <Typography component="h1" variant="h4" align="center" gutterBottom>
        Sign in to your account to continue
        </Typography>
        

        <SignInComponent />
      </Wrapper>
    </React.Fragment>
  );
}

export default Login;
