import React from 'react';

const TruckDriverDashboard = () => {

  return (
    <div style={{ textAlign: 'center', fontStyle: 'italic' }}>No stats available...</div>
  );

};

export default TruckDriverDashboard;
