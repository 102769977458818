import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  Typography,
  CircularProgress,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import {Search as SearchIcon } from "@mui/icons-material";
import { Check } from "@mui/icons-material";
import {
  setShowFullDrawerModal,
  setFullDrawerModalType,
  setFullDrawerCloseCallback
} from '../../../redux/slices/fullDrawerModal';
import { fetchDebouncer } from '../../../utils/debouncerHelpers';

const SelectList = (props) => {

  const { 
    onChange = () => {}, 
    selectedValue, 
    openModal, 
    handleClose = () => {}, 
    title, 
    data = [],
    loading = false,
    handleLoadMore = () => {},
    addEntityData,
    showSearch = false,
    searchValue = '',
    searchValueOnChange = () => {},
    searchValueTriggerHandler = () => {},
  } = props;
  const [selectedItem, setSelectedItem] = useState(selectedValue ? selectedValue : null);
  const listElem = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    const checkIfScrollIsNearBottom = () => {
      if (listElem.current) {
        if ((listElem.current.scrollHeight - listElem.current.scrollTop - 20) <= listElem.current.clientHeight) {
          fetchDebouncer(handleLoadMore);
        }
      }
    };
    if (listElem.current) listElem.current.addEventListener('scroll', checkIfScrollIsNearBottom);
    return () => {
      if (listElem.current) listElem.current.removeEventListener('scroll', checkIfScrollIsNearBottom);
    }
  }, [handleLoadMore]);

  const handleAddEntity = ({ type, closeCallback, beforeEnterTrigger }) => {
    dispatch(setShowFullDrawerModal(true));
    dispatch(setFullDrawerModalType(type));
    if (closeCallback) dispatch(setFullDrawerCloseCallback(closeCallback));
    if (beforeEnterTrigger) beforeEnterTrigger();
  }

  return (
    <Dialog
      onClose={handleClose}
      open={openModal}
    >
      <DialogTitle style={{paddingLeft: 16}}>{title}</DialogTitle>
      {
        showSearch &&
        <div style={{ paddingLeft: 15, paddingRight: 15 }}>
          <TextField
            label="Search..."
            value={searchValue}
            fullWidth
            onChange={(e) => searchValueOnChange(e?.target?.value)}
            variant="outlined"
            size="small"
            disabled={loading}
            onKeyDown={(e) => {
              if (e?.keyCode === 13) searchValueTriggerHandler();
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => searchValueTriggerHandler()}
                    edge="end"
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
        </div>
      }
      <List 
        style={{ width: 600, height: 350, overflowX: 'scroll' }}
        ref={listElem}
      > 
        {
          loading &&
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <CircularProgress color="primary" size={15} />
          </div>
        }
        {
          !loading &&
          !data.length
          ?
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Typography variant="p">No results...</Typography>
            </div>
          :
            data.map((item, index) => (
              <ListItem
                button
                onClick={() => setSelectedItem(item.id)}
                key={item.id}
                style={{ borderBottom: index + 1 !== data?.length ? '1px solid #ccc' : 'none' }}
              >
                <ListItemText primary={item.item} />
                {
                  item.id === selectedItem ?
                    <Check />
                  :
                    ''
                }
              </ListItem>
            ))
        }
      </List>
      <div style={{ display: 'flex', justifyContent: 'space-between', padding: 10 }}>
        <div>
          {
            addEntityData?.type && 
            addEntityData?.label && 
            <Button 
              variant="text" 
              color="primary"
              onClick={() => handleAddEntity(addEntityData)}
            >
              {addEntityData.label}
            </Button>
          }
        </div>
        <div>
          <Button 
            color="primary"
            onClick={handleClose}
            style={{ marginRight: 10 }}
          >
            Cancel
          </Button>
          <Button 
            color="primary" 
            variant="contained" 
            type="submit" 
            disabled={selectedItem === null ? true : false}
            onClick={() => {
              onChange(selectedItem);
              handleClose();
            }} 
          >
              Save
          </Button>
        </div>
      </div>
    </Dialog>
  );
}

export default SelectList;
