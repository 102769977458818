import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCloseDrawer } from '../../../redux/slices/fullDrawerModal';
import fullDrawerModalTypeKeys from '../../../keys/fullDrawerModalTypeKeys';

import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Slide,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullDrawerModal = ({ open }) => {

  const dispatch = useDispatch();
  const theme = useTheme();

  const { type, closeCallback } = useSelector(state => state.fullDrawerModal);

  const handleClose = () => {
    if (closeCallback) closeCallback();
    dispatch(setCloseDrawer());
  };

  const keyData = fullDrawerModalTypeKeys?.[type] ?? null;

  if (!keyData) return null;

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          backgroundColor: theme?.palette?.background?.default ?? '#fff'
        },
      }}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar style={{ marginLeft: 10 }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ flex: 1 }} style={{ marginLeft: 10 }} variant="h6" component="div">
            {keyData?.display}
          </Typography>
        </Toolbar>
      </AppBar>
      <div style={{ padding: 30 }}>
        {keyData?.component}
      </div>
    </Dialog>
  );
};

export default FullDrawerModal;
