export default {
  hst: {
    key: 'hst', 
    display: 'Hawaii Standard Time (UTC -10)'
  },
  hdt: {
    key: 'hdt',
    display: 'Hawaii-Aleutian Daylight Time (UTC -9)'
  },
  akdt: {
    key: 'akdt', 
    display: 'Alaska Daylight Time (UTC -8)'
  },
  pdt: {
    key: 'pdt', 
    display: 'Pacific Daylight Time (UTC -7)'
  },
  mdt: {
    key: 'mdt', 
    display: 'Mountain Daylight Time (UTC -6)'
  },
  cdt: {
    key: 'cdt', 
    display: 'Central Daylight Time (UTC -5)'
  },
  edt: {
    key: 'edt', 
    display: 'Eastern Daylight Time (UTC -4)'
  }
};
