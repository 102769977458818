export default {
  truck: {
    key: 'truck',
    display: 'Truck'
  },
  trailer: {
    key: 'trailer',
    display: 'Trailer'
  }
};
