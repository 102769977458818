import React, { useEffect, useRef, useState, useMemo } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import { fetchNotifications } from "../../redux/slices/notifications";
import { fetchDebouncer } from '../../utils/debouncerHelpers';

import {
  Badge,
  IconButton,
  List,
  Popover as MuiPopover,
  Tooltip,
  CircularProgress
} from "@mui/material";
import { Bell } from "react-feather";
import NavbarNotification from './NavbarNotification'

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

const NavbarNotificationsDropdown = () => {
  const ref = useRef(null);
  const listRef = useRef(null);
  const lastTimeRefreshed = useRef(null);
  const dispatch = useDispatch();
  const notifications = useSelector(state => state.notifications);
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchNotifications());
    const int = window.setInterval(() => dispatch(fetchNotifications({ refresh: true })), 60000);
    return () => {
      if (int) window.clearInterval(int);
    };
  }, []);

  const handleOpen = () => {
    setOpen(true);
    if (!lastTimeRefreshed.current || (lastTimeRefreshed.current && (Date.now() - lastTimeRefreshed.current) > 60000)) {
      dispatch(fetchNotifications({ refresh: true }));
      lastTimeRefreshed.current = Date.now();
    }
  }

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const checkIfScrollIsNearBottom = () => {
      if (listRef.current) {
        if ((listRef.current.scrollHeight - listRef.current.scrollTop - 20) <= listRef.current.clientHeight) {
          fetchDebouncer(() => dispatch(fetchNotifications()));
        }
      }
    };
    window.setTimeout(() => {
      if (listRef.current && isOpen) listRef.current.addEventListener('scroll', checkIfScrollIsNearBottom);
    }, 0);
    return () => {
      if (listRef.current) listRef.current.removeEventListener('scroll', checkIfScrollIsNearBottom);
    }
  }, [isOpen]);
  
  const badgeContent = useMemo(() => notifications.data.reduce((acc, notification) => acc + (notification?.seenAt ? 0 : 1), 0), [notifications.data]);

  return (
    <React.Fragment>
      <Tooltip title="Notifications">
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={badgeContent < 10 ? badgeContent : '9+'}>
            <Bell />
          </Indicator>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <List 
          disablePadding
          style={{ 
            height: 350,
            overflowY: 'scroll',
          }}
          ref={listRef}
        >
          {
            notifications?.showTopSpinner &&
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20, width: '100%' }}>
              <CircularProgress color="primary" size={20}  />
            </div>
          }
          {
            notifications?.initialFetch && 
            notifications?.data?.length === 0 &&
            <div style={{ textAlign: 'center', marginTop: 20, marginBottom: 20, fontStyle: 'italic' }}>
              No notifications...
            </div>
          }
          {
            notifications?.data.map(notification => (
              <NavbarNotification 
                notification={notification} 
                handleClose={handleClose}
              />
            ))
          }
          {
            notifications?.showBottomSpinner &&
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20, marginBottom: 20, width: '100%' }}>
              <CircularProgress color="primary" size={20} />
            </div>
          }
        </List>
      </Popover>
    </React.Fragment>
  );
}

export default NavbarNotificationsDropdown;
