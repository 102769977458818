import React, { useRef, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  List,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody
} from "@mui/material";
import { fetchDebouncer } from '../../../utils/debouncerHelpers';

const ModalTableList = (props) => {

  const { 
    openModal, 
    handleClose = () => {}, 
    title, 
    columns = [],
    data = [],
    loading = false,
    handleLoadMore = () => {},
  } = props;
  const listElem = useRef();

  useEffect(() => {
    const checkIfScrollIsNearBottom = () => {
      if (listElem.current) {
        if ((listElem.current.scrollHeight - listElem.current.scrollTop - 20) <= listElem.current.clientHeight) {
          fetchDebouncer(handleLoadMore);
        }
      }
    };
    if (listElem.current) listElem.current.addEventListener('scroll', checkIfScrollIsNearBottom);
    return () => {
      if (listElem.current) listElem.current.removeEventListener('scroll', checkIfScrollIsNearBottom);
    }
  }, [handleLoadMore]);

  return (
    <Dialog
      onClose={handleClose}
      open={openModal}
    >
      <DialogTitle style={{paddingLeft: 16}}>{title}</DialogTitle>
      <List 
        style={{ width: 600, height: 350, overflowX: 'scroll' }}
        ref={listElem}
      > 
        {
          loading &&
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
            <CircularProgress color="primary" size={15} />
          </div>
        }
        {
          !loading &&
          !data.length
          ?
            <div style={{ textAlign: 'center', marginTop: 20 }}>
              <Typography variant="p">No results...</Typography>
            </div>
          :
            <TableContainer>
              <Table size={"small"}>
                <TableHead>
                  <TableRow>
                    {columns.map((name, index) => (
                      <TableCell
                        key={`${name}_${index}`}
                        align="left"
                      >
                        {name}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    data.map((item, index) => (
                      <TableRow key={`${item}_${item?.id ?? index}`}>
                        {
                          item?.item?.map(column => (
                            <TableCell align="left">{column}</TableCell>
                          ))
                        }
                      </TableRow>
                    ))
                  }
                </TableBody>
              </Table>
            </TableContainer> 
        }
      </List>
      <DialogActions>
        <Button 
          color="primary"
          type="text"
          onClick={handleClose}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ModalTableList;
