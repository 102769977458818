let fetchDebouncerAllow = true;
let typeTimeoutId;

export const fetchDebouncer = (func = () => {}) => {
  if (fetchDebouncerAllow) {
    fetchDebouncerAllow = false;
    setTimeout(() => (fetchDebouncerAllow = true), 500);
    func();
  }
}

export const typeFetchDebouncer = (func = () => {}) => {
  if (typeTimeoutId) clearTimeout(typeTimeoutId);
  typeTimeoutId = setTimeout(func, 500);
}
