import loadStopTypes from '../keys/loadStopTypes';
import driverPaymentTypes from '../keys/driverPaymentTypes';
import getUSDFormattedCurrency from './getUSDFormattedCurrency';

export const getRawLocationData = (stop = {}) => {
  return stop?.companyLocation;
};

export const getLatLng = (stop = {}) => {

  const loc = {
    lat: parseFloat(stop?.companyLocation?.addressLat),
    lng: parseFloat(stop?.companyLocation?.addressLng)
  };

  return loc;

};

export const getCustomerAndPickupIndex = (loadStopsData, typeKey) => {
  let index = null;
  if (loadStopsData?.length) {
    loadStopsData.forEach((load, loopIndex) => {
      if (load?.type === typeKey) index = loopIndex;
    });
  }
  return index;
};

export const getAdditionalStopsIndexes = (loadStopsData) => {
  const indexes = [];
  if (loadStopsData?.length) {
    loadStopsData.forEach((load, loopIndex) => {
      if (load?.type === loadStopTypes.stop.key) indexes.push(loopIndex);
    });
  }
  return indexes;
};

export const convertKmToMiles = (value = 0) => {
  return (parseFloat(value) * 0.62137119).toFixed(2);
};

export const getTotalMiles = (loadStops = []) => {
  let number = 0;
  if (loadStops.length) {
    loadStops.forEach(item => {
      const a = convertKmToMiles(item?.legDistance && item.legDistance !== '' ? item.legDistance : 0);
      if (a > 0) number = parseFloat(a) + number
    });
  }
  return number.toFixed(2);
};

export const getDriversPaymentTypePrice = (params = {}, raw = false) => {
  const {
    loadStops,
    tripAmount = 0,
    currentAssignedDriver
  } = params;

  const driversData = {};
  /*
    id: {
      driverData: <object>,
      totalDistance: <number>,
      description: <string>,
      cost: <number>
    }
  */

  if (currentAssignedDriver) driversData[currentAssignedDriver.id] = {
    driverData: currentAssignedDriver
  };

  loadStops.forEach(stop => {
    if (stop?.driverDelivered) {
      const legDistance = stop?.legDistance ?? 0;
      if (driversData[stop.driverDelivered.id]) {
        if (driversData[stop.driverDelivered.id].totalDistance) {
          const d = parseFloat(legDistance);
          if (d > 0) driversData[stop.driverDelivered.id].totalDistance = d + driversData[stop.driverDelivered.id].totalDistance;
        } else {
          driversData[stop.driverDelivered.id].totalDistance = parseFloat(legDistance);
        }
      } else {
        driversData[stop.driverDelivered.id] = {
          driverData: stop.driverDelivered,
          totalDistance: parseFloat(legDistance)
        }
      }
    }
  });

  Object.keys(driversData).forEach(id => {
    const paymentType = driversData[id]?.driverData?.accountingPaymentType?.paymentType;
    const paymentValue = driversData[id]?.driverData?.accountingPaymentType?.value;
    const totalMiles = parseFloat(convertKmToMiles(driversData[id]?.totalDistance ?? 0));
    if (paymentType === driverPaymentTypes.perMile.key) {
      const perMileDollars = parseFloat(paymentValue ? paymentValue : 0);
      driversData[id].description = `Per mile ($${perMileDollars}) = ${getUSDFormattedCurrency(perMileDollars * totalMiles)}`;
      driversData[id].cost = perMileDollars * totalMiles;
    } else if (paymentType === driverPaymentTypes.percent.key) {
      const getPercentage = (num, perc) => ((num/100) * perc);
      driversData[id].cost = getPercentage(parseFloat(tripAmount), parseFloat(paymentValue));
      driversData[id].description = `Per percent (${paymentValue}%) = ${getUSDFormattedCurrency(getPercentage(parseFloat(tripAmount), parseFloat(paymentValue)))}`;
    }
  });

  return driversData;

};

export const getLoadStopsMetadata = (loadStopsData) => {
  const returnData = {
    customerData: null,
    customerIndex: null,
    pickupData: null,
    pickupIndex: null,
    additionalStopsIndexes: []
  };

  if (loadStopsData && Array.isArray(loadStopsData) && loadStopsData.length) {

    returnData.customerIndex = getCustomerAndPickupIndex(loadStopsData, loadStopTypes.customer.key);
    returnData.customerData = returnData.customerIndex !== null && loadStopsData?.length && loadStopsData[returnData.customerIndex] ? loadStopsData[returnData.customerIndex] : null;

    returnData.pickupIndex = getCustomerAndPickupIndex(loadStopsData, loadStopTypes.location.key);
    returnData.pickupData = returnData.pickupIndex !== null && loadStopsData?.length && loadStopsData[returnData.pickupIndex] ? loadStopsData[returnData.pickupIndex] : null;

    returnData.additionalStopsIndexes = getAdditionalStopsIndexes(loadStopsData);

  } 

  return returnData;

};
