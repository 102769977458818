export default {
  companyDriver: {
    key: 'companyDriver',
    display: 'Company Driver'
  },
  ownerOperator: {
    key: 'ownerOperator',
    display: 'Owner Operator'
  }
};
