import React, { useEffect } from "react";
import styled from "styled-components/macro";
import { 
  NavLink,
  useParams,
  useNavigate
} from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { 
  useDispatch,
  useSelector
} from 'react-redux';
import { fetchCurrentSelectedCompanyLocation } from '../../redux/slices/companyLocations';

import {
  Grid,
  Link,
  Button as MuiButton,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography as MuiTypography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { spacing, display} from "@mui/system";

const Card = styled(MuiCard)`
  ${spacing};

  box-shadow: none;
`;
const Divider = styled(MuiDivider)(spacing);

const Shadow = styled.div`
  box-shadow: ${(props) => props.theme.shadows[1]};
`;

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Button = styled(MuiButton)(spacing);

const Typography = styled(MuiTypography)(display);

function CompanyLocationDetails() {
  const navigate = useNavigate();
  const currentSelectedCompanyLocation = useSelector(state => state.companyLocations.currentSelectedCompanyLocation);
  const historyStack = useSelector(state => state.historyStack);
  const params = useParams();
  const dispatch = useDispatch();

  const companyLocationId = params && params.companyLocationId ? params.companyLocationId : null;

  useEffect(() => {
    if (companyLocationId) dispatch(fetchCurrentSelectedCompanyLocation(companyLocationId));
  }, [companyLocationId]);

  let contacts = [];
  try {
    if (currentSelectedCompanyLocation && currentSelectedCompanyLocation.contacts) {
      if (typeof currentSelectedCompanyLocation.contacts === 'string') {
        contacts = JSON.parse(currentSelectedCompanyLocation.contacts);
      } else if (Array.isArray(currentSelectedCompanyLocation.contacts)) {
        contacts = currentSelectedCompanyLocation.contacts;
      }
    }
  } catch (e) {}

  return (
    <React.Fragment>
      <Helmet title="Company Location Details" />

      <Grid justifyContent="space-between" container spacing={10}>
        <Grid item>
          <Typography variant="h3" gutterBottom display="inline">
            Company Location Details
          </Typography>
          <Breadcrumbs aria-label="Breadcrumb" mt={2}>
            <Link 
              style={{ cursor: 'pointer' }}
              onClick={() => {
                if (historyStack[historyStack.length - 2]?.indexOf('company-locations') === -1) {
                  navigate('/company-locations', { replace: true });
                } else {
                  navigate(-1);
                }
              }}
            >
              Company Locations
            </Link>
            
            <Typography>Company Location Details</Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item></Grid>
      </Grid>

      <Divider my={6} />

      <Grid container justifyContent="center">
        <Grid item xs={12} lg={10}>
          <Shadow>
            <Card px={6}>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell>ID:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyLocation && currentSelectedCompanyLocation.id ? currentSelectedCompanyLocation.id : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Company Name:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyLocation && currentSelectedCompanyLocation.companyName ? currentSelectedCompanyLocation.companyName : ''}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Address:</TableCell>
                    <TableCell></TableCell>
                    <TableCell align="center">{currentSelectedCompanyLocation && currentSelectedCompanyLocation.addressGeoData && currentSelectedCompanyLocation.addressGeoData.formatted_address ? currentSelectedCompanyLocation.addressGeoData.formatted_address : ''}</TableCell>
                  </TableRow>
                  {
                    currentSelectedCompanyLocation && 
                    currentSelectedCompanyLocation.commodityType
                    ?
                    <TableRow>
                      <TableCell>Commodity Type:</TableCell>
                      <TableCell></TableCell>
                      <TableCell align="center">{currentSelectedCompanyLocation.commodityType}</TableCell>
                    </TableRow>
                    :
                      ''
                  }
                </TableBody>
              </Table>
              {
                contacts &&
                contacts.length > 0 &&
                <div style={{ marginTop: 15 }}>
                  <Typography variant="h6" gutterBottom display="inline">
                    Contact(s)
                  </Typography>
                  {
                    contacts.map(contact => (
                      <div key={contact.id} style={{ border: '1px solid #eee', borderRadius: 5, padding: 20, marginBottom: 15, marginTop: 15 }}>
                        <Typography mt={2}>
                          First Name: <span style={{ fontWeight: 'bold' }}>{contact.firstName}</span>
                        </Typography>
                        <Typography mt={2}>
                          Last Name: <span style={{ fontWeight: 'bold' }}>{contact.lastName}</span>
                        </Typography>
                        <Typography mt={2}>
                          Role: <span style={{ fontWeight: 'bold' }}>{contact.role}</span>
                        </Typography>
                        <Typography mt={2}>
                          Email: <span style={{ fontWeight: 'bold' }}>{contact.email}</span>
                        </Typography>
                        <Typography mt={2}>
                          Phone Number: <span style={{ fontWeight: 'bold' }}>{contact.phoneNumber} {contact.phoneNumberExtension ? `Ext: ${contact.phoneNumberExtension}` : ''}</span>
                        </Typography>
                      </div>
                    ))
                  }
                </div>
              }
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <Button 
                  onClick={() => {
                    navigate(`/company-locations/edit/${currentSelectedCompanyLocation && currentSelectedCompanyLocation.id ? currentSelectedCompanyLocation.id : ''}`);
                  }}
                  marginBottom={5}
                  type="submit"
                  variant="contained"
                  color="primary"
                  mt={3}
                  >
                    <Icon.Edit />
                  <span style={{marginLeft:5}}> Edit </span>
                </Button>
              </div>
            </Card>
          </Shadow>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default CompanyLocationDetails;