import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { 
  useDispatch,
  useSelector 
} from "react-redux";
import { attemptLogin } from "../../redux/slices/currentLoggedUser";

import {
  Button,
  TextField as MuiTextField,
} from "@mui/material";
import { spacing } from "@mui/system";

const TextField = styled(MuiTextField)(spacing);

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentLoggedUser = useSelector(state => state.currentLoggedUser);

  if (currentLoggedUser) {
    navigate('/');
    return <></>;
  }

  return (
    <Formik
      initialValues={{
        email: "",
        password: "",
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        email: Yup.string()
          .email("Must be a valid email")
          .max(250)
          .required("Email is required"),
        password: Yup.string().max(250).required("Password is required"),
      })}
      onSubmit={async (values, { setSubmitting, setFieldValue  }) => {
        try {
          dispatch(attemptLogin(values.email, values.password, () => {
            setFieldValue('password', '');
            setSubmitting(false);
          }));
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          <TextField
            type="email"
            name="email"
            label="Email Address"
            value={values.email}
            error={Boolean(touched.email && errors.email)}
            fullWidth
            helperText={touched.email && errors.email}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <TextField
            type="password"
            name="password"
            label="Password"
            value={values.password}
            error={Boolean(touched.password && errors.password)}
            fullWidth
            helperText={touched.password && errors.password}
            onBlur={handleBlur}
            onChange={handleChange}
            my={2}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={isSubmitting}
          >
            Sign in
          </Button>
          <Button onClick={() => navigate('/reset-password')}
            component={Link}
            to="/reset-password"
            fullWidth
            color="primary"
          >
            Forgot password
          </Button>
        </form>
      )}
    </Formik>
  );
}

export default SignIn;
